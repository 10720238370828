const AddIcon = ({
    logo,
    setLogo,
    toast,
    logoPreview,
    setLogoPreview,
    submitClicked,
    desiredRatio,
    text,
    info,
    labelText,
    setShowDiscard,
    disable=false
  }) => {
    const handleImageUpload = (files) => {
      const imageFile = files[0];
      let imgSize = 500000;

      if(imageFile && imageFile.size > imgSize){
        toast({
          title: "Size should not exceed 5 Mb. ",
          // description: `Please upload an image with ratio 2 : 2`,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        setLogo(null);
        setLogoPreview(null);
        setShowDiscard(true);
        //return;
      }
      else if (imageFile) {
        const image = new Image();
        image.src = URL.createObjectURL(imageFile);
  
        image.onload = () => {
          // const ratio = info === "1" || info == "3" ? 6 / 1 : 2 / 2; // 6:1 ratio

          console.log(image);
          const currentRatio = image.width / image.height;
          //console.log(ratio === currentRatio);
          //if (currentRatio === 1) {
            setLogo(imageFile);
            setLogoPreview(URL.createObjectURL(imageFile));
            setShowDiscard(true);
          // } else {
          //   toast({
          //     title: "Invalid Image Dimensions",
          //     description: `Please upload an image with ratio 2 : 2`,
          //     status: "error",
          //     duration: 4000,
          //     isClosable: true,
          //     position: "top",
          //   });
          //   setLogo(null);
          //   setLogoPreview(null);
          //   setShowDiscard(true);
          // }
        };
      }
    };
  
    return (
      <div className="mt-5">
        <p className="text-[#3E3C42] text-lg font-medium">
          {labelText}
          <p className="text-[#AEA9B1]">( {text} )</p>
        </p>
        <div className="flex items-center gap-2">
          <div
            className={`w-[140px] rounded-md text-[white] border bg-[#6CA6FC] h-8 mt-6`}
          >
            <label htmlFor={`${info}-image-uploader`} className="cursor-pointer">
              <div className="flex justify-evenly py-1">
                <img src="/superadmin/upload.png" alt="upload" />
                <p>{"Upload icon"}</p>
              </div>
            </label>
            <input
              type="file"
              accept="image/*"
              name={`${info}-image-uploader`}
              id={`${info}-image-uploader`}
              style={{ display: "none" }}
              onChange={(e) => handleImageUpload(e.target.files)}
              disabled={disable}
            />
          </div>
          {/* {submitClicked && !logo && (
            <div className="text-red-500 text-sm mt-1">
              Upload image in the above mentioned format
            </div>
          )} */}
          {logoPreview && (
            <div className=" flex items-center w-[50px] h-[50px] mt-6">
              <img
                src={logoPreview}
                alt="Logo Preview"
                className="w-[40px] h-[40px]"
                //style={{ width: "180px", height: "30px", marginTop: "10px" }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  
  export default AddIcon;
  