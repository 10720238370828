import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  ThemeProvider,
  createTheme,
  Select as MuiSelect,
} from "@mui/material";

const CustomSelector = ({ formData, selectData, handleLocationChange }) => {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <MuiSelect
        size={"small"}
        multiple
        fullWidth
        value={formData.allLocations}
        onChange={handleLocationChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected?.length === selectData?.allLocations?.length)
            return "All locations";
          return selected?.map((item) => item?.locationName).join(", ");
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "300px",
              width: 250,
            },
          },
        }}
      >
        <MenuItem key={"All locations"} value={"all"} sx={{ padding: "0px" }}>
          <Checkbox
            checked={
              selectData?.allLocations?.length ===
              formData?.allLocations?.length
            }
            sx={{
              color: "#6CA6FC",
              "&.Mui-checked": {
                color: "#6CA6FC",
              },
            }}
          />
          <ListItemText primary={"All locations"} />
        </MenuItem>
        {selectData?.allLocations?.map((item, idx) => (
          <MenuItem key={idx} value={item} sx={{ padding: "0px" }}>
            <Checkbox
              checked={
                formData?.allLocations?.findIndex(
                  (val) => val?.locationId === item?.locationId
                ) > -1
              }
              sx={{
                color: "#6CA6FC",
                "&.Mui-checked": {
                  color: "#6CA6FC",
                },
              }}
            />
            <ListItemText primary={item?.locationName} />
          </MenuItem>
        ))}
      </MuiSelect>
    </ThemeProvider>
  );
};

export default CustomSelector;
