import TextButton from "../../../../util/Buttons/TextButton";
import { FieldLabel } from "../../Common/CommonComponents";
import { CalAnnotations } from "../../utils/HandlerFuncs";
import AddIcon from "@mui/icons-material/Add";

const AnnotationCount = ({
  labels = [],
  annotatedImages = [],
  annotationType = "",
  handleAssign = () => {},
  selectedImages = [],
}) => {
  const cols = ["No. Files", "No. Annotations"];
  return (
    <div className="flex flex-col gap-3 w-full">
      <FieldLabel
        text="Annotation details"
        color="#605D64"
        size="16px"
        weight={500}
        isRequired={false}
      />
      <div className="flex flex-wrap gap-4 w-full">
        <div
          className="flex flex-col gap-0 rounded-lg border border-[#EBEBEB] divide-y-[1px]"
          style={{
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <div className="bg-[#FFFFED] content-center rounded-t-lg p-2 text-xs font-medium text-[#605D64] h-12">
            Total Annotations
          </div>
          <div className="flex p-2 flex-col gap-2 divide-y-[1px] bg-[#FAFAFA] rounded-b-lg">
            <div className="flex gap-4 items-center">
              {cols?.map((item, idx) => (
                <p key={idx} className="text-xs text-[#79767D]">
                  {item}
                </p>
              ))}
            </div>
            <div className="flex gap-4 items-center w-full">
              <p className="text-sm flex-1 font-medium text-[#605D64]">
                {annotatedImages.length}
              </p>
              <p className="text-sm flex-1 font-medium text-[#605D64]">
                {CalAnnotations({
                  label: "all",
                  annotatedImages: annotatedImages,
                  annotationType: annotationType,
                  total: true,
                })}
              </p>
            </div>
          </div>
        </div>
        {labels?.map((item, idx) => {
          return (
            <div
              className="flex flex-col gap-0 rounded-lg border border-[#EBEBEB] divide-y-[1px]"
              key={idx}
              style={{
                boxShadow:
                  "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
              }}
            >
              <div
                className="rounded-t-lg p-2 flex gap-2 justify-between items-center h-12"
                style={{
                  backgroundColor:
                    selectedImages?.length > 0 ? "#EEF7FF" : "#F5F5F5",
                }}
              >
                <p className="text-xs font-medium text-[#605D64]">{item}</p>
                {selectedImages?.length > 0 && (
                  <TextButton
                    Icon={
                      <AddIcon
                        sx={{
                          fontSize: "20px",
                        }}
                      />
                    }
                    text={"Assign"}
                    width={"fit-content"}
                    onClick={() => handleAssign(item)}
                  />
                )}
              </div>
              <div className="flex p-2 flex-col gap-2 divide-y-[1px] bg-[#FAFAFA] rounded-b-lg">
                <div className="flex gap-4 items-center">
                  {cols?.map((item, idx) => (
                    <p key={idx} className="text-xs text-[#79767D]">
                      {item}
                    </p>
                  ))}
                </div>
                <div className="flex gap-4 items-center w-full">
                  <p className="text-sm flex-1 font-medium text-[#605D64]">
                    {CalAnnotations({
                      label: item,
                      annotatedImages: annotatedImages,
                      annotationType: annotationType,
                    })}
                  </p>
                  <p className="text-sm flex-1 font-medium text-[#605D64]">
                    {CalAnnotations({
                      label: item,
                      annotatedImages: annotatedImages,
                      annotationType: annotationType,
                      total: true,
                    })}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnnotationCount;
