import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { Auth0Provider } from "@auth0/auth0-react";
import { mixpanelInit } from "./util/mixpanelFunctions";

export const baseURL = process.env.REACT_APP_BASE_URL; //Dev backend URL
export const isOnPrem = process.env.REACT_APP_IS_ON_PREM ?? false;
export const RTSPBaseUrl = process.env.REACT_APP_RTSP_BASE_URL;
export const bypassRipikAdmin =
  process.env.REACT_APP_BYPASS_RIPIK_ADMIN === "true" ? true : false;

const root = ReactDOM.createRoot(document.getElementById("root"));

mixpanelInit();

LicenseInfo.setLicenseKey(
  "3b928bad6724fb65f90855e693f22e8eTz05NTEwMSxFPTE3NTM4NjQwNTgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const providerConfig = {
  domain: process.env.REACT_APP_AD_DOMAIN,
  clientId: process.env.REACT_APP_AD_CLIENT,
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
};

root.render(
  <Auth0Provider {...providerConfig}>
    <BrowserRouter>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </Auth0Provider>
);

reportWebVitals();
