import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";

const MuiTheme = createTheme();

const SessionLogsTable = ({ clientOrg, rowData }) => {
  const columns = [
    {
      field: "logId",
      headerName: "user id",
    },
    {
      field: "loginTime",
      headerName: "date time",
      valueFormatter: (params) => {
        return params.value
          ? new Date(params.value).toDateString().split(" ")[2] +
              " " +
              new Date(params.value).toDateString().split(" ")[1] +
              " '" +
              new Date(params.value).toDateString().split(" ")[3].slice(2, 4) +
              " " +
              new Date(params.value).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
          : "";
      },
    },
    {
      field: "email",
      headerName: "email",
    },
    {
      field: "sessionDuration",
      headerName: "session duration",
      valueGetter: (params) => {
        const sessionDurationInMillis = params.row.sessionDuration;
        const hours = Math.floor(sessionDurationInMillis / (1000 * 60 * 60));
        const minutes = Math.floor(
          (sessionDurationInMillis % (1000 * 60 * 60)) / (1000 * 60)
        );

        return `${hours} hrs ${minutes} min`;
      },
    },
    {
      field: "ipAddress",
      headerName: "ip address",
    },
    {
      field: "device",
      headerName: "device",
      renderCell: (params) => {
        return <p className="py-2">{params?.row?.device}</p>;
      },
    },
    {
      field: "location",
      headerName: "location",
    },
  ];
  const headerClass =
    "text-sm font-normal text-[#79767D] bg-[#DDEEFF] capitalize";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-normal";
  const flexMap = clientOrg
    ? [0, 1, 2, 1, 1, 3, 1.5]
    : [0, 1.5, 2, 1.5, 2, 3, 1];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });
  return (
    <div className="mt-2 overflow-auto">
      <CustomStyledDataGrid
        rows={rowData}
        columns={columns}
        getRowId={(row) => row.logId}
        getRowHeight={() => {
          return "auto";
        }}
        columnVisibilityModel={{
          logId: false,
        }}
        pagination
        initialState={{
          sorting: {
            sortModel: [{ field: "loginTime", sort: "desc" }],
          },
          pagination: { paginationModel: { pageSize: 6 } },
        }}
        // hideFooter={true}
        sx={{ minWidth: "1000px", margin: 0, padding: 0 }}
      />
    </div>
  );
};

export default SessionLogsTable;
