import mixpanel from "mixpanel-browser";

const mixpanelToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN ?? null;
//Super property set
export const mixpanelSetSandboxUser = () => {
  if (mixpanelToken) {
    mixpanel.register_once({
      source: "AI Sandbox",
    });
  }
};

//page viewed event added - RouterHOC.jsx, LandingPage.jsx
export const mixpanelPageViewedTrack = ({
  pageName = "",
  pageUrl = "",
  prevPageUrl = "",
}) => {
  if (mixpanelToken) {
    mixpanel.track("Page viewed", {
      pageName: pageName,
      pageUrl: pageUrl,
      prevPageUrl: prevPageUrl,
    });
  }
};

//new initiated event added - RouterHOC.jsx
export const mixpanelNewInitiatedTrack = ({ type = "" }) => {
  if (mixpanelToken) {
    mixpanel.track(`Create new ${type} initiated`);
  }
};

//Rename event added - CommonRenameModal.jsx
export const mixpanelRenameTrack = ({
  oldName = "",
  newName = "",
  id = "",
  type = "",
}) => {
  if (mixpanelToken) {
    mixpanel.track(`${type} rename`, {
      oldName: oldName,
      newName: newName,
      [type + "Id"]: id,
    });
  }
};

//Delete event added - projectHome, dataHome, modelHome, labelView, repoView, appHome
export const mixpanelDeleteTrack = ({ name = "", type = "", id = "" }) => {
  if (mixpanelToken) {
    mixpanel.track(`${type} deleted`, {
      [type + "Name"]: name,
      [type + "Id"]: id,
    });
  }
};

//View event added - RouterHOC.jsx
export const mixpanelViewTrack = ({ type = "", name = "", id = "" }) => {
  if (mixpanelToken) {
    mixpanel.track(`${type} viewed`, {
      [type + "Name"]: name,
      [type + "Id"]: id,
    });
  }
};

//Api fail event added - ApiUtil.js
export const mixpanelApiFailTrack = ({
  errorMessage = "",
  endPoint = "",
  errorCode = "",
  type = "",
  param = {},
  requestData = {},
  isFormData = false,
  pageLocation = "",
}) => {
  if (mixpanelToken) {
    mixpanel.track(`Api error`, {
      errorMessage: errorMessage,
      endPoint: endPoint,
      errorCode: errorCode,
      type: type,
      param: param,
      requestData: requestData,
      isFormData: isFormData,
      pageLocation: pageLocation,
    });
  }
};

//model mixpanel funcs added - modelhomeview.jsx, retrainmodal.jsx
export const mixpanelModelRetrainClickTrack = ({
  modelName = "",
  modelId = "",
}) => {
  if (mixpanelToken) {
    mixpanel.track("Model retrain clicked", {
      modelName: modelName,
      modelId: modelId,
    });
  }
};

export const mixpanelModelRetrainSubmitTrack = ({
  modelName = "",
  modelId = "",
  newModelName = "",
  newModelId = "",
}) => {
  if (mixpanelToken) {
    mixpanel.track("Model retrain submit", {
      modelName: modelName,
      modelId: modelId,
      newModelName: newModelName,
      newModelId: newModelId,
    });
  }
};

export const mixpanelModelInferTrack = ({
  modelName = "",
  modelId = "",
  oldRepoId = "",
  newRepoId = "",
  newRepoName = "",
  inferenceImages = 0,
}) => {
  if (mixpanelToken) {
    mixpanel.track(`Model infered`, {
      modelName: modelName,
      oldRepoId: oldRepoId,
      newRepoId: newRepoId,
      newRepoName: newRepoName,
      modelId: modelId,
      inferenceImages: inferenceImages,
    });
  }
};

export const mixpanelModelCloneTrack = ({
  modelName = "",
  modelId = "",
  clonedModelName = "",
  clonedModelId = "",
}) => {
  if (mixpanelToken) {
    mixpanel.track(`Model cloned`, {
      modelName: modelName,
      modelId: modelId,
      clonedModelName: clonedModelName,
      clonedModelId: clonedModelId,
    });
  }
};

//data repo funcs added - addannotation.jsx, adddata.jsx
export const mixpanelDataRepoCreateTrack = ({
  repoName = "",
  repoId = "",
  repoType = "",
  rtspLink = "",
  fileCount = 0,
}) => {
  if (mixpanelToken) {
    mixpanel.track("Data repo created", {
      repoName: repoName,
      repoId: repoId,
      repoType: repoType,
      rtspLink: rtspLink,
      fileCount: fileCount,
    });
  }
};

export const mixpanelLabelCreateUpdateTrack = ({
  repoName = "",
  repoId = "",
  labelType = "",
  labelNames = [],
  annotatedFileCounts = 0,
  type = "",
}) => {
  if (mixpanelToken) {
    mixpanel.track(`Label ${type}`, {
      repoName: repoName,
      repoId: repoId,
      labelType: labelType,
      labelNames: labelNames,
      annotatedFileCounts: annotatedFileCounts,
    });
  }
};

//app funcs
export const mixpanelAppCreateUpdateTrack = ({
  appName = "",
  appId = "",
  modelName = "",
  modelId = "",
  dataStreamName = "",
  dataStreamLink = "",
  numberOfCameras = 0,
  labels = [],
  isUpdate = false,
}) => {
  if (mixpanelToken) {
    mixpanel.track(`${isUpdate ? "Cam added" : "App created"}`, {
      appName: appName,
      appId: appId,
      modelName: modelName,
      modelId: modelId,
      dataStreamName: dataStreamName,
      dataStreamLink: dataStreamLink,
      labels: labels,
      numberOfCameras: numberOfCameras,
    });
  }
};

export const mixpanelAppEnableDisableTrack = ({
  name = "",
  id = "",
  method = "",
}) => {
  if (mixpanelToken) {
    mixpanel.track(`App ${method}`, {
      appName: name,
      appId: id,
    });
  }
};

export const mixpanelAlertAddUpdateTrack = ({
  alertName = "",
  alertId = "",
  appId = "",
  appName = "",
  alertCondition = "",
  cameraName = "",
  cameraId = "",
  method = "add",
  olderConditon = "",
}) => {
  if (mixpanelToken) {
    if (method === "add")
      mixpanel.track(`Alert add`, {
        alertName,
        appId,
        appName,
        alertCondition,
        cameraName,
        cameraId,
      });
    else
      mixpanel.track(`Alert update`, {
        alertName,
        alertId,
        appId,
        appName,
        olderConditon,
        alertCondition,
        cameraName,
        cameraId,
      });
  }
};
