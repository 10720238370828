import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useState } from "react";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";

const UserMngmtTable = ({
  rowData,
  setIsOpenE,
  setIsOpenD,
  setSelectedUser,
  setFullName,
  setUserEmail,
  setDesignation,
  setBaseLocation,
  setUserRole,
  setContact,
  clientOrg,
  clientUpdate,
  mode,
  setReactivate,
  setDelete,
}) => {
  const [page, setPage] = useState(0);
  const columns = [
    {
      field: "userid",
      headerName: "SR No.",
    },
    {
      field: "username",
      headerName: "user name",
    },
    {
      field: "fullname",
      headerName: "full name",
    },
    {
      field: "email",
      headerName: "email",
    },
    {
      field: "designation",
      headerName: "designation",
    },
    {
      field: "role",
      headerName: "role",
    },
    {
      field: "createdat",
      headerName: "created on",
      valueGetter: (params) => {
        return params.row.createdat
          ? new Date(params.row.createdat).toDateString().split(" ")[2] +
              " " +
              new Date(params.row.createdat).toDateString().split(" ")[1] +
              " '" +
              new Date(params.row.createdat)
                .toDateString()
                .split(" ")[3]
                .slice(2, 4) +
              " " +
              new Date(params.row.createdat).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
          : "";
      },
    },
    {
      field: "clientLocations",
      headerName: "base location",
      valueFormatter: ({ value }) =>
        value?.map((item) => item?.locationName)?.join(", "),
    },
    {
      field: "status",
      headerName: "status",
      // renderCell: ({ row }) => {
      //   return row.isDeleted === "true" || row.isDeleted ? (
      //     <span className="text-[#E46962] text-sm font-semibold">Deleted</span>
      //   ) : (
      //     <span className="text-[#7AC958] text-sm font-semibold">Active</span>
      //   );
      // },
      renderCell: ({ row }) => {
        if (row.status === "Deleted") {
          return (
            <span className="text-[#E46962] text-sm font-semibold">
              Deleted
            </span>
          );
        } else if (row.status === "Active") {
          return (
            <span className="text-[#7AC958] text-sm font-semibold">Active</span>
          );
        } else if (row.status === "Inactive") {
          return (
            <span className="text-[#938F96] text-sm font-semibold">
              Inactive
            </span>
          );
        } else {
          // Handle other status values if needed
          return (
            <span className="text-[your-default-color] text-sm font-semibold">
              {row.status}
            </span>
          );
        }
      },
    },
    {
      field: "action",
      headerName: "action",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <div className="flex gap-1 items-center">
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => {
                setIsOpenD(true);
                setSelectedUser(row);
              }}
              disabled={row.isDeleted}
            >
              {/* <DeleteIcon /> */}
              {row.isDeleted ? (
                <img src="/admin/deleteDisabled.svg" alt="" />
              ) : (
                <img src="/admin/delete.svg" alt="" />
              )}
            </IconButton>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setIsOpenE(true);
                setSelectedUser(row);
                setFullName(row?.fullname);
                setUserEmail(row?.email);
                setUserRole(row?.role);
                setContact(row?.phoneNumber);
                setDesignation(row?.designation);
                setBaseLocation(row?.clientLocations);
                setReactivate((prev) => ({
                  ...prev,
                  isDeactivated: row?.isDeleted,
                }));
                setDelete(row?.isDeleted);
              }}
            >
              <EditNoteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];
  const headerClass =
    "text-sm font-normal text-[#79767D] bg-[#DDEEFF] capitalize";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
  const flexMap = [0, 2, 2, 2.5, 1.5, 1, 1.5, 1, 1, 1];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  const handlePageChange = (params) => {
    // console.log(params);
    setPage((prev) => params.page);
  };
  console.log(rowData);
  return (
    <div className="overflow-x-auto mt-2">
      <CustomStyledDataGrid
        rows={rowData}
        columns={columns}
        getRowId={(row) => row.userid}
        columnVisibilityModel={{
          userid: false,
          username: false,
          action: clientOrg && mode !== "update" ? false : true,
        }}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10, page: page } },
        }}
        onPaginationModelChange={handlePageChange}
        // hideFooter={true}
        sx={{ minWidth: "1000px" }}
      />
    </div>
  );
};

export default UserMngmtTable;
