import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useState, useContext } from "react";
import Tooltip from '@mui/material/Tooltip';
import { utils, writeFile } from "xlsx";
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import axios from "axios";
import NavContext from "../../../NavContext";
import { baseURL } from "../../../..";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { CustomStyledDataGrid } from "../../../../util/MaterialDataGrid/CustomStyledDatagrid";


const MuiTheme = createTheme();

const View = ({ row, setSelectedProductGroup, setShowProductTab }) => {

    const handleView = () => {
        //console.log(products);
        setSelectedProductGroup(row);
        setShowProductTab((prev) => "viewProduct");
    }

    return (
        <IconButton
            aria-label="edit"
            sx={{ color: "#1E88E5" }}
            onClick={() => {
                handleView();
            }}
            //disabled={row?.status === "INACTIVE"}
        >
            <VisibilityIcon />
        </IconButton>
    );
}

const Update = ({ row, setSelectedProductGroup, setShowProductTab }) => {

    const handleUpdate = () => {
        //console.log(products);
        setSelectedProductGroup(row);
        setShowProductTab((prev) => "updateProduct");
    }
    return (
        <IconButton
            aria-label="edit"
            sx={{ color: "#1E88E5" }}
            onClick={() => {
                handleUpdate();
            }}
            disabled={row?.product.status === "INACTIVE"}
        >
            <EditNoteIcon />
        </IconButton>
    );
}

const Download = ({ row }) => {

    const exportAsExcel = (data, sheetName) => {
        let wb = utils.book_new();

        let newData = [Object.keys(data[0])];
        const fileColumns = Object.keys(data[0]);

        if (data.length != 0) {
            data?.forEach((item) => {
                const newArray = fileColumns?.map((i) => {
                    return i.toLowerCase().includes("time")
                        ? new Date(item[i]).toLocaleString()
                        : item[i];
                });
                newData.push(newArray);
            });
        }

        let ws = utils.aoa_to_sheet(newData);
        utils.book_append_sheet(wb, ws, `${sheetName}`);
        writeFile(wb, `report_data.xlsx`);
    };

    const handleDownloadClick = async (data) => {
        if (Array.isArray(data.product)) exportAsExcel(data.product, "Summary");
        else exportAsExcel([data.product], "Summary");
    };

    return (
        <div className="cursor-pointer" onClick={() => handleDownloadClick(row)}>
            <img src="/download.svg" />
        </div>
    );
}



const ToggleSwitch = ({ row, setShowProductTab, getProducts }) => {

    const [checked, setChecked] = useState(row?.product.status !== "INACTIVE");
    const { auth } = useContext(NavContext);

    const updateProducts = async (flag, status) => {

        const data1 = {
            productId: row.product.productId,
            isdeleted: flag,
            status: status,
        }
        const json = JSON.stringify(data1);
        const blob = new Blob([json], {
            type: "application/json",
        });
        const FormData = require("form-data");
        let datax = new FormData();
        datax.append("data", blob);

        let config = {
            method: "patch",
            maxBodyLength: Infinity,
            url: baseURL + "product/v1/update/product",
            headers: {
                "X-Auth-Token": auth,
            },
            data: datax,
        };
        axios
            .request(config)
            .then((response) => {
                getProducts();
            })
            .catch((error) => {
                // //console.log(error);
                // toast({
                //     title: "Something went wrong",
                //     status: "error",
                //     duration: 4000,
                //     isClosable: true,
                //     position: "top",
                // });
            });

    }

    const handleChange = async (event) => {

        setChecked(event.target.checked);
        if (event.target.checked === true) await updateProducts(false, 1)
        else await updateProducts(true, 2);
        console.log(event.target.checked)

    };

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "#378805",
            '&:hover': {
                backgroundColor: alpha("#378805", theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "#378805",
        },
    }));

    return (
        <div>
            {/* Switch */}
            <GreenSwitch
                checked={checked}
                onChange={handleChange}
                // color="#568203"
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </div>

    );
}


const ProductsTable = ({ products, setSelectedProductGroup, setShowProductTab, getProducts }) => {

    console.log(products);

    const [viewModal, setViewModal] = useState("");

    const obj = {
        clients: "A, B, C, D",
        deployments: "Ahmedabad, Surat, Kerela, Jamshedpur, Lucknow, Rajasthan",
        devices: "Camera, Chips",
    }

    const rowData = products;


    console.log(rowData);

    const columns = [
        {
            field: "product",
            headerName: <div className="flex">
                <img src="/toolsIcon.svg" />
                Product
            </div>,
            valueGetter: ({ row }) => row?.product?.productName,
            renderCell: ({ row }) => {
                return (

                    <Tooltip title={row?.product?.productName} placement="top">
                        <div className="flex flex-row whitespace-nowrap overflow-hidden text-ellipsis">
                            {/* <p className="text-[#938F96] text-sm">Description</p> */}
                            {row.product.productLogoUrl ?
                                <img className=" w-[20px] h-[20px] " src={row.product.productLogoUrl} alt="coins" /> : <img src="/devicesIcon.svg" alt="" className=" w-[20px] h-[20px]" />}
                            {row.product.productName}

                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "productgroup",
            headerName: <div className="flex">
                <img src="/productGroup.svg" />
                Product Group
            </div>,
            valueGetter: ({ row }) => row?.productGroup,
            renderCell: ({ row }) => {
                return (
                    <Tooltip title={row?.productGroup} placement="top">
                        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                            {/* <p className="text-[#938F96] text-sm">Description</p> */}
                            {row.productGroup}

                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "parentGroup",
            headerName: <div className="flex">
                <img src="/devicesIcon.svg" />
                Parent Group
            </div>,
            valueGetter: ({ row }) => row?.productCategory,
            renderCell: ({ row }) => {
                return (
                    <Tooltip title={row.productCategory} placement="top">
                        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                            {/* <p className="text-[#938F96] text-sm">Description</p> */}
                            {row.productCategory}

                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "clients",
            headerName: <div className="flex">
                <img src="/clientsIcon.svg" />
                Clients count
            </div>,
            valueGetter: ({ row }) => row?.clientCount,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex item-center gap-2 cursor-pointer ">
                        {row.clientCount}
                    </div>
                );
            },
        },
        {
            field: "subs",
            headerName: <div className="flex">
                <img src="/deplIcon.svg" />
                Subscriptions count
            </div>,
            valueGetter: ({ row }) => row?.subscriptionCount,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex item-center gap-2 cursor-pointer ">
                        {row.subscriptionCount}
                    </div>
                );
            },
        },
        {
            field: "createdAt",
            headerName: <div className="flex">
                <img src="/date.svg" />
                Created On
            </div>,
            valueGetter: ({ row }) => row?.product?.createdAt,
            renderCell: ({ row }) => {

                function padZero(number) {
                    return (number < 10 ? '0' : '') + number;
                }

                const date = new Date(row.product.createdAt);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getUTCHours();
                const minutes = date.getUTCMinutes();
                const seconds = date.getUTCSeconds();
                const dateString = `${day}/${month}/${year}`

                const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);


                const epochTimestamp = row.product.createdAt; // For January 17, 2024

                // Create a new Date object using the epoch timestamp
                const myDate = new Date(epochTimestamp * 1000); // Convert seconds to milliseconds

                // Format the date string as '17 Jan '24'
                const options = { day: 'numeric', month: 'short', year: '2-digit' };
                const formattedDate = new Date(row.product.createdAt).toDateString().split(" ")[2] +
                    " " +
                    new Date(row.product.createdAt).toDateString().split(" ")[1] +
                    " '" +
                    new Date(row.product.createdAt).toDateString().split(" ")[3].slice(2, 4)

                return (
                    <Tooltip title={formattedDate + " " + formattedTime} placement="top">
                        <div className="w-full flex item-center gap-2 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis">
                            {formattedDate + " " + formattedTime}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "updatedAt",
            headerName: <div className="flex">
                <img src="/date.svg" />
                Updated On
            </div>,
            valueGetter: ({ row }) => row?.product?.updatedAt,
            renderCell: ({ row }) => {

                function padZero(number) {
                    return (number < 10 ? '0' : '') + number;
                }
                const date = new Date(row.product.updatedAt);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
                const day = date.getDate().toString().padStart(2, '0');
                const dateString = `${day}/${month}/${year}`

                const hours = date.getUTCHours();
                const minutes = date.getUTCMinutes();
                const seconds = date.getUTCSeconds();

                const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);

                const formattedDate = new Date(row.product.updatedAt).toDateString().split(" ")[2] +
                    " " +
                    new Date(row.product.updatedAt).toDateString().split(" ")[1] +
                    " '" +
                    new Date(row.product.updatedAt).toDateString().split(" ")[3].slice(2, 4)
                return (
                    <Tooltip title={formattedDate + " " + formattedTime} placement="top">
                        <div className="w-full flex item-center gap-2 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis">
                            {formattedDate + " " + formattedTime}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "status",
            headerName: <div className="flex">
                <img src="/superadmin/clients/timer.svg" alt="" />
                Status
            </div>,
            valueGetter: ({ row }) => row?.product?.status,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex item-center gap-2 cursor-pointer ">
                        {row?.product?.status === "DRAFT" ? (
                                <img
                                    src={`/Common/draft.svg`}
                                    className="w-5 h-5 rounded-full"
                                    alt="status"
                                />
                            ) : row?.product?.status === "ACTIVE" ? (
                                <CheckCircleIcon sx={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "9999px",
                                    color: "green",
                                }} />
                            ) : (
                                <CancelIcon sx={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "9999px",
                                    color: "#E46962",
                                }} />
                            )}
                        <p className="capitalize">{row.product.status.toLowerCase()}</p>
                    </div>
                );
            },
        },
        {
            field: "links",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => <View row={row} setSelectedProductGroup={setSelectedProductGroup} setShowProductTab={setShowProductTab} />,
        },
        {
            field: "links2",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => <Update row={row} setSelectedProductGroup={setSelectedProductGroup} setShowProductTab={setShowProductTab} />,
        },
        // {
        //     field: "links3",
        //     headerName: "",
        //     renderCell: ({ row }) => <Download row={row} setSelectedProductGroup={setSelectedProductGroup} setShowProductTab={setShowProductTab} />,
        // },
        // {
        //     field: "links4",
        //     headerName: "",
        //     renderCell: ({ row }) => <ToggleSwitch row={row} setShowProductTab={setShowProductTab} getProducts={getProducts} />,
        // }
    ];

    const handleViewClick = (row) => {
        //console.log(products);


        setSelectedProductGroup(row);
        setShowProductTab((prev) => "viewProduct");
    }

    const handleUpdateClick = (row) => {
        //console.log(products);


        setSelectedProductGroup(row);
        setShowProductTab((prev) => "updateProduct");
    }

    const headerClass =
        "text-sm font-normal text-[#79767D] bg-[#DEF]";
    const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
    const flexMap = [1, 1, 0.8, 0.4, 0.4, 0.6, 0.6, 0.4, 0.1, 0.1,];
    columns.map((val, idx) => {
        val["headerClassName"] = headerClass;
        val["cellClassName"] = cellClass;
        val["flex"] = flexMap[idx];
    });

    return (
        <div className="overflow-x-auto mt-2 w-full">
            <CustomStyledDataGrid
                    rows={rowData}
                    columns={columns}
                    getRowId={(row) => row.product.productId}
                    //   columnVisibilityModel={{
                    //     chatId: false,
                    //     username: false,
                    //   }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "updatedAt", sort: "desc" }],
                        },
                        pagination: { paginationModel: { pageSize: 8 } },
                    }}
                    pagination
                    //   hideFooter={true}
                    sx={{ minWidth: "1000px" }}
                />
        </div>
    );
};

export default ProductsTable;
