import { CalF1, RuntimeCalculator } from "../../../utils/HandlerFuncs";

const RunDetails = ({ modelInfo, stateChangeTs }) => {
  return (
    <div className="flex flex-row flex-wrap md:flex-col gap-4 w-fit md:w-[200px] justify-between">
      <div
        className="flex gap-2 items-center bg-white p-4 border border-[#CAC5CD] rounded-lg"
        style={{
          boxShadow:
            "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <img
          src="/selfServiceIcons/clock.svg"
          alt="file"
          className="w-[40px] h-[40px]"
        />
        <div className="flex flex-col gap-0">
          <p className="text-[#938F96] text-base font-medium">Runtime</p>
          <p className="text-[#48464C] text-xl font-semibold">
            {RuntimeCalculator({
              timeStates: stateChangeTs,
            })}
          </p>
        </div>
      </div>
      <div
        className="flex gap-2 items-center bg-white p-4 border border-[#CAC5CD] rounded-lg"
        style={{
          boxShadow:
            "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <img
          src="/selfServiceIcons/brain.svg"
          alt="file"
          className="w-[35px] h-[40px]"
        />
        <div className="flex flex-col gap-0">
          <p className="text-[#938F96] text-base font-medium">Confidence</p>
          <p className="text-[#48464C] text-xl font-semibold">
            {CalF1({
              precision: modelInfo?.modelMetaData?.[0]?.performance?.precision,
              recall: modelInfo?.modelMetaData?.[0]?.performance?.recall,
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RunDetails;
