import React, { useState, useContext, useEffect } from "react";
import { Spinner, useToast } from "@chakra-ui/react";
import axios from "axios";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import ActiveSubsFilters from "../components/ActiveSubsFilters";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import TonalButton from "../../../util/Buttons/TonalButton";
import { formatColumns } from "../../../util/MaterialDataGrid/DatagridUtils";
import SubscriptionPlanModal from "../Modals/SubscriptionPlanModal";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import { Tooltip } from "@mui/material";

const statusColorMap = {
  active: "text-[#7AC958]",
  inactive: "text-gray-500",
};

const Subscriptions = ({
  setSubHistoryChange,
  issubscriptionHistoryChanged,
  clientOrg,
  clientId,
  mode,
  setSelectedSubscription = null,
  isSuperAdminClient,
}) => {
  const [tableData, setTableData] = useState([]);
  const { auth, organisation, clientId: userClientId } = useContext(NavContext);
  const [subscriptionModalData, setSubscriptionModalData] = useState({
    open: false,
    rowData: {},
  });
  const size = useWindowSize();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [changingbutton, setChangingbutton] = useState("ACTIVE");
  const navigate = useNavigate();

  const subscriptionsDefinition = [
    {
      field: "subscriptionType",
      headerName: "Subscription type",
      type: "string",
    },
    {
      field: "subscriptionStartDate",
      headerName: "Subscription start date",
      type: "dateTime",
      valueGetter: ({ value }) => new Date(value),
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleString("en-GB");
      },
    },
    {
      field: "subscriptionEndDate",
      headerName: "Subscription end date",
      type: "dateTime",
      valueGetter: ({ value }) => new Date(value),
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleString("en-GB");
      },
    },
    {
      field: "locations",
      headerName: "Locations",
      valueFormatter: ({ value }) =>
        value?.map((item) => item?.locationName)?.join(","),
      type: "string",
    },
    {
      field: "subscriptionStatus",
      headerName: "Status",
      type: "string",
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => {
        const isExistingUser = !(row?.configurationCount === 0);
        let param = "isExisting=true";
        if (!isExistingUser) {
          param = "isFirstTime=true";
        }
        let disable = row?.subscriptionConfigurationRequested;
        let tooltipData = (
          <div className="flex flex-col gap-0 text-sm">
            <p>Request raised, Please go to user requests</p>
            <p className="w-full truncate">RequestId: {row?.userRequestId}</p>
            <p>Status: {row?.currentRequestStatus}</p>
            <p>ETA: {new Date(row?.requestEta)?.toLocaleString("en-GB")} </p>
          </div>
        );
        return (
          <Tooltip arrow title={disable ? tooltipData : null}>
            {" "}
            <div className="flex gap-1">
              <TonalButton
                text={"Configure"}
                onClick={() => {
                  if (setSelectedSubscription) {
                    setSelectedSubscription((prev) => ({
                      clientId: clientId,
                      subscriptionId: row?.subscriptionId,
                      subscriptionType: row?.subscriptionType,
                      configurationNo: row?.configurationCount,
                      isExistingUser: !(row?.configurationCount === 0),
                      lastUpdatedDate: new Date(
                        row?.lastUpdatedAt
                      )?.toLocaleString("en-GB"),
                    }));
                  } else {
                    let paramString = `?clientId=${userClientId}&subscriptionId=${
                      row?.subscriptionId
                    }&subscriptionType=${
                      row?.subscriptionType
                    }&configurationNo=${
                      row?.configurationCount
                    }&lastUpdatedDate=${new Date(
                      row?.lastUpdatedAt
                    )?.toLocaleString("en-GB")}&${param}`;
                    navigate(`/admin/configuresubscription${paramString}`);
                  }
                }}
                disable={disable}
              />
              <PrimaryButton
                text={"Upgrade"}
                onClick={() => {
                  setSubscriptionModalData((prev) => ({
                    open: true,
                    rowData: row,
                  }));
                }}
                disable={disable}
              />
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const subscriptionsFlexMap = {
    subscriptionId: 1,
    subscriptionType: 1,
    subscriptionStartDate: 1,
    subscriptionEndDate: 1,
    locations: 1,
    subscriptionStatus: 1,
    actions: size?.width >= 1350 ? 1 : 1.25,
  };

  const [summary, setSummary] = useState({
    total: 4,
    active: 4,
    inactive: 0,
  });

  const [filter, setFilter] = useState({
    items: [],
  });

  const handleChange = (e) => {
    const { value } = e.target;
    // Set up an object to store filters for different fields
    const newFilters = {
      items: [],
      quickFilterValues: [value],
    };
    setFilter(newFilters);
  };

  const handleFilterModelChange = (newFilterModel) => {
    // Check if the new filter model is different from the current state
    if (JSON.stringify(newFilterModel) !== JSON.stringify(filter)) {
      setFilter(newFilterModel);
    }
  };

  const fetchTableData = async () => {
    try {
      const param = {
        clientId: clientId ? clientId : userClientId,
      };
      const response = await axios?.get(
        baseURL + "v2/aisandbox/subscription/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status === 200) setTableData(response?.data);
    } catch (error) {
      console.log(error);
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    setSummary((prev) => ({
      ...prev,
      total: tableData?.length,
      active: tableData?.filter((item) => item?.subscriptionStatus === "ACTIVE")
        ?.length,
      inactive: tableData?.filter(
        (item) => item?.subscriptionStatus === "INACTIVE"
      )?.length,
    }));
  }, [tableData]);

  useEffect(() => {
    if (auth) {
      if (clientId || userClientId) {
        fetchTableData();
      }
    }
  }, [clientId, userClientId, mode]);

  return (
    <div className="w-full px-2 !font-roboto">
      <div className="flex  flex-col lg:flex-row gap-4">
        <div className="flex w-full justify-between gap-6 items-center">
          <div className="flex gap-6 items-center">
            <div>
              <p className="text-lg font-semibold text-[#605D64]">
                {summary?.total || 0}
              </p>
              <p className="text-[#938F96]">Total</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-[#605D64]">
                {summary?.active || 0}
              </p>
              <p className="text-[#938F96]">Active</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-[#605D64]">
                {summary?.inactive || 0}
              </p>
              <p className="text-[#938F96] whitespace-nowrap">Inactive</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between lg:justify-end gap-4 w-full">
          <div className="flex items-center">
            <div className="w-full sm:min-w-[320px] h-[40px] flex flex-row items-center border-2 py-2 rounded px-4 justify-between">
              <input
                className="w-full focus:outline-none text-sm"
                placeholder="Search email subscriptionId/name"
                onChange={handleChange}
              />
              <img className="h-5 text-black" src="/search.svg" alt="" />
            </div>
          </div>
          <ActiveSubsFilters
            organisation={organisation}
            setChangingbutton={setChangingbutton}
            changingbutton={changingbutton}
            fetchTableData={fetchTableData}
            clientId={clientId ?? userClientId}
            setSubHistoryChange={setSubHistoryChange}
            issubscriptionHistoryChanged={issubscriptionHistoryChanged}
            mode={mode}
            clientOrg={clientOrg}
          />
        </div>
      </div>

      {loading ? (
        <div className="ml-[50%]">
          <Spinner speed="0.65s" />
        </div>
      ) : (
        <div className="h-[65vh] border rounded-md mt-6">
          <CustomStyledDataGrid
            rows={tableData}
            columns={formatColumns({
              color: "blue",
              cols: subscriptionsDefinition,
              flexMap: subscriptionsFlexMap,
            })}
            getRowId={(row) => row?.subscriptionId}
            autosizeOptions={{
              columns: subscriptionsDefinition?.map((item) => item.field),
              includeOutliers: true,
              includeHeaders: true,
            }}
            pagination={true}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 15, 25]}
            getCellClassName={(params) => {
              if (params.field === "status") {
                return statusColorMap[params?.value?.toLowerCase()];
              }
              return "";
            }}
            filterModel={filter}
            onFilterModelChange={handleFilterModelChange}
            columnVisibilityModel={{
              actions: clientOrg && mode !== "update" ? false : true,
            }}
          />
        </div>
      )}
      {subscriptionModalData?.open && (
        <SubscriptionPlanModal
          modalData={subscriptionModalData}
          closeModal={() => {
            setSubscriptionModalData((prev) => ({
              open: false,
              rowData: {},
            }));
          }}
          clientId={clientId}
          fetchTableData={fetchTableData}
          subscriptionId={subscriptionModalData?.rowData?.subscriptionId}
          plantType={subscriptionModalData?.rowData?.subscriptionType?.toLowerCase()}
        />
      )}
    </div>
  );
};

export default Subscriptions;
