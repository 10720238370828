import { useLocation, useParams, useSearchParams } from "react-router-dom";
import AddData from "./Data/AddData";
import DataRepoView from "./Data/DataRepoView";
import AddAnnotation from "./Data/AddAnnotation";
import DataLabelView from "./Data/DataLabelView";
import AddApp from "./App/AddApp";
import AppView from "./App/AppView";
import AddAlert from "./App/Alerts/AddAlert";
import ViewModelRuns from "./Model/ViewModelRuns";
import {
  mixpanelNewInitiatedTrack,
  mixpanelPageViewedTrack,
  mixpanelViewTrack,
} from "./utils/MixpanelEvents";
import { useWindowSize } from "@uidotdev/usehooks";
import { ThemeProvider, createTheme } from "@mui/material";
import AlertSettings from "./App/Alerts/AlertSettings";
import AddModel from "./Model/AddModel";
import RetrainModel from "./Model/RetrainModel";

// accessMode can be view , draft or add
// pageType can be model, repo, label, retrain,app,alert

const RouterHOC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const size = useWindowSize();
  const MuiTheme = createTheme();
  const { accessMode, pageType } = useParams();

  mixpanelPageViewedTrack({
    pageUrl: location?.pathname + location?.search,
    prevPageUrl: location?.state?.prevPath ?? "",
    pageName: accessMode + "-" + pageType,
  });

  if (accessMode === "add") {
    mixpanelNewInitiatedTrack({ type: pageType });
  } else if (accessMode === "view") {
    let keyName = pageType === "data" ? "repo" : pageType;
    mixpanelViewTrack({
      type: pageType,
      name: searchParams?.get(keyName + "Name") ?? "",
      id: searchParams?.get(keyName + "Id") ?? "",
    });
  }

  const widthContainer = (children) => (
    <div className="w-full">
      <ThemeProvider theme={MuiTheme}>{children}</ThemeProvider>
    </div>
  );

  switch (accessMode) {
    case "view":
      switch (pageType) {
        case "data":
          return widthContainer(
            <DataRepoView
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "label":
          return widthContainer(
            <DataLabelView
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "app":
          return widthContainer(
            <AppView
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "model":
          return widthContainer(
            <ViewModelRuns
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "alert":
          return widthContainer(
            <AlertSettings
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        default:
          return <p>Page not exists</p>;
      }
    case "draft":
      switch (pageType) {
        case "label":
          return widthContainer(
            <AddAnnotation
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "app":
          return widthContainer(
            <AddApp
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "data":
          return widthContainer(
            <AddData
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "model":
          return widthContainer(
            <AddModel
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "retrain":
          return widthContainer(
            <RetrainModel
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "alert":
          return widthContainer(
            <AddAlert
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        default:
          return <p>Page not exists</p>;
      }
    case "add":
      switch (pageType) {
        case "data":
          return widthContainer(
            <AddData
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "model":
          return widthContainer(
            <AddModel
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "retrain":
          return widthContainer(
            <RetrainModel
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "label":
          return widthContainer(
            <AddAnnotation
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "app":
          return widthContainer(
            <AddApp
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        case "alert":
          return widthContainer(
            <AddAlert
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          );
        default:
          return <p>Page not exists</p>;
      }
    default:
      return <p>Page not exists</p>;
  }
};

export default RouterHOC;
