import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Popover,
} from "@mui/material";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { useRef, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export const CommonHomeFilters = ({
  filterOptions = [
    {
      label: "",
      displayKey: "",
      valueKey: "",
      options: [],
      value: [],
      handleChange: () => {},
    },
    {
      isDateSelect: true,
      type: "date",
      value: "",
      handleChange: () => {},
      label: "",
      min: "",
      max: "",
    },
  ],
  hasSearch = false,
  searchObj = {
    value: "",
    setValue: () => {},
  },
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();
  return (
    <div className="flex gap-2 sm:gap-4 flex-row-reverse flex-wrap items-center w-full xl:w-[800px]">
      {children}
      <div
        className="flex gap-2 items-center text-[#605D64] text-lg rounded-lg bg-white px-2 py-[5px] font-medium border border-[#CAC5CD] hover:scale-105 cursor-pointer h-full"
        style={{
          boxShadow:
            "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <FilterListIcon sx={{ color: "#605D64", fontSize: "24px" }} />
        Filters
      </div>
      {/*Date */}
      {filterOptions?.map((item, idx) => {
        return item?.isDateSelect ? (
          <div className="flex-1 min-w-[100px] xs:min-w-[180px] xl:max-w-[200px]" key={idx}>
            <FloatingInput
              text={item?.label}
              type={item?.type}
              setDateTime={item?.handleChange}
              value={item?.value}
              min={item?.min}
              max={item?.max}
              isClearable={true}
              customProps={{
                sx: {
                  boxShadow:
                    "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
                  backgroundColor: "#FFF",
                  borderRadius: "8px",
                  ".css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                  ".css-segi59": {
                    borderRadius: "8px",
                  },
                },
              }}
            />
          </div>
        ) : null;
      })}
      {/*Search*/}
      {hasSearch && (
        <OutlinedInput
          inputRef={ref}
          defaultValue={searchObj?.value}
          onChange={(e) => searchObj?.setValue(e)}
          placeholder="Search..."
          sx={{
            width: "100%",
            flex: 1,
            minWidth: "180px",
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
            backgroundColor: "#FFF",
            borderRadius: "8px",
          }}
          size="small"
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
      )}
      {/*Filter panel */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              border: "1px solid #CAC5CD",
              borderRadius: "8px",
            },
          },
        }}
      >
        <div className="relative p-4 flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            {filterOptions?.map((item, idx) => {
              return !item?.isDateSelect ? (
                <div className="flex flex-col gap-2" key={idx}>
                  <p className="text-[#48464C] text-sm font-medium">
                    {item?.label}
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {item?.options?.map((option, idx2) => {
                      let displayValue = option?.[item?.displayKey];
                      let keyValue = option?.[item?.valueKey];
                      let isSelected =
                        item?.value?.findIndex(
                          (x) => x?.[item?.valueKey] === keyValue
                        ) !== -1;
                      return (
                        <p
                          className="px-3 py-2 text-xs rounded-full border cursor-pointer"
                          style={{
                            borderColor: isSelected ? "#3A74CA" : "#EBEBEB",
                            color: isSelected ? "#3A74CA" : "#79767D",
                            backgroundColor: isSelected ? "#DEF" : "#F0F0F0",
                          }}
                          onClick={() => {
                            let selection = [...item?.value];
                            if (isSelected) {
                              item?.handleChange(
                                keyValue,
                                selection?.filter(
                                  (x) => x?.[item?.valueKey] !== keyValue
                                )
                              );
                            } else {
                              selection?.push(option);
                              item?.handleChange(keyValue, selection);
                            }
                          }}
                          key={idx2}
                        >
                          {displayValue}
                        </p>
                      );
                    })}
                  </div>
                </div>
              ) : null;
            })}
          </div>
          <IconButton
            color="primary"
            size="small"
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
            }}
            onClick={() => setAnchorEl(null)}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </div>
      </Popover>
    </div>
  );
};
