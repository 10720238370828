import { useEffect, useState } from "react";
import { NavigateBack, Tablist } from "../Common/CommonComponents";
import Feed from "./Feed/Feed";
import Alert from "./Alerts/Alerts";
import Analytics from "./Analytics/Analytics";
import DetailFeed from "./Feed/DetailFeed";
import { useLocation } from "react-router-dom";
import AppFailFallBack from "./AppFailFallback";
import Feedlibrary from "./Feedlibrary/Feedlibrary";
import { callApi } from "../utils/ApiUtil";
import { useToast } from "@chakra-ui/react";

const AppView = ({ searchParams, setSearchParams }) => {
  const tabIndex = searchParams?.get("tabIndex")
    ? Number(searchParams?.get("tabIndex"))
    : 0;
  const location = useLocation();
  const toast = useToast();
  const status = searchParams?.get("status") ?? "";
  const appId = searchParams?.get("appId");
  const rtsp = searchParams?.get("rtsp");
  const cameraName = searchParams?.get("cameraName");
  const camId = searchParams?.get("camId");
  const modelId = searchParams?.get("modelId");
  const appName = searchParams?.get("appName");
  const [cameraDetails, setCameraDetails] = useState([]);
  const [appData, setAppData] = useState({});
  const tabList = [
    {
      index: 0,
      tabName: "Feed",
      element: cameraName ? (
        <DetailFeed
          rtsp={rtsp}
          cameraName={cameraName}
          appId={appId}
          status={status}
        />
      ) : (
        <Feed modelId={modelId} appId={appId} appName={appName} />
      ),
    },
    {
      index: 1,
      tabName: "Alerts",
      element: cameraDetails?.length > 0 && (
        <Alert
          appId={appId}
          cameraName={cameraName ?? ""}
          appName={appName}
          camId={camId ?? ""}
          cameraDetails={cameraDetails}
        />
      ),
    },
    {
      index: 2,
      tabName: "Feed library",
      element: cameraDetails?.length > 0 && (
        <Feedlibrary
          appId={appId}
          camId={camId ?? ""}
          cameraName={cameraName ?? ""}
          cameraDetails={cameraDetails}
        />
      ),
    },
    // {
    //   index: 3,
    //   tabName: "Analytics",
    //   element: <Analytics />,
    // },
  ];

  const handleTabChange = (val) => {
    if (val !== tabIndex) {
      setSearchParams((prev) => {
        prev.set("tabIndex", val);
        return prev;
      });
    }
  };

  const getApps = async () => {
    try {
      const param = {
        masterObjectId: appId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        let data = response?.data;
        setCameraDetails(data?.cameraDetail);
        setAppData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleTabChange(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    getApps();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <NavigateBack
        targetRoute={
          cameraName
            ? `/SandboxV2/view/app?appId=${appId}&modelId=${modelId}&appName=${appName}&status=${status}`
            : location?.state?.prevPath?.includes("selectedView")
            ? -1
            : "/SandboxV2/app"
        }
        textArray={
          cameraName
            ? ["AI Studio", appName, cameraName]
            : ["AI Studio", appName]
        }
      />
      {status !== "FAILED" ? (
        <Tablist
          tabList={tabList}
          isLazy={false}
          pageIndex={tabIndex}
          setPageIndex={handleTabChange}
        />
      ) : (
        <AppFailFallBack appId={appId} initialData={appData} />
      )}
    </div>
  );
};

export default AppView;
