import { Navigate } from "react-router-dom";
import AdminHome from "./components/Admin/Home";
import UserProfile from "./components/ContactUs/UserProfile";
import SuperAdminDashboard from "./components/SuperAdmin/SuperAdminDashboard";
import UpdateTabs from "./components/SuperAdmin/Tabs/UpdateTabs";
import ViewTabs from "./components/SuperAdmin/Tabs/ViewTabs";
import LandingPage from "./components/Sandbox/LandingPage";
import RouterHOC from "./components/Sandbox/RouterHOC";
import ConfigureSubscription from "./components/Admin/components/ConfigureSubscription";
import RequestDetails from "./components/Admin/components/RequestDetails";
import DataHomeView from "./components/Sandbox/Data/DataHomeView";
import ModelHomeView from "./components/Sandbox/Model/ModelHomeView";
import AppHomeView from "./components/Sandbox/App/AppHomeView";

export const routes = [
  {
    path: "/",
    element: <Navigate to="/SandboxV2/data" />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  //Profile pages
  {
    path: "/profile",
    element: <UserProfile />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },
  // Admin Pages
  {
    path: "/admin/usermanagement",
    element: <AdminHome />,
    role: ["SUPERADMIN", "ADMIN"],
  },
  {
    path: "/admin/configuresubscription",
    element: <ConfigureSubscription />,
    role: ["SUPERADMIN", "ADMIN"],
  },
  {
    path: "/admin/requestDetails",
    element: <RequestDetails />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Super admin pages

  {
    path: "/superadmin",
    element: <SuperAdminDashboard />,
    role: ["SUPERADMIN"],
  },

  {
    path: "/superadmin/viewDetails/:clientOrg/:clientId/:mode",
    element: <ViewTabs />,
    role: ["SUPERADMIN"],
  },
  {
    path: "/superadmin/update/:clientOrg/:clientId/:mode",
    element: <UpdateTabs />,
    role: ["SUPERADMIN"],
  },
  // Self service pages
  {
    path: "/SandboxV2/data",
    element: <DataHomeView />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/SandboxV2/model",
    element: <ModelHomeView />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/SandboxV2/app",
    element: <AppHomeView />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/SandboxV2/:accessMode/:pageType",
    element: <RouterHOC />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
];
