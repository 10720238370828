import { useContext, useEffect, useRef, useState } from "react";
import { Input, IconButton, useToast, Toast } from "@chakra-ui/react";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { baseURL } from "../../../..";
import NavContext from "../../../NavContext";

const LocationInput = ({
  location = "",
  locationId = "",
  allLocation = [],
  index = 0,
  setLocations = () => {},
  isEditing = false,
  addLocation = () => {},
  deleteLocation = () => {},
  isViewMode = false,
}) => {
  const ref = useRef();
  const [edit, setEdit] = useState(isEditing);
  const [inputValue, setInputValue] = useState("");
  const toast = useToast();
  const handleSave = () => {
    let value = ref?.current?.value?.trim();
    let isInvalid = value === "" || value?.length > 50;
    let isNotUnique = ![index, -1]?.includes(
      allLocation?.findIndex(
        (item) => item?.locationName?.toLowerCase() === value?.toLowerCase()
      )
    );
    if (isInvalid || isNotUnique) {
      toast({
        title: "Error",
        description: (
          <div>
            {isInvalid && (
              <p>The location cannot be empty or greater than 50 characters</p>
            )}
            {isNotUnique && <p>The location entered is already being used</p>}
          </div>
        ),
        status: "error",
        position: "top-right",
        duration: 6000,
        isClosable: true,
      });
      return;
    }

    if (locationId !== "")
      addLocation({
        locationName: value,
        locationId: locationId,
        method: "patch",
        setEdit: setEdit,
      });
    else
      addLocation({
        locationName: value,
        method: "post",
        setEdit: setEdit,
      });
  };

  useEffect(() => {
    setInputValue(location);
  }, [location]);

  return (
    <div className="flex gap-2 items-end flex-wrap">
      <div className="flex flex-col gap-1">
        <p className="text-[#79767D] text-sm font-medium mb-3">
          Location {index + 1}
        </p>
        {!isViewMode ? (
          <Input
            ref={ref}
            maxW={"250px"}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            isDisabled={!edit}
          />
        ) : (
          <p className="text-[#3E3C42] font-medium capitalize">{location}</p>
        )}
      </div>
      {!isViewMode &&
        (!edit ? (
          <>
            <IconButton
              colorScheme="red"
              title="Delete"
              size="xs"
              variant="outline"
              isRound={true}
              onClick={() => deleteLocation({ locationId: locationId })}
              isDisabled={allLocation?.length === 1}
              icon={<DeleteIcon fontSize="small" />}
            />
            <IconButton
              colorScheme="blue"
              title="Edit"
              size="xs"
              variant="outline"
              isRound={true}
              onClick={() => setEdit(true)}
              icon={<EditIcon fontSize="small" />}
            />
            <p className="text-green-600 text-sm font-bold">Saved</p>
          </>
        ) : (
          <>
            <IconButton
              colorScheme="gray"
              title="Cancel"
              size="xs"
              variant="outline"
              isRound={true}
              onClick={() => {
                if (ref?.current?.value === "") setInputValue(location);
                if (isEditing)
                  setLocations((prev) => {
                    let newData = JSON.parse(JSON.stringify(prev));
                    newData?.splice(index, 1);
                    return newData;
                  });
                setEdit(false);
              }}
              icon={<CloseIcon fontSize="small" />}
            />
            <IconButton
              colorScheme="blue"
              title="Save"
              size="xs"
              variant="outline"
              isRound={true}
              onClick={() => handleSave()}
              icon={<SaveIcon fontSize="small" />}
            />
          </>
        ))}
    </div>
  );
};

const LocationCrud = ({ viewMode = false, setLocs = null, clientId }) => {
  const [locations, setLocations] = useState([]);
  const toast = useToast();
  const { auth } = useContext(NavContext);

  const getLocations = async () => {
    try {
      const param = {
        clientId: clientId,
      };
      const response = await axios.get(baseURL + "v2/location/", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response?.status === 200) {
        let value = response?.data;
        setLocations(value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addPatchLocation = async ({
    locationName = "",
    locationId = "",
    method = "post",
    setEdit = () => {},
  }) => {
    try {
      const requestData = JSON.stringify(
        method === "post"
          ? {
              clientId: clientId,
              locationName: locationName,
              city: "N/A",
              state: "N/A",
              country: "N/A",
            }
          : {
              locationId: locationId,
              locationName: locationName,
              city: "N/A",
              state: "N/A",
              country: "N/A",
            }
      );
      let response;
      if (method === "post")
        response = await axios.post(baseURL + "v2/location/", requestData, {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        });
      else
        response = await axios.patch(baseURL + "v2/location/", requestData, {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        });

      if (response?.status === 200) {
        getLocations();
        toast({
          title: "Location added",
          status: "success",
          duration: 3000,
          position: "top-right",
        });
        setEdit(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLocation = async ({ locationId = "" }) => {
    try {
      const param = {
        locationId: locationId,
      };
      const response = await axios.delete(baseURL + "v2/location/", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response?.status === 200) {
        toast({
          title: "Location deleted",
          status: "success",
          position: "top-right",
          duration: 3000,
        });
        getLocations();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    let val = locations?.map((item) => item?.locationName);
    if (setLocs) setLocs(val);
  }, [locations]);

  return viewMode && locations?.length === 0 ? (
    <p className="text-[#3E3C42] text-lg font-medium ">No location added</p>
  ) : (
    <div className="flex flex-col gap-4">
      <div className="flex items-start gap-8">
        <div className="flex flex-col gap-4">
          {locations &&
            locations?.map((location, index) => (
              <LocationInput
                key={index}
                location={location?.locationName}
                locationId={location?.locationId}
                allLocation={locations}
                index={index}
                setLocations={setLocations}
                addLocation={addPatchLocation}
                deleteLocation={deleteLocation}
                isEditing={location?.locationName === ""}
                isViewMode={viewMode}
              />
            ))}
        </div>
        {locations?.length !== 0 && (
          <div className="flex flex-col gap-1">
            <p className="text-[#79767D] text-sm font-medium mb-3">
              Total client locations{" "}
              <span className="text-[#AEA9B1] ml-1">(count) </span>{" "}
            </p>
            <div>{locations?.length}</div>
          </div>
        )}
      </div>
      {!viewMode && (
        <PrimaryButton
          text={"Add location"}
          width={"fit-content"}
          onClick={() =>
            setLocations((prev) => [
              ...prev,
              { locationName: "", locationId: "" },
            ])
          }
          disable={locations?.some((item) => item?.locationId === "")}
        />
      )}
    </div>
  );
};

export default LocationCrud;
