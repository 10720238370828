import { useToast } from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { callApi } from "../utils/ApiUtil";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { nameDescCheck, nameRegex } from "../utils/HandlerFuncs";
import { mixpanelRenameTrack } from "../utils/MixpanelEvents";
import { Modal, OutlinedInput } from "@mui/material";
import { CommonUniqueTextCheck, modalStyle } from "./CommonComponents";

const endPointType = {
  app: "selfserve/v2/project/v1/app/",
  project: "selfserve/v2/project/v1/project/",
  model: "selfserve/v2/project/v1/model/",
  dataRepo: "selfserve/v2/project/v1/dataRepository/",
};

const CommonRenameModal = ({
  openModal = false,
  closeModal = () => {},
  fetchCall = () => {},
  objectId = "",
  oldName = "",
  type = "",
}) => {
  const nameRef = useRef();
  const toast = useToast();

  const renameApi = async () => {
    if (!nameDescCheck({ val: nameRef?.current?.value, toast: toast })) return;
    try {
      const requestData = JSON.stringify({
        [type + "ObjectId"]: objectId,
        [type + "Name"]: nameRef?.current?.value,
      });
      const response = await callApi({
        type: "patch",
        endPoint: endPointType[type],
        requestBody: requestData,
        toast: toast,
      });
      if (response?.status === 200) {
        fetchCall();
        closeModal();
        mixpanelRenameTrack({
          oldName: oldName,
          newName: nameRef?.current?.value,
          id: objectId,
          type: type,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div
        style={{ ...modalStyle, maxWidth: "300px" }}
        className="flex flex-col gap-3"
      >
        <div
          className="flex justify-between items-center"
          style={{
            borderBottom: "1px solid #EBE2F0",
          }}
        >
          <p className="text-[#605D64] text-sm font-medium">Rename {type}</p>
          <CloseIcon
            className="cursor-pointer hover:scale-105"
            onClick={closeModal}
            sx={{ color: "#605D64" }}
          />
        </div>
        <div className="flex flex-col gap-4 items-end">
          <OutlinedInput
            inputRef={nameRef}
            placeholder="Enter name"
            onKeyDownCapture={(e) => {
              if (e.code === "Enter") renameApi();
            }}
            size="small"
            sx={{
              width: "100%",
            }}
          />
          <PrimaryButton
            text={"Save"}
            onClick={renameApi}
            width={"fit-content"}
          />
        </div>
        <CommonUniqueTextCheck
          valueKey="name"
          name={`${type} name`}
          inputRefValue={nameRef}
          endPoint={endPointType[type]}
        />
      </div>
    </Modal>
  );
};

export default CommonRenameModal;
