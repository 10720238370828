import { useEffect, useRef, useState } from "react";
import {
  CardContainer,
  Chip,
  ImageContainer,
} from "../Common/CommonComponents";
import ReactCardFlip from "react-card-flip";
import Paginator from "../../../util/VisionUtils/Paginator";
import ExpandImage from "../Data/Components/ExpandImage";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import TonalButton from "../../../util/Buttons/TonalButton";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import { callApi } from "../utils/ApiUtil";
import { useToast } from "@chakra-ui/react";

export const FlipCard = ({
  data,
  width = "210px",
  height = "250px",
  expandWidth = "35px",
  expandHeight = "35px",
  textSize = "12px",
  hasExpand = true,
  bottomBarHeight = "fit-content",
}) => {
  const [flip, setFlip] = useState(
    data.hasOwnProperty("annotatedImg") ? true : false
  );
  const [expand, setExpand] = useState({
    open: false,
    img: "",
  });
  return (
    <div className="w-full relative">
      <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
        <ImageContainer minWidth={width} height={height}>
          <img
            src={data?.img}
            alt="inputImage"
            className="w-full h-full rounded-lg"
            loading="lazy"
          />
          {!data?.hasOwnProperty("annotatedImg") && (
            <p
              className="absolute top-2 left-2 text-white p-1 rounded-lg bg-black bg-opacity-50"
              style={{
                fontSize: textSize,
              }}
            >
              {data?.tagName}
            </p>
          )}
        </ImageContainer>

        {data?.hasOwnProperty("annotatedImg") && (
          <ImageContainer minWidth={width} height={height}>
            <img
              src={data?.annotatedImg}
              alt="annotate"
              className="w-full h-full rounded-lg"
              loading="lazy"
            />
          </ImageContainer>
        )}
      </ReactCardFlip>
      <div
        className="absolute bottom-0 py-1 pl-2 pr-[10px] rounded bg-black bg-opacity-50 flex justify-between w-full text-[#FAFAFA]"
        style={{
          fontSize: textSize,
          height: bottomBarHeight,
        }}
      >
        {data?.hasOwnProperty("annotatedImg") && (
          <img
            src="/selfServiceIcons/flip.svg"
            alt="flip"
            className="z-10 cursor-pointer hover:scale-105"
            onClick={() => setFlip((prev) => !prev)}
          />
        )}
        <div className="flex gap-1 items-center">
          <img
            alt="brain"
            src="/selfServiceIcons/brain.svg"
            className="w-4 h-5"
          />
          <p>{(data.precision * 100)?.toFixed(2)}%</p>
        </div>
      </div>
      {hasExpand && (
        <Chip
          top={"10px"}
          right={"10px"}
          padding="1px"
          width={expandWidth}
          height={expandHeight}
          customElem={
            <FullscreenIcon
              sx={{ color: "#48464C", width: "100%", height: "100%" }}
            />
          }
          handleClick={() =>
            setExpand((prev) => ({
              ...prev,
              open: true,
              img: flip ? data?.annotatedImg : data?.img,
            }))
          }
        />
      )}
      {expand?.open && (
        <ExpandImage
          openModal={expand?.open}
          closeModal={() =>
            setExpand((prev) => ({
              ...prev,
              open: false,
              img: "",
            }))
          }
          img={expand?.img}
        />
      )}
    </div>
  );
};

const OutputImages = ({
  predictionData,
  selectedModelRun = "",
  modelId = "",
  history = [],
  getHistory = () => {},
}) => {
  const [formattedData, setFormattedData] = useState([]);
  const [disable, setDisable] = useState(false);
  const rightRef = useRef();
  const leftRef = useRef();
  const toast = useToast();
  const [disableToggle, setDisableToggle] = useState({
    left: true,
    right: true,
  });
  const [displayData, setDisplayData] = useState([]);

  const stitchVideo = async () => {
    try {
      const requestData = JSON.stringify({
        modelObjectId: modelId,
        modelRunId: selectedModelRun,
      });
      const response = await callApi({
        type: "post",
        endPoint: "selfserve/v2/project/v1/model/stitch-prediction-image/",
        toast: toast,
        requestBody: requestData,
      });
      if (response?.status === 200) {
        toast({
          title: "Success",
          description:
            "Video stitching process has started. You will get a mail as soon as the process is complete",
          status: "success",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });
        getHistory();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object?.keys(predictionData)?.length > 0) {
      setFormattedData((prev) => {
        let newData = [];
        Object.keys(predictionData)?.map((label) => {
          return predictionData[label].img.map((item) => {
            newData.push(item);
          });
        });
        return newData;
      });
    } else setDisplayData([]);
  }, [predictionData]);

  useEffect(() => {
    let currTime = new Date().getTime();
    let startedOn = history?.find(
      (item) => item?.modelRunId === selectedModelRun
    )?.lastImageStitchingStartedAt;
    if (startedOn && startedOn + 15 * 60 * 1000 >= currTime) setDisable(true);
    else setDisable(false);
  }, [history, selectedModelRun]);

  return (
    <div className="flex flex-col gap-2 items-end">
      <CardContainer
        showLeft={!disableToggle?.left}
        showRight={!disableToggle?.right}
        leftFunc={() => leftRef?.current?.click()}
        rightFunc={() => rightRef?.current?.click()}
      >
        {displayData?.map((item, idx) => {
          return <FlipCard key={idx} data={item} textSize="16px" />;
        })}
      </CardContainer>
      <div className="flex gap-2 items-center justify-between w-full">
        {!history?.find((item) => item?.modelRunId === selectedModelRun)
          ?.predictionVideoLink ? (
          <TonalButton
            Icon={<ArtTrackIcon />}
            text={"Stitch frames to video"}
            width={"fit-content"}
            height={"40px"}
            onClick={stitchVideo}
            disable={disable}
          />
        ) : (
          <div />
        )}
        <Paginator
          limit={8}
          setDisplayData={setDisplayData}
          rightRef={rightRef}
          leftRef={leftRef}
          setDisableToggle={setDisableToggle}
          data={formattedData}
          additionalStyles={{
            style: {
              boxShadow:
                "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
              backgroundColor: "#FFF",
              border: "1px solid #CAC5CD",
              borderRadius: "8px",
            },
          }}
        />
      </div>
    </div>
  );
};

export default OutputImages;
