import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Paginator from "../../../util/VisionUtils/Paginator";
import {
  ButtonContainer,
  Card,
  CardContainer,
  Chip,
  CustomMenu,
  DivTabs,
  ImageContainer,
  NavigateBack,
} from "../Common/CommonComponents";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import AddIcon from "@mui/icons-material/Add";
import { callApi } from "../utils/ApiUtil";
import { useToast } from "@chakra-ui/react";
import ExpandImage from "./Components/ExpandImage";
import ImageIcon from "@mui/icons-material/Image";
import { mixpanelDeleteTrack } from "../utils/MixpanelEvents";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Skeleton } from "@mui/material";
import UploadAnnotation from "./Components/UploadAnnotation";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const DataRepoView = ({ searchParams, setSearchParams }) => {
  const selectedView =
    searchParams?.get("selectedView") ?? "original-frame-set";
  const toast = useToast();
  const [expand, setExpand] = useState({
    open: false,
    img: "",
  });
  const [labelData, setLabelData] = useState([]);
  const [rtsp, setRtsp] = useState("");
  const [videos, setVideos] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [allFrames, setAllFrames] = useState([]);
  const rightRef = useRef();
  const leftRef = useRef();
  const [openUploadAnnotation, setOpenUploadAnnotation] = useState(false);
  const [loading, setLoading] = useState({
    frames: true,
    labels: true,
  });
  const [disableToggle, setDisableToggle] = useState({
    left: true,
    right: true,
  });
  const dataType = searchParams.get("dataType");
  const repoName = searchParams.get("repoName");
  const repoId = searchParams.get("repoId");
  const location = useLocation();
  const navigate = useNavigate();
  const valueOptions = ["original-frame-set", "annotated"];

  const handleViewChange = (val) => {
    if (val !== selectedView) {
      setSearchParams((prev) => {
        prev.set("selectedView", val);
        return prev;
      });
    }
  };

  const getAllFrames = async () => {
    try {
      const param = {
        masterObjectId: repoId,
      };
      const response = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/image/",
        params: param,
      });
      if (response?.status === 200) {
        setAllFrames(response?.data);
        setLoading((prev) => ({
          ...prev,
          frames: false,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVideo = async () => {
    try {
      const param = {
        masterObjectId: repoId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/video/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        let data = Object.entries(response?.data?.videoDataset);
        if (data[0][0]?.includes("feed")) {
          setRtsp(data[0][1]?.link);
        } else {
          let videoList = [];
          data?.map((item) => {
            videoList.push({
              link: item[1]?.signedUrl,
            });
          });
          setVideos(videoList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExistingLabels = async () => {
    try {
      const params = {
        masterObjectId: repoId,
      };
      const response = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/labelInfo/",
        params: params,
      });
      if (response?.status === 200) {
        setLabelData(response?.data);
        setLoading((prev) => ({
          ...prev,
          labels: false,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const imageDelete = async (id) => {
    try {
      const param = {
        masterObjectId: repoId,
        fileIds: id,
      };
      const response = await callApi({
        toast: toast,
        type: "delete",
        endPoint: "selfserve/v2/project/v1/image/",
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "Image deleted",
          position: "top-right",
          duration: 2000,
          status: "success",
        });
        getAllFrames();
        mixpanelDeleteTrack({
          name: repoName + "-" + id,
          type: "image",
          id: repoId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const labelDelete = async (label, annotationType) => {
    try {
      const param = {
        masterObjectId: repoId,
        label: label,
        annotationType: annotationType,
      };
      const response = await callApi({
        type: "delete",
        toast: toast,
        endPoint: "selfserve/v2/project/v1/labelData/",
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "Label deleted",
          position: "top-right",
          duration: 2000,
          status: "success",
        });
        getExistingLabels();
        mixpanelDeleteTrack({
          name: repoName + "-" + label + `(${annotationType})`,
          type: "label",
          id: repoId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = ({ val, label, annotationType }) => {
    switch (val) {
      case "Delete":
        labelDelete(label, annotationType);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleViewChange(selectedView);
  }, [selectedView]);

  useEffect(() => {
    getAllFrames();
    getExistingLabels();
    if (dataType === "video") getVideo();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <NavigateBack
        targetRoute={
          location?.state?.prevPath?.includes("selectedView")
            ? -1
            : "/SandboxV2/data"
        }
        textArray={["AI Studio", repoName]}
      />
      <div className="flex flex-col gap-6 rounded-xl">
        <div className="flex gap-3 flex-col min-[850px]:flex-row justify-between items-start min-[850px]:items-center">
          <DivTabs
            options={valueOptions}
            value={selectedView}
            setValue={handleViewChange}
            responsiveClasses="w-full min-[430px]:w-fit"
          />
          {selectedView !== "annotated" ? (
            <ButtonContainer>
              <div className="flex gap-2 items-center w-full sm:w-[330px]">
                <PrimaryButton
                  text={"Add files"}
                  width={"100%"}
                  onClick={() => {
                    navigate(
                      `/SandboxV2/draft/data?repoName=${repoName}&repoId=${repoId}&dataType=${dataType}&rtsp=${rtsp}`,
                      {
                        state: {
                          prevPath: location?.pathname + location?.search,
                        },
                      }
                    );
                  }}
                />
                <TonalButton
                  text={"Start Annotation"}
                  Icon={<AddIcon />}
                  width={"100%"}
                  onClick={() => {
                    navigate(
                      `/SandboxV2/add/label?repoName=${repoName}&repoId=${repoId}&dataType=${dataType}`,
                      {
                        state: {
                          prevPath: location?.pathname + location?.search,
                        },
                      }
                    );
                  }}
                  disable={allFrames?.length === 0}
                />
              </div>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <div className="w-full sm:w-[170px]">
                <TonalButton
                  text={"Upload Annotation"}
                  Icon={<UploadFileIcon />}
                  onClick={() => setOpenUploadAnnotation(true)}
                />
              </div>
            </ButtonContainer>
          )}
        </div>
        {selectedView === "annotated" ? (
          <CardContainer
            showLeft={!disableToggle?.left}
            showRight={!disableToggle?.right}
            leftFunc={() => leftRef?.current?.click()}
            rightFunc={() => rightRef?.current?.click()}
          >
            {loading?.labels ? (
              [...Array(8)]?.map((val, idx) => (
                <Skeleton
                  variant="rectangular"
                  height={"250px"}
                  width={"100%"}
                  key={idx}
                />
              ))
            ) : (
              <>
                <Card
                  isAddbutton={true}
                  addText="Create new label"
                  handleAdd={() =>
                    navigate(
                      `/SandboxV2/add/label?repoName=${repoName}&repoId=${repoId}&dataType=${dataType}`,
                      {
                        state: {
                          prevPath: location?.pathname + location?.search,
                        },
                      }
                    )
                  }
                  disableAdd={allFrames?.length === 0}
                />
                {displayData?.map((item, idx) => {
                  return (
                    <Card
                      key={idx}
                      heading={item?.labelName}
                      buttonInfo={[
                        {
                          text: "View",
                          type: "primary",
                          color: "blue",
                          handleClick: () => {
                            navigate(
                              `/SandboxV2/view/label?repoName=${repoName}&annotationType=${item?.annotationType}&labelName=${item?.labelName}&repoId=${repoId}&dataType=${dataType}`,
                              {
                                state: {
                                  prevPath:
                                    location?.pathname + location?.search,
                                },
                              }
                            );
                          },
                        },
                      ]}
                      labels={[item?.annotationType]}
                      bgImage={item?.SampleImgUrl}
                    >
                      <Chip
                        top={"12px"}
                        right={"12px"}
                        customElem={
                          <CustomMenu
                            position="block"
                            color="#48464C"
                            options={[
                              {
                                type: "Delete",
                                icon: "/delete.svg",
                              },
                            ]}
                            handleMenuClick={(val) =>
                              handleMenuClick({
                                val: val,
                                label: item?.labelName,
                                annotationType: item?.annotationType,
                              })
                            }
                          />
                        }
                      />
                      <Chip
                        top={"12px"}
                        left={"12px"}
                        width="fit-content"
                        customElem={
                          <div
                            className="flex gap-1 items-center text-[#525056] font-semibold text-sm"
                            title="Number of files"
                          >
                            <ImageIcon />
                            <p>{item?.labelCount}</p>
                          </div>
                        }
                      />
                    </Card>
                  );
                })}
              </>
            )}
          </CardContainer>
        ) : (
          <>
            {videos?.length > 0 && (
              <CardContainer>
                {videos?.map((item, idx) => {
                  return (
                    <ImageContainer key={idx}>
                      <video
                        muted={true}
                        playsInline={true}
                        controls={true}
                        src={item?.link}
                        className="w-full h-full rounded-lg"
                      />
                    </ImageContainer>
                  );
                })}
              </CardContainer>
            )}
            <CardContainer
              showLeft={!disableToggle?.left}
              showRight={!disableToggle?.right}
              leftFunc={() => leftRef?.current?.click()}
              rightFunc={() => rightRef?.current?.click()}
            >
              {loading?.frames
                ? [...Array(8)]?.map((val, idx) => (
                    <Skeleton
                      variant="rectangular"
                      height={"250px"}
                      width={"100%"}
                      key={idx}
                    />
                  ))
                : displayData?.map((item, idx) => {
                    return (
                      <ImageContainer key={idx}>
                        <img
                          className="h-full w-full rounded-lg"
                          src={item?.url}
                          alt="image"
                          loading="lazy"
                          referrerPolicy="origin"
                        />
                        <Chip
                          top={"6px"}
                          right={"46px"}
                          size="30px"
                          padding="4px"
                          customElem={
                            <DeleteOutlineRoundedIcon
                              sx={{
                                color: "#48464C",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          }
                          handleClick={() => imageDelete(item?.fileId)}
                        />
                        <Chip
                          top={"6px"}
                          right={"6px"}
                          size="30px"
                          padding="4px"
                          customElem={
                            <FullscreenIcon
                              sx={{
                                color: "#48464C",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          }
                          handleClick={() =>
                            setExpand((prev) => ({
                              ...prev,
                              open: true,
                              img: item?.url,
                            }))
                          }
                        />
                      </ImageContainer>
                    );
                  })}
            </CardContainer>
          </>
        )}
        <Paginator
          setDisplayData={setDisplayData}
          data={selectedView === "annotated" ? labelData : allFrames}
          limit={selectedView === "annotated" ? 7 : 8}
          rightRef={rightRef}
          leftRef={leftRef}
          setDisableToggle={setDisableToggle}
          additionalStyles={{
            style: {
              boxShadow:
                "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
              backgroundColor: "#FFF",
              border: "1px solid #CAC5CD",
              borderRadius: "8px",
              alignSelf: "flex-end",
            },
          }}
        />
      </div>
      {expand?.open && (
        <ExpandImage
          openModal={expand?.open}
          closeModal={() =>
            setExpand((prev) => ({
              ...prev,
              open: false,
              img: "",
            }))
          }
          img={expand?.img}
        />
      )}
      {openUploadAnnotation && (
        <UploadAnnotation
          openModal={openUploadAnnotation}
          closeModal={() => setOpenUploadAnnotation(false)}
          repoId={repoId}
          getLabels={getExistingLabels}
        />
      )}
    </div>
  );
};

export default DataRepoView;
