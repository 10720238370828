const theme = {
  blue: {
    bg: "bg-[#084298]",
    hover: "hover:bg-[#3A74CA]",
    active: "active:bg-[#3A74CA]",
    focus: "focus:bg-[#3A74CA]",
  },
  green: {
    bg: "bg-[#69B04B]",
    hover: "hover:bg-[#43A047]",
    active: "active:bg-[#43A047]",
    focus: "focus:bg-[#43A047]",
  },
};

const PrimaryButton = ({
  disable = false,
  onClick = null,
  text,
  width = null,
  height = null,
  Icon = null,
  color = "blue",
}) => {
  return (
    <button
      className={
        disable
          ? "flex items-center gap-1 justify-center text-[#AEA9B1] text-sm font-medium rounded-lg bg-[#EBEBEB]"
          : `flex items-center gap-1 justify-center text-[#FFF] text-sm font-medium rounded-lg ${theme[color].bg} ${theme[color].hover} hover:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15),0px_1px_2px_0px_rgba(0,0,0,0.30)] ${theme[color].focus} focus:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15),0px_1px_2px_0px_rgba(0,0,0,0.30)] focus:outline-none ${theme[color].active}`
      }
      style={{
        width: width ? width : "100%",
        height: height ? height : "auto",
        padding: Icon ? "6px 16px 6px 8px" : "8px 16px 8px 16px",
      }}
      onClick={onClick}
      disabled={disable}
    >
      {Icon}
      {text}
    </button>
  );
};

export default PrimaryButton;
