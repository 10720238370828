import {
  RuntimeCalculator,
  appStatusColors,
  appStatusIcons,
  dataTypeIconMap,
  modelIcons,
  modelStatusColors,
  modelStatusIcon,
} from "../utils/HandlerFuncs";
import { Chip } from "./CommonComponents";

const iconNameCommonClass = "flex flex-row gap-2 capitalize items-center";

export const dataColumnDefinition = [
  {
    field: "name",
    headerName: "Name",
    type: "string",
  },
  {
    field: "owner",
    headerName: "Owner",
    type: "string",
  },
  {
    field: "lastUpdatedAt",
    headerName: "Updated At",
    type: "dateTime",
    valueGetter: ({ value }) => new Date(value),
    valueFormatter: ({ value }) => {
      return new Date(value).toLocaleString("en-GB");
    },
  },
  {
    field: "fileCount",
    headerName: "File Count",
    type: "number",
  },
  {
    field: "videoCount",
    headerName: "No. of Videos",
    type: "number",
  },
  {
    field: "linkedModelCount",
    headerName: "Model Count",
    type: "number",
  },
  {
    field: "dataRepositoryType",
    headerName: "Type",
    type: "string",
    valueGetter: ({ row }) => {
      return row?.isCameraRepo ? "CAMERA" : row?.dataRepositoryType;
    },
    renderCell: ({ row, value }) => {
      return (
        <div className={iconNameCommonClass}>
          {dataTypeIconMap[value]}
          <p>{value}</p>
        </div>
      );
    },
  },
];

export const dataFlexMap = {
  name: 1,
  owner: 1,
  lastUpdatedAt: 1,
  fileCount: 0.5,
  videoCount: 0.5,
  linkedModelCount: 0.5,
  dataRepositoryType: 0.5,
  actions: 1,
};

export const ModelColumnDefinition = [
  {
    field: "name",
    headerName: "Name",
    type: "string",
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "dateTime",
    valueGetter: ({ value }) => new Date(value),
    valueFormatter: ({ value }) => {
      return new Date(value).toLocaleString("en-GB");
    },
  },
  {
    field: "modelType",
    headerName: "Model Type",
    type: "string",
  },
  {
    field: "modelPlatform",
    headerName: "Model Platform",
    type: "string",
    renderCell: ({ value }) => {
      return (
        <div className={iconNameCommonClass} title={value}>
          {<img src={modelIcons[value]} alt={value} className="w-5 h-5" />}
          <p className="w-full truncate">{value}</p>
        </div>
      );
    },
  },
  {
    field: "predictionRunCount",
    headerName: "Prediction iterations",
    valueGetter: ({ value }) => value ?? 0,
    type: "number",
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    renderCell: ({ value, row }) => {
      let { text, elem } = modelStatusIcon({ status: value });
      let isString = typeof elem === "string";
      return (
        <div className={iconNameCommonClass} title={text}>
          {!row?.isDefault && (
            <div
              className="relative w-6 h-6"
              style={{
                color: modelStatusColors({ status: value })?.hex,
              }}
            >
              <Chip
                src={isString ? elem : null}
                customElem={isString ? null : elem}
                padding="0px 0px"
                width="24px"
                height="24px"
                rounded="999px"
                top={"0px"}
                right={"0px"}
              />
            </div>
          )}
          <p className="truncate">{row?.isDefault ? "Default" : text}</p>
        </div>
      );
    },
  },
];

export const modelFlexMap = {
  name: 1,
  createdAt: 1,
  modelType: 0.5,
  modelPlatform: 1,
  status: 0.75,
  predictionRunCount: 0.6,
  actions: 1,
};

export const AppColumnDefinition = [
  {
    field: "name",
    headerName: "Name",
    type: "string",
  },
  {
    field: "owner",
    headerName: "Owner",
    type: "string",
  },
  {
    field: "lastUpdatedAt",
    headerName: "Updated At",
    type: "dateTime",
    valueGetter: ({ value }) => new Date(value),
    valueFormatter: ({ value }) => {
      return new Date(value).toLocaleString("en-GB");
    },
  },
  {
    field: "modelType",
    headerName: "Model Type",
    type: "string",
  },
  {
    field: "modelPlatform",
    headerName: "Model Platform",
    type: "string",
    renderCell: ({ value }) => {
      return (
        <div className={iconNameCommonClass} title={value}>
          {<img src={modelIcons[value]} alt={value} className="w-5 h-5" />}
          <p className="w-full truncate text-end">{value}</p>
        </div>
      );
    },
  },
  {
    field: "dataRepoType",
    headerName: "Type",
    type: "string",
    renderCell: ({ value }) => {
      return (
        <div className={iconNameCommonClass} title={value}>
          {dataTypeIconMap[value]}
          <p className="w-full truncate text-end">{value}</p>
        </div>
      );
    },
  },
  {
    field: "cameraDetail",
    headerName: "No. of feeds",
    type: "number",
    valueGetter: ({ value }) => value?.length,
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    renderCell: ({ value }) => {
      let { text, elem } = appStatusIcons({ status: value });
      let isString = typeof elem === "string";
      return (
        <div className={iconNameCommonClass} title={text}>
          <div
            className="relative w-6 h-6"
            style={{
              color: appStatusColors({ status: value })?.hex,
            }}
          >
            <Chip
              src={isString ? elem : null}
              customElem={isString ? null : elem}
              padding="0px 0px"
              width="24px"
              height="24px"
              rounded="999px"
              top={"0px"}
              right={"0px"}
            />
          </div>
          {text}
        </div>
      );
    },
  },
];

export const appFlexMap = {
  name: 1,
  owner: 1,
  lastUpdatedAt: 1,
  modelType: 0.5,
  modelPlatform: 0.75,
  dataRepoType: 0.5,
  cameraDetail: 0.5,
  status: 0.5,
  actions: 1,
};

export const modelHistoryColumnDefinition = [
  {
    field: "modelType",
    headerName: "Model Type",
    type: "string",
  },
  {
    field: "modelPlatform",
    headerName: "Model Platform",
    type: "string",
    renderCell: ({ value }) => {
      return (
        <div className={iconNameCommonClass} title={value}>
          {<img src={modelIcons[value]} alt={value} className="w-5 h-5" />}
          <p className="w-full truncate text-end">{value}</p>
        </div>
      );
    },
  },
  {
    field: "dataRepoName",
    headerName: "Data repo used",
    type: "string",
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    valueGetter: ({ row }) => {
      return Object?.entries(row?.stateChangeTs || {})?.slice(-1)?.[0]?.[0];
    },
  },
  {
    field: "predictedResultSize",
    headerName: "Files predicted",
    type: "number",
  },
  {
    field: "imagesInferenceOn",
    headerName: "Files provided",
    type: "number",
  },
  {
    field: "stateChangeTs",
    headerName: "Time taken",
    valueGetter: ({ value }) => RuntimeCalculator({ timeStates: value }),
    type: "string",
  },
];

export const modelHistoryFlexMap = {
  __check__: 0.25,
  modelType: 1,
  modelPlatform: 1,
  dataRepoName: 1,
  status: 0.5,
  predictedResultSize: 0.5,
  imagesInferenceOn: 0.5,
  stateChangeTs: 0.5,
};

export const alertSettingsColumnDefinition = [
  {
    field: "alertName",
    headerName: "Alert name",
    type: "string",
  },
  {
    field: "cameraName",
    headerName: "Camera name",
    type: "string",
  },
  {
    field: "lastUpdatedAt",
    headerName: "Last updated on",
    type: "dateTime",
    valueGetter: ({ value }) => new Date(value),
    valueFormatter: ({ value }) => {
      return new Date(value).toLocaleString("en-GB");
    },
  },
  {
    field: "alertCondition",
    headerName: "Alert condition",
    type: "string",
    renderCell: ({ value }) => {
      return (
        <div className="flex flex-col gap-1 w-full text-sm capitalize items-center p-2">
          {value?.map((item, idx) => {
            let notCondition = item?.length > 1 || !("operation" in item[0]);
            let chainedItem = item?.length >= 3;
            return notCondition ? (
              <div
                key={idx}
                className="flex whitespace-nowrap gap-1 items-start"
              >
                {chainedItem && (
                  // <div className="h-[1px] w-full border border-dashed border-black" />
                  <p>[</p>
                )}
                {item?.map((val, idx2) => {
                  let isOperation = "operation" in val;
                  return isOperation ? (
                    <p key={idx2} className="text-gray-400 self-center">
                      {val?.operation}
                    </p>
                  ) : (
                    <p key={idx2} className="self-center">
                      When {val?.label} is {val?.state}
                    </p>
                  );
                })}
                {chainedItem && (
                  // <div className="h-[1px] w-full border border-dashed border-black" />
                  <p>]</p>
                )}
              </div>
            ) : (
              <p key={idx} className="text-gray-400">
                {item[0]?.operation}
              </p>
            );
          })}
        </div>
      );
    },
  },
];

export const alertSettingsFlexMap = {
  alertName: 1,
  cameraName: 1,
  lastUpdatedAt: 1,
  alertCondition: 4,
  actions: 1,
};

export const camAlertsColumnDefinition = [
  {
    field: "alertName",
    headerName: "Alert name",
    type: "string",
  },
  {
    field: "cameraName",
    headerName: "Camera name",
    type: "string",
  },
  {
    field: "createdAt",
    headerName: "Detection time",
    type: "dateTime",
    valueGetter: ({ value }) => new Date(value * 1000),
    valueFormatter: ({ value }) => {
      return new Date(value).toLocaleString("en-GB");
    },
  },
  {
    field: "alertCondition",
    headerName: "Alert condition",
    type: "string",
    renderCell: ({ value }) => {
      return (
        <div className="flex flex-col gap-1 w-full text-sm capitalize items-center p-2">
          {value?.map((item, idx) => {
            let notCondition = item?.length > 1 || !("operation" in item[0]);
            let chainedItem = item?.length >= 3;
            return notCondition ? (
              <div
                key={idx}
                className="flex whitespace-nowrap gap-1 items-start"
              >
                {chainedItem && (
                  // <div className="h-[1px] w-full border border-dashed border-black" />
                  <p>[</p>
                )}
                {item?.map((val, idx2) => {
                  let isOperation = "operation" in val;
                  return isOperation ? (
                    <p key={idx2} className="text-gray-400 self-center">
                      {val?.operation}
                    </p>
                  ) : (
                    <p key={idx2} className="self-center">
                      When {val?.label} is {val?.state}
                    </p>
                  );
                })}
                {chainedItem && (
                  // <div className="h-[1px] w-full border border-dashed border-black" />
                  <p>]</p>
                )}
              </div>
            ) : (
              <p key={idx} className="text-gray-400">
                {item[0]?.operation}
              </p>
            );
          })}
        </div>
      );
    },
  },
];

export const camAlertsFlexMap = {
  alertName: 1,
  cameraName: 1,
  createdAt: 1,
  alertCondition: 4,
  actions: 1,
};

export const formatColumns = ({ cols = [], color = "", flexMap = {} }) => {
  let newCols = cols?.map((item, idx) => {
    let align =
      idx === 0 ? "left" : idx === cols.length - 1 ? "right" : "center";
    let className = color === "green" ? "!bg-[#c8e6c9]" : "bg-[#DEF]";
    return {
      ...item,
      flex: flexMap[item?.field] ?? 1,
      minWidth: flexMap[item?.field] * 150 ?? 150,
      headerAlign: align,
      align: align,
      headerClassName: className,
    };
  });
  return newCols;
};
