import { useContext, useEffect, useState } from "react";
import { callApi } from "../../utils/ApiUtil";
import CamCard from "./CamCard";
import { useToast } from "@chakra-ui/react";
import Timer from "../../../../util/VisionUtils/Timer";
import { useWindowSize } from "@uidotdev/usehooks";
import NavContext from "../../../NavContext";

const Feed = ({ modelId, appName, appId }) => {
  const [camData, setCamData] = useState([]);
  const { creationLimits } = useContext(NavContext);
  const [initialRender, setInitialRender] = useState(true);
  const size = useWindowSize();
  const toast = useToast();

  const getCamStatus = async () => {
    try {
      const param = {
        appObjectId: appId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/inference/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        setCamData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCamStatus();
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 relative">
      <div
        className={
          size?.width <= 900
            ? "col-span-full flex justify-end w-full"
            : "absolute -top-14 right-0 w-fit whitespace-nowrap"
        }
      >
        <Timer
          initialSeconds={15}
          initialRender={initialRender}
          setInitialRender={setInitialRender}
          callFunc={getCamStatus}
        />
      </div>
      {camData?.map((item, idx) => {
        return (
          <CamCard
            key={idx}
            isAdd={
              idx === 0 && creationLimits?.cameraPerAppAllowed > camData?.length
            }
            modelId={modelId}
            appName={appName}
            data={item}
            appId={appId}
          />
        );
      })}
    </div>
  );
};

export default Feed;
