import { useEffect, useMemo, useRef, useState } from "react";
import {
  CardContainer,
  CommonProgressBar,
  CommonUniqueTextCheck,
  CustomCheckbox,
  CustomSelect,
  FieldLabel,
  NavigateBack,
  TransitionCards,
} from "../Common/CommonComponents";
import { useToast } from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { callApi } from "../utils/ApiUtil";
import {
  debounce,
  nameDescCheck,
  rtspPingCheck,
  rtspRegex,
} from "../utils/HandlerFuncs";
import { useLocation, useNavigate } from "react-router-dom";
import { mixpanelAppCreateUpdateTrack } from "../utils/MixpanelEvents";
import Paginator from "../../../util/VisionUtils/Paginator";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, OutlinedInput } from "@mui/material";

const AddApp = ({ searchParams, setSearchParams }) => {
  const [models, setModels] = useState([]);
  const [disable, setDisable] = useState(false);
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const appName = searchParams?.get("appName") ?? "";
  const [selectedModel, setSelectedModel] = useState(
    searchParams?.get("modelId") ? searchParams?.get("modelId") : ""
  );
  const [appId, setAppId] = useState(
    searchParams?.get("appId") ? searchParams?.get("appId") : ""
  );
  const [startPoll, setStartPoll] = useState(false);
  const [validateRtsp, setValidateRtsp] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [labels, setLabels] = useState([]);
  const [displayLabels, setDisplayLabels] = useState([]);
  const rtspRef = useRef();
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef();
  const camNameRef = useRef();
  const appNameRef = useRef();

  const labelHandler = (e, item) => {
    if (e.target.checked) {
      setSelectedLabels((prev) => [...prev, item]);
    } else {
      setSelectedLabels((prev) => {
        let newData = [...prev];
        let idx = newData?.findIndex((val) => val === item);
        newData.splice(idx, 1);
        return newData;
      });
    }
  };

  const getLabels = async () => {
    try {
      const param = {
        modelObjectId: selectedModel,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/model/modelInfo/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        setLabels(response?.data?.labelInfo ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getModels = async () => {
    try {
      const response = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/model/",
      });
      if (response?.status === 200) {
        setModels(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleValidateRtsp = async () => {
    if (
      !nameDescCheck({
        val: appNameRef?.current?.value,
        toast: toast,
        name: "app name",
      }) ||
      !nameDescCheck({
        val: camNameRef?.current?.value,
        toast: toast,
        name: "camera name",
      })
    )
      return;
    if (selectedModel === "" || !rtspRegex?.test(rtspRef?.current?.value)) {
      toast({
        title: "Error",
        description: (
          <div>
            {selectedModel === "" && <p>Please select a model</p>}
            {!rtspRegex?.test(rtspRef?.current?.value) && (
              <p>Invalid rtsp provided</p>
            )}
          </div>
        ),
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      return;
    } else {
      let res = await rtspPingCheck({
        uuid: "unique_Id_" + Math.random() * 1000000,
        toast: toast,
        rtsp: rtspRef?.current?.value,
        setProgressState: setProgressState,
      });
      if (!res) return;
    }
    setDisable(true);
    setValidateRtsp(true);
  };

  const handleSubmit = () => {
    if (selectedLabels?.length === 0) {
      toast({
        title: "No labels",
        description: "Please select labels for the app",
        status: "error",
        isClosable: true,
        position: "top-right",
        duration: 6000,
      });
      return;
    }
    if (appId !== "") addCam();
    else createApp();
  };

  useEffect(() => {
    getModels();
  }, []);

  useEffect(() => {
    if (selectedModel !== "") getLabels();
  }, [selectedModel]);

  const createApp = async () => {
    try {
      const requestData = JSON.stringify({
        modelObjectId: selectedModel,
        appName: appNameRef?.current?.value,
        rtspLink: rtspRef?.current?.value?.trim(),
        cameraName: camNameRef?.current?.value,
        labels: selectedLabels?.map((item) => item?.label),
      });
      const response = await callApi({
        type: "post",
        endPoint: "selfserve/v2/project/v1/app/",
        toast: toast,
        requestBody: requestData,
      });
      if (response?.status === 200) {
        setAppId(response?.data?.objectId);
        setStartPoll(true);
        mixpanelAppCreateUpdateTrack({
          appName: appNameRef?.current?.value,
          appId: response?.data?.objectId,
          modelName: models?.find(
            (item) => item?.masterObjectId === selectedModel
          )?.name,
          modelId: selectedModel,
          dataStreamName: camNameRef?.current?.value,
          dataStreamLink: rtspRef?.current?.value,
          labels: selectedLabels,
          numberOfCameras: 1,
          isUpdate: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addCam = async () => {
    try {
      const requestData = JSON.stringify({
        appObjectId: appId,
        cameraName: camNameRef?.current?.value,
        rtspLink: rtspRef?.current?.value,
        labels: selectedLabels?.map((item) => item?.label),
      });
      const response = await callApi({
        type: "post",
        toast: toast,
        endPoint: "selfserve/v2/project/v1/app/camera/",
        requestBody: requestData,
      });
      if (response?.status === 200) {
        setStartPoll(true);
        mixpanelAppCreateUpdateTrack({
          appName: appNameRef?.current?.value,
          appId: appId,
          modelName: models?.find(
            (item) => item?.masterObjectId === selectedModel
          )?.name,
          modelId: selectedModel,
          dataStreamName: camNameRef?.current?.value,
          dataStreamLink: rtspRef?.current?.value,
          labels: selectedLabels,
          numberOfCameras: response?.data?.cameraDetail?.length,
          isUpdate: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getApps = async () => {
    try {
      const param = {
        masterObjectId: appId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        let data = response?.data;
        if (data) {
          if (data?.status === "READY") {
            navigate(
              `/SandboxV2/view/app?appId=${data?.objectMasterId}&modelId=${data?.modelObjectId}&appName=${data?.name}`,
              {
                state: {
                  prevPath: location?.pathname + location?.search,
                },
              }
            );
            setProgressState((prev) => ({ ...prev, state: 2 }));
          } else if (data?.status === "FAILED") {
            toast({
              title: "Failed",
              status: "error",
              duration: 3000,
              position: "top-right",
            });
            setProgressState((prev) => ({ ...prev, state: 0 }));
            navigate("/SandboxV2/app", {
              state: {
                prevPath: location?.pathname + location?.search,
              },
            });
          }
        }
      } else throw new Error("get failed");
    } catch (error) {
      setProgressState((prev) => ({ ...prev, state: 0 }));
      navigate("/SandboxV2/app", {
        state: {
          prevPath: location?.pathname + location?.search,
        },
      });
      console.log(error);
    }
  };

  const debouncedSearchSet = debounce(
    (e) => setSearchValue((prev) => e?.target?.value),
    300
  );

  useEffect(() => {
    if (startPoll) {
      setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
      getApps();
      let intervalId = setInterval(() => {
        getApps();
      }, 10 * 1000);
      return () => clearInterval(intervalId);
    }
  }, [startPoll]);

  const filteredLabels = useMemo(() => {
    return labels?.filter((item) =>
      item.label.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [labels, searchValue]);

  useEffect(() => {
    if (filteredLabels?.length === 0) setDisplayLabels([]);
  }, [filteredLabels]);
  return (
    <div className="flex flex-col gap-2">
      <NavigateBack
        targetRoute={-1}
        textArray={
          appName === ""
            ? ["AI Studio", "create new application"]
            : ["AI Studio", appName, "Add camera"]
        }
      />
      <div className="flex flex-col gap-6">
        <div className="p-6 rounded-xl bg-white flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <FieldLabel text="App name" />
            <OutlinedInput
              inputRef={appNameRef}
              placeholder="Enter app name"
              sx={{
                width: "250px",
              }}
              size="small"
              defaultValue={appName}
              disabled={disable || appName !== ""}
            />
          </div>
          <div className="flex flex-col gap-3">
            <FieldLabel text="Select model" />
            <CustomSelect
              options={[
                { name: "Select model", masterObjectId: "" },
                ...models,
              ]}
              isPlain={false}
              displayKey="name"
              valueKey="masterObjectId"
              value={selectedModel}
              displayEmpty={true}
              setValue={setSelectedModel}
              disable={disable || appName !== ""}
            />
          </div>
          <div className="flex gap-2 items-center flex-wrap">
            <div className="flex flex-col gap-3">
              <FieldLabel text="Data stream name" />
              <OutlinedInput
                size="small"
                inputRef={camNameRef}
                placeholder="Enter name"
                sx={{
                  width: "250px",
                }}
                disabled={disable}
              />
            </div>
            <div className="flex flex-col gap-3">
              <FieldLabel text="Enter data source live link" />
              <OutlinedInput
                size="small"
                inputRef={rtspRef}
                placeholder="Enter rtsp link"
                sx={{
                  width: "250px",
                }}
                disabled={disable}
              />
            </div>
            {validateRtsp && (
              <div className="self-end p-2 rounded bg-[#E7F3E2] text-[#69B04B] flex gap-[10px]">
                RTSP link validated successfully
                <img src="/selfServiceIcons/live.svg" alt="ok" />
              </div>
            )}
          </div>
          {validateRtsp ? (
            <TonalButton
              text={"Edit"}
              width={"fit-content"}
              onClick={() => {
                setSelectedLabels([]);
                setDisable(false);
                setValidateRtsp(false);
              }}
            />
          ) : (
            <PrimaryButton
              text={"Validate RTSP"}
              width={"fit-content"}
              onClick={handleValidateRtsp}
            />
          )}
        </div>
        <TransitionCards step={true} currentStep={validateRtsp}>
          <FieldLabel text="Select labels" />
          <div className="flex items-center gap-2 justify-between">
            <OutlinedInput
              ref={inputRef}
              defaultValue={searchValue}
              onChange={(e) => debouncedSearchSet(e)}
              placeholder="Search..."
              sx={{
                width: "100%",
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            {filteredLabels?.length !== 0 && (
              <Paginator
                limit={10}
                setDisplayData={setDisplayLabels}
                data={filteredLabels}
              />
            )}
          </div>
          <CardContainer>
            {displayLabels?.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="w-full flex justify-between rounded px-4 py-5 capitalize"
                  style={{
                    boxShadow:
                      "4px 4px 24px 0px rgba(0, 0, 0, 0.07), -4px -4px 24px 0px rgba(0, 0, 0, 0.07)",
                  }}
                >
                  <FieldLabel text={item?.label} isRequired={false} />
                  <CustomCheckbox
                    isChecked={selectedLabels?.some(
                      (val) => val?.label === item?.label
                    )}
                    handleChange={(e) => labelHandler(e, item)}
                  />
                </div>
              );
            })}
          </CardContainer>
          <PrimaryButton
            text={"Start inference"}
            width={"fit-content"}
            onClick={handleSubmit}
          />
        </TransitionCards>
      </div>
      {!(disable || appName !== "") && (
        <CommonUniqueTextCheck
          valueKey="name"
          name={`App name`}
          inputRefValue={appNameRef}
          endPoint={"selfserve/v2/project/v1/app/"}
        />
      )}
      {progressState?.open && (
        <CommonProgressBar
          openModal={progressState?.open}
          closeModal={() =>
            setProgressState((prev) => ({
              ...prev,
              open: false,
              state: null,
            }))
          }
          state={progressState?.state}
        />
      )}
    </div>
  );
};

export default AddApp;
