import { Portal } from "@mui/material";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";

import {
  DataGridPro,
  GridPagination,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";

// footer with only pagination and page select option
export const CustomFooter = (props) => {
  return (
    <>
      <div className="flex flex-row gap-0 items-center h-[35px] justify-end border-t border-gray-200">
        <GridPagination />
      </div>
    </>
  );
};

// portal for taking filter's out of the heirarchy of data grid
export const CustomToolbar = (props) => {
  return (
    <>
      {props?.toolBarId && (
        <Portal container={() => document.getElementById(props?.toolBarId)}>
          <GridToolbarQuickFilter
            variant={"outlined"}
            label={"Search Board"}
            placeholder={""}
            size={"small"}
          />
        </Portal>
      )}
    </>
  );
};

// material data grid with custom styling
const StyledDataGrid = styled(DataGridPro)({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#DDEEFF",
    textTransform: "capitalize",
    color: "#3E3C42",
    border: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "600 !important",
  },
  "& .MuiDataGrid-cell": {
    borderColor: "#EDF2F7",
  },
  "& .MuiDataGrid-row": {
    color: "#3E3C42",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F5F5F5",
    },
  },
  "& .css-9vna8i-MuiButtonBase-root-MuiIconButton-root": {
    color: "white !important",
  },
  "&.MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiDataGrid-menuIconButton": {
    color: "#3E3C42",
  },
});
export const CustomStyledDataGrid = (props) => {
  const MuiTheme = createTheme({
    typography: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 13,
    },
    components: {
      MuiDataGrid: {
        defaultProps: {
          slotProps: {
            panel: {
              sx: {
                "& .MuiDataGrid-panelWrapper": {
                  maxWidth: "calc(100vw - 3rem)",
                },
              },
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={MuiTheme}>
      <StyledDataGrid {...props} />
    </ThemeProvider>
  );
};
