import { useEffect, useRef, useState } from "react";
import {
  CustomSelect,
  DivTabs,
  FieldLabel,
  NavigateBack,
} from "../../Common/CommonComponents";
import AlertCondition from "./AlertCondition";
import TonalButton from "../../../../util/Buttons/TonalButton";
import { useToast } from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { callApi } from "../../utils/ApiUtil";
import { OutlinedInput } from "@mui/material";
import { mixpanelAlertAddUpdateTrack } from "../../utils/MixpanelEvents";

const AddAlert = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const appId = searchParams?.get("appId");
  const toast = useToast();
  const cameraName = searchParams?.get("cameraName");
  const camId = searchParams?.get("camId");
  const appName = searchParams?.get("appName");
  const alertName = searchParams?.get("alertName");
  const alertId = searchParams?.get("alertId");
  const navigate = useNavigate();
  const [cameraData, setCameraData] = useState([]);
  const [selectedCam, setSelectedCam] = useState(camId);
  const nameRef = useRef();
  const oldConditionRef = useRef();
  const [conditions, setConditions] = useState([
    [
      {
        label: "",
        state: "",
      },
    ],
  ]);
  const [labels, setLabels] = useState([]);
  const found = [
    { state: "Select state", stateId: "" },
    { state: "Detected", stateId: "Detected" },
    { state: "Not Detected", stateId: "Not Detected" },
  ];

  const handleAdd = () => {
    setConditions((prev) => {
      let newData = structuredClone(prev);
      newData.push([
        {
          operation: "",
        },
      ]);
      newData.push([
        {
          label: "",
          state: "",
        },
      ]);
      return newData;
    });
  };

  const getApps = async () => {
    try {
      const param = {
        masterObjectId: appId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        setCameraData(response?.data?.cameraDetail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAlerts = async () => {
    try {
      const param = {
        masterObjectId: appId,
        cameraId: selectedCam,
        alertId: alertId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/alert/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        oldConditionRef.current = response?.data[0]?.alertCondition;
        setConditions(response?.data[0]?.alertCondition);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemove = (idx) => {
    setConditions((prev) => {
      let newData = structuredClone(prev);
      newData.splice(idx, 1);
      newData.splice(idx - 1, 1);
      return newData;
    });
  };

  const checkAlertCondition = () => {
    let conditionsOk = true;
    for (let idx in conditions) {
      let isArray = Array.isArray(conditions[idx]);
      if (isArray) {
        for (let item in conditions[idx]) {
          let arr = conditions[idx][item];
          for (let value in arr) {
            if (arr[value] === "") {
              toast({
                title: "Incomplete alert",
                description: (
                  <div>
                    <p>Please ensure mandatory fields are not empty</p>
                    <p>{value} is empty</p>
                  </div>
                ),
                status: "error",
                position: "top-right",
                duration: 6000,
                isClosable: true,
              });
              conditionsOk = false;
              break;
            }
          }
        }
      }
    }
    return conditionsOk;
  };

  const saveAlert = async () => {
    try {
      const param = alertId
        ? {
            masterObjectId: appId,
            alertId: alertId,
            alertName: nameRef?.current?.value,
          }
        : {
            masterObjectId: appId,
            cameraId: selectedCam,
            alertName: nameRef?.current?.value,
          };
      const requestData = JSON.stringify(conditions);
      const response = await callApi({
        type: alertId ? "patch" : "post",
        endPoint: "selfserve/v2/project/v1/app/alert/",
        toast: toast,
        params: param,
        requestBody: requestData,
      });
      if (response?.status === 200) {
        toast({
          title: alertId ? "Alert updated" : "Alert added",
          description: "The alert will take affect in 1-2 minutes",
          status: "success",
          position: "top-right",
          duration: 6000,
        });
        if (alertId)
          mixpanelAlertAddUpdateTrack({
            alertCondition: JSON.stringify(conditions),
            olderConditon: JSON.stringify(oldConditionRef?.current),
            alertName: nameRef?.current?.value,
            alertId: alertId,
            cameraId: selectedCam,
            cameraName: cameraData?.find((item) => item?.camId === selectedCam)
              ?.cameraName,
            appId: appId,
            appName: appName,
            method: "update",
          });
        else
          mixpanelAlertAddUpdateTrack({
            alertCondition: JSON.stringify(conditions),
            alertName: nameRef?.current?.value,
            cameraId: selectedCam,
            cameraName: cameraData?.find((item) => item?.camId === selectedCam)
              ?.cameraName,
            appId: appId,
            appName: appName,
          });
        navigate(-1, {
          state: {
            prevPath: location?.pathname + location?.search,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getApps();
    if (alertId) getAlerts();
  }, []);

  useEffect(() => {
    if (selectedCam !== "" && cameraData?.length > 0)
      setLabels((prev) => [
        { label: "Select label", labelId: "" },
        ...cameraData
          ?.find((item) => item?.camId === selectedCam)
          ?.labels?.map((item) => ({
            label: item,
            labelId: item,
          })),
      ]);
  }, [selectedCam, cameraData]);

  return (
    <div className="flex flex-col gap-2">
      <NavigateBack
        targetRoute={-1}
        textArray={
          cameraName !== ""
            ? [
                "AI Studio",
                appName,
                cameraName,
                alertId ? "Update alert" : "Create alert",
              ]
            : ["AI Studio", appName, alertId ? "Update alert" : "Create alert"]
        }
      />
      <div className="flex flex-col gap-6 p-6 bg-white rounded-xl">
        {/*names */}
        <div className="flex flex-col gap-3">
          <FieldLabel text="Enter alert name" />
          <OutlinedInput
            size="small"
            defaultValue={alertName ?? ""}
            placeholder="Enter alert name"
            inputRef={nameRef}
            sx={{
              width: "250px",
            }}
          />
        </div>
        {/*Camera */}
        <div className="flex flex-col gap-3">
          <FieldLabel text="Select camera" />
          {cameraData?.length > 0 && (
            <CustomSelect
              options={[
                { cameraName: "Select camera", camId: "" },
                ...cameraData,
              ]}
              isPlain={false}
              displayKey="cameraName"
              valueKey="camId"
              value={selectedCam}
              displayEmpty={true}
              disable={
                camId !== "" ||
                conditions?.length > 1 ||
                conditions[0][0]["label"] !== ""
              }
              setValue={(value) => setSelectedCam(value)}
            />
          )}
        </div>
        {/*Conditions */}
        {selectedCam !== "" && (
          <>
            <div className="flex flex-col gap-6 items-center p-2 rounded-lg border border-[#CAC5CD] w-fit">
              {conditions?.map((item, idx) => {
                return idx % 2 === 0 ? (
                  <AlertCondition
                    key={idx}
                    conditionIndex={idx}
                    conditions={conditions}
                    labels={labels}
                    found={found}
                    setConditions={setConditions}
                    handleRemove={handleRemove}
                    selectedCam={selectedCam}
                  />
                ) : (
                  <DivTabs
                    key={idx}
                    options={["and", "or"]}
                    value={conditions[idx][0]?.operation}
                    setValue={(e) =>
                      setConditions((prev) => {
                        let newData = structuredClone(prev);
                        newData[idx][0]["operation"] = e;
                        return newData;
                      })
                    }
                  />
                );
              })}
            </div>
            <TonalButton
              Icon={<AddIcon sx={{ color: "#447ED4" }} />}
              text={"Add Condition"}
              width={"fit-content"}
              onClick={handleAdd}
              disable={selectedCam === ""}
            />
          </>
        )}
        {/*Medium */}
        <PrimaryButton
          text={alertId ? "Update Alert" : "Create alert"}
          width={"fit-content"}
          onClick={() => {
            if (checkAlertCondition()) saveAlert();
          }}
        />
      </div>
    </div>
  );
};

export default AddAlert;
