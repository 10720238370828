import LabelPie from "../../../../Charts/SelfService/LabelPie";
import { ImageContainer } from "../../../Common/CommonComponents";

export const colors = [
  "#ffc107",
  "#5193f6",
  "#ef6f12",
  "#1c56ac",
  "#e91e63",
  "#00bcd4",
  "#8bc34a",
  "#9c27b0",
  "#673ab7",
  "#ff9800",
  "#4caf50",
  "#795548",
  "#ff5722",
  "#03a9f4",
  "#cddc39",
  "#009688",
  "#e65100",
  "#ffeb3b",
  "#c2185b",
  "#3f51b5",
  "#2196f3",
  "#004d40",
  "#f44336",
  "#8e24aa",
  "#ff6f00",
  "#689f38",
  "#bcaaa4",
  "#757575",
  "#607d8b",
  "#3949ab",
  "#ff4081",
  "#7c4dff",
  "#76ff03",
  "#8c9eff",
  "#ffab00",
  "#ff5252",
  "#aa00ff",
  "#00e676",
  "#d500f9",
  "#00bfa5",
  "#ffd600",
  "#e040fb",
  "#ff3d00",
  "#6200ea",
  "#304ffe",
  "#64ffda",
  "#00c853",
  "#ff9100",
  "#33691e",
  "#ff1744",
  "#dd2c00",
  "#1a237e",
  "#00acc1",
  "#5d4037",
  "#a1887f",
  "#283593",
  "#b388ff",
  "#1de9b6",
  "#dce775",
  "#fdd835",
  "#00796b",
  "#d32f2f",
  "#c51162",
  "#1b5e20",
  "#d50000",
  "#263238",
  "#bf360c",
  "#01579b",
  "#a5d6a7",
  "#ff80ab",
  "#ffab91",
  "#8eacbb",
  "#ffd180",
  "#69f0ae",
  "#40c4ff",
  "#ff9e80",
  "#c6ff00",
  "#f48fb1",
  "#cfd8dc",
  "#a7ffeb",
  "#ff7043",
  "#d1c4e9",
  "#ffca28",
  "#ffccbc",
  "#b0bec5",
  "#d7ccc8",
  "#d500f9",
  "#004d40",
  "#b2ff59",
  "#ff4081",
  "#ea80fc",
  "#eeff41",
  "#00e5ff",
  "#00c853",
];

const LabelStats = ({ predictionData }) => {
  return (
    <div
      className="p-4 flex gap-4 bg-white border border-[#CAC5CD] rounded-lg w-full md:w-[calc(100%-200px)]"
      style={{
        boxShadow:
          "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
      }}
    >
      <LabelPie predictionData={predictionData} />
      <div className="flex gap-4 items-center overflow-x-auto">
        {predictionData &&
          Object.keys(predictionData).length > 0 &&
          Object.keys(predictionData).map((item, idx) => {
            let sum = Object.keys(predictionData).reduce((a, b) => {
              return a + predictionData[b]?.img?.length;
            }, 0);
            let percentage = (
              (predictionData[item]?.img?.length / sum) *
              100
            ).toFixed(1);
            return (
              <div
                className="rounded-lg flex flex-col gap-0 bg-[#f8f8fa] min-w-[200px] whitespace-nowrap"
                key={idx}
              >
                <div className="flex items-center gap-2 rounded-t-lg p-2 w-full bg-[#F5F5F5]">
                  <div
                    className="h-3 w-3 rounded-full"
                    style={{ backgroundColor: colors[idx] }}
                  />
                  <p
                    className="text-[#48464C] text-sm w-full truncate font-medium"
                    title={item}
                  >
                    {item}
                  </p>
                </div>
                <ImageContainer width="200px" height="108px">
                  <img
                    src={predictionData[item]?.img[0]?.img}
                    alt="img"
                    className="h-full w-full rounded-b-lg"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 flex gap-2 items-center p-2 rounded-b-lg text-white text-base">
                    <p>{predictionData[item]?.img?.length} Files</p>
                    <p className="font-semibold">{percentage}%</p>
                  </div>
                </ImageContainer>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LabelStats;
