import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, useToast } from '@chakra-ui/react';
import OTPInput from 'react-otp-input';
import { baseURL, isOnPrem } from '../../index';
import { EditIcon } from '@chakra-ui/icons';
import FloatingInput from './FloatingInput';
import NavContext from '../NavContext';
import { useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { User, useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { mixpanelLoginFunction } from '../../util/mixpanelFunctions';

const LoginV2 = () => {
  const toast = useToast();
  const param = useAuth0();
  const { setLogin, login } = useContext(NavContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (login) {
      navigate('/');
    }
  }, [login]);

  const emailRef = useRef();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [authToken, setAuthToken] = useState('');

  //OTP States
  const [sentOTP, setSentOTP] = useState(false);
  const [sendingOTP, setSendingOTP] = useState(false);
  const [submittingOTP, setSubmittingOTP] = useState(false);
  const [errorOTP, setErrorOTP] = useState('');

  const handleSubmitOTP = (e) => {
    e.preventDefault();
    setSendingOTP(false);
    setSubmittingOTP(true);
    login_action({
      email,
      password,
      authToken,
      setLogin,
      setErrorOTP,
      setPassword,
      setSubmittingOTP,
    });
  };

  const sendOTPCall = async () => {
    try {
      const data = await fetch(baseURL + 'login' + (isOnPrem === 'true' ? `?onPremDeployment=${isOnPrem}` : ''), {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailRef?.current?.value,
        }),
      });
      if (data.status === 202) {
        //console.log(data?.headers?.get('x-auth-token'))
        setAuthToken(data?.headers?.get('x-auth-token'));
        setSendingOTP(false);
        setSentOTP(true);
        setEmail(emailRef.current?.value);
        setErrorOTP('');
      } else if (data.status === 500) {
        setSendingOTP(false);
        if (emailRef?.current?.value?.length > 0) {
          setErrorOTP('Unauthorized or invalid email address');
          setSendingOTP(false);
        } else {
          setErrorOTP('Please enter an email address');
          setSendingOTP(false);
        }
      } else if (data.status === 400) {
        console.log(data);
        setErrorOTP('This Email id is Deactivated.Please contact your Admin');
        setSendingOTP(false);
      }
    } catch (e) {
      //console.log("Hehehehe");
      setSendingOTP(false);
      console.log(e);
      if (emailRef?.current?.value?.length > 0) {
        //console.log("Hello");
        setErrorOTP('Unauthorized or invalid email address');
      } else setErrorOTP('Please enter an email address');
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setSendingOTP(true);
    sendOTPCall();
  };

  const login_action = async ({
    email,
    password,
    authToken,
    setErrorOTP,
    setPassword,
    setSubmittingOTP,
  }) => {
    try {
      console.log('making a post request for login');
      const login_response = await fetch(baseURL + 'verify', {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': authToken,
        },
        body: JSON.stringify({
          otp: password,
        }),
      });
      const data = login_response.status;
      if (data === 202) {
        localStorage.setItem('logged_in', true);
        localStorage.setItem('auth_token', authToken);
        localStorage.setItem('email', email);
        console.log('token', authToken);
        fetchUserProfile();
        setSubmittingOTP(false);
      } else if (data === 403) {
        setErrorOTP('Please check the OTP you have entered!');
        setPassword('');
        setSubmittingOTP(false);
      }
    } catch (err) {
      setErrorOTP('Error: Please try again!');
      setSubmittingOTP(false);
      setPassword('');
    }
  };

  const handleSignInWithMicrosoft = () => {
    try {
      // Redirect user to Okta for Microsoft sign-in
      param.loginWithPopup();
    } catch (error) {
      console.error('Error signing in with Microsoft:', error);
    }
  };

  const verifySSO = async () => {
    try {
      const requestData = JSON.stringify(param.user);
      const response = await axios.post(baseURL + 'verify/sso', requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response?.status == 200) {
        let token = response?.headers?.get('x-auth-token');
        localStorage.setItem('logged_in', true);
        setAuthToken(token);
        localStorage.setItem('auth_token', token);
        localStorage.setItem('email', param.user.email);
        fetchUserProfile();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const data = await fetch(baseURL + 'user', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': localStorage.getItem('auth_token'),
        },
      });
      const res = await data.json();
      // console.log(res?.data);
      localStorage.setItem('user_type', res?.data?.role || 'GUEST');
      localStorage.setItem('organisation', res?.data?.organisation);
      localStorage.setItem('clientId', res?.data?.clientId);
      localStorage.setItem('location', res?.data?.location);
      localStorage.setItem('fullname', res?.data?.fullname);
      localStorage.setItem(
        'phone',
        res?.data?.phoneNumber ? res?.data?.phoneNumber : ''
      );
      mixpanelLoginFunction(res, res?.data?.email);
      setLogin(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!param.isLoading && param?.isAuthenticated) {
      verifySSO();
    }
  }, [param]);

  return (
    <div className="bg-gradient-to-tl w-screen h-screen from-[#0B295E] to-[#3C8FD4]">
      <div className="absolute top-0 bottom-0 left-0 right-0 h-[70%] m-auto w-[80%] md:h-[65%] md:w-[50%] bg-white rounded-xl flex flex-row normal-case">
        <div className="lg:w-[60%] w-[100%] p-4 pb-0 flex flex-col justify-center gap-20 lg:gap-[25%]">
          <img
            src="/ripik.svg"
            alt="Ripik Logo"
            className="h-[30px] lg:h-[40px] absolute top-[2%] justify-self-center self-center lg:top-4 lg:left-4 lg:justify-self-start lg:self-start "
          />
          <div className="self-center grid">
            {sentOTP === false ? (
              <>
                <p className="text-xl mb-6 text-center justify-self-center">
                  <img className="w-44" src="/verse.jpg" />
                </p>
                <form className="">
                  <div className="w-full flex flex-col justify-center items-center">
                    <div className="mb-6 w-[200px]">
                      <FloatingInput text={'Email'} inputRef={emailRef} />
                    </div>
                    <button
                      type="submit"
                      className="w-[200px] !rounded-full !justify-center !text-center bg-[#3D8FD2] text-white px-2 py-2 cursor-pointer hover:bg-[#0B295E] hover:transition duration-200 "
                      onClick={handleSubmitForm}
                      style={{ textAlign: 'center' }}
                    >
                      {sendingOTP || param.isLoading ? <Spinner /> : 'Get OTP'}
                    </button>
                    <p
                      className="text-[#0B295E] hover:underline hover:scale-105 cursor-pointer mt-3"
                      onClick={() => handleSignInWithMicrosoft()}
                    >
                      Sign in with SSO
                    </p>
                  </div>
                </form>
              </>
            ) : (
              <>
                <p className="text-xl text-center mb-2">Enter OTP</p>
                <div className="mb-10 text-gray-500 text-xs md:text-sm text-center">
                  We've sent an OTP to{' '}
                  <span className="font-semibold">
                    {email?.slice(0, email.indexOf('@'))?.toLowerCase() +
                      email?.slice(email.indexOf('@'))?.toLowerCase() +
                      ' '}
                  </span>
                  <EditIcon
                    className="!text-blue-500 text-xs mb-1"
                    onClick={() => {
                      setSentOTP(false);
                      setSendingOTP(false);
                    }}
                  />
                </div>
                <OTPInput
                  value={password}
                  onChange={setPassword}
                  type="number"
                  numInputs={6}
                  renderSeparator={<span>{'  '}</span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    border: '0.5px solid #79767D',
                    padding: '8px',
                    width: '25%',
                    borderRadius: '5px',
                  }}
                  containerStyle={{
                    width: '220px',
                    gap: '5px',
                    marginBottom: '32px',
                    justifySelf: 'center',
                  }}
                />
                <button
                  type="submit"
                  className="w-full !rounded-full !justify-center !text-center bg-[#3D8FD2] text-white px-2 py-2 cursor-pointer hover:bg-[#0B295E] hover:transition duration-200 "
                  onClick={handleSubmitOTP}
                  appearance="primary"
                >
                  {submittingOTP === false ? 'Login' : <Spinner />}
                </button>

                <p className="text-red-500 mt-4 text-sm">{errorOTP}</p>
              </>
            )}
          </div>
        </div>
        <div className="lg:bg-[#0B295E] lg:rounded-r-xl lg:grid lg:grid-rows-3 hidden">
          <div className="-ml-10">
            <img src="/loginT.svg" className="" alt="login top svg" />
          </div>
          <div className="-mt-12">
            <img src="/loginR.svg" className="pl-10" alt="login top svg" />
          </div>
          <div className="mt-24 pt-1">
            <img src="/loginB.svg" className="" alt="login top svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginV2;
