import {
  ImageList,
  ImageListItem,
  Autocomplete,
  TextField,
  Slider,
  Radio,
  Modal,
  Tabs,
  Tab,
  RadioGroup,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  IconButton as MuiIconButton,
  Chip as MuiChip,
  Select,
  InputAdornment,
  Breadcrumbs,
  Tooltip,
  styled,
  Switch,
  Popover,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Checkbox,
  LinearProgress,
  ListItemText,
} from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect, useRef } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation, useNavigate } from "react-router-dom";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMemo } from "react";
import TextButton from "../../../util/Buttons/TextButton";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useToast } from "@chakra-ui/react";
import { callApi } from "../utils/ApiUtil";
import { useRefValueChange } from "../utils/HandlerFuncs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Lottie from "lottie-react";
import ProcessingLottie from "../utils/ProcessingLottie.json";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  padding: "16px",
  width: "100%",
  borderRadius: "12px",
};

// Radio checked mui
export const CustomRadio = ({
  value = "",
  setValue = () => {},
  options = [],
  disable = false,
}) => {
  return (
    <RadioGroup
      onChange={(e) => setValue(e?.target?.value)}
      value={value}
      sx={{
        overflowX: "auto",
        height: "fit-content",
        width: "100%",
      }}
    >
      <div className="flex gap-1 w-full whitespace-nowrap capitalize items-center">
        {options.map((x, idx) => {
          return (
            <div
              key={idx}
              style={{
                backgroundColor: x == value ? "#DDEEFF80" : "inherit",
                borderRadius: "8px",
              }}
            >
              <FormControlLabel
                value={x}
                control={
                  <Radio
                    sx={{
                      color: "#6CA6FC",
                      "&.Mui-checked": {
                        color: "#6CA6FC",
                      },
                      "&.MuiRadio-colorSecondary": {
                        color: "#6CA6FC",
                      },
                    }}
                  />
                }
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#3E3C42",
                  marginLeft: "0px",
                }}
                disabled={disable}
                label={x}
              />
            </div>
          );
        })}
      </div>
    </RadioGroup>
  );
};

// Progress bar checked mui
export const CommonProgressBar = ({
  openModal = false,
  closeModal = () => {},
  state = 1,
}) => {
  const stateInfo = {
    1: {
      name: "Processing",
      icon: null,
      color: "blue",
    },
    2: {
      name: "Complete",
      icon: "/selfServiceIcons/live.svg",
      color: "green",
    },
    0: {
      name: "Failed",
      icon: "/selfServiceIcons/fail.svg",
      color: "red",
    },
  };
  const [progress, setProgress] = useState(0);
  let intervalId;

  useEffect(() => {
    intervalId = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 99) {
          clearInterval(intervalId);
          return prev;
        } else {
          return prev + 1;
        }
      });
    }, 3 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (state === 2) {
      setProgress(100);
      setTimeout(() => closeModal(), 2000);
    } else if (state === 0) {
      clearInterval(intervalId);
      setTimeout(() => closeModal(), 2000);
    }
  }, [state]);

  return (
    <Modal
      open={openModal}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div style={{ ...modalStyle, maxWidth: "360px" }}>
        <div className="flex flex-col gap-2 w-full">
          <Lottie animationData={ProcessingLottie} loop={true} />
          <div className="flex gap-1 items-center text-sm font-medium whitespace-nowrap">
            <LinearProgress
              value={progress}
              sx={{
                width: "100%",
                "&.MuiLinearProgress-bar": {
                  color: stateInfo[state]?.color,
                },
              }}
              variant="determinate"
            />
            {stateInfo[state]?.icon ? (
              <img src={stateInfo[state]?.icon} alt="icons" />
            ) : (
              progress + " %"
            )}
          </div>
          <p className="text-lg font-medium">{stateInfo[state]?.name}</p>
        </div>
      </div>
    </Modal>
  );
};

//Select checked mui
export const CustomSelect = ({
  value = "",
  setValue = () => {},
  options = [],
  isPlain = true,
  valueKey = "",
  displayKey = "",
  disable = false,
  width = "250px",
  size = "small",
  displayEmpty = false,
}) => {
  return (
    <Select
      onChange={(e) => setValue(e.target.value)}
      value={value}
      disabled={disable}
      displayEmpty={displayEmpty}
      sx={{
        width: width,
      }}
      size={size}
    >
      {options.map((item, idx) => {
        return (
          <MenuItem key={idx} value={isPlain ? item : item[valueKey]}>
            {isPlain ? item : item[displayKey]}
          </MenuItem>
        );
      })}
    </Select>
  );
};

//Tablist checked mui
export const Tablist = ({
  tabList = [],
  isLazy = false,
  pageIndex = 0,
  setPageIndex = () => {},
  visible = true,
}) => {
  const tabComp = useMemo(() => {
    if (isLazy) return tabList?.find((item) => item?.index === pageIndex);
    else {
      const obj = {
        element: (
          <>
            {tabList?.map((item, idx) => (
              <div
                key={idx}
                style={{
                  display: item?.index !== pageIndex ? "none" : "block",
                }}
                className="w-full h-full"
              >
                {item?.element}
              </div>
            ))}
          </>
        ),
      };
      return obj;
    }
  }, [tabList, isLazy]);
  return (
    <>
      <Tabs
        orientation="horizontal"
        value={pageIndex}
        indicatorColor=""
        variant="scrollable"
        sx={{
          display: !visible ? "none" : "",
        }}
        scrollButtons={"auto"}
      >
        {tabList.map((item, idx) => {
          let x = item.index;
          return (
            <Tab
              key={idx}
              label={item?.tabName}
              value={x}
              sx={{
                padding: "0px 16px",
                borderRadius: "8px",
                border: x == pageIndex ? "1px solid #6CA6FC" : "",
                fontSize: "16px",
                marginRight: "16px",
                height: "38px",
                minHeight: "38px",
                boxShadow:
                  "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
                fontWeight: x == pageIndex ? 500 : 400,
                color: x == pageIndex ? "#084298" : "#605D64",
                cursor: x != pageIndex ? "pointer" : "default",
                backgroundColor: x == pageIndex ? "#F1F7FF" : "#fff",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
              }}
              onClick={() => setPageIndex(x)}
            />
          );
        })}
      </Tabs>
      {tabComp?.element}
    </>
  );
};

//div tabs checked html
export const DivTabs = ({
  options = [],
  value = "",
  setValue = () => {},
  responsiveClasses = "",
}) => {
  let lastIdx = options?.length - 1;
  let maxW = Math.max(...options?.map((item) => item?.length)) * 9 + 32;
  return (
    <div
      className={
        "flex gap-0 items-cente overflow-x-auto h-fit rounded-lg " +
        responsiveClasses
      }
      style={{
        boxShadow:
          "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
      }}
    >
      {options?.map((item, idx) => {
        return (
          <div
            key={idx}
            className="py-[6px] px-4 capitalize border text-sm text-center"
            style={{
              backgroundColor: value === item ? "#6CA6FC33" : "white",
              minWidth: maxW,
              borderRadius:
                idx === 0
                  ? "8px 0px 0px 8px"
                  : idx === lastIdx
                  ? "0px 8px 8px 0px"
                  : "0px",
              borderColor: value === item ? "#6CA6FC" : "#EBEBEB",
              color: "#605D64",
              fontWeight: value === item ? 500 : 400,
              cursor: value === item ? "" : "pointer",
            }}
            onClick={() => setValue(item)}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

// Bage/Chip checked mui
export const Chip = ({
  top = null,
  right = null,
  left = null,
  bottom = null,
  src = "",
  customElem = null,
  isTooltip = false,
  toolTipText = "",
  toolTipProps = {},
  width = "35px",
  height = "35px",
  rounded = "8px",
  padding = "4px 8px",
  border = "1px solid #CAC5CD",
  handleClick = null,
}) => {
  const elem = (
    <div
      className={`absolute bg-white flex justify-center items-center ${
        handleClick ? "hover:scale-105" : ""
      }`}
      style={{
        padding: padding,
        top: top,
        right: right,
        left: left,
        bottom: bottom,
        cursor: handleClick ? "pointer" : "",
        width: width,
        height: height,
        border: border,
        borderRadius: rounded,
      }}
      onClick={handleClick}
    >
      {!customElem ? (
        <img
          src={src}
          alt="icon-image"
          className="w-full h-full"
          style={{
            borderRadius: rounded,
          }}
        />
      ) : (
        customElem
      )}
    </div>
  );
  return isTooltip ? (
    <Tooltip title={toolTipText} arrow placement="top" {...toolTipProps}>
      {elem}
    </Tooltip>
  ) : (
    elem
  );
};

//Common Menu checked mui
export const CustomMenu = ({
  top = "20px",
  right = "8px",
  position = "absolute",
  width = "fit-content",
  color = "#757575",
  options = [
    {
      icon: <img />,
      type: "",
    },
  ],
  handleMenuClick = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        position: position,
        top: top,
        right: right,
      }}
    >
      <MuiIconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: color }} />
      </MuiIconButton>
      <MuiMenu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            style: {
              width: width,
              minWidth: "fit-content",
              borderRadius: "8px",
            },
          },
        }}
      >
        {options.map((item, idx) => (
          <MuiMenuItem
            key={idx}
            onClick={() => {
              handleMenuClick(item.type);
              handleClose();
            }}
          >
            <div className="flex gap-2 items-center">
              {item?.icon ? (
                <img
                  src={item.icon}
                  alt={item.type}
                  height={"15px"}
                  width={"15px"}
                />
              ) : null}
              {item.type}
            </div>
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};

//Common Card checked html
export const Card = ({
  borderClass = "",
  buttonInfo = [
    {
      text: "",
      type: "primary",
      handleClick: () => {},
      disable: false,
      color: "blue",
      elem: null,
    },
  ],
  bgImage = "",
  heading = "",
  status = "",
  statusIcon = null,
  statusColor = "",
  labels = [],
  isAddbutton = false,
  handleAdd = () => {},
  disableAdd = false,
  addText = "",
  children,
}) => {
  return (
    <div
      className="min-w-[250px] h-[250px] flex flex-col gap-0 w-full rounded-lg relative bg-white"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
        border: borderClass,
      }}
    >
      {isAddbutton ? (
        <div className="w-full h-full border-[3px] border-[#3A74CA] border-dashed rounded-lg text-center content-center">
          <div className="flex flex-col gap-4 items-center">
            <TonalButton
              text={<AddIcon />}
              onClick={handleAdd}
              disable={disableAdd}
              width={"44px"}
              height={"44px"}
            />
            <p className="text-[#3A74CA] text-lg font-medium text-center">
              {addText}
            </p>
          </div>
        </div>
      ) : (
        <>
          <img
            src={bgImage ? bgImage : "/image.svg"}
            className="rounded-t-lg object-cover w-full h-0 flex-grow"
            loading="lazy"
            alt="No image"
          />
          <div className="flex flex-col gap-2 p-4 rounded-b-lg bg-white h-fit w-full">
            {/* Heading */}
            <div className="flex justify-between items-center gap-2 w-full">
              <Tooltip title={heading} arrow placement="top">
                <p className="truncate text-[#48464C] capitalize text-lg leading-5 font-medium w-0 flex-grow">
                  {heading}
                </p>
              </Tooltip>
              {status && (
                <div
                  className="py-[2px] px-2 flex gap-1 font-medium items-center text-white text-xs rounded-full"
                  style={{
                    backgroundColor: statusColor,
                  }}
                >
                  <p className="leading-3">{status}</p>
                  <div className="h-4 w-4 flex justify-center items-center">
                    {statusIcon && statusIcon}
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-3 flex-grow">
              {/* Labels */}
              <div className="flex gap-2 items-center w-full">
                {labels?.slice(0, 2)?.map((item, idx) => (
                  <p
                    key={idx}
                    className="px-3 py-1 text-center content-center leading-[14px] bg-[#F0F0F0] rounded-full border border-[#EBEBEB] text-[#79767D] text-xs truncate"
                  >
                    {item}
                  </p>
                ))}
                {labels?.length - 2 > 0 && (
                  <p className="px-3 py-1 text-center content-center leading-[14px] bg-[#DEF] rounded-full text-[#084298] text-xs">
                    +{labels?.length - 2}
                  </p>
                )}
              </div>
              {/* Buttons */}
              <div className="flex w-full flex-grow gap-2 items-end">
                {buttonInfo?.map((item, idx) => {
                  const buttonProps = {
                    text: item?.text || "",
                    onClick: item?.handleClick || null,
                    disable: item?.disable || false,
                    color: item?.color || "blue",
                  };
                  const Button =
                    item?.type === "primary" ? (
                      <PrimaryButton key={idx} {...buttonProps} />
                    ) : item?.type === "ternary" ? (
                      <SecondaryButton
                        key={idx}
                        {...buttonProps}
                        height={"36px"}
                      />
                    ) : (
                      <TonalButton key={idx} {...buttonProps} />
                    );
                  return item?.elem ? (
                    <div
                      key={idx}
                      className="w-full text-center content-center h-[36px]"
                    >
                      {item?.elem}
                    </div>
                  ) : (
                    Button
                  );
                })}
              </div>
            </div>
          </div>
          {children}
        </>
      )}
    </div>
  );
};

//Back toggle checked mui
export const NavigateBack = ({ targetRoute = "/", textArray = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="font-medium flex justify-start gap-2 items-center rounded-md capitalize">
      <img
        src="/backtick.svg"
        alt="backtick"
        className="cursor-pointer"
        onClick={() =>
          navigate(targetRoute, {
            state: {
              prevPath: location?.pathname + location?.search,
            },
          })
        }
      />
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            fontSize="small"
            sx={{
              color: "#084298",
            }}
          />
        }
        maxItems={3}
      >
        {textArray?.map((item, idx) => (
          <p
            key={idx}
            className="text-lg font-medium md:text-xl text-[#084298]"
          >
            {item}
          </p>
        ))}
      </Breadcrumbs>
    </div>
  );
};

//Upload Button checked html
export const UploadButton = ({
  forwardRef,
  handleChange = () => {},
  text = "",
  icon = <UploadFileIcon />,
  disable = false,
  allowFolder = false,
  width = "fit-content",
  height = "40px",
}) => {
  let inputProps = {
    multiple: true,
  };
  if (allowFolder) {
    inputProps["directory"] = "";
    inputProps["webkitdirectory"] = "";
  }
  return (
    <>
      <input
        ref={forwardRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleChange}
        {...inputProps}
      />
      <SecondaryButton
        Icon={icon}
        text={text}
        width={width}
        height={height}
        onClick={() => {
          forwardRef.current.click();
        }}
        disable={disable}
      />
    </>
  );
};

//Upload DragDrop checked html
export const UploadDragDrop = ({
  forwardRef,
  handleChange = () => {},
  text = "",
  icon = <UploadFileIcon />,
  width = "100%",
  height = "100px",
  accept = undefined,
  allowMultiple = false,
  disable = false,
}) => {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      handleChange({ target: { files } });
    }
  };

  return (
    <>
      <input
        ref={forwardRef}
        type="file"
        accept={accept}
        multiple={allowMultiple}
        style={{ display: "none" }}
        onChange={handleChange}
      />
      <div
        className="p-2 border border-dashed border-[#447ED4] flex justify-center items-center rounded"
        style={{
          boxShadow:
            "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
          width: width,
          height: height,
          backgroundColor: dragOver ? "#E0E7FF" : "#F5F5F5",
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <TextButton
          Icon={icon}
          text={text}
          width={"fit-content"}
          onClick={() => {
            forwardRef.current.click();
          }}
          disable={disable}
        />
      </div>
    </>
  );
};

//Card grid container checked html
export const CardContainer = ({
  children,
  showRight = false,
  showLeft = false,
  leftFunc = null,
  rightFunc = null,
}) => {
  const size = useWindowSize();
  return (
    <div className="relative p-1 grid grid-cols-1 sm:grid-cols-2 min-[1100px]:grid-cols-3 min-[1470px]:grid-cols-4 min-[1800px]:grid-cols-5 min-[2100px]:grid-cols-6 min-[2400px]:grid-cols-7 gap-x-4 gap-y-6 w-full h-fit">
      {children}
      {showRight && size?.width >= 640 && (
        <Chip
          top={"calc(50% - 20px)"}
          handleClick={rightFunc}
          width="40px"
          height="40px"
          right={-18}
          padding="1px"
          rounded="999px"
          display={showRight ? "block" : "hidden"}
          customElem={
            <ArrowCircleRightOutlinedIcon
              sx={{ color: "#48464C", width: "100%", height: "100%" }}
            />
          }
        />
      )}
      {showLeft && size?.width >= 640 && (
        <Chip
          top={"calc(50% - 20px)"}
          width="40px"
          height="40px"
          handleClick={leftFunc}
          display={showLeft ? "block" : "hidden"}
          left={-18}
          padding="1px"
          rounded="999px"
          customElem={
            <ArrowCircleLeftOutlinedIcon
              sx={{ color: "#48464C", width: "100%", height: "100%" }}
            />
          }
        />
      )}
    </div>
  );
};

//Create Button Common checked
export const CreateButton = ({
  text = "",
  icon = <AddCircleOutlineIcon />,
  height = "40px",
  handleClick = () => {},
  disable,
}) => {
  const size = useWindowSize();
  return (
    <div
      className={
        size.width >= 640 ? "w-fit" : "fixed bottom-[90px] z-10 left-4 right-4"
      }
    >
      <SecondaryButton
        Icon={icon}
        text={text}
        height={height}
        onClick={handleClick}
        disable={disable}
      />
    </div>
  );
};

//Responsive button container
export const ButtonContainer = ({ children, normalClasses = "w-fit" }) => {
  const size = useWindowSize();
  return (
    <div
      className={
        size.width >= 640
          ? normalClasses
          : "fixed bottom-[90px] z-10 left-4 right-4"
      }
    >
      {children}
    </div>
  );
};

//Image Cards checked html
export const ImageContainer = ({
  handleClick = () => {},
  minWidth = "210px",
  width = "100%",
  height = "250px",
  children,
}) => {
  return (
    <div
      className="relative flex justify-center items-center rounded-lg bg-black bg-opacity-60"
      style={{
        minWidth: minWidth,
        height: height,
        width: width,
      }}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

//Image Masonary Grid checked mui
export const CustomImageGrid = ({ itemData = [{ img: "" }], count = 0 }) => {
  const size = useWindowSize();
  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
  return (
    <ImageList
      sx={{
        width: size?.width < 1024 ? "100%" : "50vw",
        height: "fit-content",
        position: "relative",
      }}
      variant="quilted"
      cols={4}
      rowHeight={100}
    >
      {itemData?.slice(0, 6)?.map((item) => (
        <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows || 1, item.cols || 1)}
            alt={"item.title"}
            loading="lazy"
          />
        </ImageListItem>
      ))}
      <p className="absolute bottom-2 left-2 p-2 rounded bg-black bg-opacity-70 text-white text-lg font-medium">
        {count} files uploaded
      </p>
    </ImageList>
  );
};

//Custom Label checked html
export const FieldLabel = ({
  text = "",
  color = "#79767D",
  size = "14px",
  weight = 500,
  isRequired = true,
}) => {
  return (
    <p
      style={{
        color: color,
        fontSize: size,
        fontWeight: weight,
      }}
    >
      {text}
      {isRequired && <span className="text-red-700">*</span>}
    </p>
  );
};

//Custom Checkbox checked mui
export const CustomCheckbox = ({
  text = "",
  isChecked = false,
  handleChange = () => {},
  borderColor = "#FFC107",
  backgroundColor = "#FFC107",
  extraProps = {},
}) => {
  return (
    <FormControlLabel
      value={text}
      control={
        <Checkbox
          sx={{
            padding: 0,
            "&.Mui-checked": {
              color: backgroundColor,
            },
            "&:hover": {
              color: backgroundColor,
            },
            ...extraProps,
          }}
          onChange={(e) => {
            handleChange(e);
          }}
          checked={isChecked}
        />
      }
      sx={{
        m: 0,
      }}
      label={text}
      labelPlacement="end"
    />
  );
};

//transition form steps checked html
export const TransitionCards = ({
  step = 0,
  currentStep = 0,
  width = "100%",
  children,
}) => {
  return (
    <div
      className={`flex flex-col gap-6 p-6 bg-white rounded-xl transition-all duration-700 ease-in ${
        currentStep >= step ? "opacity-100" : "opacity-0"
      }`}
      style={{
        width: width,
      }}
      id={"step" + step}
    >
      {children}
    </div>
  );
};

//Autocomplete checked mui
export const AutoComplete = ({
  handleChange = () => {},
  value,
  options = [],
  label = "",
  autoCompleteProps = {},
}) => {
  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      disablePortal
      id="combo-box-demo"
      options={options}
      size="small"
      sx={{ width: "auto" }}
      {...autoCompleteProps}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

//Custom slider checked mui
export const CustomSliderMui = ({
  value = 0,
  setValue = () => {},
  isInput = false,
  min = 0,
  max = 100,
  isTooltip = false,
  trackMarks = [
    {
      value: "",
      label: "",
    },
  ] && false,
}) => {
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (event) => {
    let val = Number(event?.target?.value);
    let ans = val < min ? min : val > max ? max : val;
    setValue(ans);
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <div className="flex w-full gap-6 items-center">
      <Slider
        value={typeof value === "number" ? value : 0}
        onChange={handleSliderChange}
        marks={trackMarks}
        min={min}
        max={max}
        valueLabelDisplay={isTooltip ? "on" : "off"}
        aria-labelledby="input-slider"
        sx={{
          width: "100%",
        }}
      />
      {isInput && (
        <TextField
          value={value}
          variant={"standard"}
          size="small"
          type="number"
          onChange={handleInputChange}
          onBlur={handleBlur}
          aria-labelledby="input-slider"
          sx={{
            width: "45px",
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      )}
    </div>
  );
};

// multi label select checked mui
export const LabelSelect = ({
  width = "fit-content",
  value = [],
  setValue = () => {},
  options = [],
  disable = false,
  size = "small",
}) => {
  return (
    <Select
      multiple
      displayEmpty
      value={value}
      sx={{
        width: width,
      }}
      size={size}
      onChange={(event) => {
        const {
          target: { value },
        } = event;
        setValue(typeof value === "string" ? value.split(",") : value);
      }}
      input={<OutlinedInput label="" />}
      renderValue={(selected) => (
        <div className="flex gap-1 items-center overflow-x-auto">
          {selected.map((value, idx) => {
            let labelData = JSON.parse(value);
            return (
              <MuiChip
                key={idx}
                label={`${labelData?.labelName} (${labelData?.annotationType})`}
              />
            );
          })}
        </div>
      )}
      disabled={disable}
    >
      {options?.map((item, idx) => (
        <MenuItem key={idx} value={JSON.stringify(item)}>
          <Checkbox checked={value?.indexOf(JSON.stringify(item)) !== -1} />
          <ListItemText
            primary={`${item?.labelName} (${item?.annotationType})`}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

//Card / table toggle checked html
export const CardTableToggle = ({ ViewType = "", setViewType = () => {} }) => {
  return (
    <div className="flex gap-0">
      {["card", "table"].map((i, idx) => {
        return (
          <div
            key={idx}
            className={`py-[7px] px-3 capitalize border text-sm ${
              idx == 0 ? "rounded-l-lg" : "rounded-r-lg"
            }`}
            style={{
              backgroundColor: ViewType == i ? "#FFFFED" : "white",
              borderColor: ViewType == i ? "#FFC107" : "#EBEBEB",
              color: ViewType == i ? "#3E3C42" : "#605D64",
              fontWeight: ViewType == i ? 500 : 400,
              cursor: ViewType == i ? "" : "pointer",
            }}
            onClick={() => setViewType(i)}
          >
            <img
              src={`/${i === "card" ? "cardIcon" : "tableIcon"}.svg`}
              alt={i}
            />
          </div>
        );
      })}
    </div>
  );
};

//Custom switch checked mui
export const IosSwitch = ({
  checked = false,
  handleClick = () => {},
  disable = false,
  isConfirmPopover = false,
  PopoverText = "Please confirm",
  title = "",
  size = "small",
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const anchorHandler = (e) => {
    if (isConfirmPopover) setAnchorEl(e.currentTarget);
    else handleClick();
  };

  return (
    <>
      <FormControlLabel
        control={<Switch size={size} />}
        label=""
        sx={{
          m: 0,
        }}
        checked={checked}
        onClick={(e) => {
          if (!disable) anchorHandler(e);
        }}
        title={title}
        disabled={disable}
      />
      <ConfirmPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        text={PopoverText}
        handleConfirm={() => {
          handleClick();
          setAnchorEl(null);
        }}
      />
    </>
  );
};

//Confirm popover checked mui
export const ConfirmPopover = ({
  anchorEl = null,
  setAnchorEl = () => {},
  text = "Please confirm",
  handleConfirm = () => {},
}) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className="text-sm text-[#605D64] flex flex-col gap-2 p-2 rounded-lg w-[200px]">
        <p>{text}</p>
        <div className="flex w-full items-center gap-1">
          <TonalButton text={"Cancel"} onClick={() => setAnchorEl(null)} />
          <PrimaryButton text={"Confirm"} onClick={() => handleConfirm()} />
        </div>
      </div>
    </Popover>
  );
};

//Common Data grid container html checked
export const DataGridContainer = ({
  children,
  height = "50vh",
  width = "100%",
  addUrl = "",
  handleAdd = () => {},
  addText = "",
  disableAdd = false,
  showAdd = true,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      className="border rounded-lg flex flex-col gap-1"
      style={{
        height: height,
        width: width,
      }}
    >
      {showAdd && (
        <div className="pt-1 pl-1">
          <TextButton
            text={addText}
            Icon={<AddIcon />}
            width={"fit-content"}
            disable={disableAdd}
            onClick={() =>
              addUrl
                ? navigate(addUrl, {
                    state: {
                      prevPath: location?.pathname + location?.search,
                    },
                  })
                : handleAdd()
            }
          />
        </div>
      )}
      {children}
    </div>
  );
};

//Common unique checker html checked
export const CommonUniqueTextCheck = ({
  valueKey = "",
  name = "",
  inputRefValue,
  endPoint = "",
  bypassValue = "",
  bypassValueKey = "",
}) => {
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const text = useRefValueChange(inputRefValue);
  const [data, setData] = useState([]);

  useEffect(() => {
    let exist = data?.findIndex((item) =>
      item[valueKey]?.toLowerCase()?.includes(text?.toLowerCase())
    );
    if (!open && text !== "" && exist !== -1) setOpen(true);
    else if (open && (text === "" || exist === -1)) setOpen(false);
  }, [text, data]);

  const getData = async () => {
    try {
      const res = await callApi({
        toast: toast,
        type: "get",
        endPoint: endPoint,
      });
      if (res?.status === 200) {
        let filterData = res?.data?.filter((item) => {
          if (bypassValueKey && bypassValue)
            return item[bypassValueKey] !== bypassValue;
          return item;
        });
        setData(filterData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    open && (
      <Popover
        open={open}
        anchorEl={inputRefValue?.current}
        onClose={(e, reason) => setOpen(false)}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        onClick={(e) => e.preventDefault()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="flex flex-col w-[280px] gap-4 p-4 rounded-lg">
          <p className="text-red-500 text-base font-medium">
            Similar {name} already exist. Please ensure your {name} is not
            duplicate.
          </p>
          <div className="grid grid-cols-2 gap-2 max-h-[120px] w-full overflow-y-auto">
            {data
              ?.filter((item) =>
                item[valueKey]?.toLowerCase()?.includes(text?.toLowerCase())
              )
              ?.map((item, idx) => (
                <p
                  title={item[valueKey]}
                  className="bg-gray-100 shadow-inner text-center truncate content-center rounded-full w-full py-1 px-2"
                  key={idx}
                >
                  {item[valueKey]}
                </p>
              ))}
          </div>
        </div>
      </Popover>
    )
  );
};
