import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useState } from "react";

import UserMgmt from "../../Admin/Tabs/UserMgmt";
import UpdateClient from "../AddClients/UpdateClient";
import DevelopmentInProgress from "../Deploy/DevelopmentInProgress";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Subscriptions from "../../Admin/Tabs/Subscriptions";
import UserRequests from "../../Admin/Tabs/UserRequests";
import ConfigureSubscription from "../../Admin/components/ConfigureSubscription";
import RequestDetails from "../../Admin/components/RequestDetails";

const UpdateTabs = ({ setShowClientTab, selectedClient }) => {
  const [isDeployClicked, setDeployClicked] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isfieldsChanged, setFieledsChange] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabList = [
    {
      index: 0,
      tabName: "Edit",
      element: (
        <UpdateClient
          setDeployClicked={setDeployClicked}
          selectedClient={selectedClient}
          setShowClientTab={setShowClientTab}
          isfieldsChanged={isfieldsChanged}
          setFieledsChange={setFieledsChange}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ),
    },
    {
      index: 1,
      tabName: "Subscriptions",
      element: (
        <Subscriptions
          clientId={selectedClient.clientId}
          clientOrg={selectedClient.organisation}
          mode="update"
          setSelectedSubscription={setSelectedSubscription}
          isSuperAdminClient={
            selectedClient.organisation !== "Ripik.Ai" ? true : false
          }
        />
      ),
    },
    {
      index: 2,
      tabName: "User Management",
      element: (
        <UserMgmt
          clientId={selectedClient.clientId}
          clientOrg={selectedClient.organisation}
          mode="update"
        />
      ),
    },
    {
      index: 3,
      tabName: "User Requests",
      element: (
        <UserRequests
          clientId={selectedClient.clientId}
          clientOrg={selectedClient.organisation}
          mode="update"
          setSelectedSubscription={setSelectedSubscription}
        />
      ),
    },
  ];
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };
  const handleBackButton = () => {
    if (isfieldsChanged) {
      setIsModalOpen(true);
    } else {
      setShowClientTab((prev) => "addclient");
    }
  };

  const renderExtraComp = () => {
    switch (selectedSubscription?.type) {
      case "requestDetail":
        return (
          <RequestDetails
            selectedSubscription={selectedSubscription}
            setSelectedSubscription={setSelectedSubscription}
          />
        );
        break;
      default:
        return (
          <ConfigureSubscription
            selectedSubscription={selectedSubscription}
            setSelectedSubscription={setSelectedSubscription}
          />
        );
        break;
    }
  };

  return (
    <>
      {!isDeployClicked ? (
        !selectedSubscription ? (
          <div className="font-roboto flex flex-col rounded-lg">
            <div className="flex items-between mb-3">
              <div className="cursor-pointer w-8" onClick={handleBackButton}>
                <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
              </div>
              <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
                Update Client Details
              </p>
            </div>

            <Tabs
              className="bg-white rounded-md px-6 pt-4"
              index={tabIndex}
              onChange={handleTabsChange}
              isLazy={true}
            >
              <TabList
                className="!flex !border-0 !justify-between !items-center"
                width={"full"}
              >
                <div className="flex sm:w-[75%] xl:w-[100%] items-center gap-4 overflow-x-auto h-14 xl:h-10">
                  {tabList?.map((item, id) => (
                    <Tab
                      key={id}
                      className={`xl:!text-sm !text-[14px] !text-[#605D64] whitespace-nowrap !border !rounded-full md:w-auto ${
                        tabIndex === item?.index
                          ? "  !bg-[#6CABFC] !bg-opacity-20 !border-[#6CA6FC]  "
                          : "!border-[#EBEBEB]"
                      }`}
                      onClick={() => handleTabsChange(item?.index)}
                    >
                      {item?.tabName}
                    </Tab>
                  ))}
                </div>
              </TabList>

              <TabPanels className="!pt-4">
                {tabList?.map(
                  (item) =>
                    selectedClient && (
                      <TabPanel className="!pl-0 !pr-0">
                        {item?.element}
                      </TabPanel>
                    )
                )}
              </TabPanels>
            </Tabs>
          </div>
        ) : (
          renderExtraComp()
        )
      ) : (
        <DevelopmentInProgress setDeployClicked={setDeployClicked} />
      )}
    </>
  );
};

export default UpdateTabs;
