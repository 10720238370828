import MovieCreationIcon from "@mui/icons-material/MovieCreation";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import ImageIcon from "@mui/icons-material/Image";
import { RTSPBaseUrl } from "../../..";

export const CalAnnotations = ({
  label = "",
  annotatedImages = [],
  annotationType = "",
  total = false,
}) => {
  let count = 0;
  if (total) {
    if (label == "all") {
      count = annotatedImages.reduce((a, b) => {
        let sum = 0;
        if (annotationType === "classify") {
          sum = 1;
        } else {
          sum = b.regions.length;
        }
        return a + sum;
      }, 0);
    } else {
      count = annotatedImages.reduce((a, b) => {
        let sum = 0;
        if (annotationType === "classify") {
          sum = b.label == label;
        } else {
          sum = b.regions.reduce((ac, bc) => {
            let i = bc.cls == label ? 1 : 0;
            return ac + i;
          }, 0);
        }
        return a + sum;
      }, 0);
    }
  } else {
    if (annotationType === "classify") {
      count = annotatedImages.filter((item) => item.label == label).length;
    } else {
      count = annotatedImages.filter((item) =>
        item.regions.some((val) => val?.cls === label)
      )?.length;
    }
  }
  return count;
};

export const SelectAll = ({
  e = {},
  paginatedData = [],
  setSelected = () => {},
  matchKey = "id",
}) => {
  if (e.target.checked) {
    setSelected((prev) => {
      let newData = [...prev];
      paginatedData.forEach((item) => {
        if (
          newData.findIndex((val) => val?.[matchKey] === item?.[matchKey]) ===
          -1
        ) {
          newData.push(item);
        }
      });
      return newData;
    });
  } else {
    setSelected((prev) => {
      let newData = [...prev];
      paginatedData.forEach((item) => {
        let idx = newData.findIndex(
          (val) => val?.[matchKey] === item?.[matchKey]
        );
        if (idx !== -1) newData.splice(idx, 1);
      });
      return newData;
    });
  }
};

export const ImageVideoFileChecker = async ({
  dataType = "image",
  selectedFile = [],
  toast = () => {},
  imgSize = 2, // MB
  totalFileSize = 1000, // MB for images & videos
  fileSpecs = {
    // for videos
    size: 500, // MB,
    duration: 15, // Min
    maxHeight: 2160, //px
    maxWidth: 3840, //px
  },
}) => {
  const format = {
    image: ["jpg", "jpeg", "png"],
    video: ["mp4"],
  };

  if (selectedFile) {
    const acceptedTypes = format[dataType].map((ext) => {
      return dataType.toLowerCase() + "/" + ext;
    });
    let goodToGo = true;
    let totalSize = selectedFile?.reduce((a, b) => a + b.size, 0);
    if (totalSize > totalFileSize * 1024 * 1024) {
      toast({
        title: "Error",
        description: `Files uploaded exceed the total size limit of ${totalFileSize} MB`,
        status: "error",
        position: "top-right",
        duration: 6000,
        isClosable: true,
      });
      goodToGo = false;
      return;
    } else {
      if (dataType === "image") {
        selectedFile.map((file) => {
          if (
            !acceptedTypes.includes(file.type) ||
            file.size > imgSize * 1024 * 1024
          ) {
            toast({
              title: "Error",
              description: (
                <div>
                  <p>{`Image name: ${file.name}`}</p>
                  <p>{`Accepted formats: [${acceptedTypes.join(", ")}]`}</p>
                  <p>{`Uploaded file: ${file.type}`}</p>
                  <p>{`Max size: 2MB, Uploaded size: ${(
                    file.size /
                    (1024 * 1024)
                  ).toFixed(1)}MB`}</p>
                </div>
              ),
              status: "error",
              position: "top-right",
              duration: 6000,
              isClosable: true,
            });
            goodToGo = false;
            return;
          }
        });
      } else if (dataType === "video") {
        await Promise.all(
          selectedFile.map(async (file) => {
            if (file.type.startsWith("video/")) {
              try {
                const video = await videoInfoReturn(file);
                if (
                  video.duration > fileSpecs?.duration * 60 ||
                  video.videoWidth > fileSpecs?.maxWidth ||
                  video.videoHeight > fileSpecs?.maxHeight ||
                  file.size > fileSpecs?.size * 1024 * 1024
                ) {
                  toast({
                    title: "Error",
                    description: (
                      <div>
                        <p>{`Image name: ${file.name}`}</p>
                        {video.duration > fileSpecs?.duration * 60 && (
                          <p>{`Video duration ${video.duration} exceeds the allowed duration of 15 mins`}</p>
                        )}
                        {(video.videoWidth > fileSpecs?.maxWidth ||
                          video.videoHeight > fileSpecs?.maxHeight) && (
                          <p>{`Video dimension ${
                            video.videoWidth + "x" + video.videoHeight
                          } exceeds the required dimensions of 1080x720`}</p>
                        )}
                        {file.size > fileSpecs?.size * 1024 * 1024 && (
                          <p>{`Uploaded size: ${(
                            file.size /
                            (1024 * 1024)
                          ).toFixed(1)}MB exceeds the max size of ${
                            fileSpecs?.size
                          }MB`}</p>
                        )}
                      </div>
                    ),
                    status: "error",
                    position: "top-right",
                    duration: 6000,
                    isClosable: true,
                  });
                  goodToGo = false;
                  return;
                }
              } catch (error) {
                console.error("Error loading video metadata:", error);
                toast({
                  title: "Error",
                  description:
                    "The video metadata cannot be loaded, Please ensure the file is not corrupted",
                  status: "error",
                  position: "top-right",
                  duration: 6000,
                  isClosable: true,
                });
                goodToGo = false;
                return;
              }
            } else {
              toast({
                title: "Error",
                description: (
                  <div>
                    <p>{`File name: ${file.name}`}</p>
                    <p>{`Accepted formats: [${acceptedTypes.join(", ")}]`}</p>
                    <p>{`Uploaded file: ${file.type}`}</p>
                  </div>
                ),
                status: "error",
                position: "top-right",
                duration: 6000,
                isClosable: true,
              });
              goodToGo = false;
              return;
            }
          })
        );
      }
    }
    return goodToGo;
  }
};

export const checkAnnotation = ({
  toast = () => {},
  annotationType = "",
  labels = [],
  annotatedImages = [],
}) => {
  let type = annotationType;
  let limit = type == "Classify" ? 15 : 20;
  for (let l in labels) {
    let count = CalAnnotations({
      label: labels[l],
      annotatedImages: annotatedImages,
      annotationType: annotationType,
    });
    if (count < limit) {
      toast({
        title: "Less images annotated",
        description:
          "label : " +
          labels[l] +
          " & annotations: " +
          count +
          `, Provide min ${limit - count} more to submit`,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
  }
  return true;
};

export const filterAnnotateUnannotate = ({
  allImages = [],
  annotatedData = {},
  annotationType = "",
  setAnnotatedImages = () => {},
  setImageSet = () => {},
  setAllImages = () => {},
}) => {
  switch (annotationType) {
    case "classify":
      setAllImages((prev) => {
        let newData = [];
        newData = allImages
          ?.filter((item) => {
            return !Object.entries(annotatedData)?.some(
              (val) => val[0] === item.fileId
            );
          })
          ?.map((item) => {
            return {
              img: item.url,
              id: item.fileId,
            };
          });
        return newData;
      });
      setAnnotatedImages((prev) => {
        let newData = Object.entries(annotatedData)?.map((item) => {
          return {
            img: allImages?.find((val) => val.fileId === item[0])?.url,
            id: item[0],
            label: item[1],
          };
        });
        return newData;
      });
      break;
    case "detect":
    case "segment":
      setImageSet((prev) => {
        let newData = [];
        newData = allImages
          ?.filter((item) => {
            return !Object.entries(annotatedData)?.some(
              (val) => val[0] === item.fileId
            );
          })
          ?.map((item) => {
            return {
              src: item.url,
              id: item.fileId,
              name: item.fileId,
              regions: [],
            };
          });
        let annotatedImages = Object.entries(annotatedData)?.map((item) => {
          return {
            src: allImages?.find((val) => val.fileId === item[0])?.url,
            id: item[0],
            name: item[0],
            regions: item[1],
          };
        });
        setAnnotatedImages((prev) => annotatedImages);
        newData = newData?.concat(annotatedImages);
        return newData;
      });
      break;
    default:
      break;
  }
};

export const videoInfoReturn = async (file) => {
  const video = document.createElement("video");
  video.preload = "metadata";
  const loadMetadata = new Promise((resolve, reject) => {
    video.onloadedmetadata = resolve;
    video.onerror = reject;
  });
  video.src = URL.createObjectURL(file);

  try {
    await loadMetadata;
    window.URL.revokeObjectURL(video.src);
    return video;
  } catch (error) {
    console.error("Error loading video metadata:", error);
    throw new Error(error);
  }
};

export const CalF1 = ({ precision = 0, recall = 0 }) => {
  if (precision && recall) {
    let f1 = (2 * precision * recall) / (precision + recall);
    return Math.round(f1 * 100) + "%";
  } else return "N/A";
};

export const RuntimeCalculator = ({ timeStates = {} }) => {
  let vals = ["COMPLETED", "TERMINATED", "FAILED"];
  if (timeStates?.hasOwnProperty("STARTED")) {
    for (let i in vals) {
      if (timeStates?.hasOwnProperty(vals[i])) {
        return (
          ((timeStates[vals[i]] - timeStates["STARTED"]) / 60000)?.toFixed(1) +
          " min"
        );
      }
    }
  }
  return "N/A";
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const nameDescCheck = ({
  val = "",
  isDesc = false,
  name = "",
  toast = () => {},
}) => {
  val = val?.trim();
  if (
    val === "" ||
    (!isDesc && val.length > 100) ||
    (isDesc && val.length > 1000) ||
    (!nameRegex.test(val) && !isDesc)
  ) {
    toast({
      title: "Error",
      description: (
        <div>
          {val === "" && <p>Please ensure {name} is not empty</p>}
          {!isDesc && val.length > 100 && (
            <p>The {name} cannot be greater then 100 characters</p>
          )}
          {isDesc && val.length > 1000 && (
            <p>The {name} cannot be greater then 1000 characters</p>
          )}
          {!nameRegex.test(val) && !isDesc && (
            <p>The {name} can only contain alphabets, numbers, "_" or "-" </p>
          )}
        </div>
      ),
      status: "error",
      position: "top-right",
      duration: 6000,
      isClosable: true,
    });
    return false;
  }
  return true;
};

export const filterHomePageComparator = ({
  filterState = {},
  typeKey = "modelType",
  bypassTypeCheck = false,
  timeKey = "createdAt",
  data: item = {},
}) => {
  let type =
    filterState?.[typeKey]?.length !== 0 && !bypassTypeCheck
      ? filterState?.[typeKey]?.some((val) => {
          return val?.type === item?.[typeKey]?.toLowerCase();
        })
      : true;
  let lastUpdated = filterState?.date
    ? new Date(filterState?.date)?.getTime() <= item?.[timeKey]
    : true;
  let owner =
    filterState?.owner?.length !== 0
      ? filterState?.owner?.some((val) => {
          return val?.owner?.toLowerCase() === item?.owner?.toLowerCase();
        })
      : true;
  let searchItem =
    filterState?.search !== ""
      ? Object?.entries(item)?.some((val) =>
          JSON.stringify(val[1])
            ?.toLowerCase()
            ?.includes(filterState?.search?.toLowerCase())
        )
      : true;
  return type && lastUpdated && searchItem && owner;
};

export function useRefValueChange(ref) {
  const [value, setValue] = useState(ref.current?.value);

  useEffect(() => {
    const handleValueChange = () => {
      if (ref.current && ref.current.value !== value) {
        setValue(ref.current.value);
      }
    };

    // Create a MutationObserver to watch for changes in the value
    const observer = new MutationObserver(handleValueChange);

    if (ref.current) {
      observer.observe(ref.current, {
        attributes: true,
        attributeFilter: ["value"],
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, value]);

  return value;
}

export const killHlsStream = async ({
  uuid = "",
  toast = () => {},
  rtsp = "",
}) => {
  try {
    const requestData = JSON.stringify({
      uuid: uuid,
      rtsp: rtsp?.trim(),
    });
    const response = await axios?.post(RTSPBaseUrl + "/delete", requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    toast({
      title: error?.response?.status + " Error",
      description: error?.response?.data?.message,
      status: "error",
      duration: 6000,
      isClosable: true,
      position: "top-right",
    });
    console.log(error);
  }
};

export const rtspPingCheck = async ({
  rtsp = "",
  toast = () => {},
  uuid = "",
  setProgressState = () => {},
}) => {
  let res = true;
  const baseURl = RTSPBaseUrl;
  const handleRtspAdd = async () => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    try {
      const requestData = JSON.stringify({
        rtsp: rtsp?.trim(),
        uuid: uuid,
      });
      const response = await axios.post(baseURl + "/create", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.status === 200) {
        let data = response?.data;
        let hlsUrl = baseURl + data?.replace(/^\./, "");
        return new Promise((resolve) => {
          setTimeout(async () => {
            const pingResult = await pingRtsp(hlsUrl);
            resolve(pingResult);
          }, 20 * 1000);
        });
      }
    } catch (error) {
      toast({
        title: "Hls converter not working",
        description: "Not able to create hls stream at the moment",
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      setProgressState((prev) => ({ ...prev, state: 0 }));
      res = false;
      return false;
    }
  };

  const pingRtsp = async (val) => {
    try {
      const response = await axios.get(val);
      if (response?.status === 200) {
        await killHlsStream({
          uuid: uuid,
          rtsp: rtsp,
          toast: toast,
        });
        toast({
          title: "RTSP validated",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setProgressState((prev) => ({ ...prev, state: 2 }));
        return true;
      }
    } catch (error) {
      await killHlsStream({
        uuid: uuid,
        rtsp: rtsp,
        toast: toast,
      });
      toast({
        title: "RTSP not working",
        description:
          "Provided rtsp link cannot be accessed, please make sure it is accessible or retry after sometime",
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      setProgressState((prev) => ({ ...prev, state: 0 }));
      console.log(error);
      return false;
    }
  };

  res = await handleRtspAdd();
  return res;
};

export const uploadAnnotationFormats = (annotationType) => {
  switch (annotationType) {
    case "classify":
      return ".csv";
    case "detect":
      return ".txt,.xml,.yaml,.yml";
    case "segment":
      return ".json,.txt,.xml,.yaml,.yml";
    default:
      return ".json,.txt,.xml,.yaml,.yml,.csv";
  }
};

//Icon maps and funcs

export const modelOptions = [
  { type: "classify" },
  { type: "segment" },
  { type: "detect" },
];

export const modelArchitectureOptions = [{ type: "yolo" }, { type: "resnet" }];

export const annotationIconMap = {
  detect: "/selfServiceIcons/detect.svg",
  segment: "/selfServiceIcons/segment.svg",
  classify: "/selfServiceIcons/classify.svg",
};

export const modelIcons = {
  AZURE: "/selfServiceIcons/azure.svg",
  RIPIK: "/logo192.png",
  CUSTOM_UPLOAD: "/selfServiceIcons/customModel.svg",
  CUSTOM_TRAIN: "/selfServiceIcons/customModel.svg",
};

export const dataTypeIconMap = {
  IMAGE: <ImageIcon sx={{ color: "#FFC107" }} />,
  VIDEO: <MovieCreationIcon sx={{ color: "#f44336" }} />,
  CAMERA: <VideoCameraBackIcon sx={{ color: "#448aff" }} />,
};

export const modelStatusIcon = ({ status = "COMPLETED" }) => {
  switch (status) {
    case "COMPLETED":
      return {
        text: "Live",
        elem: <CheckCircleOutlineIcon sx={{ width: "100%", height: "100%" }} />,
      };
    case null:
      return {
        text: "Ready",
        elem: <CheckCircleOutlineIcon sx={{ width: "100%", height: "100%" }} />,
      };
    case "PENDING":
      return {
        text: "Draft",
        elem: <SaveAsRoundedIcon sx={{ width: "100%", height: "100%" }} />,
      };
    case "FAILED":
    case "TERMINATED":
      return {
        text: "Failed",
        elem: <CancelOutlinedIcon sx={{ width: "100%", height: "100%" }} />,
      };
    default:
      return {
        text: status?.toLowerCase(),
        elem: (
          <CircularProgress
            variant="indeterminate"
            color="inherit"
            disableShrink
            sx={{
              animationDuration: "1200ms",
            }}
            size={"100%"}
            thickness={5}
          />
        ),
      };
  }
};

export const appStatusIcons = ({ status = "READY" }) => {
  switch (status) {
    case "READY":
      return {
        text: "Live",
        elem: <CheckCircleOutlineIcon sx={{ width: "100%", height: "100%" }} />,
      };
    case "FAILED":
    case "TERMINATED":
      return {
        text: "Failed",
        elem: <CancelOutlinedIcon sx={{ width: "100%", height: "100%" }} />,
      };
    case "DISABLED":
      return {
        text: "Disabled",
        elem: <SaveAsRoundedIcon sx={{ width: "100%", height: "100%" }} />,
      };
    default:
      return {
        text: status?.toLowerCase(),
        elem: (
          <CircularProgress
            variant="indeterminate"
            color="inherit"
            disableShrink
            sx={{
              animationDuration: "1200ms",
            }}
            size={"100%"}
            thickness={5}
          />
        ),
      };
  }
};

export const modelStatusColors = ({ status = "COMPLETED" }) => {
  switch (status) {
    case "COMPLETED":
      return { hex: "#7cb518", text: "text-[#329932]" };
    case "FAILED":
    case "TERMINATED":
      return { hex: "#fb6107", text: "text-[#FF3232]" };
    default:
      return { hex: "#fbb02d", text: "text-[#FFB732]" };
  }
};

export const appStatusColors = ({ status = "READY" }) => {
  switch (status) {
    case "READY":
      return { hex: "#7cb518", text: "text-[#329932]" };
    case "FAILED":
    case "TERMINATED":
      return { hex: "#fb6107", text: "text-[#FF3232]" };
    default:
      return { hex: "#fbb02d", text: "text-[#FFB732]" };
  }
};

//Regex
export const rtspRegex = /(rtsp):\/\/([^\s@/]+)([^\s/:]+)(?::([0-9]+))?(\/.*)/;
export const nameRegex = /^(?! *$)[a-zA-Z0-9\-_\s]+$/;
