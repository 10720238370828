import { useToast } from "@chakra-ui/react";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import {
  CustomCheckbox,
  CustomRadio,
  CustomSelect,
  FieldLabel,
  ImageContainer,
  TransitionCards,
} from "../../Common/CommonComponents";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { callApi } from "../../utils/ApiUtil";
import Paginator from "../../../../util/VisionUtils/Paginator";
import { SelectAll } from "../../utils/HandlerFuncs";
import { mixpanelModelInferTrack } from "../../utils/MixpanelEvents";
import { Drawer } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";

const ModelInferDrawer = ({
  openModal = false,
  closeModal = () => {},
  modelName = "",
  dataRepoId = "",
  modelId = "",
  depthView = true,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [allFrames, setAllFrames] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const size = useWindowSize();
  const toast = useToast();

  const getData = async () => {
    try {
      const res = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/dataRepository/",
      });
      if (res?.status === 200) setData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllFrames = async () => {
    try {
      const param = {
        masterObjectId: selectedData,
      };
      const response = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/image/",
        params: param,
      });
      if (response?.status === 200) {
        setAllFrames(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (x) => {
    setSelectedImages((prev) => {
      let newData = [...prev];
      let idx = newData.findIndex((item) => item.fileId === x.fileId);
      if (idx === -1) {
        newData.push(x);
      } else {
        newData.splice(idx, 1);
      }
      return newData;
    });
  };

  const checkSelectAll = () => {
    let checked = true;
    displayData?.forEach((item) => {
      if (!selectedImages.some((val) => val.fileId === item.fileId))
        checked = false;
    });
    return checked;
  };

  const rerunStart = async () => {
    try {
      const requestData = JSON.stringify({
        modelObjectId: modelId,
        dataRepoObjectId: selectedData,
        fileIds: selectedImages?.map((item) => item?.fileId),
      });
      const response = await callApi({
        type: "post",
        endPoint: "selfserve/v2/project/v1/model/inference/",
        toast: toast,
        requestBody: requestData,
      });
      if (response?.status === 200) {
        toast({
          title: "Inference started",
          description: "ETA: " + (response?.data?.eta / 60)?.toFixed(2) + "min",
          status: "success",
          isClosable: true,
          duration: 6000,
          position: "top-right",
        });
        mixpanelModelInferTrack({
          modelName: modelName,
          modelId: modelId,
          oldRepoId: dataRepoId,
          newRepoId: selectedData,
          newRepoName: data?.find(
            (item) => item?.masterObjectId === selectedData
          )?.name,
          inferenceImages: selectedImages?.length,
        });
        setTimeout(() => {
          if (depthView)
            setSearchParams((prev) => {
              prev.set("tabIndex", 1);
              prev.set("noModelRuns", false);
              prev.set("modelId", response?.data?.modelObjectId);
              return prev;
            });
          window?.location?.reload();
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCheckedAll(checkSelectAll());
  }, [displayData, selectedImages]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedData !== "") getAllFrames();
  }, [selectedData]);

  return (
    <Drawer
      anchor="right"
      open={openModal}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div className="flex flex-col gap-3 relative">
        <div className="flex justify-between items-center p-6 top-0 w-full bg-white z-10 sticky">
          <FieldLabel isRequired={false} text="Rerun project" size="18px" />
          <ClearIcon
            className="hover:scale-105 cursor-pointer"
            onClick={closeModal}
          />
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3 px-6">
            <FieldLabel text="Select data repo" />
            <CustomSelect
              options={[
                {
                  name: "Select repo",
                  masterObjectId: "",
                },
                ...data,
              ]}
              value={selectedData}
              setValue={(value) => setSelectedData(value)}
              isPlain={false}
              displayEmpty={true}
              displayKey={"name"}
              valueKey={"masterObjectId"}
              disable={selectedImages?.length > 0}
            />
          </div>
          <TransitionCards step={true} currentStep={selectedData !== ""}>
            <div className="flex gap-2 items-center justify-between flex-wrap">
              <p className="text-lg font-medium text-[#3E3C42]">
                Select images to run inference on
              </p>
              <p className="text-[#2660B6] text-base font-medium">
                {selectedImages?.length} Selected
              </p>
            </div>
            <div className="w-full flex justify-between gap-2 items-center flex-wrap">
              {allFrames?.length !== 0 && (
                <Paginator
                  limit={6}
                  data={allFrames}
                  setDisplayData={setDisplayData}
                />
              )}
              <CustomCheckbox
                handleChange={(e) =>
                  SelectAll({
                    e: e,
                    displayData: allFrames,
                    paginatedData: displayData,
                    setSelected: setSelectedImages,
                    pageSize: 6,
                    matchKey: "fileId",
                  })
                }
                text="Select all"
                isChecked={checkedAll}
              />
            </div>
            <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 gap-3 w-full sm:w-[600px]">
              {displayData?.map((item, idx) => {
                return (
                  <ImageContainer
                    minWidth="169px"
                    height="169px"
                    key={idx}
                    handleClick={() => handleSelect(item)}
                  >
                    <img
                      src={item?.url}
                      alt="image"
                      className="w-full h-full rounded cursor-pointer"
                    />
                    <CustomCheckbox
                      isChecked={selectedImages?.some(
                        (selectedItem) => selectedItem?.fileId === item?.fileId
                      )}
                      handleChange={() => handleSelect(item)}
                      extraProps={{
                        position: "absolute",
                        top: "8px",
                        left: "8px",
                        shadow: "dark-lg",
                      }}
                    />
                  </ImageContainer>
                );
              })}
            </div>
          </TransitionCards>
        </div>
        <div className="px-6 pt-3 flex justify-end items-center pb-6 w-full bottom-0 sticky bg-white">
          <PrimaryButton
            text={"Submit"}
            width={"fit-content"}
            onClick={() => rerunStart()}
            disable={selectedImages?.length === 0}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ModelInferDrawer;
