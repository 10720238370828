import React, { useEffect, useState } from "react";
import ExpandModal from "./ExpandModal";
import { ImageContainer } from "../../../Common/CommonComponents";
import { colors } from "./LabelStats";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import YardOutlinedIcon from "@mui/icons-material/YardOutlined";
import { Divider } from "@mui/material";

const CalConfidenceParams = (data) => {
  let precisionValues,
    minPrecision,
    maxPrecision,
    sortedPrecisionValues,
    midIndex,
    medianPrecision;

  precisionValues = data?.img?.map((obj) => obj.precision);
  minPrecision = Math.min(...precisionValues);
  maxPrecision = Math.max(...precisionValues);
  sortedPrecisionValues = precisionValues.sort((a, b) => a - b);
  midIndex = Math.floor(sortedPrecisionValues.length / 2);
  medianPrecision =
    sortedPrecisionValues.length % 2 === 0
      ? (sortedPrecisionValues[midIndex - 1] +
          sortedPrecisionValues[midIndex]) /
        2
      : sortedPrecisionValues[midIndex];
  return {
    min: (minPrecision * 100)?.toFixed(1),
    max: (maxPrecision * 100)?.toFixed(1),
    median: (medianPrecision * 100)?.toFixed(1),
  };
};

const LabelCard = ({ label, predictionData, modelInfo, starred, index }) => {
  const [confidence, setConfidence] = useState({
    min: 0,
    max: 0,
    median: 0,
  });
  const confidenceOptions = [
    {
      name: "Minimum",
      key: "min",
    },
    {
      name: "Maximum",
      key: "max",
    },
    {
      name: "Median",
      key: "median",
    },
  ];
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    if (predictionData[label]?.img?.length > 0) {
      setConfidence((prev) => {
        return CalConfidenceParams(predictionData[label]);
      });
    }
  }, [label, predictionData]);

  return (
    <div
      className="flex flex-col gap-0 border border-[#CAC5CD] bg-white rounded-lg"
      style={{
        boxShadow:
          "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
      }}
    >
      <div className="flex p-2 justify-between w-full items-start rounded-t-lg bg-[#F5F5F5]">
        <div className="flex gap-4 items-center">
          <div className="flex gap-2 items-center">
            <div
              className="h-3 w-3 rounded-full"
              style={{ backgroundColor: colors[index] }}
            />
            <p className="text-[#48464C] text-sm">{label}</p>
          </div>
          <Divider orientation="vertical" flexItem />
          <p
            className="text-[#48464C] text-sm font-semibold"
            title={"No. of files"}
          >
            <YardOutlinedIcon /> {predictionData[label]?.img?.length}
          </p>
        </div>
        <FullscreenIcon
          sx={{
            color: "#3A74CA",
          }}
          className="cursor-pointer hover:scale-105"
          onClick={() => setExpand(true)}
        />
      </div>
      <div className="flex flex-col gap-4 rounded-b-lg p-2">
        <div className="flex flex-col gap-1">
          <p className="text-[#525056] text-xs">Model Confidence</p>
          <div
            className="py-1 px-2 border border-[#EBEBEB] flex gap-1 items-center justify-between w-full rounded-full"
            style={{
              boxShadow:
                "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
            }}
          >
            {confidenceOptions?.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div className="flex gap-1 items-center">
                    <p className="text-[#79767D] text-xs">{item?.name}</p>
                    <p className="text-[#6CA6FC] text-xs font-semibold">
                      {confidence?.[item?.key]} %
                    </p>
                  </div>
                  {idx !== confidenceOptions?.length - 1 && (
                    <Divider orientation="vertical" flexItem />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="flex gap-2 w-full justify-between items-center">
          {predictionData[label]?.img?.slice(0, 3).map((item, idx) => {
            let len = predictionData[label]?.img?.slice(0, 3)?.length;
            return (
              <ImageContainer
                width="92px"
                minWidth="92px"
                height="92px"
                key={idx}
              >
                <img
                  src={item?.img}
                  alt="img"
                  className="h-full w-full rounded-lg"
                />
                {idx == len - 1 && (
                  <p className="absolute flex w-full h-full justify-center rounded text-center items-center text-[#FAFAFA] text-base bg-black bg-opacity-40">
                    + {predictionData[label]?.img?.length - len}
                  </p>
                )}
              </ImageContainer>
            );
          })}
        </div>
      </div>
      {expand && (
        <ExpandModal
          starred={starred}
          predictionData={predictionData}
          label={label}
          isOpen={expand}
          closeModal={() => setExpand(false)}
        />
      )}
    </div>
  );
};

export default LabelCard;
