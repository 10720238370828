import React, { useState, useContext, useEffect } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";

import NavContext from "../../NavContext";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { baseURL } from "../../..";
import axios from "axios";
import CustomSelector from "../components/CustomSelector";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";

function getFormattedDateBasedOnDuration(duration, setFormData) {
  const today = new Date();
  let targetDate = new Date(); // Clone the current date

  switch (duration) {
    case "1 Month":
      targetDate.setMonth(today.getMonth() + 1);
      break;
    case "3 Months":
      targetDate.setMonth(today.getMonth() + 3);
      break;
    case "6 Months":
      targetDate.setMonth(today.getMonth() + 6);
      break;
    case "12 Months":
      targetDate.setFullYear(today.getFullYear() + 1);
      break;
    case "Custom":
      return null;
    default:
      console.error("Invalid duration specified");
      return null; // or throw an error
  }
  setFormData((prev) => ({
    // changing the end date when subscription type changes
    ...prev,
    subscriptionEndDate: targetDate,
  }));
  return targetDate;
}

function calculateMonthsBetweenDates(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const yearsDifference = end.getFullYear() - start.getFullYear();
  const monthsDifference = end.getMonth() - start.getMonth();

  const totalMonths = yearsDifference * 12 + monthsDifference;

  return totalMonths;
}

const AddSubscriptionsModal = ({
  isOpen,
  onClose,
  fetchTableData,
  data,
  clientId,
}) => {
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const subscriptionDurationOptions = {
    trial: ["1 Month", "3 Months"],
    standard: ["3 Months", "6 Months", "12 Months", "Custom"],
    enterprise: ["3 Months", "6 Months", "12 Months", "Custom"],
  };

  const [clientData, setClientData] = useState({
    allLocations: [],
    supportType: ["standard", "professional", "advance"],
  });

  const [formData, setFormData] = useState({
    productName: "AI Studio",
    allLocations: [...clientData?.allLocations],
    platformSubscriptionType: "trial",
    subscriptionDuration: "1 Month",
    subscriptionStartDate: new Date(),
    subscriptionEndDate: new Date()?.setMonth(new Date()?.getMonth() + 1),
    supportType: "standard",
  });

  const subscriptionTypeData = [
    { type: "trial", users: "Upto 2 Live Inference" },
    { type: "standard", users: "Upto 10 Live Inference" },
    { type: "enterprise", users: "Custom number of Live Inferences" },
  ];

  const AddSubscriptions = async () => {
    const data = JSON.stringify({
      clientId: clientId,
      locationList: formData?.allLocations?.map((item) => item?.locationId),
      subscriptionType: formData?.platformSubscriptionType?.toUpperCase(),
      subscriptionDurationInMonth: calculateMonthsBetweenDates(
        formData?.subscriptionStartDate,
        formData?.subscriptionEndDate
      ),
      subscriptionStartDate: new Date(
        formData?.subscriptionStartDate
      )?.getTime(),
      subscriptionEndDate: new Date(formData?.subscriptionEndDate)?.getTime(),
      supportType: formData?.supportType?.toUpperCase(),
    });
    try {
      const response = await axios.post(
        baseURL + "v2/aisandbox/subscription/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      if (response?.status === 200) {
        fetchTableData();
        toast({
          title: "Subscription added",
          description: "Subscription added, please configure the user limits",
          duration: 3000,
          position: "top-right",
          status: "success",
          isClosable: true,
        });
        setTimeout(() => onClose(), 1000);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getLocations = async () => {
    try {
      const param = {
        clientId: clientId,
      };
      const response = await axios.get(baseURL + "v2/location/", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response?.status === 200) {
        let value = response?.data;
        setClientData((prev) => ({
          ...prev,
          allLocations: response?.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiscard = () => {
    onClose();
    setFormData({});
  };

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubscriptionDuration = (duration) => {
    setFormData((prev) => ({
      ...prev,
      subscriptionDuration: subscriptionDurationOptions[duration][0],
    }));
    getFormattedDateBasedOnDuration(
      subscriptionDurationOptions[duration][0],
      setFormData
    ); // to update the end date
  };

  const handleLocationChange = (event, obj) => {
    const { props } = obj;
    let value = props?.value;
    if (value === "all") {
      if (clientData?.allLocations?.length === formData?.allLocations?.length)
        setFormData((prev) => ({
          ...prev,
          allLocations: [],
        }));
      else
        setFormData((prev) => ({
          ...prev,
          allLocations: [...clientData?.allLocations],
        }));
    } else {
      setFormData((prev) => {
        let newData = JSON.parse(JSON.stringify(prev));
        let idx = newData?.allLocations?.findIndex(
          (item) => item?.locationId === value?.locationId
        );
        if (idx !== -1) newData?.allLocations?.splice(idx, 1);
        else newData?.allLocations?.push(value);
        return newData;
      });
    }
  };

  useEffect(() => {
    if (clientId) getLocations();
  }, [clientId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay zIndex={1200} />
      <ModalContent containerProps={{ zIndex: 1200 }} maxW={"400px"}>
        <div className="text-white w-full h-12  flex bg-[#2660B6] font-semibold justify-center items-center rounded-t-md">
          Add AI Studio Subscription
        </div>
        <ModalCloseButton color={"white"} />
        <ModalBody mt={"16px"}>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-2">
              <div className="!text-xs !text-[#2660B6] !font-semibold">
                Product Name
              </div>
              <Input
                value={formData?.productName}
                color={"#48464C"}
                placeholder={"Enter Product Name"}
                isDisabled={true}
              />
            </div>
            <div className="flex flex-col gap-2">
              <p className="!text-xs !text-[#2660B6] !font-semibold">
                Locations<span className="text-red-500">*</span>
              </p>
              <div>
                <CustomSelector
                  formData={formData}
                  selectData={clientData}
                  handleLocationChange={handleLocationChange}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-xs text-[#2660B6] font-semibold">
                Platform Subscription Type
              </div>
              <div className="flex gap-1 items-center">
                {subscriptionTypeData?.map((item) => (
                  <div
                    key={item?.type}
                    className={` rounded py-1 px-2 flex-1 ${
                      formData.platformSubscriptionType === item?.type
                        ? "bg-[#E2EDFE] border border-[#2660B6]"
                        : "bg-[#EBEBEB]"
                    }`}
                    onClick={() => {
                      handleChange("platformSubscriptionType", item?.type);
                      handleSubscriptionDuration(item?.type);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>{item?.type}</p>
                    <p
                      className="line-clamp-2 text-[12px] w-full"
                      title={item?.users}
                    >
                      {item?.users}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="!text-xs !text-[#2660B6] font-semibold">
                Select Subscription Duration
              </div>
              <Select
                // placeholder="Select Subscription Duration"
                value={formData?.subscriptionDuration}
                name="subscriptionDuration"
                rounded={"4px"}
                onChange={(e) => {
                  handleChange("subscriptionDuration", e.target.value);
                  getFormattedDateBasedOnDuration(e.target.value, setFormData);
                }}
              >
                {subscriptionDurationOptions[
                  formData?.platformSubscriptionType
                ]?.map((name) => (
                  <option value={name}>{name}</option>
                ))}
              </Select>
            </div>
            <div className="flex justify-center items-center gap-2 w-full flex-wrap">
              <div className="flex flex-col gap-2 flex-1">
                <div className="text-xs text-[#2660B6] font-semibold">
                  Subscription Start date
                </div>
                <div className="flex">
                  <FloatingInput
                    type="date"
                    value={formData?.subscriptionStartDate}
                    disabled={formData?.subscriptionDuration !== "Custom"}
                    setDateTime={(e) =>
                      handleChange("subscriptionStartDate", e)
                    }
                    min={new Date()}
                    max={formData?.subscriptionEndDate}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 flex-1">
                <div className="text-xs text-[#2660B6] font-semibold">
                  Subscription End date
                </div>
                <div className="flex">
                  <FloatingInput
                    type="date"
                    value={formData?.subscriptionEndDate}
                    disabled={formData?.subscriptionDuration !== "Custom"}
                    setDateTime={(e) => handleChange("subscriptionEndDate", e)}
                    min={formData?.subscriptionStartDate}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="!text-xs !text-[#2660B6] !font-semibold">
                Select Support Type
              </div>
              <Select
                value={formData?.supportType}
                name="supportType"
                onChange={(e) => handleChange("supportType", e.target.value)}
                textTransform={"capitalize"}
              >
                {clientData &&
                  clientData?.supportType?.length > 0 &&
                  clientData?.supportType?.map((name) => (
                    <option value={name}>{name}</option>
                  ))}
              </Select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          flexDirection={"row"}
          display={"flex"}
          gap={"4px"}
          justifyContent={"start"}
        >
          <PrimaryButton
            text={"Save"}
            width="80px"
            onClick={() => AddSubscriptions()}
            disable={formData?.allLocations?.length < 1}
          />
          <TonalButton text={"Discard"} width="80px" onClick={handleDiscard} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { AddSubscriptionsModal };
