export const formatColumns = ({ cols = [], color = "", flexMap = {} }) => {
    let newCols = cols?.map((item, idx) => {
      let align =
        idx === 0 ? "left" : idx === cols.length - 1 ? "right" : "center";
      let className = color === "green" ? "!bg-[#c8e6c9]" : "bg-[#DEF]";
      return {
        ...item,
        flex: flexMap[item?.field] ?? 1,
        minWidth: flexMap[item?.field] * 150 ?? 150,
        headerAlign: align,
        align: align,
        headerClassName: className,
      };
    });
    return newCols;
  };