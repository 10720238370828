import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import { useEffect, useState } from "react";
import TonalButton from "../../../../util/Buttons/TonalButton";
import { useToast } from "@chakra-ui/react";
import SettingsIcon from "@mui/icons-material/Settings";
import { callApi } from "../../utils/ApiUtil";
import { CustomSelect, DataGridContainer } from "../../Common/CommonComponents";
import { CustomStyledDataGrid } from "../../../../util/MaterialDataGrid/CustomStyledDatagrid";
import {
  camAlertsColumnDefinition,
  camAlertsFlexMap,
  formatColumns,
} from "../../Common/CommonColDefine";
import SecondaryButton from "../../../../util/Buttons/SecondaryButton";
import { CircularProgress } from "@mui/material";
import FloatingInput from "../../../../util/VisionUtils/FloatingInput";
import TextButton from "../../../../util/Buttons/TextButton";
import DetailModal from "./DetailModal";

const Alert = ({
  appId = "",
  cameraName = "",
  appName = "",
  camId = "",
  cameraDetails = [],
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedCam, setSelectedCam] = useState(camId);
  const [openAlertModal, setOpenAlertModal] = useState({
    open: false,
    data: {},
  });
  const [selectedPreset, setSelectedPreset] = useState("Today");
  const [fromDate, setFromDate] = useState(new Date()?.setHours(0, 0, 0, 0));
  const [toDate, setToDate] = useState(new Date());
  const [alertConditions, setAlertConditions] = useState({
    loading: true,
    data: [],
  });
  const [alerts, setAlerts] = useState({
    loading: false,
    data: [],
  });
  const datePresets = [
    {
      text: "Today",
      func: () => {
        setFromDate(new Date()?.setHours(0, 0, 0, 0));
        setToDate(new Date());
      },
    },
    {
      text: "Yesterday",
      func: () => {
        let currDate = new Date();
        currDate?.setDate(currDate?.getDate() - 1);
        currDate?.setHours(0, 0, 0, 0);
        setFromDate(currDate);
        currDate = new Date();
        currDate?.setHours(0, 0, 0, 0);
        setToDate(currDate);
      },
    },
    {
      text: "Custom",
    },
  ];

  const getAlertConditions = async () => {
    setAlertConditions((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const param =
        camId !== ""
          ? {
              masterObjectId: appId,
              cameraId: camId,
            }
          : {
              masterObjectId: appId,
            };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/alert/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200)
        setAlertConditions((prev) => ({
          ...prev,
          loading: false,
          data: response?.data,
        }));
      else
        setAlertConditions((prev) => ({
          ...prev,
          loading: false,
          data: [],
        }));
    } catch (error) {
      console.log(error);
    }
  };

  const getAlerts = async (specificId = "") => {
    setAlerts((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const param = {
        camId: specificId !== "" ? specificId : selectedCam,
        appId: appId,
        fromDate: new Date(fromDate)?.getTime(),
        toDate: new Date(toDate)?.getTime(),
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/feed/alert/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        setAlerts((prev) => ({
          ...prev,
          loading: false,
          data: response?.data,
        }));
      } else
        setAlerts((prev) => ({
          ...prev,
          loading: false,
          data: [],
        }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAlertConditions();
  }, []);

  useEffect(() => {
    if (alertConditions?.data?.length > 0) {
      if (camId !== "") getAlerts();
      else {
        let id = cameraDetails[0]?.camId;
        setSelectedCam(id);
        getAlerts(id);
      }
    }
  }, [alertConditions]);

  if (alertConditions?.loading) return null;

  const additionalColumns = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      valueGetter: ({ row }) => {
        let prob = row?.result?.probs;
        let hasAnnotatedImage = "annotImage" in row;
        let data = hasAnnotatedImage
          ? {
              annotatedImg: row?.annotImage,
              img: row?.origImage,
              precision: prob?.length > 0 ? Math.max(...prob) : 0,
              createdAt: row?.createdAt,
            }
          : {
              img: row?.origImage,
              precision: prob,
              tagName: row?.result?.cls,
              createdAt: row?.createdAt,
            };
        return data;
      },
      renderCell: ({ value }) => {
        return (
          <TextButton
            text={"View detail"}
            width={"fit-content"}
            onClick={() =>
              setOpenAlertModal((prev) => ({
                ...prev,
                open: true,
                data: value,
              }))
            }
          />
        );
      },
    },
  ];

  return (
    <div className="flex flex-col gap-4 p-6 w-full bg-white rounded-xl items-end">
      {alertConditions?.data?.length > 0 ? (
        <>
          <TonalButton
            text={"Alert Settings"}
            Icon={<SettingsIcon />}
            width={"fit-content"}
            onClick={() => {
              navigate(
                `/SandboxV2/view/alert?appId=${appId}&cameraName=${cameraName}&appName=${appName}&camId=${camId}`,
                {
                  state: {
                    prevPath: location?.pathname + location?.search,
                  },
                }
              );
            }}
          />
          <div className="flex gap-3 items-center overflow-x-auto w-full h-fit p-2">
            <CustomSelect
              value={selectedCam}
              setValue={setSelectedCam}
              options={[
                {
                  camId: camId !== "" ? camId : "",
                  cameraName: camId !== "" ? cameraName : "Select camera",
                },
                ...cameraDetails,
              ]}
              isPlain={false}
              valueKey="camId"
              displayKey="cameraName"
              displayEmpty={true}
              width="200px"
              disable={camId !== ""}
            />
            <CustomSelect
              value={selectedPreset}
              setValue={(value) => {
                let data = datePresets?.find((item) => item?.text === value);
                if ("func" in data) data?.func();
                setSelectedPreset(value);
              }}
              options={datePresets}
              isPlain={false}
              valueKey="text"
              displayKey="text"
              width="200px"
            />
            {selectedPreset === "Custom" && (
              <>
                <div className="min-w-[200px]">
                  <FloatingInput
                    text={"From"}
                    type={"dateTime-local"}
                    setDateTime={setFromDate}
                    value={fromDate}
                    max={toDate}
                  />
                </div>
                <div className="min-w-[200px]">
                  <FloatingInput
                    text={"To"}
                    type={"dateTime-local"}
                    setDateTime={setToDate}
                    value={toDate}
                    min={fromDate}
                    max={new Date()}
                  />
                </div>
              </>
            )}
            <SecondaryButton
              text={
                alerts?.loading ? (
                  <CircularProgress
                    size={"80%"}
                    disableShrink
                    variant="indeterminate"
                  />
                ) : (
                  "Apply"
                )
              }
              width={"60px"}
              height={"38px"}
              onClick={() => getAlerts()}
              disable={
                selectedCam === "" || !fromDate || !toDate || alerts?.loading
              }
            />
          </div>
          <DataGridContainer showAdd={false} height="54vh">
            <CustomStyledDataGrid
              rows={alerts?.data}
              columns={formatColumns({
                cols: [...camAlertsColumnDefinition, ...additionalColumns],
                color: "blue",
                flexMap: camAlertsFlexMap,
              })}
              disableRowSelectionOnClick={true}
              getRowId={(row) => row?.alertId + row?.createdAt}
              pagination={true}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              sx={{
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
              }}
              getRowHeight={() => "auto"}
              pageSizeOptions={[10, 15, 25]}
              autosizeOptions={{
                columns: camAlertsColumnDefinition?.map((item) => item.field),
                includeOutliers: true,
                includeHeaders: true,
              }}
            />
          </DataGridContainer>
        </>
      ) : (
        <div className="w-full h-[50vh] border-2 border-dashed border-[#3A74CA] rounded-lg flex justify-center items-center">
          <div className="flex flex-col gap-4 items-center">
            <img
              src="/selfServiceIcons/noAlert.svg"
              className="h-auto w-[150px] xs:w-auto"
              alt="noAlert"
            />
            <p className="text-[#084298] text-xl font-medium text-center">
              No Alert Created yet ! Click on the below button to create an
              alert
            </p>
            <PrimaryButton
              text={"Create Alert"}
              width={"fit-content"}
              onClick={() =>
                navigate(
                  `/SandboxV2/add/alert?appId=${appId}&cameraName=${cameraName}&appName=${appName}&camId=${camId}`,
                  {
                    state: {
                      prevPath: location?.pathname + location?.search,
                    },
                  }
                )
              }
            />
          </div>
        </div>
      )}
      {openAlertModal?.open && (
        <DetailModal
          openModal={openAlertModal?.open}
          closeModal={() =>
            setOpenAlertModal((prev) => ({
              ...prev,
              open: false,
              data: {},
            }))
          }
          data={openAlertModal?.data}
        />
      )}
    </div>
  );
};

export default Alert;
