import { Modal } from "@mui/material";
import { modalStyle } from "../../Common/CommonComponents";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const ExpandImage = ({
  openModal = false,
  closeModal = () => {},
  img = "",
}) => {
  return (
    <Modal
      open={openModal}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div
        style={{ ...modalStyle, maxWidth: "90vw", padding: 0 }}
        className="flex flex-col gap-0"
      >
        <div className="pt-2 pr-2 pl-6 flex justify-end items-center bg-[#F5F5F5] rounded-tr-xl rounded-tl-xl">
          <FullscreenExitIcon
            className="cursor-pointer hover:scale-105"
            sx={{ fontSize: "24px" }}
            onClick={closeModal}
          />
        </div>
        <div className="flex w-full h-[90vh] relative justify-center items-center rounded-b-lg bg-black">
          <img src={img} alt="image" className="h-full w-full rounded-b-lg" />
        </div>
      </div>
    </Modal>
  );
};

export default ExpandImage;
