import {
  CustomSelect,
  DivTabs,
  FieldLabel,
} from "../../Common/CommonComponents";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton } from "@mui/material";

const AlertRule = ({
  labels = [],
  conditions = [],
  ruleIndex = 0,
  conditionIndex = 0,
  setConditions = () => {},
  found = [],
  selectedCam = {},
}) => {
  const initialRule = ruleIndex === 0;

  const handleRuleAdd = () => {
    setConditions((prev) => {
      let newData = structuredClone(prev);
      newData?.[conditionIndex]?.push({
        operation: "",
      });
      newData?.[conditionIndex]?.push({
        label: "",
        state: "",
      });
      return newData;
    });
  };

  const handleRemoveRule = () => {
    setConditions((prev) => {
      let newData = structuredClone(prev);
      newData?.[conditionIndex]?.splice(ruleIndex, 1);
      newData?.[conditionIndex]?.splice(ruleIndex - 1, 1);
      return newData;
    });
  };

  const handleRuleChange = ({ name = "", value = "" }) => {
    setConditions((prev) => {
      let newData = structuredClone(prev);
      newData[conditionIndex][ruleIndex][name] = value;
      return newData;
    });
  };

  return (
    <div className="flex flex-col min-[900px]:flex-row gap-2 items-start min-[900px]:items-end">
      <p className="text-[#79767D] text-lg font-medium">When</p>
      <div className="flex flex-col gap-3">
        <FieldLabel text="Select label" />
        {labels?.length > 0 && (
          <CustomSelect
            options={labels}
            isPlain={false}
            displayKey="label"
            valueKey="labelId"
            displayEmpty={true}
            value={conditions?.[conditionIndex]?.[ruleIndex]?.label}
            setValue={(value) =>
              handleRuleChange({
                name: "label",
                value: value,
              })
            }
          />
        )}
      </div>
      <p className="text-[#79767D] text-lg font-medium">Is</p>
      <div className="flex flex-col gap-3">
        <FieldLabel text="Select state" />
        <CustomSelect
          options={found}
          value={conditions?.[conditionIndex]?.[ruleIndex]?.state}
          isPlain={false}
          displayEmpty={true}
          displayKey="state"
          valueKey="stateId"
          setValue={(value) =>
            handleRuleChange({
              name: "state",
              value: value,
            })
          }
        />
      </div>
      <IconButton
        size="small"
        onClick={initialRule ? handleRuleAdd : handleRemoveRule}
        color={initialRule ? "primary" : "error"}
        sx={{
          marginBottom: 4,
        }}
        title={initialRule ? "Add rule" : "Delete rule"}
        disabled={
          (conditions?.[conditionIndex]?.length === 3 && initialRule) ||
          selectedCam === ""
        }
      >
        {initialRule ? <AddIcon /> : <RemoveIcon />}
      </IconButton>
    </div>
  );
};

const AlertCondition = ({
  conditionIndex = 0,
  conditions = [],
  setConditions = () => {},
  labels = [],
  found = [],
  handleRemove = () => {},
  selectedCam = {},
}) => (
  <div className="flex flex-col gap-4 p-4 w-fit bg-[#FAFAFA] rounded-lg items-center shadow-sm border border-gray-100">
    <p className="text-[#3E3C42] text-lg font-medium flex justify-between w-full">
      Condition {conditionIndex / 2 + 1}{" "}
      {conditionIndex != 0 && (
        <img
          src="/delete.svg"
          className="hover:scale-105 cursor-pointer w-4 h-auto"
          alt="delete"
          onClick={() => handleRemove(conditionIndex)}
        />
      )}
    </p>
    {conditions[conditionIndex]?.map((item, index) => {
      return index % 2 === 0 ? (
        <AlertRule
          key={index}
          conditions={conditions}
          labels={labels}
          found={found}
          conditionIndex={conditionIndex}
          ruleIndex={index}
          setConditions={setConditions}
          selectedCam={selectedCam}
        />
      ) : (
        <DivTabs
          key={index}
          options={["and", "or"]}
          value={conditions?.[conditionIndex]?.[index]?.operation}
          setValue={(e) =>
            setConditions((prev) => {
              let newData = structuredClone(prev);
              newData[conditionIndex][index]["operation"] = e;
              return newData;
            })
          }
        />
      );
    })}
  </div>
);

export default AlertCondition;
