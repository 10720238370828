import ReactPlayer from "react-player";

const CommonWidgets = ({ type = "data", tagLine = "Play with our models" }) => {
  return <p className="text-[#48464C] text-2xl font-medium capitalize">{type}</p>;
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <p
          className="text-2xl font-semibold"
          style={{
            background:
              "var(--Gradient-New, linear-gradient(180deg, #2660B6 0%, #112A50 100%))",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Welcome to AI Studio
        </p>
        <p className="text-[#525056] text-base">
          Get started with AI studio and explore what this can do
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div
          className="flex-1 h-[30vh] flex flex-col gap-2 items-center justify-center rounded-lg border border-[#EBEBEB]"
          style={{
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <ReactPlayer
            muted
            controls
            playsinline
            light={
              "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/ai.png"
            }
            height={"100%"}
            width={"100%"}
            url={
              "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/AI+Sandbox+-+Ripik+v3.mp4"
            }
            playIcon={<></>}
          />
        </div>
        <div
          className="flex-1 flex flex-col gap-0 rounded-lg border border-[#EBEBEB] h-[30vh]"
          style={{
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <img
            alt="image"
            src={
              "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/tiltedWidget.svg"
            }
            className="w-full h-[calc(100%-50px)] rounded-lg"
          />
          <div className="w-full h-fit text-center content-center rounded-b-lg p-[10px] text-xl font-medium bg-[#F5F5F5]">
            <p
              style={{
                background:
                  "var(--Gradient-New, linear-gradient(180deg, #2660B6 0%, #112A50 100%))",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {tagLine}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonWidgets;
