import axios from "axios";
import { baseURL } from "../../../index";
import { mixpanelApiFailTrack } from "./MixpanelEvents";

export const callApi = async ({
  toast = () => {},
  type = "",
  endPoint = "",
  requestBody = null,
  params = null,
  isFormData = false,
}) => {
  try {
    const config = {
      method: type?.toLowerCase(),
      url: baseURL + endPoint,
      params: params,
      credentials: "same-origin",
      headers: {
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
        "X-Auth-Token": localStorage.getItem("auth_token"),
      },
    };
    if (requestBody && type.toLowerCase() !== "get") {
      config["data"] = requestBody;
    }
    const response = await axios(config);
    return response;
  } catch (error) {
    toast({
      title: error?.response?.status + " Error",
      description: error?.response?.data?.message,
      status: "error",
      duration: 6000,
      isClosable: true,
      position: "top-right",
    });
    mixpanelApiFailTrack({
      errorMessage: error?.response?.data?.message ?? "",
      endPoint: endPoint,
      errorCode: error?.response?.status,
      type: type,
      param: params ?? {},
      requestData: requestBody ?? {},
      isFormData: isFormData,
      pageLocation: window?.location?.href ?? "",
    });
    console.log(error);
  }
};
