import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useState } from "react";
import ViewClient from "../AddClients/ViewClient";
import SessionLogs from "../../Admin/Tabs/SessionLogs";
import UserMgmt from "../../Admin/Tabs/UserMgmt";
import DevelopmentInProgress from "../Deploy/DevelopmentInProgress";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Subscriptions from "../../Admin/Tabs/Subscriptions";
import UserRequests from "../../Admin/Tabs/UserRequests";

const ViewTabs = ({ setShowClientTab, selectedClient }) => {
  const [isDeployClicked, setDeployClicked] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  const tabList = [
    {
      index: 0,
      tabName: "Summary",
      element: (
        <ViewClient
          setDeployClicked={setDeployClicked}
          selectedClient={selectedClient}
        />
      ),
    },
    {
      index: 1,
      tabName: "Subscriptions",
      element: (
        <Subscriptions
          clientOrg={selectedClient.organisation}
          clientId={selectedClient?.clientId}
          mode="view"
          isSuperAdminClient={
            selectedClient.organisation !== "Ripik.Ai" ? true : false
          }
        />
      ),
    },
    {
      index: 2,
      tabName: "User Management",
      element: (
        <UserMgmt
          clientId={selectedClient.clientId}
          clientOrg={selectedClient.organisation}
          mode="view"
        />
      ),
    },
    {
      index: 3,
      tabName: "User Requests",
      element: <UserRequests clientId={selectedClient.clientId} mode="view" />,
    },
    {
      index: 4,
      tabName: "Session Logs",
      element: (
        <SessionLogs
          clientId={selectedClient.clientId}
          clientOrg={selectedClient.organisation}
          mode="view"
        />
      ),
    },
  ];

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const handleBackButton = () => {
    setShowClientTab((prev) => "addclient");
  };

  return (
    <>
      {!isDeployClicked ? (
        <div className=" font-roboto flex flex-col rounded-lg">
          <div className="flex items-between mb-3">
            <div className="cursor-pointer w-8" onClick={handleBackButton}>
              <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
            </div>
            <p className="text-lg sm:text-2xl font-semibold text-[#024D87] capitalize">
              View Details
            </p>
          </div>

          <Tabs
            className="bg-white rounded-md pt-4"
            width={"full"}
            index={tabIndex}
            onChange={handleTabsChange}
            isLazy={true}
          >
            <TabList
              className="!flex !border-0 !justify-between !items-center"
              width={"full"}
            >
              <div className="flex sm:w-[75%] xl:w-[100%] items-center gap-4 overflow-x-auto h-14 xl:h-10">
                {tabList?.map((item, id) => (
                  <Tab
                    key={id}
                    className={`xl:!text-sm !text-[14px] !text-[#605D64] whitespace-nowrap !border !rounded-full md:w-auto ${
                      tabIndex === item?.index
                        ? "  !bg-[#6CABFC] !bg-opacity-20  !border-[#6CA6FC]  "
                        : "!border-[#EBEBEB]"
                    }`}
                    onClick={() => handleTabsChange(item?.index)}
                  >
                    {item?.tabName}
                  </Tab>
                ))}
              </div>
            </TabList>
            <div className="flex">
              <TabPanels className="!pt-4">
                {tabList?.map(
                  (item) =>
                    selectedClient && (
                      <TabPanel className="!pl-0 !pr-0">
                        {item?.element}
                      </TabPanel>
                    )
                )}
              </TabPanels>
            </div>
          </Tabs>
        </div>
      ) : (
        <DevelopmentInProgress setDeployClicked={setDeployClicked} />
      )}
    </>
  );
};

export default ViewTabs;
