import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Select,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  Textarea,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { queryTypes } from "../Modals/AddRequestModal";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import RequestComments from "./RequestComments";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

export const priority = ["CRITICAL", "HIGH", "MEDIUM", "LOW"];

export const hiddenStatusColor = (val) => {
  switch (val) {
    case "will not resolved":
      return {
        text: "text-orange-400",
        hexCode: "#F90",
      };
    case "hold":
      return {
        text: "text-orange-400",
        hexCode: "#F90",
      };
    case "returned":
      return {
        text: "text-gray-400",
        hexCode: "#938F96",
      };
    case "closed":
      return {
        text: "text-gray-400",
        hexCode: "#69B04B",
      };
    default:
      return {
        text: "text-gray-400",
        hexCode: "#938F96",
      };
  }
};

const RequestDetails = ({
  selectedSubscription,
  setSelectedSubscription = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useContext(NavContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const requestId =
    selectedSubscription?.requestId || searchParams?.get("requestId");
  const isRipikAdmin = requestId && location?.pathname?.includes("superadmin");
  const subscriptionId =
    selectedSubscription?.subscriptionId || searchParams?.get("subscriptionId");
  const email = selectedSubscription?.email || searchParams?.get("email");
  const [userState, setUserState] = useState({
    queryType:
      selectedSubscription?.queryType || searchParams?.get("queryType"),
    priority: "",
    attachedFiles: [],
    details: "",
    issues: "",
  });
  const [adminState, setAdminState] = useState({
    nextState: "",
    remarks: "",
  });
  const detailRef = useRef();
  const issueRef = useRef();
  const fileRef = useRef();
  const [activeStep, setActiveStep] = useState({
    majorStep: "New Request",
    hiddenStep: "New Request",
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [steps, setSteps] = useState({});
  const requestClosed = activeIndex === steps?.userFacingStatus?.length;

  const handleChange = (name, value) => {
    setUserState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeAdmin = (name, value) => {
    setAdminState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveRequest = async () => {
    try {
      if (
        !userState?.queryType ||
        !userState?.priority ||
        !detailRef?.current?.value?.trim()
      ) {
        toast({
          title: "Error",
          description: (
            <div>
              {!userState?.queryType && <p>Please select request type</p>}
              {!userState?.priority && <p>Please select priority</p>}
              {!detailRef?.current?.value && <p>Please enter details</p>}
            </div>
          ),
          status: "error",
          position: "top-right",
          duration: 6000,
          isClosable: true,
        });
        return;
      }
      //bug case
      if (
        userState?.queryType === "BUG" &&
        (!issueRef?.current?.value?.trim() || !userState?.attachedFiles?.length)
      ) {
        toast({
          title: "Error",
          description: (
            <div>
              {!issueRef?.current?.value && (
                <p>Please enter steps to reproduce</p>
              )}
              {!userState?.attachedFiles?.length && (
                <p>Please attach files to support issue</p>
              )}
            </div>
          ),
          status: "error",
          position: "top-right",
          duration: 6000,
          isClosable: true,
        });
        return;
      }

      const requestData = JSON.stringify({
        subscriptionId: subscriptionId,
        email: email,
        requestType: userState?.queryType,
        priority: userState?.priority?.toUpperCase(),
        details: detailRef?.current?.value,
        stepsToReplicateBug: issueRef?.current?.value,
      });
      const formData = new FormData();
      userState?.attachedFiles?.forEach((file) =>
        formData?.append("files", file)
      );
      let userRequestBlob = new Blob([requestData], {
        type: "application/json",
      });
      formData?.append("userRequest", userRequestBlob);
      const response = await axios.post(
        baseURL + "v2/aisandbox/userRequests/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status === 200) {
        toast({
          title: "Request added",
          status: "success",
          position: "top-right",
          duration: 3000,
        });
        setTimeout(
          () =>
            selectedSubscription ? setSelectedSubscription(null) : navigate(-1),
          2000
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveAdminChange = async () => {
    try {
      const requestData = {
        userRequestId: requestId,
        requestStatus: adminState?.nextState,
        remark: adminState?.remarks,
      };
      const response = await axios?.post(
        baseURL + "v2/aisandbox/userRequests/update/",
        null,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
          params: requestData,
        }
      );

      if (response?.status === 200) {
        toast({
          title: "State changed",
          status: "success",
          position: "top-right",
          duration: 3000,
        });
        setAdminState((prev) => ({
          ...prev,
          nextState: "",
          remarks: "",
        }));
        getRequestData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRequestData = async () => {
    try {
      const param = {
        userRequestId: requestId,
      };
      const response = await axios?.get(
        baseURL + "v2/aisandbox/userRequests/",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
          params: param,
        }
      );
      if (response?.status === 200) {
        let data = response?.data[0];
        setUserState((prev) => ({
          ...prev,
          priority: data?.requestPriority,
          details: data?.description,
          issues: data?.stepsToReplicate,
          attachedFiles: data?.userRequestDocumentList,
        }));
        setAdminState((prev) => ({
          ...prev,
          remarks: data?.remarks ?? "",
        }));
        setActiveStep((prev) => ({
          ...prev,
          majorStep: data?.userFacingStatus,
          hiddenStep: data?.requestStatus,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRequestStatus = async () => {
    try {
      const response = await axios.get(
        baseURL + "v2/aisandbox/userRequests/requestStatus/",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status === 200) {
        setSteps(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (requestId) getRequestData();
    getRequestStatus();
  }, []);

  useEffect(() => {
    setActiveIndex((prev) => {
      let idx = steps?.userFacingStatus?.findIndex(
        (item) => item === activeStep?.majorStep
      );
      if (idx === steps?.userFacingStatus?.length - 1) idx += 1;
      return idx;
    });
  }, [steps, activeStep]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2 items-center">
        <img
          src="/backtick.svg"
          alt="back"
          className="hover:scale-105 cursor-pointer"
          onClick={() =>
            selectedSubscription ? setSelectedSubscription(null) : navigate(-1)
          }
        />
        <p className="text-[#084298] text-xl font-medium">Request details</p>
      </div>
      <div className="p-6 rounded-xl border border-[#CAC5CD] bg-white flex flex-col gap-6 w-full">
        {/*Stepper*/}
        <div className="w-full overflow-x-auto">
          <Stepper
            index={activeIndex}
            bg={"#FAFAFA"}
            p={"8px"}
            border={"1px solid #EBEBEB"}
            borderRadius={"8px"}
            minWidth={"1200px"}
          >
            {steps?.userFacingStatus?.map((step, index) => {
              let isActiveIndex = step === activeStep?.majorStep;
              let toolTipValue =
                "Current request status is '" +
                (isActiveIndex ? activeStep?.hiddenStep : step) +
                "'";
              let isHiddenStep =
                isActiveIndex &&
                activeStep?.hiddenStep !== activeStep?.majorStep;
              let hiddenStepColor = hiddenStatusColor(
                activeStep?.hiddenStep?.toLowerCase()
              )?.hexCode;
              return (
                <Step key={index}>
                  <StepIndicator
                    borderRadius={"8px"}
                    sx={{
                      "[data-status=complete] &": {
                        background: "#69B04B",
                        borderColor: "#69B04B",
                      },
                      "[data-status=active] &": {
                        background: isHiddenStep ? hiddenStepColor : "#6CA6FC",
                        borderColor: isHiddenStep ? hiddenStepColor : "#6CA6FC",
                        color: "#FFF",
                      },
                      "[data-status=incomplete] &": {
                        background: "#F5F5F5",
                        borderColor: "#CAC5CD",
                        color: "#084298",
                      },
                    }}
                  >
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={
                        <Tooltip label={toolTipValue} hasArrow placement="top">
                          <StepNumber />
                        </Tooltip>
                      }
                    />
                  </StepIndicator>

                  <p
                    className="text-base font-medium whitespace-nowrap"
                    style={{
                      color: index > activeIndex ? "#938F96" : "#3E3C42",
                    }}
                  >
                    {step}
                  </p>

                  <StepSeparator />
                </Step>
              );
            })}
          </Stepper>
        </div>
        {/*User mail and request id */}
        {requestId && (
          <div className="flex flex-wrap gap-4 items-center whitespace-nowrap w-full">
            <div
              className="flex max-w-full gap-3 items-center text-sm font-medium p-2 border border-[#CAC5CD] rounded"
              style={{
                boxShadow:
                  "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
              }}
            >
              <p className="text-[#2660B6]">User email ID</p>
              <p className="text-[#605D64] truncate" title={email}>
                {email}
              </p>
            </div>
            <div
              className="flex max-w-full gap-3 items-center text-sm font-medium p-2 border border-[#CAC5CD] rounded"
              style={{
                boxShadow:
                  "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
              }}
            >
              <p className="text-[#2660B6]">User request ID</p>
              <p className="text-[#605D64] truncate" title={"239473urh2r"}>
                {requestId}
              </p>
            </div>
          </div>
        )}
        {/*User and admin forms */}
        <div className="flex gap-6 flex-wrap">
          {/*User form */}
          <div className="flex flex-col gap-6">
            {/*Request type and priority*/}
            <div className="flex gap-6 items-center flex-wrap w-full">
              <div className="flex flex-col gap-3">
                <p className="text-[#2660B6] text-sm font-medium">
                  Request type<span className="text-red-700">*</span>
                </p>
                <Select
                  value={userState?.queryType}
                  onChange={(e) => handleChange("queryType", e.target.value)}
                  width={"300px"}
                  isDisabled={requestId}
                >
                  <option value={""}>Select request type</option>
                  {queryTypes
                    ?.filter((item) =>
                      requestId ? item : item?.role === "all"
                    )
                    ?.map((item, idx) => (
                      <option key={idx} value={item?.type}>
                        {item?.type}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-[#2660B6] text-sm font-medium">
                  Priority<span className="text-red-700">*</span>
                </p>
                <Select
                  value={userState?.priority}
                  onChange={(e) => handleChange("priority", e.target.value)}
                  width={"300px"}
                  isDisabled={requestId}
                >
                  <option value={""}>Select priority</option>
                  {priority?.map((item, idx) => (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/*Details & bug steps*/}
            <div className="flex gap-6 items-center flex-wrap">
              <div className="flex flex-col gap-3">
                <p className="text-[#2660B6] text-sm font-medium">
                  Details<span className="text-red-700">*</span>
                </p>
                <Textarea
                  placeholder="Enter details about request"
                  width={"300px"}
                  ref={detailRef}
                  defaultValue={userState?.details}
                  isDisabled={requestId}
                />
              </div>
              {userState?.queryType === "BUG" && (
                <div className="flex flex-col gap-3">
                  <p className="text-[#2660B6] text-sm font-medium">
                    Steps to replicate Bug
                    <span className="text-red-700">*</span>
                  </p>
                  <Textarea
                    placeholder="Enter the instructions to replicate the issue"
                    width={"300px"}
                    ref={issueRef}
                    defaultValue={userState?.issues}
                    isDisabled={requestId}
                  />
                </div>
              )}
            </div>
            {/*Attach file*/}
            <div className="flex flex-col gap-3">
              <p className="text-[#2660B6] text-sm font-medium">
                {requestId
                  ? "Files uploaded by user"
                  : "Attach files or screenshots"}
                {userState?.queryType === "BUG" && !requestId && (
                  <span className="text-red-700">*</span>
                )}
              </p>
              <input
                style={{ display: "none" }}
                multiple
                type="file"
                ref={fileRef}
                onChange={(e) => {
                  let files = e.target.files;
                  handleChange(
                    "attachedFiles",
                    userState?.attachedFiles?.concat([...files])
                  );
                  fileRef.current.value = null;
                }}
              />
              {requestId ? (
                <div className="flex gap-3 items-center flex-wrap">
                  {userState?.attachedFiles?.map((item, idx) => {
                    return (
                      <div
                        className="h-9 flex gap-1 items-center py-1 px-3 text-[#48464C] text-sm bg-[#EBEBEB] rounded"
                        key={idx}
                      >
                        <p
                          className="max-w-[100px] truncate"
                          title={item?.fileName}
                        >
                          {item?.fileName}
                        </p>
                        <FileDownloadOutlinedIcon
                          className="cursor-pointer hover:scale-105"
                          onClick={() => {
                            let downloadLink = item?.fileUrl;
                            window.open(downloadLink, "_blank").focus();
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex gap-3 items-center flex-wrap">
                  <SecondaryButton
                    Icon={<UploadFileIcon />}
                    text={"Upload"}
                    width={"fit-content"}
                    height={"36px"}
                    onClick={() => fileRef?.current?.click()}
                  />

                  {userState?.attachedFiles?.map((item, idx) => {
                    return (
                      <div
                        className="h-9 flex gap-1 items-center py-1 px-[6px] text-[#48464C] text-sm bg-[#EBEBEB] rounded"
                        key={idx}
                      >
                        <p
                          className="max-w-[100px] truncate"
                          title={item?.name}
                        >
                          {item?.name}
                        </p>
                        <CancelOutlinedIcon
                          className="hover:scale-105 cursor-pointer"
                          onClick={() => {
                            setUserState((prev) => {
                              let newData = { ...prev };
                              let index = newData?.attachedFiles?.findIndex(
                                (val) => val?.name === item?.name
                              );
                              if (index !== -1)
                                newData?.attachedFiles?.splice(index, 1);
                              return newData;
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {/*Ripik admin comments */}
          <div className="flex flex-col gap-6">
            {isRipikAdmin && !requestClosed && (
              <div className="flex flex-col gap-3">
                <p className="text-[#2660B6] text-sm font-medium">
                  Update request
                </p>
                <Select
                  value={adminState?.nextState}
                  onChange={(e) =>
                    handleChangeAdmin("nextState", e.target.value)
                  }
                  width={"300px"}
                >
                  <option value={""}>Select next state</option>
                  {steps?.nextPossibleStatus?.[activeStep?.hiddenStep]?.map(
                    (item, idx) => (
                      <option key={idx} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </Select>
              </div>
            )}
            <div className="flex flex-col gap-3">
              <p className="text-[#2660B6] text-sm font-medium">Remarks</p>
              <Textarea
                placeholder="Enter..."
                width={"300px"}
                value={adminState?.remarks}
                onChange={(e) => handleChangeAdmin("remarks", e.target.value)}
                disabled={!isRipikAdmin || requestClosed}
              />
            </div>
          </div>
        </div>
        {/*CTA */}
        {!requestClosed && (
          <div className="flex gap-2 items-center">
            {isRipikAdmin ? (
              <PrimaryButton
                text={"Confirm request update"}
                width={"fit-content"}
                onClick={saveAdminChange}
                disable={!adminState?.nextState || !adminState?.remarks?.trim()}
              />
            ) : requestId ? null : (
              <PrimaryButton
                text={"Submit"}
                width={"fit-content"}
                onClick={saveRequest}
              />
            )}
          </div>
        )}
      </div>
      {requestId && <RequestComments requestId={requestId} />}
    </div>
  );
};

export default RequestDetails;
