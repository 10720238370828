import { useToast } from "@chakra-ui/react";
import {
  ButtonContainer,
  NavigateBack,
  Tablist,
} from "../Common/CommonComponents";
import { Skeleton } from "@mui/material";
import { callApi } from "../utils/ApiUtil";
import OutputImages from "./OuputImages";
import OutputSummary from "./OutputSummary/OutputSummary";
import { useEffect, useRef, useState } from "react";
import FallBack from "./Components/FallBack";
import { useLocation, useNavigate } from "react-router-dom";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import SyncIcon from "@mui/icons-material/Sync";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import ModelInferDrawer from "./Components/ModelInferDrawer";
import ModelRunHistory from "./Components/ModelRunHistory";
import ModelDetails from "./Components/ModelDetails";

const ViewModelRuns = ({ searchParams, setSearchParams }) => {
  const page = searchParams.get("tabIndex")
    ? isNaN(searchParams.get("tabIndex"))
      ? 0
      : Number(searchParams.get("tabIndex"))
    : 0;
  const location = useLocation();
  const navigate = useNavigate();
  const modelId = searchParams?.get("modelId");
  const noModelRuns = searchParams?.get("noModelRuns") === "true";
  const modelName = searchParams?.get("modelName");
  const dataId = searchParams?.get("dataId");
  const intervalRef = useRef();
  const [loadingPredictions, setLoadingPredictions] = useState(true);
  const [model, setModel] = useState({});
  const [predictionData, setPredictionData] = useState({});
  const [rerun, setRerun] = useState(false);
  const [isReady, setIsReady] = useState({
    processed: false,
    success: false,
  });
  const [history, setHistory] = useState([]);
  const [selectedModelRun, setSelectedModelRun] = useState(
    searchParams?.get("selectedModelRun")
  );
  const [selectedModelRunObj, setSelectedModelRunObj] = useState({});
  const [modelInfo, setModelInfo] = useState({});
  const toast = useToast();
  const handlePageChange = (val) => {
    if (val !== page) {
      setSearchParams((prev) => {
        prev.set("tabIndex", val);
        return prev;
      });
    }
  };

  const getExistingModels = async () => {
    try {
      const param = {
        modelObjectId: modelId,
      };
      const response = await callApi({
        type: "get",
        toast: toast,
        endPoint: "selfserve/v2/project/v1/model/",
        params: param,
      });
      if (response?.status === 200) {
        setModel(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPredictionData = async () => {
    setLoadingPredictions(true);
    try {
      const param = {
        modelObjectId: modelId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/model/prediction/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        setPredictionData(response?.data);
      }
      setLoadingPredictions(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getPredictionDataById = async () => {
    setLoadingPredictions(true);
    try {
      const param = {
        modelObjectId: modelId,
        modelRunId: selectedModelRun,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/model/predictionList/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200 && response?.data[0])
        setPredictionData(response?.data[0] ?? {});

      setLoadingPredictions(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getRunInfo = async () => {
    try {
      const param = {
        modelObjectId: modelId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/model/modelInfo/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        let data = response?.data;
        let status = Object?.entries(data?.stateChangeTs || {})?.slice(
          -1
        )?.[0]?.[0];
        if (["COMPLETED", "FAILED", "TERMINATED"]?.includes(status)) {
          if (status === "COMPLETED")
            setIsReady((prev) => ({
              ...prev,
              processed: true,
              success: true,
            }));
          else
            setIsReady((prev) => ({
              ...prev,
              processed: true,
              success: false,
            }));
          clearInterval(intervalRef.current);
        }
        setModelInfo(data);
      }
      setLoadingPredictions(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getHistory = async () => {
    try {
      const param = {
        modelObjectId: modelId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/model/history/",
        params: param,
        toast: toast,
      });
      if (response?.status === 200) setHistory(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleModelRunChange = (val) => {
    if (val !== selectedModelRun) {
      setSelectedModelRun(val);
      setSelectedModelRunObj(
        history?.find((item) => item?.modelRunId === val) ?? {}
      );
      setSearchParams((prev) => {
        prev.set("selectedModelRun", val);
        return prev;
      });
    }
  };

  const fallBack = (
    <FallBack
      modelInfo={modelInfo}
      stateChangeTs={
        selectedModelRun === history?.[0]?.modelRunId
          ? modelInfo?.stateChangeTs
          : selectedModelRunObj?.stateChangeTs
      }
      isLatest={selectedModelRun === history?.[0]?.modelRunId}
      predictionData={predictionData}
      noModelRuns={noModelRuns}
    />
  );

  const tabList = [
    {
      index: 0,
      tabName: "Model details",
      element: <ModelDetails modelInfo={modelInfo} model={model} />,
    },
    {
      index: 1,
      tabName: "Output data",
      element:
        isReady?.processed && Object?.entries(predictionData)?.length !== 0 ? (
          <OutputSummary
            predictionData={predictionData}
            modelInfo={modelInfo}
            stateChangeTs={
              selectedModelRun === history?.[0]?.modelRunId
                ? modelInfo?.stateChangeTs
                : selectedModelRunObj?.stateChangeTs
            }
          />
        ) : (
          fallBack
        ),
    },
    {
      index: 2,
      tabName: "Output images",
      element:
        isReady?.processed && Object?.entries(predictionData)?.length !== 0 ? (
          <OutputImages
            predictionData={predictionData}
            selectedModelRun={selectedModelRun}
            modelId={modelId}
            history={history}
            getHistory={getHistory}
          />
        ) : (
          fallBack
        ),
    },
    {
      index: 3,
      tabName: "Output video",
      element: isReady?.processed ? (
        <video
          src={selectedModelRunObj?.predictionVideoLink}
          muted
          playsInline
          controls
          className="w-full h-[70vh] rounded"
        />
      ) : (
        fallBack
      ),
    },
    {
      index: 4,
      tabName: "Model history",
      element: (
        <ModelRunHistory
          selectedModelRun={selectedModelRun}
          setSelectedModelRun={handleModelRunChange}
          setIsReady={setIsReady}
          history={history}
          isReady={isReady}
          setPredictionData={setPredictionData}
        />
      ),
    },
  ]?.filter((item) => {
    if (item?.index === 3) return selectedModelRunObj?.predictionVideoLink;
    return item;
  });

  useEffect(() => {
    if (!noModelRuns) getHistory();
    else getRunInfo();
    getExistingModels();
  }, []);

  useEffect(() => {
    if (isReady?.success && selectedModelRun === history?.[0]?.modelRunId) {
      getPredictionData();
    }
    if (isReady?.processed) getHistory();
  }, [isReady]);

  useEffect(() => {
    if (selectedModelRun && history?.length > 0) {
      if (selectedModelRun === history?.[0]?.modelRunId) {
        getRunInfo();
        intervalRef.current = setInterval(() => {
          getRunInfo();
        }, 10 * 1000);
        return () => clearInterval(intervalRef.current);
      } else {
        getRunInfo();
        getPredictionDataById();
      }
    }
  }, [selectedModelRun, history?.length]);

  useEffect(() => {
    if (history?.length > 0) {
      if (!selectedModelRun) {
        setSelectedModelRun(history?.[0]?.modelRunId);
        setSelectedModelRunObj(history[0]);
      } else
        setSelectedModelRunObj(
          history?.find((item) => item?.modelRunId === selectedModelRun) ?? {}
        );
    }
  }, [history?.length]);

  if (loadingPredictions)
    return (
      <div className="w-full h-[80vh] flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row gap-4 justify-between items-start sm:items-center">
          <Skeleton width={"200px"} height={"40px"} variant="rectangular" />
          <div className="flex gap-2 items-center self-end">
            {[...Array(2)]?.map((item, idx) => (
              <Skeleton
                key={idx}
                width={"100px"}
                height={"40px"}
                variant="rectangular"
              />
            ))}
          </div>
        </div>
        <div className="flex gap-4 items-center">
          {[...Array(4)]?.map((item, idx) => (
            <Skeleton
              key={idx}
              width={"100px"}
              height={"40px"}
              variant="rectangular"
            />
          ))}
        </div>
        <Skeleton width={"100%"} height={"100%"} variant="rectangular" />
      </div>
    );

  return (
    <div className="relative flex flex-col gap-2">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
        <NavigateBack
          targetRoute={
            location?.state?.prevPath?.includes("tabIndex")
              ? -1
              : "/SandboxV2/model"
          }
          textArray={["AI Studio", modelName]}
        />
        <ButtonContainer normalClasses="absolute -top-2 right-2">
          <div className="flex gap-2 items-center w-full sm:w-[260px]">
            <SecondaryButton
              height={"36px"}
              width={"100%"}
              text={"Re-run"}
              Icon={<SyncIcon />}
              onClick={() => setRerun(true)}
              disable={!isReady?.processed && !noModelRuns}
            />
            <PrimaryButton
              width={"100%"}
              text={"Create app"}
              Icon={<PersonalVideoIcon />}
              onClick={() =>
                navigate(`/SandboxV2/draft/app?modelId=${modelId}`, {
                  state: {
                    prevPath: location?.pathname + location?.search,
                  },
                })
              }
              disable={!isReady?.success && !noModelRuns}
            />
          </div>
        </ButtonContainer>
      </div>
      <Tablist
        tabList={tabList}
        isLazy={true}
        pageIndex={page}
        setPageIndex={handlePageChange}
      />
      {rerun && (
        <ModelInferDrawer
          openModal={rerun}
          closeModal={() => setRerun(false)}
          modelId={modelId}
          modelName={modelName}
          dataRepoId={dataId}
        />
      )}
    </div>
  );
};

export default ViewModelRuns;
