import StarIcon from "@mui/icons-material/Star";
import { useEffect, useRef, useState } from "react";
import { CustomSelect, modalStyle } from "../../../Common/CommonComponents";
import { FlipCard } from "../../OuputImages";
import LabelBar from "../../../../Charts/SelfService/LabelBar";
import Paginator from "../../../../../util/VisionUtils/Paginator";
import LabelScatterPlot from "../../../../Charts/SelfService/LabelScatterPlot";
import { InputAdornment, Modal, OutlinedInput } from "@mui/material";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ClearIcon from "@mui/icons-material/Clear";

export const CalMax = (data) => {
  let values = data?.map((item) => {
    return item?.precision;
  });
  return Math.max(...values);
};

const ExpandModal = ({
  isOpen,
  closeModal,
  label,
  predictionData,
  starred,
}) => {
  const sortVal = ["High to Low", "Low to High"];
  const [selectedSort, setSelectedSort] = useState("");
  const [data, setData] = useState({});
  const [minConf, setMinConf] = useState(0);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    switch (selectedSort) {
      case "High to Low":
        setData((prev) => ({
          ...prev,
          [label]: {
            ...prev[label],
            img: [...prev[label]?.img]?.sort((a, b) => {
              return b.precision - a.precision;
            }),
          },
        }));
        break;
      case "Low to High":
        setData((prev) => ({
          ...prev,
          [label]: {
            ...prev[label],
            img: [...prev[label]?.img]?.sort((a, b) => {
              return a.precision - b.precision;
            }),
          },
        }));
        break;
      default:
        setData(predictionData);
        setMinConf(0);
        break;
    }
  }, [selectedSort]);

  useEffect(() => {
    if ([label] in data) {
      setData((prev) => ({
        ...prev,
        [label]: {
          ...prev[label],
          img: predictionData[label]?.img?.filter((item) => {
            let conf = (item.precision * 100)?.toFixed(2);
            return parseFloat(conf) >= parseFloat(minConf);
          }),
        },
      }));
    }
  }, [minConf, predictionData]);

  useEffect(() => {
    setData({ ...predictionData });
  }, [predictionData]);

  return (
    <Modal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div
        style={{ ...modalStyle, maxWidth: "1160px", maxHeight: "90vh" }}
        className="flex flex-col gap-4 items-center overflow-y-auto"
      >
        <div className="flex items-center justify-between gap-2 w-full">
          <div className="flex gap-[6px] flex-col">
            <div className="flex gap-2 items-center">
              {starred && <StarIcon sx={{ color: "#FFC107" }} />}
              <p className="text-[#3E3C42] font-medium text-base">{label}</p>
            </div>
            <p className="text-[#79767D] text-sm">
              Total :{" "}
              <span className="text-[#3E3C42]">
                {data[label]?.img?.length} Files
              </span>
            </p>
          </div>
          <div className="flex gap-1 flex-col sm:flex-row items-center">
            {selectedSort == "" ? (
              <CustomSelect
                value={selectedSort}
                setValue={setSelectedSort}
                isPlain={false}
                displayKey="type"
                valueKey="field"
                options={[
                  { type: "Select sort", field: "" },
                  ...sortVal?.map((item) => ({
                    type: item,
                    field: item,
                  })),
                ]}
                displayEmpty={true}
                width="170px"
              />
            ) : (
              <div className="flex gap-2 px-1 pl-3 pr-2 h-[38px] items-center bg-[#EEF5FF] border border-[#6CA6FC] rounded">
                <p className="text-[#605D64] text-sm">
                  Confidence:{" "}
                  <span className="font-medium">{selectedSort}</span>
                </p>
                <ClearIcon
                  className="cursor-pointer hover:scale-105"
                  onClick={() => setSelectedSort("")}
                />
              </div>
            )}
            <OutlinedInput
              type="number"
              value={minConf}
              onChange={(e) =>
                setMinConf(
                  e.target.value > 100
                    ? 100
                    : e.target.value < 0
                    ? 0
                    : e.target.value
                )
              }
              size="small"
              sx={{
                width: "170px",
              }}
              startAdornment={
                <InputAdornment position="start">
                  {"Confidence >="}
                </InputAdornment>
              }
            />
          </div>
          <FullscreenExitIcon
            sx={{
              color: "#3A74CA",
            }}
            className="cursor-pointer hover:scale-105"
            onClick={closeModal}
          />
        </div>
        <div className="flex flex-col xl:flex-row gap-4 w-full">
          {data[label]?.img?.length > 0 ? (
            <>
              <div className="flex flex-col gap-1 items-end w-full xl:w-[65%]">
                <Paginator
                  data={data[label]?.img}
                  limit={10}
                  setDisplayData={setDisplayData}
                />
                <div className="w-full grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 h-fit gap-4">
                  {displayData?.map((item, idx) => {
                    return (
                      <FlipCard
                        key={idx}
                        data={item}
                        width="128px"
                        height="128px"
                        expandHeight="25px"
                        expandWidth="25px"
                      />
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col gap-4 w-full xl:w-[35%]">
                {data.hasOwnProperty(label) && (
                  <LabelBar data={data[label]?.img} />
                )}
                {data.hasOwnProperty(label) && (
                  <LabelScatterPlot data={data[label]?.img} />
                )}
              </div>
            </>
          ) : (
            <p className="text-xl w-full flex justify-center items-center">
              No {label} with confidence {">= " + minConf + "%"} were detected
              kindly provide better annotations, better image quality or try
              lowering the confidence
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ExpandModal;
