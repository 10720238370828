import { useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  CommonProgressBar,
  CommonUniqueTextCheck,
  CustomSelect,
  FieldLabel,
  UploadDragDrop,
} from "../Common/CommonComponents";
import { callApi } from "../utils/ApiUtil";
import CancelIcon from "@mui/icons-material/Cancel";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { IconButton, Modal, OutlinedInput, TextField } from "@mui/material";
import {
  modelArchitectureOptions,
  modelOptions,
  nameDescCheck,
} from "../utils/HandlerFuncs";
import { NavigateBack, TransitionCards } from "../Common/CommonComponents";
import { useLocation, useNavigate } from "react-router-dom";
import TonalButton from "../../../util/Buttons/TonalButton";

const initState = {
  modelId: "",
  modelName: "",
  modelDescription: "",
  modelType: "",
  modelArchitecture: "",
  modelFile: null,
};

const AddModel = ({ searchParams }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [draftSet, setDraftSet] = useState(false);
  const [userState, setUserState] = useState(initState);
  const modelId = searchParams?.get("modelId");
  const modelName = searchParams?.get("modelName");
  const nameRef = useRef();
  const descRef = useRef();
  const modelFileRef = useRef();
  const toast = useToast();
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });

  const draftPostApi = async () => {
    try {
      const param = {
        draftId: userState?.modelId ? userState?.modelId : null,
        draftType: "UPLOAD_MODEL",
      };
      const requestData = JSON.stringify(userState);
      const response = await callApi({
        type: "post",
        endPoint: "selfserve/v2/project/v1/model/draft/",
        toast: toast,
        requestBody: requestData,
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "Draft saved",
          status: "success",
          position: "top-right",
          duration: 3000,
        });
        setUserState((prev) => ({
          ...prev,
          modelId: response?.data?.draftId,
        }));
        return response?.data?.draftId;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  const getDraftApi = async () => {
    try {
      const param = {
        draftId: modelId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/model/draft/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        setUserState((prev) => ({
          ...response?.data,
          modelId: modelId,
        }));
        setDraftSet(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (name, value) => {
    setUserState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const uploadModel = async (id) => {
    if (
      !nameDescCheck({
        val: nameRef?.current?.value,
        toast: toast,
        name: "model name",
      }) ||
      !nameDescCheck({
        val: descRef?.current?.value,
        isDesc: true,
        toast: toast,
        name: "model description",
      })
    )
      return;

    if (
      !userState?.modelFile ||
      !userState?.modelType ||
      !userState?.modelArchitecture
    ) {
      toast({
        title: "Error",
        description: (
          <div>
            {!userState?.modelType && <p>Please select model type</p>}
            {!userState?.modelFile && <p>Please upload model file</p>}
            {!userState?.modelArchitecture && (
              <p>Please select model Architecture</p>
            )}
          </div>
        ),
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    try {
      const param = {
        draftId: id,
        modelType: userState?.modelType?.toUpperCase(),
        modelArch: userState?.modelArchitecture?.toUpperCase(),
        modelName: nameRef?.current?.value,
        modelDescription: descRef?.current?.value,
      };
      const requestData = new FormData();
      requestData?.append("file", userState?.modelFile);
      const response = await callApi({
        endPoint: "selfserve/v2/project/v1/model/upload/",
        toast: toast,
        isFormData: true,
        type: "post",
        params: param,
        requestBody: requestData,
      });
      if (response?.status === 200) {
        setProgressState((prev) => ({ ...prev, state: 2 }));
        setTimeout(
          () =>
            navigate(-1, {
              state: {
                prevPath: location?.pathname + location?.search,
              },
            }),
          2000
        );
      } else setProgressState((prev) => ({ ...prev, state: 0 }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modelId) getDraftApi();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <NavigateBack
        targetRoute={-1}
        textArray={["AI Studio", "Create new model"]}
      />
      <TransitionCards>
        <p className="text-[#3E3C42] text-xl font-medium">Upload model</p>
        <div className="flex flex-col gap-4 items-end w-[280px] xs:w-[350px]">
          <div className="flex flex-col gap-3 w-full">
            <FieldLabel text="Enter model name" />
            <OutlinedInput
              inputRef={nameRef}
              value={userState?.modelName}
              onChange={(e) => handleChange("modelName", e?.target?.value)}
              placeholder="Name"
              size="small"
              sx={{
                width: "100%",
              }}
            />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <FieldLabel text="Enter model description" />
            <TextField
              inputRef={descRef}
              value={userState?.modelDescription}
              onChange={(e) =>
                handleChange("modelDescription", e?.target?.value)
              }
              placeholder="model description"
              sx={{
                width: "100%",
              }}
              multiline
              rows={2}
            />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <FieldLabel text="Select model type" />
            <CustomSelect
              setValue={(value) => handleChange("modelType", value)}
              value={userState?.modelType}
              options={[
                { name: "Select type", type: "" },
                ...modelOptions?.map((item) => ({
                  name: item?.type?.toUpperCase(),
                  type: item?.type,
                })),
              ]}
              isPlain={false}
              displayEmpty={true}
              displayKey="name"
              valueKey="type"
              width="100%"
            />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <FieldLabel text="Select model Architecture" />
            <CustomSelect
              setValue={(value) => handleChange("modelArchitecture", value)}
              value={userState?.modelArchitecture}
              options={[
                { name: "Select model Architecture", type: "" },
                ...modelArchitectureOptions
                  ?.filter((item) => {
                    if (userState?.modelType === "classify") return item;
                    else return item?.type !== "resnet";
                  })
                  ?.map((item) => ({
                    name: item?.type?.toUpperCase(),
                    type: item?.type,
                  })),
              ]}
              isPlain={false}
              displayEmpty={true}
              displayKey="name"
              valueKey="type"
              width="100%"
            />
          </div>
          {!userState?.modelFile ? (
            <UploadDragDrop
              forwardRef={modelFileRef}
              handleChange={(e) =>
                handleChange("modelFile", e?.target?.files[0])
              }
              text="Drag/Upload your model"
              accept={".pt"}
            />
          ) : (
            <div className="self-start bg-[#F5F5F5] border border-[#EBEBEB] py-1 px-2 flex gap-2 items-center w-fit rounded">
              <p className="text-[#3E3C42] text-base font-medium truncate">
                {userState?.modelFile?.name}
              </p>
              <IconButton
                onClick={() => {
                  handleChange("modelFile", null);
                }}
                size="small"
                color="error"
              >
                <CancelIcon />
              </IconButton>
            </div>
          )}
          <div className="flex gap-2 items-center">
            <PrimaryButton
              text={"Save"}
              width={"120px"}
              disable={!userState?.modelName || !userState?.modelDescription}
              onClick={async () => {
                let id = await draftPostApi();
                uploadModel(id);
              }}
            />
            <TonalButton
              text={"Save as draft"}
              width={"120px"}
              onClick={draftPostApi}
              disable={
                !userState?.modelName ||
                !userState?.modelDescription ||
                userState?.modelFile
              }
            />
          </div>
        </div>
        <CommonUniqueTextCheck
          valueKey="name"
          name={"Model name"}
          inputRefValue={nameRef}
          endPoint={"selfserve/v2/project/v1/model/"}
          bypassValue={modelId ?? ""}
          bypassValueKey="masterObjectId"
        />
        {progressState?.open && (
          <CommonProgressBar
            openModal={progressState?.open}
            closeModal={() =>
              setProgressState((prev) => ({
                ...prev,
                open: false,
                state: null,
              }))
            }
            state={progressState?.state}
          />
        )}
      </TransitionCards>
    </div>
  );
};

export default AddModel;
