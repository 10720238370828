import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import { callApi } from "../../utils/ApiUtil";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { mixpanelApiFailTrack } from "../../utils/MixpanelEvents";
import { Modal, Skeleton } from "@mui/material";
import { modalStyle } from "../../Common/CommonComponents";
import { killHlsStream } from "../../utils/HandlerFuncs";
import { RTSPBaseUrl } from "../../../..";

const RtspModal = ({
  openModal = false,
  closeModal = () => {},
  pollFrames = {},
  setPollFrames = () => {},
  masterId = "",
  rtsp = "",
  setProgressState = () => {},
  isExistingRepo = false,
}) => {
  const [hlsLink, setHlsLink] = useState("");
  const toast = useToast();
  const [pollRunning, setPollRunning] = useState({
    poll: false,
    status: "RUNNING",
  });
  const [pollCaptureStatus, setPollCaptureStatus] = useState(false);
  const [captureStates, setCaptureStates] = useState(0); // 0 none, 1 capture, 2 stop
  const handlRtspAdd = async () => {
    try {
      const requestData = JSON.stringify({
        rtsp: rtsp?.trim(),
        uuid: masterId,
      });
      const response = await axios.post(RTSPBaseUrl + "/create", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.status === 200) {
        let data = response?.data;
        let hlsUrl = RTSPBaseUrl + data?.replace(/^\./, "");
        toast({
          title: "Processing",
          description: "This can take upto a minute",
          status: "info",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setTimeout(() => setHlsLink(hlsUrl), 20 * 1000);
      }
    } catch (error) {
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      mixpanelApiFailTrack({
        errorMessage: error?.response?.data?.message ?? "",
        endPoint: RTSPBaseUrl,
        errorCode: error?.response?.status,
        type: "post",
        param: {},
        requestData: {
          uri: rtsp,
          alias: "liveRtsp",
        },
        isFormData: false,
      });
      console.log(error);
    }
  };

  const startCapture = async () => {
    try {
      const param = {
        masterObjectId: masterId,
      };
      const requestData = JSON.stringify({
        link: rtsp?.trim(),
        linkType: "RTSP",
        config: {
          targetFps: 1,
        },
      });
      const response = await callApi({
        type: "post",
        params: param,
        requestBody: requestData,
        toast: toast,
        endPoint: "selfserve/v2/project/v1/camerafeed/",
      });
      if (response?.status === 200) {
        setPollFrames((prev) => ({
          ...prev,
          jobIds: [
            ...prev["jobIds"],
            Object?.entries(response?.data?.videoDataset)[0][0],
          ],
        }));
        setPollCaptureStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startCaptureStatus = async (intervalId) => {
    try {
      const param = {
        masterObjectId: masterId,
        videoDatasetId: pollFrames?.jobIds?.join(","),
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/video/framing/status/",
        params: param,
      });
      if (response?.status === 200) {
        if (response?.data?.status === "RUNNING") {
          setCaptureStates(2);
          setPollCaptureStatus(false);
          clearInterval(intervalId);
          setPollRunning((prev) => ({
            ...prev,
            poll: true,
          }));
        }
      }
    } catch (error) {
      clearInterval(intervalId);
      console.log(error);
    }
  };

  const setCaptureStatus = async (intervalId) => {
    try {
      const param = {
        masterObjectId: masterId,
        videoDatasetId: pollFrames?.jobIds?.join(","),
        liveCameraFeedStatus: pollRunning?.status,
      };
      const response = await callApi({
        toast: toast,
        endPoint: "selfserve/v2/project/v1/camerafeed/status/",
        params: param,
        type: "post",
      });
      if (response?.status === 200) {
        if (
          pollRunning?.status === "TERMINATE" ||
          response?.data?.status === "COMPLETED"
        ) {
          setPollFrames((prev) => ({
            ...prev,
            id: masterId,
          }));
          setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
          closeModal();
        }
      }
    } catch (error) {
      console.log(error);
      if (intervalId) clearInterval(intervalId);
    }
  };

  const cancelRtsp = async () => {
    setPollFrames((prev) => ({
      ...prev,
      id: "",
      jobIds: [],
    }));
    if (!isExistingRepo) {
      try {
        const param = {
          masterObjectId: masterId,
        };
        const response = await callApi({
          toast: toast,
          type: "delete",
          endPoint: "selfserve/v2/project/v1/dataRepository/",
          params: param,
        });

        if (response?.status === 200) closeModal();
      } catch (error) {
        console.log(error);
      }
    } else closeModal();
  };

  useEffect(() => {
    if (rtsp !== "") {
      handlRtspAdd();
    }
    return () =>
      killHlsStream({
        uuid: masterId,
        rtsp: rtsp,
        toast: toast,
      });
  }, [rtsp]);

  useEffect(() => {
    if (pollRunning?.poll) {
      if (pollRunning?.status === "RUNNING") {
        let intervalId = setInterval(() => {
          setCaptureStatus(intervalId);
        }, 30 * 1000);
        return () => clearInterval(intervalId);
      } else setCaptureStatus();
    }
  }, [pollRunning]);

  useEffect(() => {
    if (pollCaptureStatus) {
      let intervalId = setInterval(() => {
        startCaptureStatus(intervalId);
      }, [5 * 1000]);
      return () => clearInterval(intervalId);
    }
  }, [pollCaptureStatus]);

  return (
    <Modal
      open={openModal}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div
        style={{ ...modalStyle, maxWidth: "500px" }}
        className="flex flex-col gap-3"
      >
        <div
          style={{
            borderBottom: "1px solid #EBE2F0",
          }}
          className="flex justify-between items-center"
        >
          <p className="text-[#605D64] text-sm font-medium">Rtsp feed</p>
          <ClearIcon
            onClick={cancelRtsp}
            className="hover:scale-105 cursor-pointer"
          />
        </div>
        <div className="flex flex-col gap-2 w-full rounded-lg">
          {hlsLink !== "" ? (
            <ReactPlayer
              url={hlsLink}
              width={"100%"}
              height={"40vh"}
              playing={true}
              muted={true}
              playsinline={true}
              controls={true}
              config={{
                file: {
                  attributes: { disablePictureInPicture: true },
                },
              }}
              onPlay={() => {
                if (captureStates === 0) setCaptureStates(1);
              }}
            />
          ) : (
            <>
              <Skeleton width={"100%"} variant="rectangular" height={"38vh"} />
              <Skeleton width={"140px"} variant="rectangular" height={"40px"} />
            </>
          )}
          {captureStates !== 0 && (
            <div className="flex gap-2 items-center">
              <PrimaryButton
                text={
                  captureStates === 1 ? "Start capturing" : "Stop capturing"
                }
                width={"fit-content"}
                onClick={() => {
                  if (captureStates === 1) {
                    startCapture();
                  } else {
                    setPollRunning((prev) => ({
                      ...prev,
                      status: "TERMINATE",
                    }));
                    setCaptureStates(0);
                  }
                }}
                disable={pollCaptureStatus}
              />
              {captureStates === 2 && (
                <p className="text-sm animate-pulse">Capturing...</p>
              )}
              {captureStates === 1 && pollCaptureStatus && (
                <p className="text-sm animate-pulse">Please wait...</p>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RtspModal;
