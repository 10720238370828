import RunDetails from "./HomeComponents/RunDetails";
import LabelStats from "./HomeComponents/LabelStats";
import LabelCard from "./HomeComponents/LabelCard";
import Paginator from "../../../../util/VisionUtils/Paginator";
import { useMemo, useRef, useState } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Chip } from "../../Common/CommonComponents";
import { useWindowSize } from "@uidotdev/usehooks";

const MaxLabelFinder = (data) => {
  let label;
  let max = 0;
  Object.keys(data).forEach((item) => {
    let len = data[item]?.img?.length;
    if (len >= max) {
      max = len;
      label = item;
    }
  });
  return label;
};

const OutputSummary = ({ modelInfo, predictionData, stateChangeTs }) => {
  const [displayData, setDisplayData] = useState([]);
  const rightRef = useRef();
  const leftRef = useRef();
  const [disableToggle, setDisableToggle] = useState({
    left: true,
    right: true,
  });
  const size = useWindowSize();
  const formattedData = useMemo(
    () =>
      Object.entries(predictionData || {}).map((entries) => ({
        label: entries[0],
        [entries[0]]: entries[1],
      })),
    [predictionData]
  );
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col md:flex-row gap-4 items-stretch w-full">
        <RunDetails modelInfo={modelInfo} stateChangeTs={stateChangeTs} />
        <LabelStats predictionData={predictionData} />
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-[#605D64] text-base font-medium">Label cards</p>
        <div className="relative grid grid-cols-1 min-[800px]:grid-cols-2 min-[1150px]:grid-cols-3 min-[1475px]:grid-cols-4 3xl:grid-cols-5 min-[2300px]:grid-cols-6 gap-4">
          {displayData?.map((item) => {
            let label = MaxLabelFinder(predictionData);
            return (
              <LabelCard
                key={item?.idx}
                predictionData={item}
                label={item?.label}
                modelInfo={modelInfo}
                starred={label === item?.label}
                index={item?.idx}
              />
            );
          })}
          {!disableToggle?.right && size?.width >= 640 && (
            <Chip
              top={"calc(50% - 20px)"}
              handleClick={() => rightRef?.current?.click()}
              width="40px"
              height="40px"
              right={-18}
              padding="1px"
              rounded="999px"
              display={!disableToggle?.right ? "block" : "hidden"}
              customElem={
                <ArrowCircleRightOutlinedIcon
                  sx={{ color: "#48464C", width: "100%", height: "100%" }}
                />
              }
            />
          )}
          {!disableToggle?.left && size?.width >= 640 && (
            <Chip
              top={"calc(50% - 20px)"}
              width="40px"
              height="40px"
              handleClick={() => leftRef?.current?.click()}
              display={!disableToggle?.left ? "block" : "hidden"}
              left={-18}
              padding="1px"
              rounded="999px"
              customElem={
                <ArrowCircleLeftOutlinedIcon
                  sx={{ color: "#48464C", width: "100%", height: "100%" }}
                />
              }
            />
          )}
        </div>
        <Paginator
          limit={8}
          setDisplayData={setDisplayData}
          data={formattedData}
          leftRef={leftRef}
          rightRef={rightRef}
          setDisableToggle={setDisableToggle}
          additionalStyles={{
            style: {
              boxShadow:
                "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
              backgroundColor: "#FFF",
              border: "1px solid #CAC5CD",
              borderRadius: "8px",
              alignSelf: "flex-end",
            },
          }}
        />
      </div>
    </div>
  );
};

export default OutputSummary;
