import { IconButton, Modal } from "@mui/material";
import {
  CommonProgressBar,
  CustomRadio,
  FieldLabel,
  modalStyle,
  UploadDragDrop,
} from "../../Common/CommonComponents";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import {
  annotationIconMap,
  uploadAnnotationFormats,
} from "../../utils/HandlerFuncs";
import CancelIcon from "@mui/icons-material/Cancel";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import { callApi } from "../../utils/ApiUtil";
import { useToast } from "@chakra-ui/react";

const UploadAnnotation = ({
  openModal = false,
  closeModal = () => {},
  repoId = "",
  getLabels = () => {},
}) => {
  const [annotationType, setAnnotationType] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [annotationId, setAnnotationId] = useState("");
  const intervalRef = useRef();
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const uploadRef = useRef();
  const toast = useToast();

  const uploadAnnotation = async () => {
    try {
      setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
      const param = {
        dataRepoId: repoId,
        annotationType: annotationType?.toLowerCase(),
      };
      const requestData = new FormData();
      selectedFiles?.forEach((file) =>
        requestData?.append("annotationFiles", file)
      );
      const response = await callApi({
        type: "post",
        endPoint: "selfserve/v2/project/v1/upload_annotations/",
        params: param,
        requestBody: requestData,
        isFormData: true,
        toast: toast,
      });
      if (response?.status === 200) {
        setAnnotationId(response?.data);
      } else setProgressState((prev) => ({ ...prev, state: 0 }));
    } catch (error) {
      console.log(error);
    }
  };

  const pollAnnotation = async () => {
    try {
      const param = {
        annotationId: annotationId,
        dataRepoId: repoId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/poll_annotations/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        if (response?.data === "COMPLETED") {
          toast({
            title: "Annotation saved",
            status: "success",
            duration: 3000,
            position: "top-right",
          });
          clearInterval(intervalRef?.current);
          setProgressState((prev) => ({ ...prev, state: 2 }));
          setTimeout(() => {
            closeModal();
            getLabels();
          }, 2000);
        }
      } else {
        clearInterval(intervalRef?.current);
        setProgressState((prev) => ({ ...prev, state: 0 }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (annotationId !== "") {
      intervalRef.current = setInterval(() => pollAnnotation(), 10 * 1000);

      return () => clearInterval(intervalRef?.current);
    }
  }, [annotationId]);

  return (
    <Modal
      open={openModal}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div
        style={{ ...modalStyle, maxWidth: "400px" }}
        className="flex flex-col gap-4 items-end"
      >
        <div
          style={{
            borderBottom: "1px solid #EBE2F0",
          }}
          className="flex justify-between items-center w-full"
        >
          <p className="text-[#605D64] text-sm font-medium">
            Upload Annotations
          </p>
          <CloseIcon
            className="cursor-pointer hover:scale-105"
            onClick={closeModal}
            sx={{ color: "#605D64" }}
          />
        </div>
        <div className="flex flex-col gap-3 w-full">
          <FieldLabel text="Select annotation type" />
          <CustomRadio
            value={annotationType}
            setValue={setAnnotationType}
            options={Object.keys(annotationIconMap)}
          />
        </div>
        {selectedFiles?.length > 0 ? (
          <div className="self-start bg-[#F5F5F5] border border-[#EBEBEB] py-1 px-2 flex gap-2 items-center w-fit rounded">
            <p className="text-[#3E3C42] text-base font-medium truncate">
              Remove upload
            </p>
            <IconButton
              onClick={() => {
                setSelectedFiles([]);
                uploadRef.current = null;
              }}
              size="small"
              color="error"
            >
              <CancelIcon />
            </IconButton>
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <FieldLabel
              text="Accepted formats: COCO JSON, Pascal VOC XML, YOLO PyTorch TXT & Classification CSV"
              isRequired={false}
            />
            <UploadDragDrop
              forwardRef={uploadRef}
              text="Upload Annotation"
              handleChange={(e) =>
                setSelectedFiles(Array.from(e?.target?.files))
              }
              allowMultiple={true}
              accept={uploadAnnotationFormats(annotationType)}
            />
          </div>
        )}
        <PrimaryButton
          text={"Confirm"}
          onClick={() => uploadAnnotation()}
          width={"fit-content"}
          disable={selectedFiles?.length === 0 || !annotationType}
        />
        {progressState?.open && (
          <CommonProgressBar
            openModal={progressState?.open}
            closeModal={() =>
              setProgressState((prev) => ({
                ...prev,
                open: false,
                state: null,
              }))
            }
            state={progressState?.state}
          />
        )}
      </div>
    </Modal>
  );
};

export default UploadAnnotation;
