import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import "./Sidebar.css";
import TwoFactorAuthModal from "../AuthenticationModals/TwoFactorAuthModal";
import NavContext from "../NavContext";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DvrIcon from "@mui/icons-material/Dvr";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import FolderIcon from "@mui/icons-material/Folder";
import GridViewIcon from "@mui/icons-material/GridView";
import WindowIcon from "@mui/icons-material/Window";
import { bypassRipikAdmin } from "../..";
import ProfileNav from "../Main/ProfileNav";

const Sidebar = ({ userRole, homeRef }) => {
  const { setTwoFactorAuthInfo } = useContext(NavContext);
  const location = useLocation();
  const size = useWindowSize();
  const navigate = useNavigate();
  const [isAuthentModalOpen, setAuthenticationModal] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const checkAuthExpiration = () => {
      const isAdminRoute = location.pathname.includes("/admin");
      const isSuperAdminRoute = location.pathname.includes("/superadmin");

      const authInfo = JSON.parse(localStorage.getItem("authInfo")) || {};
      const is2FAuthenticatedAdmin = authInfo.isAdminAuthenticated;

      const is2FAuthenticatedSuperAdmin = authInfo.isSuperAdminAuthenticated;

      // Check for authentication expiration in local storage
      const expirationTimeAdmin = authInfo.adminAuthExpiration;
      const expirationTimeSuperAdmin = authInfo.superAdminAuthExpiration;

      // if (isAdminRoute && !is2FAuthenticatedAdmin) {
      //   setAuthenticationModal(true);
      //   setTwoFactorAuthInfo((prev) => ({
      //     ...prev,
      //     is2FAuthenticatedAdmin: false,
      //   }));
      // }

      if (
        isSuperAdminRoute &&
        !is2FAuthenticatedSuperAdmin &&
        !bypassRipikAdmin
      ) {
        setAuthenticationModal(true);
        setTwoFactorAuthInfo((prev) => ({
          ...prev,
          is2FAuthenticatedSuperAdmin: false,
        }));
      }

      // if (expirationTimeAdmin && isAdminRoute) {
      //   const isExpiredAdmin = Date.now() > parseInt(expirationTimeAdmin, 10);

      //   if (isExpiredAdmin) {
      //     setIsExpired(true);
      //     setAuthenticationModal(true);
      //     authInfo.isAdminAuthenticated = false;
      //     authInfo.adminAuthExpiration = null;
      //     localStorage.setItem("authInfo", JSON.stringify(authInfo));
      //   }
      // }

      if (expirationTimeSuperAdmin && isSuperAdminRoute && !bypassRipikAdmin) {
        const isExpiredSuperAdmin =
          Date.now() > parseInt(expirationTimeSuperAdmin, 10);

        if (isExpiredSuperAdmin) {
          setIsExpired(true);
          setAuthenticationModal(true);
          authInfo.isSuperAdminAuthenticated = false;
          authInfo.superAdminAuthExpiration = null;
          localStorage.setItem("authInfo", JSON.stringify(authInfo));
        }
      }
    };

    // Call the function initially
    checkAuthExpiration();

    // Set up an interval to check every 5 minutes
    const intervalId = setInterval(checkAuthExpiration, 5 * 10 * 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [location.pathname]);

  const tabInfo = [
    {
      name: "Datasets",
      img: <FolderOpenIcon />,
      selectedImg: <FolderIcon />,
      location: "/data",
      navigateTo: "/SandboxV2/data",
      renderCondition: true,
    },
    {
      name: "Models",
      img: <GridViewIcon />,
      selectedImg: <WindowIcon />,
      location: "/model",
      navigateTo: "/SandboxV2/model",
      renderCondition: true,
    },
    {
      name: "Apps",
      img: <DvrIcon />,
      selectedImg: <DesktopWindowsIcon />,
      location: "/app",
      navigateTo: "/SandboxV2/app",
      renderCondition: true,
    },
    {
      name: "Admin",
      img: "/admin.svg",
      selectedImg: "/admin.svg",
      location: "/admin",
      navigateTo: "/admin/usermanagement",
      renderCondition: userRole === "ADMIN" || userRole === "SUPERADMIN",
    },
    {
      name: "Ripik Admin",
      img: "/supericon2.png",
      selectedImg: "/supericon2.png",
      location: "/superadmin",
      navigateTo: "/superadmin",
      renderCondition: userRole === "SUPERADMIN",
    },
  ];

  return (
    <>
      {size.width >= 768 ? (
        <>
          <p className="text-xl font-bold text-white absolute top-4 left-24">
            Ripik AI Studio
          </p>
          <div className="absolute top-3 right-4">
            <ProfileNav />
          </div>
          <div className="absolute top-14 bottom-5 left-[10px] flex flex-col gap-4 justify-between items-center">
            <div className="flex flex-col gap-4 items-center">
              {tabInfo?.map((item, idx) => {
                let isIcon = typeof item?.img === "string";
                let isSelected = (
                  location?.pathname + location?.search
                )?.includes(item?.location);
                return item?.renderCondition ? (
                  <div
                    className="flex flex-col gap-1 text-white text-sm font-medium p-[6px] items-center rounded-lg"
                    style={{
                      backgroundColor: isSelected ? "#6898DF" : "transparent",
                      cursor: isSelected ? "default" : "pointer",
                    }}
                    key={idx}
                    onClick={() =>
                      navigate(item?.navigateTo, {
                        state: {
                          prevPath: location?.pathname + location?.search,
                        },
                      })
                    }
                  >
                    <div className="hover:rotate-12">
                      {isIcon ? (
                        <img
                          src={isSelected ? item?.selectedImg : item?.img}
                          alt={item?.name}
                          className="h-6 w-6"
                        />
                      ) : isSelected ? (
                        item?.selectedImg
                      ) : (
                        item?.img
                      )}
                    </div>
                    <p className="w-16 text-center">{item?.name}</p>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="text-xl font-bold text-white fixed top-4 left-4">
            Ripik AI Studio
          </p>
          <div className="absolute top-3 right-4">
            <ProfileNav />
          </div>
          <div className="absolute bottom-0 right-0 left-0 flex items-center justify-between gap-4 overflow-x-auto px-4 py-[6px]">
            {tabInfo?.map((item, idx) => {
              let isIcon = typeof item?.img === "string";
              let isSelected = (
                location?.pathname + location?.search
              )?.includes(item?.location);
              return item?.renderCondition ? (
                <div
                  className="flex flex-col gap-1 text-white text-sm font-medium p-[6px] items-center rounded-lg whitespace-nowrap"
                  style={{
                    backgroundColor: isSelected ? "#6898DF" : "transparent",
                    cursor: isSelected ? "default" : "pointer",
                  }}
                  key={idx}
                  onClick={() =>
                    navigate(item?.navigateTo, {
                      state: {
                        prevPath: location?.pathname + location?.search,
                      },
                    })
                  }
                >
                  <div className="hover:rotate-12">
                    {isIcon ? (
                      <img
                        src={isSelected ? item?.selectedImg : item?.img}
                        alt={item?.name}
                        className="h-6 w-6"
                      />
                    ) : isSelected ? (
                      item?.selectedImg
                    ) : (
                      item?.img
                    )}
                  </div>
                  <p className="w-16 text-center truncate" title={item?.name}>
                    {item?.name}
                  </p>
                </div>
              ) : null;
            })}
          </div>
        </>
      )}
      {isAuthentModalOpen && (
        <TwoFactorAuthModal
          isOpen={isAuthentModalOpen}
          onOpen={() => setAuthenticationModal(true)}
          onClose={() => setAuthenticationModal(false)}
          isExpired={isExpired}
          setIsExpired={setIsExpired}
          size="lg"
        />
      )}
    </>
  );
};

export default Sidebar;
