import ReactApexChart from "react-apexcharts";
import { useState, useEffect } from "react";

const colors = [
  "#ffc107",
  "#5193f6",
  "#ef6f12",
  "#1c56ac",
  "#e91e63",
  "#00bcd4",
  "#8bc34a",
  "#9c27b0",
  "#673ab7",
  "#ff9800",
  "#4caf50",
  "#795548",
  "#ff5722",
  "#03a9f4",
  "#cddc39",
  "#009688",
  "#e65100",
  "#ffeb3b",
  "#c2185b",
  "#3f51b5",
  "#2196f3",
  "#004d40",
  "#f44336",
  "#8e24aa",
  "#ff6f00",
  "#689f38",
  "#bcaaa4",
  "#757575",
  "#607d8b",
  "#3949ab",
  "#ff4081",
  "#7c4dff",
  "#76ff03",
  "#8c9eff",
  "#ffab00",
  "#ff5252",
  "#aa00ff",
  "#00e676",
  "#d500f9",
  "#00bfa5",
  "#ffd600",
  "#e040fb",
  "#ff3d00",
  "#6200ea",
  "#304ffe",
  "#64ffda",
  "#00c853",
  "#ff9100",
  "#33691e",
  "#ff1744",
  "#dd2c00",
  "#1a237e",
  "#00acc1",
  "#5d4037",
  "#a1887f",
  "#283593",
  "#b388ff",
  "#1de9b6",
  "#dce775",
  "#fdd835",
  "#00796b",
  "#d32f2f",
  "#c51162",
  "#1b5e20",
  "#d50000",
  "#263238",
  "#bf360c",
  "#01579b",
  "#a5d6a7",
  "#ff80ab",
  "#ffab91",
  "#8eacbb",
  "#ffd180",
  "#69f0ae",
  "#40c4ff",
  "#ff9e80",
  "#c6ff00",
  "#f48fb1",
  "#cfd8dc",
  "#a7ffeb",
  "#ff7043",
  "#d1c4e9",
  "#ffca28",
  "#ffccbc",
  "#b0bec5",
  "#d7ccc8",
  "#d500f9",
  "#004d40",
  "#b2ff59",
  "#ff4081",
  "#ea80fc",
  "#eeff41",
  "#00e5ff",
  "#00c853",
];

const LabelPie = ({ predictionData }) => {
  const [series, setSeries] = useState([]);
  const [label, setLabel] = useState([]);

  useEffect(() => {
    if (predictionData && Object.keys(predictionData).length > 0) {
      setSeries((prev) => {
        let data = [];
        let names = [];
        Object.keys(predictionData).forEach((item) => {
          data.push(predictionData[item]?.img?.length);
          names.push(item);
        });
        setLabel((prev) => {
          return names;
        });
        return data;
      });
    }
  }, [predictionData]);

  const options = {
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
        },
      },
    },
    chart: {
      type: "donut",
    },
    colors: colors,
    labels: label,
    legend: {
      show: false,
      position: "right",
      fontSize: "12px",
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "16px",
        fontWeight: 600,
      },
      background: {
        enabled: true,
        foreColor: "#3E3C42",
        padding: 8,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: "#EBEBEB",
        opacity: 1,
        dropShadow: {
          enabled: true,
          top: 4,
          left: 0,
          blur: 8,
          color: "#000000",
          opacity: 0.06,
        },
      },
      dropShadow: {
        enabled: false,
      },
    },
  };
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height={"180px"}
      width={"180px"}
    />
  );
};

export default LabelPie;
