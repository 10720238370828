import { Modal } from "@mui/material";
import { modalStyle } from "../../Common/CommonComponents";
import { FlipCard } from "../../Model/OuputImages";
import { useWindowSize } from "@uidotdev/usehooks";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const DetailModal = ({
  openModal = false,
  closeModal = () => {},
  data = {},
}) => {
  const size = useWindowSize();
  return (
    <Modal
      open={openModal}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div
        style={{ ...modalStyle, maxWidth: "90vw", padding: 0 }}
        className="flex flex-col gap-0"
      >
        <div className="pt-2 pr-2 pl-6 flex justify-end items-center bg-[#F5F5F5] rounded-tr-xl rounded-tl-xl">
          <FullscreenExitIcon
            className="cursor-pointer hover:scale-105"
            onClick={closeModal}
          />
        </div>
        <div className="flex w-full relative justify-center items-center rounded-b-lg bg-black">
          <FlipCard
            data={data}
            width={size?.width >= 1024 ? "80vh" : "80vw"}
            height={size?.width >= 1024 ? "80vh" : "80vw"}
            textSize={size?.width >= 1024 ? "18px" : "14px"}
            hasExpand={false}
            bottomBarHeight="9%"
          />
          <p
            className="absolute top-2 left-2 text-white p-1 rounded-lg bg-black bg-opacity-50"
            style={{
              fontSize: size?.width >= 1024 ? "18px" : "14px",
            }}
          >
            {new Date(data?.createdAt * 1000)?.toLocaleString("en-GB")}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default DetailModal;
