import { useEffect, useRef, useState } from "react";
import TonalButton from "../../../util/Buttons/TonalButton";
import Paginator from "../../../util/VisionUtils/Paginator";
import {
  CardContainer,
  Chip,
  ImageContainer,
  NavigateBack,
} from "../Common/CommonComponents";
import AddIcon from "@mui/icons-material/Add";
import ExpandImage from "./Components/ExpandImage";
import { useLocation, useNavigate } from "react-router-dom";
import { callApi } from "../utils/ApiUtil";
import { useToast } from "@chakra-ui/react";
import { mixpanelDeleteTrack } from "../utils/MixpanelEvents";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Skeleton } from "@mui/material";

const DataLabelView = ({ searchParams, setSearchParams }) => {
  const repoId = searchParams?.get("repoId");
  const repoName = searchParams?.get("repoName");
  const labelName = searchParams?.get("labelName");
  const annotationType = searchParams?.get("annotationType");
  const dataType = searchParams?.get("dataType");
  const [expand, setExpand] = useState({
    open: false,
    img: "",
  });
  const rightRef = useRef();
  const leftRef = useRef();
  const [loading, setLoading] = useState(true);
  const [disableToggle, setDisableToggle] = useState({
    left: true,
    right: true,
  });
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [labelData, setLabelData] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  const getLabelData = async () => {
    try {
      const param = {
        masterObjectId: repoId,
        label: labelName,
        annotationType: annotationType?.toUpperCase(),
      };
      const response = await callApi({
        type: "get",
        toast: toast,
        endPoint: "selfserve/v2/project/v1/labelData/",
        params: param,
      });
      if (response?.status === 200) {
        let data = Object.entries(response?.data)?.map((item) => {
          return {
            id: item[0],
            img: item[1],
          };
        });
        setLabelData(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAnnotatedImage = async (id) => {
    try {
      const param = {
        masterObjectId: repoId,
        label: labelName,
        annotationType: annotationType,
        fileId: id,
      };
      const response = await callApi({
        type: "delete",
        endPoint: "selfserve/v2/project/v1/annotation/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "Deleted annotated image",
          status: "success",
          duration: 2000,
          position: "top-right",
        });
        getLabelData();
        mixpanelDeleteTrack({
          name: repoName + "-" + labelName + "-" + id,
          type: "annotation",
          id: repoId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLabelData();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 sm:flex-row justify-between items-start sm:items-center">
        <NavigateBack
          targetRoute={-1}
          textArray={["AI Studio", repoName, labelName]}
        />
        <TonalButton
          text={"Add more to label"}
          Icon={<AddIcon />}
          width={"fit-content"}
          onClick={() => {
            navigate(
              `/SandboxV2/draft/label?labelName=${labelName}&annotationType=${annotationType}&repoName=${repoName}&repoId=${repoId}&dataType=${dataType}`,
              {
                state: {
                  prevPath: location?.pathname + location?.search,
                },
              }
            );
          }}
        />
      </div>
      <div className="flex flex-col gap-6 rounded-xl">
        <CardContainer
          showLeft={!disableToggle?.left}
          showRight={!disableToggle?.right}
          leftFunc={() => leftRef?.current?.click()}
          rightFunc={() => rightRef?.current?.click()}
        >
          {loading
            ? [...Array(8)]?.map((val, idx) => (
                <Skeleton
                  variant="rectangular"
                  height={"250px"}
                  width={"100%"}
                  key={idx}
                />
              ))
            : displayData?.map((item, idx) => {
                return (
                  <ImageContainer key={idx}>
                    <img
                      key={idx}
                      className="h-full w-full rounded"
                      src={item?.img}
                      alt="image"
                    />
                    <Chip
                      top={"6px"}
                      right={"46px"}
                      padding="4px"
                      customElem={
                        <DeleteOutlineRoundedIcon
                          sx={{ color: "#48464C", width: "100%", height: "100%" }}
                        />
                      }
                      handleClick={() => deleteAnnotatedImage(item?.id)}
                    />
                    <Chip
                      top={"6px"}
                      right={"6px"}
                      padding="4px"
                      customElem={
                        <FullscreenIcon
                          sx={{ color: "#48464C", width: "100%", height: "100%" }}
                        />
                      }
                      handleClick={() =>
                        setExpand((prev) => ({
                          ...prev,
                          open: true,
                          img: item?.img,
                        }))
                      }
                    />
                  </ImageContainer>
                );
              })}
        </CardContainer>
        <Paginator
          setDisplayData={setDisplayData}
          data={labelData}
          leftRef={leftRef}
          rightRef={rightRef}
          setDisableToggle={setDisableToggle}
          limit={8}
          additionalStyles={{
            style: {
              boxShadow:
                "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
              backgroundColor: "#FFF",
              border: "1px solid #CAC5CD",
              borderRadius: "8px",
              alignSelf: "flex-end",
            },
          }}
        />
      </div>
      {expand?.open && (
        <ExpandImage
          openModal={expand?.open}
          closeModal={() =>
            setExpand((prev) => ({
              ...prev,
              open: false,
              img: "",
            }))
          }
          img={expand?.img}
        />
      )}
    </div>
  );
};

export default DataLabelView;
