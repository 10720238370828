import { CustomStyledDataGrid } from "../../../../util/MaterialDataGrid/CustomStyledDatagrid";
import {
  formatColumns,
  modelHistoryColumnDefinition,
  modelHistoryFlexMap,
} from "../../Common/CommonColDefine";
import { DataGridContainer } from "../../Common/CommonComponents";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import {
  RadioButtonChecked,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { modelStatusColors } from "../../utils/HandlerFuncs";

const ModelRunHistory = ({
  selectedModelRun = "",
  setSelectedModelRun = () => {},
  setIsReady = () => {},
  setPredictionData = () => {},
  history = [],
  isReady = false,
}) => {
  const selectionColumn = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => null,
      renderCell: ({ value, api, id }) => {
        let isDisabled =
          ["FAILED", "TERMINATED"]?.includes(api?.getCellValue(id, "status")) ||
          !isReady?.processed;
        if (value) {
          return (
            <IconButton
              title="Selected run"
              color="primary"
              disabled={isDisabled}
            >
              <RadioButtonChecked fontSize={"medium"} />
            </IconButton>
          );
        } else {
          return (
            <IconButton
              title="Select this run"
              color="primary"
              disabled={isDisabled}
              onClick={() => {
                if (selectedModelRun !== id && isReady?.processed) {
                  if (id === history?.[0]?.modelRunId)
                    setIsReady((prev) => ({
                      ...prev,
                      processed: false,
                      success: false,
                    }));
                  setPredictionData({});
                  setSelectedModelRun(id);
                }
              }}
            >
              <RadioButtonUncheckedRounded fontSize={"medium"} />
            </IconButton>
          );
        }
      },
    },
  ];

  return (
    <DataGridContainer height="65vh" showAdd={false}>
      <CustomStyledDataGrid
        rows={history}
        columns={formatColumns({
          cols: [...selectionColumn, ...modelHistoryColumnDefinition],
          color: "blue",
          flexMap: modelHistoryFlexMap,
        })}
        getRowId={(row) => row?.modelRunId}
        disableRowSelectionOnClick
        disableMultipleRowSelection
        checkboxSelection
        rowSelectionModel={[selectedModelRun]}
        autosizeOptions={{
          columns: modelHistoryColumnDefinition?.map((item) => item.field),
          includeOutliers: true,
          includeHeaders: true,
        }}
        getCellClassName={(params) => {
          if (params.field === "status") {
            return modelStatusColors({ status: params?.value })?.text;
          }
        }}
        pagination={true}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 15, 25]}
      />
    </DataGridContainer>
  );
};

export default ModelRunHistory;
