import { useEffect, useState } from "react";
import Paginator from "../../../../util/VisionUtils/Paginator";
import { SelectAll } from "../../utils/HandlerFuncs";
import {
  CardContainer,
  CustomCheckbox,
  DivTabs,
  ImageContainer,
} from "../../Common/CommonComponents";

const ImageSelector = ({
  selectedImages = [],
  setSelectedImages = () => {},
  annotatedImages = [],
  setAnnotatedImages = () => {},
  images = [],
  setImages = () => {},
  page = "",
  setPage = () => {},
}) => {
  const [displayData, setDisplayData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const handleChange = (x) => {
    setSelectedImages((prev) => {
      let newData = JSON.parse(JSON.stringify(prev));
      let idx = newData.findIndex((item) => item.id == x.id);
      if (idx == -1) {
        newData.push(x);
      } else {
        newData.splice(idx, 1);
      }
      console.log(newData, "hello");
      return newData;
    });
  };

  const handleRemove = (x) => {
    setAnnotatedImages((prev) => {
      let newData = [...prev];
      let idx = newData.findIndex((item) => item.id == x.id);
      newData.splice(idx, 1);
      return newData;
    });
    setImages((prev) => {
      let newData = [...prev];
      delete x.label;
      newData.push(x);
      return newData;
    });
  };

  const checkSelectAll = () => {
    let checked = true;
    paginatedData.forEach((item) => {
      if (!selectedImages.some((val) => val.id == item.id)) checked = false;
    });
    return checked;
  };

  const handleClick = (x) => {
    if (!x.hasOwnProperty("label")) handleChange(x);
  };

  useEffect(() => {
    if (page == "Unannotated") setDisplayData(images);
    else setDisplayData(annotatedImages);
  }, [images, annotatedImages, page]);

  useEffect(() => {
    if (displayData.length == 0) setPaginatedData([]);
  }, [displayData]);

  useEffect(() => {
    setCheckedAll(checkSelectAll());
  }, [paginatedData, selectedImages]);

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-between gap-2 w-full">
        <DivTabs
          options={["Unannotated", "Annotated"]}
          value={page}
          setValue={setPage}
          responsiveClasses="w-fit"
        />
        <Paginator
          data={displayData}
          limit={8}
          setDisplayData={setPaginatedData}
          additionalStyles={{
            style: {
              boxShadow:
                "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
              backgroundColor: "#FFF",
              border: "1px solid #CAC5CD",
              borderRadius: "8px",
            },
          }}
        />
      </div>

      <div
        className={`flex w-full ${
          page == "Unannotated" ? "justify-between" : "justify-end"
        } items-center`}
      >
        {page == "Unannotated" && (
          <CustomCheckbox
            handleChange={(e) =>
              SelectAll({
                e: e,
                displayData: displayData,
                paginatedData: paginatedData,
                setSelected: setSelectedImages,
                pageSize: 8,
              })
            }
            text="Select all"
            isChecked={checkedAll}
          />
        )}
      </div>
      <CardContainer>
        {paginatedData.map((item, idx) => {
          return (
            <ImageContainer key={idx}>
              <img
                src={item.img}
                alt="image"
                className={`w-full h-full rounded-lg ${
                  item.hasOwnProperty("label")
                    ? "cursor-default"
                    : "cursor-pointer"
                }`}
                onClick={() => handleClick(item)}
              />
              {!item.hasOwnProperty("label") && (
                <CustomCheckbox
                  isChecked={selectedImages.some(
                    (selectedItem) => selectedItem.id === item.id
                  )}
                  handleChange={() => handleChange(item)}
                  extraProps={{
                    position: "absolute",
                    top: "8px",
                    left: "8px",
                    shadow: "dark-lg",
                  }}
                />
              )}
              {item.hasOwnProperty("label") && (
                <>
                  <p className="flex items-center gap-1 p-1 rounded-full bg-black absolute top-2 right-2 text-white font-medium text-sm bg-opacity-50">
                    {item.label}
                    <img
                      className="cursor-pointer w-5 h-5 hover:scale-110 text-white"
                      alt="delete"
                      src="/selfServiceIcons/cross.svg"
                      onClick={() => handleRemove(item)}
                    />
                  </p>
                </>
              )}
            </ImageContainer>
          );
        })}
      </CardContainer>
    </div>
  );
};

export default ImageSelector;
