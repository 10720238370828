import Lottie from "lottie-react";
import ModelBuildingLottie from "../../utils/ModelBuildingLottie.json";
import { Tooltip } from "@mui/material";
const FallBack = ({
  modelInfo,
  stateChangeTs,
  isLatest,
  predictionData,
  noModelRuns = false,
}) => {
  const status = Object?.entries(stateChangeTs || {})?.slice(-1)?.[0]?.[0];
  const noPredictions =
    status === "COMPLETED" && Object?.entries(predictionData)?.length === 0;
  const remarks =
    modelInfo?.remarks && isLatest
      ? modelInfo?.remarks
      : "Model wasn't able to make any predictions ";
  return (
    <div className="flex justify-center items-center w-full h-[65vh]">
      <div className="flex flex-col gap-3 items-center">
        <div className="w-[300px] h-[300px]">
          {["FAILED", "TERMINATED"].includes(status) || noPredictions ? (
            <img
              src="/selfServiceIcons/modelFail.svg"
              className="w-full h-full"
              alt="failed"
            />
          ) : noModelRuns ? (
            <img
              src="/selfServiceIcons/unusedModel.svg"
              className="w-full h-full"
              alt="failed"
            />
          ) : (
            <Lottie animationData={ModelBuildingLottie} loop={true} />
          )}
        </div>
        {!noModelRuns ? (
          <div className="flex flex-col gap-1">
            {status && (
              <div className="flex gap-2 items-center text-lg">
                <p className="text-[#938F96] w-[200px]">Status:</p>
                <p className="text-[#3E3C42] font-medium">{status}</p>
              </div>
            )}
            {modelInfo?.labelInfo?.every(
              (item) => "trainedOnCount" in item
            ) && (
              <div className="flex gap-2 items-center text-lg">
                <p className="text-[#938F96] w-[200px]">Annotated Samples:</p>
                <p className="text-[#3E3C42] font-medium">
                  {modelInfo?.labelInfo?.reduce(
                    (a, b) => a + b?.trainedOnCount,
                    0
                  )}
                </p>
              </div>
            )}
            {(noPredictions || ["FAILED", "TERMINATED"]?.includes(status)) && (
              <div className="flex gap-2 items-center text-lg w-[350px]">
                <Tooltip
                  title={modelInfo?.remarks ? modelInfo?.remarks : remarks}
                  arrow
                  placement="right"
                >
                  <p className="text-red-600 font-medium line-clamp-3 text-ellipsis">
                    {remarks}
                  </p>
                </Tooltip>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-0 items-center text-center">
            <p className="text-[#48464C] text-xl font-semibold">
              This model hasn't been infered yet
            </p>
            <p className="text-[#938F96] text-base font-medium">
              Once you infer this model you will see output here
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FallBack;
