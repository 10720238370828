import { Modal } from "@mui/material";
import { modalStyle } from "../../Common/CommonComponents";
import CloseIcon from "@mui/icons-material/Close";

const url = {
  classify:
    "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/Self+service.mp4",
  detect:
    "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/Data+detection.mp4",
  segment: "",
};

const HelpModal = ({ openModal, closeModal, type }) => {
  return (
    <Modal
      open={openModal}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div style={{ ...modalStyle, maxHeight: "90dvh", maxWidth: "90vw" }}>
        <div
          style={{
            borderBottom: "1px solid #EBE2F0",
          }}
          className="flex justify-between items-center"
        >
          <p className="text-[#605D64] text-sm font-medium">How to {type}</p>
          <CloseIcon
            className="cursor-pointer hover:scale-105"
            onClick={closeModal}
            sx={{ color: "#605D64" }}
          />
        </div>
        <div className="w-full h-full flex justify-center items-center bg-black rounded-lg">
          <video
            controls
            src={url[type]}
            alt="tutorial"
            className="max-w-full max-h-[80dvh] rounded-lg"
          />
        </div>
      </div>
    </Modal>
  );
};

export default HelpModal;
