import { useEffect, useState } from "react";
import {
  CardContainer,
  CustomSelect,
  ImageContainer,
} from "../../Common/CommonComponents";
import FloatingInput from "../../../../util/VisionUtils/FloatingInput";
import SecondaryButton from "../../../../util/Buttons/SecondaryButton";
import Paginator from "../../../../util/VisionUtils/Paginator";
import { callApi } from "../../utils/ApiUtil";
import { useToast } from "@chakra-ui/react";
import { FlipCard } from "../../Model/OuputImages";
import { CircularProgress } from "@mui/material";

const Feedlibrary = ({
  appId = "",
  cameraName = "",
  camId = "",
  cameraDetails = [],
}) => {
  const [selectedCam, setSelectedCam] = useState(camId);
  const [selectedPreset, setSelectedPreset] = useState("Today");
  const [loading, setLoading] = useState(false);
  const [imgData, setImgData] = useState([]);
  const toast = useToast();
  const [displayData, setDisplayData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date()?.setHours(0, 0, 0, 0)
  );
  const datePresets = [
    {
      text: "Today",
      func: () => {
        setSelectedDate(new Date()?.setHours(0, 0, 0, 0));
      },
    },
    {
      text: "Yesterday",
      func: () => {
        let currDate = new Date();
        currDate?.setDate(currDate?.getDate() - 1);
        setSelectedDate(currDate?.setHours(0, 0, 0, 0));
      },
    },
    {
      text: "Custom",
    },
  ];

  const getFeedImages = async (specificId = "") => {
    try {
      setLoading(true);
      const param = {
        camId: specificId !== "" ? specificId : selectedCam,
        date: new Date(selectedDate)?.getTime(),
        appId: appId,
      };
      const response = await callApi({
        type: "get",
        toast: toast,
        endPoint: "selfserve/v2/project/v1/app/feed/",
        params: param,
      });
      if (response?.status === 200) {
        setImgData(response?.data);
        setLoading(false);
      } else setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (appId !== "") {
      if (camId !== "") getFeedImages();
      else {
        let id = cameraDetails[0]?.camId;
        setSelectedCam(id);
        getFeedImages(id);
      }
    }
  }, []);

  return (
    <div className="flex flex-col gap-4 p-6 w-full bg-white items-end rounded-xl">
      <div className="flex gap-3 items-center overflow-x-auto w-full h-fit p-2">
        <CustomSelect
          value={selectedCam}
          setValue={setSelectedCam}
          options={[
            {
              camId: camId !== "" ? camId : "",
              cameraName: camId !== "" ? cameraName : "Select camera",
            },
            ...cameraDetails,
          ]}
          isPlain={false}
          valueKey="camId"
          displayKey="cameraName"
          displayEmpty={true}
          width="200px"
          disable={camId !== ""}
        />
        <CustomSelect
          value={selectedPreset}
          setValue={(value) => {
            let data = datePresets?.find((item) => item?.text === value);
            if ("func" in data) data?.func();
            setSelectedPreset(value);
          }}
          options={datePresets}
          isPlain={false}
          valueKey="text"
          displayKey="text"
          width="200px"
        />
        {selectedPreset === "Custom" && (
          <div className="min-w-[200px]">
            <FloatingInput
              text={"Select date"}
              type={"date"}
              setDateTime={setSelectedDate}
              value={selectedDate}
              max={new Date()}
            />
          </div>
        )}
        <SecondaryButton
          text={
            loading ? (
              <CircularProgress
                size={"80%"}
                disableShrink
                variant="indeterminate"
              />
            ) : (
              "Apply"
            )
          }
          width={"60px"}
          height={"38px"}
          onClick={() => getFeedImages()}
          disable={selectedCam === "" || !selectedDate || loading}
        />
      </div>
      <Paginator data={imgData} limit={8} setDisplayData={setDisplayData} />
      <CardContainer>
        {displayData?.map((item, idx) => {
          let prob = item?.result?.probs;
          let hasAnnotatedImage = "annotImage" in item;
          let data = hasAnnotatedImage
            ? {
                annotatedImg: item?.annotImage,
                img: item?.origImage,
                precision: prob?.length > 0 ? Math.max(...prob) : 0,
              }
            : {
                img: item?.origImage,
                precision: prob,
                tagName: item?.result?.cls,
              };
          return (
            <ImageContainer key={idx}>
              <FlipCard data={data} textSize="14px" />
              <p
                className="absolute top-2 left-2 text-white p-1 rounded-lg bg-black bg-opacity-50"
                style={{
                  fontSize: "14px",
                }}
              >
                {new Date(item?.createdAt * 1000)?.toLocaleTimeString("en-GB")}
              </p>
            </ImageContainer>
          );
        })}
      </CardContainer>
    </div>
  );
};

export default Feedlibrary;
