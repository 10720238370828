import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";


import AddClients from "./AddClients/AddClients";
import ManageProducts from "./Tabs/ManageProducts";
import CreateClient from "./AddClients/CreateClient";
import ViewTabs from "./Tabs/ViewTabs";
import UpdateTabs from "./Tabs/UpdateTabs";
import AddNewProductCategory from "./Products/AddNewProductCategory";
import AddNewProduct from "./Products/AddNewProduct";
import ExpertsTab from "./Experts/ExpertsTab";
import ViewProductGroup from "./Products/ViewProductGroup";
import UpdateProductGroup from "./Products/UpdateProductGroup";
import ViewProduct from "./Products/ViewProduct";
import UpdateProduct from "./Products/UpdateProduct";



import NavContext from "../NavContext";

const SuperAdminDashboard = () => {
  const { twoFactorAuthoInfo } = useContext(NavContext);
  const [showClientTab, setShowClientTab] = useState("addclient");
  const [showProductsTab, setShowProductTab] = useState("products");
  const [showExpertsTab, setShowExpertsTab] = useState("experts");

  const [view, setView] = useState("products");
  const [format, setFormat] = useState("card");
  const [clientView, setClientView] = useState("card");

  const [selectedClient, setSelectedClient] = useState({});
  const [selectedProductGroup, setSelectedProductGroup] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = searchParams.get("tabIndex")
    ? parseInt(searchParams.get("tabIndex"), 10)
    : 0;

  const clientsTabMapping = {
    createclient: <CreateClient setShowClientTab={setShowClientTab} />,
    addclient: (
      <AddClients
        setShowClientTab={setShowClientTab}
        setSelectedClient={setSelectedClient}
        clientView={clientView}
        setClientView={setClientView}
      />
    ),
    viewclient: (
      <ViewTabs
        setShowClientTab={setShowClientTab}
        selectedClient={selectedClient}
      />
    ),
    updateClient: (
      <UpdateTabs
        setShowClientTab={setShowClientTab}
        selectedClient={selectedClient}
      />
    ),
  };
  const productsTabMapping = {
    // <ManageProducts
    //     setSelectedProductGroup={setSelectedProductGroup}
    //     setShowProductTab={setShowProductTab}
    //   />
    products: (
      <ManageProducts
        view={view}
        setView={setView}
        format={format}
        setFormat={setFormat}
        setSelectedProductGroup={setSelectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
    addNewProductCategory: (
      <AddNewProductCategory setShowProductTab={setShowProductTab} />
    ),
    addNewProduct: <AddNewProduct setShowProductTab={setShowProductTab} />,
    viewProductGroup: (
      <ViewProductGroup
        selectedProductGroup={selectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
    updateProductGroup: (
      <UpdateProductGroup
        selectedProductGroup={selectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
    viewProduct: (
      <ViewProduct
        selectedProductGroup={selectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
    updateProduct: (
      <UpdateProduct
        selectedProductGroup={selectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
  };
  const expertsTabMapping = {
    experts: <ExpertsTab setShowExpertsTab={setShowExpertsTab} />,
  };

  const tabList = [
    {
      index: 0,
      tabName: "Clients",
      element: clientsTabMapping[showClientTab] ? (
        clientsTabMapping[showClientTab]
      ) : (
        <div>No such Tab Exist</div>
      ),
    },
    {
      index: 1,
      tabName: "Prodcuts",
      element: productsTabMapping[showProductsTab] ? (
        productsTabMapping[showProductsTab]
      ) : (
        <div>No such Tab Exist</div>
      ),
    },
   
  ];

  const handleTabIndexChange = (index) => {
    setSearchParams({ tabIndex: index },{ replace: true });
  };

  useEffect(() => {
    handleTabIndexChange(tabIndex);
  }, [tabIndex]);

  return (
    <>
      <div
        className="pl-0 mt-[3vh]  font-roboto flex flex-col rounded-lg"
      >
        {twoFactorAuthoInfo?.is2FAuthenticatedSuperAdmin && ( // rendering the tabs based on 2FA
          <Tabs
            className="bg-white rounded-md px-6 border-2 pt-4"
            width={"full"}
            isLazy={true}
            index={tabIndex}
            onChange={handleTabIndexChange}
          >
            <TabList
              className="!flex !border-0 !justify-between !items-center"
              width={"full"}
            >
              <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
                {tabList?.map((item, id) => (
                <Tab
                  key={id}
                  className={`xl:!text-sm !text-[14px] !text-[#605D64] whitespace-nowrap !border !rounded-full md:w-auto ${
                    tabIndex === item?.index
                      ? "  !bg-[#6CABFC] !bg-opacity-20  !border-[#6CA6FC]  "
                      : "!border-[#EBEBEB]"
                  }`}
                  onClick={() => handleTabIndexChange(item?.index)}
                >
                  {item?.tabName}
                </Tab>
              ))}
              </div>
            </TabList>
            <TabPanels className="!pt-4">
            {tabList?.map((item) => {
              return (
                <TabPanel className="!pl-0 !pr-0">{item?.element}</TabPanel>
              );
            })}
          </TabPanels>
          </Tabs>
        )}
      </div>
    </>
  );
};

export default SuperAdminDashboard;
