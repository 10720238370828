import TextButton from "../../../util/Buttons/TextButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { callApi } from "../utils/ApiUtil";
import ImageSelector from "./Components/ImageSelector";
import DetectSegment from "./Components/DetectSegment";
import HelpModal from "./Components/HelpModal";
import AnnotationCount from "./Components/AnnotationCount";
import {
  CommonProgressBar,
  ConfirmPopover,
  CustomRadio,
  FieldLabel,
  NavigateBack,
} from "../Common/CommonComponents";
import { filterAnnotateUnannotate } from "../utils/HandlerFuncs";
import { mixpanelLabelCreateUpdateTrack } from "../utils/MixpanelEvents";
import { Skeleton } from "@mui/material";
import AddLabels from "./Components/AddLabels";

const AddAnnotation = ({ searchParams }) => {
  const { accessMode } = useParams();
  const [existingLabels, setExistingLabels] = useState([]);
  const [existingAnnotations, setExistingAnnotations] = useState({});
  const existingAnnotationsGotRef = useRef(false);
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [labels, setLabels] = useState(
    searchParams.get("labelName") ? [searchParams.get("labelName")] : []
  ); // labels user has added , max 5
  const [annotationType, setAnnotationType] = useState(
    searchParams.get("annotationType")
      ? searchParams.get("annotationType")?.toLowerCase()
      : ""
  ); // detect , segment and classify
  const [uploadedFiles, setUploadedFiles] = useState([]); // all frames the user has uploaded
  const repoName = searchParams.get("repoName");
  const repoId = searchParams.get("repoId");
  const dataType = searchParams.get("dataType");
  const modelId = searchParams?.get("modelId") ?? "";
  const modelRoute = `/SandboxV2/draft/retrain?modelId=${modelId}`;
  const [page, setPage] = useState("Unannotated"); // page view for classify
  const [selectedImages, setSelectedImages] = useState([]); // Unannotated image selection used in classify
  const [annotatedImages, setAnnotatedImages] = useState([]); // Manage final annotations done by user in classify/detect/segment
  const [confirm, setConfirm] = useState(accessMode === "draft" ? true : false); // used to confirm label addition in detect/segment
  const [anchorEl, setAnchorEl] = useState(null); // state containing anchor element for confirm labels and submit annotation.
  const [allImages, setAllImages] = useState([]); // state which contains all images, used in classify/detect/segment
  const [ImageSet, setImageSet] = useState([]); // set image with regions empty for annotation detect/segment
  const [annotationId, setAnnotationId] = useState(""); // Id using which polling is done on whether drawing is complete or not
  const intervalRef = useRef(); // interval ref to capture when polling starts
  const [helpModal, setHelpModal] = useState(false);
  const toast = useToast();

  const getAllFrames = async () => {
    try {
      const param = {
        masterObjectId: repoId,
      };
      const response = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/image/",
        params: param,
      });
      if (response?.status === 200) {
        setUploadedFiles(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnotations = async () => {
    try {
      const param = {
        masterObjectId: repoId,
        label: labels[0],
        annotationType: annotationType?.toUpperCase(),
      };
      const response = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/annotationData/",
        params: param,
      });
      if (response?.status === 200) {
        setExistingAnnotations(response?.data);
        existingAnnotationsGotRef.current = true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveAnnotation = async () => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    try {
      const requestData = JSON.stringify({
        annotations: annotatedImages?.map((item) => {
          return {
            fileId: item?.id,
            label: annotationType === "classify" ? item?.label : item?.regions,
          };
        }),
        annotationType: annotationType?.toUpperCase(),
        masterObjectId: repoId,
      });
      const response = await callApi({
        toast: toast,
        type: "post",
        endPoint: "selfserve/v2/project/v1/annotations/",
        requestBody: requestData,
      });
      if (response?.status === 200) {
        setAnnotationId(response?.data);
      } else throw new Error("Annotation set failed");
    } catch (error) {
      console.log(error);
      setProgressState((prev) => ({ ...prev, state: 0 }));
    }
  };

  const pollAnnotation = async () => {
    try {
      const param = {
        annotationId: annotationId,
        dataRepoId: repoId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/poll_annotations/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        if (response?.data === "COMPLETED") {
          setProgressState((prev) => ({ ...prev, state: 2 }));
          toast({
            title: "Annotations saved",
            duration: 3000,
            isClosable: true,
            status: "success",
            position: "top-right",
          });
          let repoRoute = `/SandboxV2/view/data?repoId=${repoId}&repoName=${repoName}&dataType=${dataType}&selectedView=annotated`;
          setTimeout(
            () =>
              navigate(
                modelId !== ""
                  ? modelRoute +
                      `&labelNames=${JSON.stringify(
                        labels
                      )}&labelType=${annotationType}`
                  : repoRoute,
                {
                  state: {
                    prevPath: location?.pathname + location?.search,
                  },
                }
              ),
            2000
          );
          mixpanelLabelCreateUpdateTrack({
            repoName: repoName,
            repoId: repoId,
            labelType: annotationType,
            labelNames: labels,
            annotatedFileCounts: annotatedImages?.length,
            type: accessMode === "draft" ? "updated" : "created",
          });
          clearInterval(intervalRef?.current);
        }
      } else {
        clearInterval(intervalRef?.current);
        setProgressState((prev) => ({ ...prev, state: 0 }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExistingLabels = async () => {
    try {
      const params = {
        masterObjectId: repoId,
      };
      const response = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/labelInfo/",
        params: params,
      });
      setExistingLabels(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = (value) => {
    if (
      existingLabels?.some((item) => {
        return (
          item?.labelName?.toLowerCase() === value?.toLowerCase() &&
          item.annotationType === annotationType?.toUpperCase()
        );
      })
    ) {
      toast({
        title: "Label already exists!",
        description:
          "You cannot create same label with same annotation type again",
        status: "error",
        duration: 6000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }
    if (/\S/.test(value)) {
      setLabels((prev) => {
        let newData = [...prev];
        newData.push(value?.trim());
        return newData;
      });
    }
  };

  const handleAssign = (value) => {
    setAnnotatedImages((prev) => {
      let newData = [...prev];
      selectedImages.forEach((item) => {
        newData.push({
          img: item.img,
          id: item.id,
          label: value,
        });
      });
      return newData;
    });
    setAllImages((prev) => {
      let newData = [...prev];
      selectedImages.forEach((item) => {
        let idx = newData.findIndex((x) => x.id == item.id);
        newData.splice(idx, 1);
      });
      return newData;
    });
    setSelectedImages([]);
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      if (accessMode !== "draft") {
        setAllImages(
          uploadedFiles.map((item) => {
            return {
              img: item.url,
              id: item.fileId,
            };
          })
        );
        setAnnotatedImages([]);
        setSelectedImages([]);
        setLabels([]);
      } else if (existingAnnotationsGotRef?.current) {
        filterAnnotateUnannotate({
          allImages: uploadedFiles,
          annotatedData: existingAnnotations,
          annotationType: annotationType,
          setAnnotatedImages: setAnnotatedImages,
          setImageSet: setImageSet,
          setAllImages: setAllImages,
        });
        existingAnnotationsGotRef.current = false;
      }
    }
  }, [uploadedFiles, existingAnnotations]);

  useEffect(() => {
    if (accessMode !== "draft") getExistingLabels();
    else getAnnotations();
    getAllFrames();
  }, []);

  useEffect(() => {
    if (annotationId !== "") {
      intervalRef.current = setInterval(() => pollAnnotation(), 10 * 1000);

      return () => clearInterval(intervalRef?.current);
    }
  }, [annotationId]);
  
  const skeleton2 = (
    <div className="flex flex-col gap-2">
      <Skeleton variant="rectangular" width={"200px"} height={"50px"} />
      <div className="flex gap-2 items-center">
        {[...Array(2)]?.map((item, idx) => (
          <Skeleton
            key={idx}
            width={"200px"}
            height={"150px"}
            variant="rectangular"
          />
        ))}
      </div>
      <div className="flex w-full justify-between items-center">
        {[...Array(2)]?.map((item, idx) => (
          <Skeleton
            key={idx}
            width={"200px"}
            height={"50px"}
            variant="rectangular"
          />
        ))}
      </div>
      <Skeleton width={"100%"} height={"30vh"} variant="rectangular" />
    </div>
  );

  const skeleton1 = (
    <div className="flex flex-col gap-2">
      <Skeleton width={"300px"} height={"50px"} variant="rectangular" />
      <Skeleton width={"150px"} variant="rectangular" />
      <div className="flex gap-2 items-center">
        {[...Array(3)]?.map((item, idx) => (
          <Skeleton
            key={idx}
            width={"100px"}
            height={"50px"}
            variant="rectangular"
          />
        ))}
      </div>
      <Skeleton width={"200px"} height={"50px"} variant="rectangular" />
      <Skeleton width={"150px"} height={"50px"} variant="rectangular" />
      {skeleton2}
    </div>
  );
  console.log(labels, "hello");

  return (
    <div className="flex flex-col gap-3 relative">
      <NavigateBack
        targetRoute={modelId !== "" ? modelRoute : -1}
        textArray={
          accessMode == "draft"
            ? ["AI Studio", repoName, labels[0], "Add data"]
            : ["AI Studio", repoName, "Add new label"]
        }
      />
      {uploadedFiles?.length > 0 ? (
        <div className="flex flex-col gap-6">
          {/*Title */}
          <div className="flex gap-2 items-center flex-wrap">
            <p className="text-[#3E3C42] text-xl font-medium">{`Assign labels (Total files: ${
              Object.entries(uploadedFiles)?.length
            })`}</p>
            <TextButton
              text={"Know how?"}
              width={"fit-content"}
              onClick={() => setHelpModal(true)}
              disable={!annotationType}
            />
          </div>

          {/*Radio select */}
          <div className="flex flex-col gap-3">
            <FieldLabel
              color="#605D64"
              size="16px"
              weight={500}
              text="Select annotation type"
            />
            <CustomRadio
              options={["classify", "detect", "segment"]}
              value={annotationType}
              setValue={setAnnotationType}
              disable={labels.length > 0 || accessMode === "draft"}
            />
          </div>

          {/* Label selection and additon */}
          <AddLabels
            confirm={confirm}
            setAnchorEl={setAnchorEl}
            labels={labels}
            setLabels={setLabels}
            handleAdd={handleAdd}
            annotationType={annotationType}
          />
          {confirm && (
            <AnnotationCount
              labels={labels}
              annotatedImages={annotatedImages}
              annotationType={annotationType}
              selectedImages={selectedImages}
              handleAssign={handleAssign}
            />
          )}

          {/* Image selection area */}
          {confirm ? (
            annotationType === "classify" ? (
              <ImageSelector
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                annotatedImages={annotatedImages}
                setAnnotatedImages={setAnnotatedImages}
                images={allImages}
                setImages={setAllImages}
                page={page}
                setPage={setPage}
              />
            ) : (
              <DetectSegment
                labels={labels}
                setAnnotatedImages={setAnnotatedImages}
                annotatedImages={annotatedImages}
                allImages={allImages}
                options={
                  annotationType === "detect"
                    ? ["select", "create-box"]
                    : ["select", "create-polygon"]
                }
                ImageSet={ImageSet}
                setImageSet={setImageSet}
                confirm={confirm}
              />
            )
          ) : (
            <div className="flex flex-col gap-1">
              <p className="text-[#605D64] text-base font-medium">
                Add & confirm labels to proceed to annotation
              </p>
              {skeleton2}
            </div>
          )}

          <div className="self-end rounded-lg w-fit md:sticky bottom-0 p-2 z-10">
            <PrimaryButton
              text={"Submit"}
              width={"180px"}
              disable={annotatedImages.length === 0}
              onClick={(e) => setAnchorEl(e?.currentTarget)}
            />
          </div>
        </div>
      ) : (
        skeleton1
      )}
      {helpModal && (
        <HelpModal
          openModal={helpModal}
          closeModal={() => setHelpModal(false)}
          type={annotationType}
        />
      )}
      {progressState?.open && (
        <CommonProgressBar
          openModal={progressState?.open}
          closeModal={() =>
            setProgressState((prev) => ({
              ...prev,
              open: false,
              state: null,
            }))
          }
          state={progressState?.state}
        />
      )}
      {anchorEl && (
        <ConfirmPopover
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          text={
            anchorEl?.innerText === "Submit"
              ? "Please confirm that you have saved the annotation in the annotator and are ready to submit."
              : "Once confirmed, you won't be able to add more labels. Are you sure you want to proceed?"
          }
          handleConfirm={() => {
            if (anchorEl?.innerText === "Submit") saveAnnotation();
            else setConfirm(true);
            setAnchorEl(null);
          }}
        />
      )}
    </div>
  );
};

export default AddAnnotation;
