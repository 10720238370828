import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ImageContainer } from "../../Common/CommonComponents";
import ReactPlayer from "react-player";
import { useWindowSize } from "@uidotdev/usehooks";
import axios from "axios";
import Timer from "../../../../util/VisionUtils/Timer";
import { callApi } from "../../utils/ApiUtil";
import { mixpanelApiFailTrack } from "../../utils/MixpanelEvents";
import { Skeleton } from "@mui/material";
import { killHlsStream } from "../../utils/HandlerFuncs";
import { RTSPBaseUrl } from "../../../..";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

const DetailFeed = ({
  rtsp = "",
  status = "",
  cameraName = "",
  appId = "",
}) => {
  const [hlsLink, setHlsLink] = useState("");
  const isDisabled = status === "DISABLED";
  const [initialRender, setInitialRender] = useState(true);
  const [prediction, setPrediction] = useState({});
  const [startCapture, setStartCapture] = useState(false);
  const size = useWindowSize();
  const toast = useToast();

  const handlRtspAdd = async () => {
    try {
      const requestData = JSON.stringify({
        rtsp: rtsp,
        uuid: appId,
      });
      const response = await axios.post(RTSPBaseUrl + "/create", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.status === 200) {
        let data = response?.data;
        let hlsUrl = RTSPBaseUrl + data?.replace(/^\./, "");
        toast({
          title: "Processing",
          description: "This can take upto a minute",
          status: "info",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setTimeout(() => setHlsLink(hlsUrl), 20 * 1000);
      }
    } catch (error) {
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      mixpanelApiFailTrack({
        errorMessage: error?.response?.data?.message ?? "",
        endPoint: RTSPBaseUrl,
        errorCode: error?.response?.status,
        type: "post",
        param: {},
        requestData: {
          uri: rtsp,
          alias: "liveRtsp",
        },
        isFormData: false,
      });
      console.log(error);
    }
  };

  const getCamStatus = async () => {
    try {
      const param = {
        appObjectId: appId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/inference/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        let camData =
          response?.data?.find((item) => item?.cameraName === cameraName) ?? {};
        setPrediction(camData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isDisabled) {
      handlRtspAdd();
      return () =>
        killHlsStream({
          uuid: appId,
          rtsp: rtsp,
          toast: toast,
        });
    } else {
      setStartCapture(true);
      toast({
        title: "No live view",
        description: "The app is disabled, live feed not available",
        status: "info",
        position: "top-right",
        duration: 3000,
      });
    }
  }, [rtsp]);

  useEffect(() => {
    if (startCapture) getCamStatus();
  }, [startCapture]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 relative bg-white rounded-xl p-6">
      {startCapture && (
        <div
          className={
            size?.width <= 900
              ? "col-span-full flex justify-end w-full"
              : "absolute -top-14 right-0 w-fit whitespace-nowrap"
          }
        >
          <Timer
            initialSeconds={15}
            initialRender={initialRender}
            setInitialRender={setInitialRender}
            callFunc={getCamStatus}
          />
        </div>
      )}
      <div className="h-full w-full col-span-2">
        {!isDisabled ? (
          hlsLink !== "" ? (
            <ReactPlayer
              url={hlsLink}
              width={"100%"}
              height={"65vh"}
              playing={true}
              muted={true}
              playsinline={true}
              controls={true}
              config={{
                file: {
                  attributes: { disablePictureInPicture: true },
                },
              }}
              onStart={() => setStartCapture(true)}
            />
          ) : (
            <Skeleton width={"100%"} variant="rectangular" height={"65vh"} />
          )
        ) : (
          <div className="w-full h-[65vh] content-center text-center bg-gray-100 rounded">
            <VideocamOffIcon sx={{ fontSize: "100px" }} />
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 h-full gap-4">
        <div className="flex flex-col gap-4 w-full">
          <p className="text-[#605D64] text-base font-medium">
            Captured frame from {cameraName}
          </p>
          {"imgUrl" in prediction ? (
            <ImageContainer width="100%" height="100%">
              <img
                alt="image"
                src={
                  "annotatedImgUrl" in prediction
                    ? prediction?.annotatedImgUrl
                    : prediction?.imgUrl
                }
                className="w-full h-full rounded"
              />
            </ImageContainer>
          ) : (
            <Skeleton width={"100%"} variant="rectangular" height={"100%"} />
          )}
        </div>
        <div className="grid grid-cols-2 gap-x-[46px] gap-y-4 pt-4 px-4 bg-[#FAFAFA] border border-[#EBEBEB] rounded">
          {["Tag name", "Probability"]?.map((item) => {
            return <p className="text-[#938F96] text-base">{item}</p>;
          })}
          <div className="border-b border-[#EBEBEB] col-span-2" />
          {(typeof prediction?.tagName === "string"
            ? [prediction?.tagName]
            : prediction?.tagName
          )?.map((item, idx) => {
            const probability =
              typeof prediction?.precision === "number"
                ? prediction?.precision
                : prediction?.prediction[idx];
            return (
              <>
                <p
                  className="text-[#605D64] text-base font-medium"
                  key={idx + "label"}
                >
                  {item}
                </p>
                <p
                  className="text-base font-medium"
                  style={{
                    color:
                      Math.round(probability * 100) <= 30
                        ? "#FF0000"
                        : "#008000",
                  }}
                  key={idx + "prob"}
                >
                  {Math.round(probability * 100)}%
                </p>
                <div
                  className="border-b border-[#EBEBEB] col-span-2"
                  key={idx + "bar"}
                />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(DetailFeed);
