import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { callApi } from "../utils/ApiUtil";
import { FieldLabel } from "../Common/CommonComponents";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { debounce, rtspRegex } from "../utils/HandlerFuncs";
import { OutlinedInput } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const AppFailFallBack = ({ appId = "", initialData = {} }) => {
  const [appData, setAppData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const checkValidRtsp = () => {
    let data = appData?.cameraDetail;
    for (let idx in data) {
      if (!rtspRegex?.test(data[idx]?.rtspLink)) {
        toast({
          title: "Invalid rtsp",
          description:
            "You have provided invalid rtsp for " + data[idx]?.cameraName,
          status: "error",
          position: "top-right",
          duration: 6000,
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  };

  const saveChangeApi = async () => {
    try {
      const param = {
        masterObjectId: appId,
      };
      const requestData = JSON.stringify(
        appData?.cameraDetail?.map((item) => {
          delete item["cameraName"];
          return item;
        })
      );
      const response = await callApi({
        type: "patch",
        endPoint: "selfserve/v2/project/v1/app/camera/",
        requestBody: requestData,
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        navigate(
          location?.state?.prevPath?.includes("selectedView")
            ? -1
            : "/SandboxV2/app",
          {
            state: {
              prevPath: location?.pathname + location?.search,
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setAppData((prev) => structuredClone(initialData));
  }, [initialData]);

  const handleRtspChange = ({ camId = "", newVal = "" }) => {
    setAppData((prev) => ({
      ...prev,
      cameraDetail: prev?.["cameraDetail"]?.map((item) => {
        if (item?.camId === camId) {
          item["rtspLink"] = newVal?.trim();
        }
        return item;
      }),
    }));
  };
  const debouncedChangeHandler = debounce(handleRtspChange, 300);
  return (
    <div className="p-6 rounded-xl w-full bg-white min-h-[65vh]">
      <div className="h-full w-full border-[2px] border-dashed border-[#E46962] rounded-xl flex justify-center">
        <div className="self-center flex flex-col gap-6 w-full px-[60px] py-[90px] justify-center items-center">
          <img
            src="/selfServiceIcons/appFail.svg"
            className="max-w-[50%] h-auto"
            alt="error"
          />
          <p className="text-[#E46962] text-xl font-medium text-center">
            App failed due to following reason: {appData?.remarks}
          </p>
          <div className="flex flex-wrap w-full gap-x-4 gap-y-6 items-end">
            {appData?.cameraDetail?.map((item, idx) => {
              return (
                <div key={idx} className="flex-1 flex flex-col gap-3">
                  <FieldLabel
                    text={idx + 1 + ". " + item?.cameraName}
                    isRequired={false}
                  />
                  <OutlinedInput
                    size="small"
                    defaultValue={item?.rtspLink}
                    onChange={(e) =>
                      debouncedChangeHandler({
                        newVal: e.target.value,
                        camId: item?.camId,
                      })
                    }
                    placeholder="Enter rtsp link"
                    sx={{
                      width: "100%",
                      minWidth: "200px",
                      height: "36px",
                    }}
                  />
                </div>
              );
            })}
            <div className="flex-1 w-full">
              <PrimaryButton
                text={"Save RTSP"}
                width={"100%"}
                onClick={() => {
                  if (checkValidRtsp()) saveChangeApi();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFailFallBack;
