import { Spinner } from "@chakra-ui/react";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { useContext, useEffect, useState } from "react";
import { baseURL } from "../../../index";
import axios from "axios";
import { formatColumns } from "../../../util/MaterialDataGrid/DatagridUtils";
import NavContext from "../../NavContext";
import TextButton from "../../../util/Buttons/TextButton";
import AddIcon from "@mui/icons-material/Add";
import AddRequestModal from "../Modals/AddRequestModal";
import SubscriptionPlanModal from "../Modals/SubscriptionPlanModal";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserRequests = ({
  clientId = "",
  clientOrg = "",
  mode = "",
  setSelectedSubscription = null,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { clientId: userClientId, auth } = useContext(NavContext);
  const [subscriptionModalData, setSubscriptionModalData] = useState({
    open: false,
    rowData: {},
  });
  const [openRequest, setOpenRequest] = useState(false);
  const [tableData, setTableData] = useState([]);

  const statusColorMap = {
    submitted: {
      text: "text-gray-400",
      hexCode: "#F5F5F5",
    },
    accepted: {
      text: "text-green-400",
      hexCode: "#ECFBE7",
    },
    "in progress": {
      text: "text-red-400",
      hexCode: "#FCEEEE",
    },
    resolved: {
      text: "text-blue-400",
      hexCode: "#EBF5FF",
    },
    closed: {
      text: "text-gray-400",
      hexCode: "#F5F5F5",
    },
  };

  const priorityColorMap = {
    high: "text-[#E46962] capitalize",
    medium: "text-[#F75C03] capitalize",
    low: "text-[#6CA6FC] capitalize",
  };

  const userRequestColumnDefinition = [
    {
      field: "userRequestId",
      headerName: "Request Id",
      type: "string",
    },
    {
      field: "requestDate",
      headerName: "Request date",
      type: "dateTime",
      valueGetter: ({ value }) => new Date(value),
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleString("en-GB");
      },
    },
    {
      field: "requesterEmail",
      headerName: "Requester email",
      type: "string",
    },
    {
      field: "requestType",
      headerName: "Request type",
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
    },
    {
      field: "userType",
      headerName: "User type",
      type: "string",
    },
    {
      field: "requestPriority",
      headerName: "Priority",
      type: "string",
    },
    {
      field: "userFacingStatus",
      headerName: "Status",
      type: "string",
    },
    {
      field: "sla",
      headerName: "SLA",
      valueFormatter: ({ value }) =>
        (value / (24 * 60 * 60))?.toFixed(2) + " days",
      type: "number",
    },
    {
      field: "eta",
      headerName: "ETA",
      type: "dateTime",
      valueGetter: ({ value }) => new Date(value),
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleString("en-GB");
      },
    },
    {
      field: "remarks",
      headerName: "Remarks",
      type: "string",
      // renderCell: ({ value }) => {
      //   let data = JSON.parse(value);
      //   return (
      //     <div className="flex flex-col gap-1 h-full w-full" title={value}>
      //       {Object?.entries(data)?.map((item) => {
      //         return (
      //           <p>
      //             {item[0]} = {item[1]}
      //           </p>
      //         );
      //       })}
      //       <p>{value}</p>
      //     </div>
      //   );
      // },
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => {
        const handleClick = () => {
          let cltId = clientId ? clientId : userClientId;
          if (setSelectedSubscription) {
            setSelectedSubscription((prev) => ({
              type: "requestDetail",
              clientId: cltId,
              queryType: row?.requestType,
              email: row?.requesterEmail,
              requestId: row?.userRequestId,
              subscriptionId: row?.subscriptionId,
            }));
          } else {
            let paramString = `?clientId=${cltId}&requestId=${row?.userRequestId}&email=${row?.requesterEmail}&queryType=${row?.requestType}&subscriptionId=${row?.subscriptionId}`;
            navigate(`/admin/requestDetails${paramString}`);
          }
        };
        return (
          <div className="flex gap-2 items-center justify-center w-full">
            <IconButton title="View" color="primary" onClick={handleClick}>
              <VisibilityRoundedIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const userRequestFlexMap = {
    userRequestId: 1,
    requesterEmail: 1,
    userType: 0.75,
    requestType: 0.75,
    description: 1,
    requestPriority: 0.5,
    sla: 0.5,
    requestDate: 1,
    eta: 1,
    userFacingStatus: 0.75,
    remarks: 1,
    actions: 0.5,
  };

  const getUserRequests = async () => {
    try {
      const param = {
        clientId: clientId ? clientId : userClientId,
      };
      const response = await axios.get(baseURL + "v2/aisandbox/userRequests/", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response?.status === 200) setTableData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [summary, setSummary] = useState({
    ...Object.entries(statusColorMap)?.reduce((a, b) => {
      a[b[0]] = 0;
      return a;
    }, {}),
  });

  const [filter, setFilter] = useState({
    items: [],
  });

  const handleChange = (e) => {
    const { value } = e.target;
    // Set up an object to store filters for different fields
    const newFilters = {
      items: [],
      quickFilterValues: [value],
    };
    setFilter(newFilters);
  };

  const handleFilterModelChange = (newFilterModel) => {
    // Check if the new filter model is different from the current state

    if (JSON.stringify(newFilterModel) !== JSON.stringify(filter)) {
      setFilter(newFilterModel);
    }
  };

  useEffect(() => {
    getUserRequests();
  }, []);

  useEffect(() => {
    setSummary((prev) => {
      let newData = { ...prev };
      for (let val in newData) {
        let count = tableData?.filter(
          (item) => item?.userFacingStatus?.toLowerCase() === val?.toLowerCase()
        )?.length;
        newData[val] = count;
      }
      return newData;
    });
  }, [tableData]);

  return (
    <div className="w-full px-2 !font-roboto">
      <div className="flex  flex-col lg:flex-row justify-between gap-4 items-start lg:items-end">
        <div className="flex flex-wrap gap-4 items-center">
          {Object.entries(summary)?.map((item, idx) => {
            return (
              <div
                className="flex flex-col gap-2 p-2 border border-[#CAC5CD] rounded-lg w-[100px]"
                style={{
                  backgroundColor: statusColorMap[item[0]]?.hexCode,
                }}
                key={idx}
              >
                <p className="text-[#525056] text-lg font-medium">{item[1]}</p>
                <p className="text-[#79767D] text-base capitalize">{item[0]}</p>
              </div>
            );
          })}
        </div>
        <div className="flex items-center">
          <div className="w-full sm:min-w-[320px] h-[40px] flex flex-row items-center border-2 py-2 rounded px-4 justify-between">
            <input
              className="w-full focus:outline-none text-sm"
              placeholder="Search Request"
              onChange={handleChange}
            />
            <img className="h-5 text-black" src="/search.svg" alt="" />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="ml-[50%]">
          <Spinner speed="0.65s" />
        </div>
      ) : (
        <div className="h-[65vh] border rounded-md mt-6 flex flex-col gap-1">
          {mode === "update" && (
            <div className="pl-1 pt-1 w-fit">
              <TextButton
                Icon={<AddIcon />}
                onClick={() => setOpenRequest(true)}
                text={"Add new request"}
              />
            </div>
          )}
          <CustomStyledDataGrid
            rows={tableData}
            columns={formatColumns({
              color: "blue",
              cols: userRequestColumnDefinition,
              flexMap: userRequestFlexMap,
            })}
            getRowId={(row) => row?.userRequestId}
            autosizeOptions={{
              columns: userRequestColumnDefinition?.map((item) => item.field),
              includeOutliers: true,
              includeHeaders: true,
            }}
            pagination={true}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
              pinnedColumns: {
                left: [],
                right: ["userFacingStatus", "actions"],
              },
            }}
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
            }}
            getRowHeight={() => "auto"}
            pageSizeOptions={[5, 15, 25]}
            getCellClassName={(params) => {
              if (params.field === "userFacingStatus") {
                return (
                  statusColorMap[params?.value?.toLowerCase()]?.text +
                  " bg-[#FAFAFA] capitalize py-1"
                );
              } else if (params.field === "requestPriority") {
                return priorityColorMap[params?.value?.toLowerCase()];
              }
              return "";
            }}
            filterModel={filter}
            onFilterModelChange={handleFilterModelChange}
          />
        </div>
      )}
      {openRequest && (
        <AddRequestModal
          isOpen={openRequest}
          onClose={() => setOpenRequest(false)}
          clientId={clientId ? clientId : userClientId}
          clientOrg={clientOrg}
          setSelectedSubscription={setSelectedSubscription}
          setSubscriptionModalData={setSubscriptionModalData}
        />
      )}
      {subscriptionModalData?.open && (
        <SubscriptionPlanModal
          modalData={subscriptionModalData}
          closeModal={() => {
            setSubscriptionModalData((prev) => ({
              open: false,
              rowData: {},
            }));
          }}
          clientId={clientId ? clientId : userClientId}
          fetchTableData={getUserRequests}
          subscriptionId={subscriptionModalData?.rowData?.subscriptionId}
          plantType={subscriptionModalData?.rowData?.subscriptionType?.toLowerCase()}
        />
      )}
    </div>
  );
};

export default UserRequests;
