import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Card,
  CardContainer,
  CardTableToggle,
  Chip,
  CustomMenu,
  DataGridContainer,
} from "../Common/CommonComponents";
import { callApi } from "../utils/ApiUtil";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import {
  dataTypeIconMap,
  debounce,
  filterHomePageComparator,
} from "../utils/HandlerFuncs";
import { CommonHomeFilters } from "../Common/CommonFilters";
import CommonRenameModal from "../Common/CommonRenameModal";
import { mixpanelDeleteTrack } from "../utils/MixpanelEvents";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import {
  dataColumnDefinition,
  dataFlexMap,
  formatColumns,
} from "../Common/CommonColDefine";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import {
  createTheme,
  IconButton,
  Skeleton,
  ThemeProvider,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import NavContext from "../../NavContext";
import Paginator from "../../../util/VisionUtils/Paginator";
import CommonWidgets from "../Common/CommonWidgets";

const DataHomeView = () => {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { creationLimits } = useContext(NavContext);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const rightRef = useRef();
  const leftRef = useRef();
  const [disableToggle, setDisableToggle] = useState({
    left: true,
    right: true,
  });
  const selectedView = searchParams?.get("selectedView")
    ? searchParams?.get("selectedView")
    : "card";
  const [renameModal, setRenameModal] = useState({
    open: false,
    oldName: null,
    id: null,
  });
  const [filterState, setFilterState] = useState({
    dataType: [],
    owner: [],
    date: "",
    search: "",
  });
  const filteredData = useMemo(
    () =>
      data?.filter((item) => {
        let type =
          filterState?.dataType?.length !== 0
            ? filterState?.dataType?.some((val) => {
                return item?.isCameraRepo
                  ? val?.type === "camera"
                  : val?.type === item?.dataRepositoryType?.toLowerCase();
              })
            : true;
        let check = filterHomePageComparator({
          filterState: filterState,
          bypassTypeCheck: true,
          typeKey: "dataType",
          timeKey: "lastUpdatedAt",
          data: item,
        });
        return type && check;
      }),
    [filterState, data]
  );
  const viewOptions = [
    { type: "image" },
    { type: "video" },
    { type: "camera" },
  ];

  const getData = async () => {
    try {
      const res = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/dataRepository/",
      });
      if (res?.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async ({ id = "", name = "" }) => {
    try {
      const param = {
        masterObjectId: id,
      };
      const response = await callApi({
        toast: toast,
        type: "delete",
        endPoint: "selfserve/v2/project/v1/dataRepository/",
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "Deleted successfully",
          status: "success",
          position: "top-right",
          duration: 2000,
        });
        getData();
        mixpanelDeleteTrack({
          name: name,
          type: "data",
          id: id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = ({ val, id, name }) => {
    switch (val) {
      case "Delete":
        deleteData({ id: id, name: name });
        break;
      case "Share access":
        break;
      case "Rename":
        setRenameModal({
          open: true,
          oldName: name,
          id: id,
        });
        break;
      default:
        break;
    }
  };

  const handleViewChange = (val) => {
    if (val !== selectedView) {
      setSearchParams((prev) => {
        prev.set("selectedView", val);
        return prev;
      });
    }
  };

  const debouncedSetSearchData = debounce(
    (e) =>
      setFilterState((prev) => ({
        ...prev,
        search: e.target.value,
      })),
    300
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    handleViewChange(selectedView);
  }, [selectedView]);

  const additionalActionCols = [
    {
      field: "actions",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => {
        return (
          <div className="flex justify-evenly items-center gap-1 w-full">
            <IconButton
              title="View"
              color="success"
              onClick={() =>
                navigate(
                  `/SandboxV2/view/data?repoId=${
                    row?.masterObjectId
                  }&repoName=${
                    row?.name
                  }&dataType=${row?.dataRepositoryType?.toLowerCase()}`,
                  {
                    state: {
                      prevPath: location?.pathname + location?.search,
                    },
                  }
                )
              }
            >
              <VisibilityRoundedIcon />
            </IconButton>
            <IconButton
              title="Annotate"
              color="success"
              onClick={() =>
                navigate(
                  `/SandboxV2/add/label?repoName=${row?.name}&repoId=${
                    row?.masterObjectId
                  }&dataType=${row?.dataRepositoryType?.toLowerCase()}`,
                  {
                    state: {
                      prevPath: location?.pathname + location?.search,
                    },
                  }
                )
              }
              disabled={!row?.fileCount}
            >
              <FormatShapesIcon />
            </IconButton>
            <div className="relative w-10 h-10">
              <CustomMenu
                top="0px"
                right="0px"
                options={[
                  // {
                  //   type: "Share access",
                  //   icon: "/selfServiceIcons/share.svg",
                  // },
                  {
                    type: "Rename",
                    icon: "/edit.svg",
                  },
                  {
                    type: "Delete",
                    icon: "/delete.svg",
                  },
                ]}
                handleMenuClick={(val) =>
                  handleMenuClick({
                    val: val,
                    id: row?.masterObjectId,
                    name: row?.name,
                  })
                }
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <ThemeProvider theme={createTheme()}>
      <div className="flex flex-col gap-3 rounded-xl w-full relative">
        <CommonWidgets type="datasets" tagLine="Bring your data" />
        <div className="flex flex-col gap-6 w-full items-end">
          <div className="w-full flex flex-col xl:flex-row justify-between gap-4 items-start xl:items-center">
            <p className="text-[#605D64] text-lg font-medium whitespace-nowrap w-fit">
              Your list of datasets (
              {data?.length + "/" + creationLimits?.dataRepoPerUserAllowed})
            </p>
            <CommonHomeFilters
              filterOptions={[
                {
                  label: "Data type",
                  displayKey: "type",
                  valueKey: "type",
                  options: viewOptions,
                  value: filterState?.dataType,
                  handleChange: (val, newVal) => {
                    setFilterState((prev) => ({
                      ...prev,
                      dataType: newVal,
                    }));
                  },
                },
                {
                  label: "Owner",
                  displayKey: "owner",
                  valueKey: "owner",
                  options: Array.from(
                    new Set(data?.map((item) => item?.owner))
                  )?.map((item) => ({ owner: item })),
                  value: filterState?.owner,
                  handleChange: (val, newVal) => {
                    setFilterState((prev) => ({
                      ...prev,
                      owner: newVal,
                    }));
                  },
                },
                {
                  isDateSelect: true,
                  type: "date",
                  value: filterState?.date,
                  handleChange: (val) => {
                    setFilterState((prev) => ({
                      ...prev,
                      date: val,
                    }));
                  },
                  label: "Updated after",
                  max: new Date(),
                },
              ]}
              hasSearch={true}
              searchObj={{
                value: filterState?.search,
                setValue: debouncedSetSearchData,
              }}
            >
              <CardTableToggle
                setViewType={handleViewChange}
                ViewType={selectedView}
              />
            </CommonHomeFilters>
          </div>
          {selectedView === "card" ? (
            <CardContainer
              showLeft={!disableToggle?.left}
              showRight={!disableToggle?.right}
              leftFunc={() => leftRef?.current?.click()}
              rightFunc={() => rightRef?.current?.click()}
            >
              {loading ? (
                [...Array(8)]?.map((val, idx) => (
                  <Skeleton
                    variant="rectangular"
                    height={"250px"}
                    width={"100%"}
                    key={idx}
                  />
                ))
              ) : (
                <>
                  <Card
                    isAddbutton={true}
                    addText="Add data repo"
                    handleAdd={() =>
                      navigate("/SandboxV2/add/data", {
                        state: {
                          prevPath: location?.pathname + location?.search,
                        },
                      })
                    }
                    disableAdd={
                      creationLimits?.dataRepoPerUserAllowed <= data?.length
                    }
                  />
                  {displayData?.map((item, idx) => {
                    return (
                      <Card
                        key={idx}
                        heading={item?.name}
                        bgImage={item?.sampleDataRepoUrl}
                        labels={item?.labelInfo?.map((val) => val?.labelName)}
                        buttonInfo={[
                          {
                            text: "View",
                            handleClick: () => {
                              navigate(
                                `/SandboxV2/view/data?repoId=${
                                  item?.masterObjectId
                                }&repoName=${
                                  item?.name
                                }&dataType=${item?.dataRepositoryType?.toLowerCase()}`,
                                {
                                  state: {
                                    prevPath:
                                      location?.pathname + location?.search,
                                  },
                                }
                              );
                            },
                            type: "primary",
                          },
                          {
                            text: "Annotate",
                            type: "secondary",
                            handleClick: () => {
                              navigate(
                                `/SandboxV2/add/label?repoName=${
                                  item?.name
                                }&repoId=${
                                  item?.masterObjectId
                                }&dataType=${item?.dataRepositoryType?.toLowerCase()}`,
                                {
                                  state: {
                                    prevPath:
                                      location?.pathname + location?.search,
                                  },
                                }
                              );
                            },
                            disable: !item?.fileCount,
                          },
                        ]}
                      >
                        <Chip
                          top={"12px"}
                          right={"12px"}
                          customElem={
                            <CustomMenu
                              position="block"
                              color="#48464C"
                              options={[
                                {
                                  type: "Rename",
                                  icon: "/edit.svg",
                                },
                                {
                                  type: "Delete",
                                  icon: "/delete.svg",
                                },
                              ]}
                              handleMenuClick={(val) =>
                                handleMenuClick({
                                  val: val,
                                  id: item?.masterObjectId,
                                  name: item?.name,
                                })
                              }
                            />
                          }
                        />
                        <Chip
                          top={"12px"}
                          left={"12px"}
                          width="fit-content"
                          customElem={
                            <div
                              className="flex gap-1 items-center text-[#525056] font-semibold text-sm"
                              title="Number of files"
                            >
                              <ImageIcon />
                              <p>{item?.fileCount}</p>
                            </div>
                          }
                        />
                      </Card>
                    );
                  })}
                </>
              )}
            </CardContainer>
          ) : (
            <DataGridContainer
              showAdd={true}
              disableAdd={
                creationLimits?.dataRepoPerUserAllowed <= data?.length
              }
              addText="Add data repo"
              addUrl="/SandboxV2/add/data"
            >
              <CustomStyledDataGrid
                rows={filteredData}
                columns={formatColumns({
                  cols: [...dataColumnDefinition, ...additionalActionCols],
                  color: "blue",
                  flexMap: dataFlexMap,
                })}
                getRowId={(row) => row?.masterObjectId}
                autosizeOptions={{
                  columns: dataColumnDefinition?.map((item) => item.field),
                  includeOutliers: true,
                  includeHeaders: true,
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                pagination
                pageSizeOptions={[5, 15, 25]}
              />
            </DataGridContainer>
          )}
          {selectedView === "card" && (
            <Paginator
              data={filteredData}
              limit={8}
              leftRef={leftRef}
              rightRef={rightRef}
              setDisableToggle={setDisableToggle}
              setDisplayData={setDisplayData}
              additionalStyles={{
                style: {
                  boxShadow:
                    "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
                  backgroundColor: "#FFF",
                  border: "1px solid #CAC5CD",
                  borderRadius: "8px",
                },
              }}
            />
          )}
        </div>
        {renameModal?.open && renameModal?.id && (
          <CommonRenameModal
            openModal={renameModal?.open}
            closeModal={() =>
              setRenameModal({
                open: false,
                oldName: null,
                id: null,
              })
            }
            oldName={renameModal?.oldName}
            type="dataRepo"
            objectId={renameModal?.id}
            fetchCall={getData}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default DataHomeView;
