import { useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  CommonUniqueTextCheck,
  CustomSelect,
  FieldLabel,
  LabelSelect,
  NavigateBack,
  TransitionCards,
} from "../Common/CommonComponents";
import { callApi } from "../utils/ApiUtil";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { mixpanelModelRetrainSubmitTrack } from "../utils/MixpanelEvents";
import { nameDescCheck } from "../utils/HandlerFuncs";
import { OutlinedInput, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TonalButton from "../../../util/Buttons/TonalButton";

const initData = {
  modelId: "",
  baseModelId: "",
  modelName: "",
  baseModelName: "",
  selectedRepo: "",
  selectedRepoData: {},
  selectedLabels: [],
  modelType: "",
  modelDescription: "",
};

const RetrainModel = ({ searchParams }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const modelName = searchParams?.get("modelName"); // name of model retrained on
  const modelId = searchParams?.get("modelId"); // id of the drafted model
  const repoId = searchParams?.get("repoId"); // repoId incase user adds repo
  const labelNames = searchParams?.get("labelNames"); // array of user added labels
  const labelType = searchParams?.get("labelType"); // lowercase string of segment,detect or classify
  const baseModelId = searchParams?.get("baseModelId"); // id of the model retrained on
  const modelType = searchParams?.get("modelType"); // type of model retrained on
  const [draftSet, setDraftSet] = useState(false); // state to check if draft is set
  const [userState, setUserState] = useState({
    ...initData,
    modelName: modelName ?? "",
    baseModelName: modelName ?? "",
    modelType: modelType ?? "",
    baseModelId: baseModelId ?? "",
  }); // setting inital name, baseModelId & modelType, will be overrided if drafted by draft get
  const ref = useRef();
  const descRef = useRef();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [models, setModels] = useState([]);
  const [labelData, setLabelData] = useState([]);

  const draftPostApi = async () => {
    try {
      if (!checkUniqueName()) return null;
      const param = {
        draftId: userState?.modelId ? userState?.modelId : null,
        draftType: "RETRAIN_MODEL",
      };
      let data = { ...userState };
      delete data["selectedLabels"];
      const requestData = JSON.stringify(data);
      const response = await callApi({
        type: "post",
        endPoint: "selfserve/v2/project/v1/model/draft/",
        toast: toast,
        requestBody: requestData,
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "Draft saved",
          status: "success",
          position: "top-right",
          duration: 3000,
        });
        setUserState((prev) => ({
          ...prev,
          modelId: response?.data?.draftId,
        }));
        return response?.data?.draftId;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  const getDraftApi = async () => {
    try {
      const param = {
        draftId: modelId,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/model/draft/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        setUserState((prev) => ({
          ...response?.data,
          modelId: modelId,
        }));
        setDraftSet(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExistingModels = async () => {
    try {
      const res = await callApi({
        toast: toast,
        type: "get",
        endPoint: "selfserve/v2/project/v1/model/",
      });
      if (res?.status === 200) {
        let filterData = res?.data?.filter((item) => {
          return item?.masterObjectId !== modelId;
        });
        setModels(filterData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkUniqueName = () => {
    let found = models?.some(
      (item) => item?.name?.toLowerCase() === ref?.current?.value?.toLowerCase()
    );
    if (found)
      toast({
        title: "Error",
        description: `Model with name: ${ref?.current?.value} already exists`,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    return !found;
  };

  const getData = async () => {
    try {
      const response = await callApi({
        type: "get",
        toast: toast,
        endPoint: "selfserve/v2/project/v1/dataRepository/",
      });
      if (response?.status === 200) {
        setData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLabels = async () => {
    try {
      const param = {
        masterObjectId: userState?.selectedRepo,
      };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/labelInfo/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        setLabelData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startRetrain = async (id) => {
    if (
      !nameDescCheck({
        val: ref?.current?.value,
        toast: toast,
        name: "model name",
      }) ||
      !nameDescCheck({
        val: descRef?.current?.value,
        toast: toast,
        isDesc: true,
        name: "model description",
      })
    )
      return;
    if (userState?.selectedLabels?.length === 0) {
      toast({
        title: "Please select labels",
        status: "error",
        position: "top-right",
        duration: 3000,
      });
      return;
    }

    let sanity = userState?.selectedLabels?.every((item) => {
      let val = JSON.parse(item);
      return (
        val.annotationType?.toLowerCase() ===
        userState?.modelType?.toLowerCase()
      );
    });
    let enoughForTrain = userState?.selectedLabels?.every((item) => {
      let val = JSON.parse(item);
      return val.labelCount >= 20;
    });
    if (!sanity || !enoughForTrain) {
      toast({
        title: "Data wrong or insufficient",
        description: (
          <div>
            {!sanity && <p>The selected labels don't match the model type</p>}
            {!enoughForTrain && (
              <p>Please annotate more, need 20 images per label</p>
            )}
          </div>
        ),
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    try {
      const requestData = JSON.stringify({
        modelObjectId: userState?.baseModelId,
        draftId: id,
        dataRepoObjectId: userState?.selectedRepo,
        modelName: ref?.current?.value,
        modelType: userState?.modelType,
        description: descRef?.current?.value,
        labels: userState?.selectedLabels?.map((item) => {
          let val = JSON.parse(item);
          return val?.labelName;
        }),
      });
      const response = await callApi({
        type: "post",
        endPoint: "selfserve/v2/project/v1/model/retrain/",
        toast: toast,
        requestBody: requestData,
      });
      if (response?.status === 200) {
        toast({
          title: "Retraining started",
          description:
            "success ETA: " + (response?.data?.eta / 60)?.toFixed(2) + " min",
          status: "success",
          duration: 6000,
          position: "top-right",
          isClosable: true,
        });
        navigate(
          location?.state?.prevPath?.includes("selectedView")
            ? -1
            : "/SandboxV2/model",
          {
            state: {
              prevPath: location?.pathname + location?.search,
            },
          }
        );
        mixpanelModelRetrainSubmitTrack({
          modelId: userState?.baseModelId,
          modelName: userState?.baseModelName,
          newModelId: userState?.modelId,
          newModelName: ref?.current?.value,
        });
      } else throw new Error("Retrain failed");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (name, value) => {
    setUserState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (modelId) getDraftApi();
    getData();
    getExistingModels();
  }, []);

  useEffect(() => {
    if (userState?.selectedRepo !== "") {
      getLabels();
    }
  }, [userState?.selectedRepo]);

  // select the repo if user added data
  useEffect(() => {
    if (repoId && draftSet && data?.length > 0) {
      handleChange("selectedRepo", repoId);
      handleChange(
        "selectedRepoData",
        data?.find((item) => item?.masterObjectId === repoId)
      );
    }
  }, [data, draftSet]);

  // select the labels if user added labels
  useEffect(() => {
    if (labelNames && labelType && draftSet && labelData?.length > 0) {
      let labels = JSON.parse(labelNames);
      handleChange(
        "selectedLabels",
        labelData
          ?.filter(
            (item) =>
              labels?.includes(item?.labelName) &&
              item?.annotationType === labelType?.toUpperCase()
          )
          ?.map((item) => JSON.stringify(item))
      );
    }
  }, [labelData, draftSet]);

  return (
    <div className="flex flex-col gap-2">
      <NavigateBack
        targetRoute={
          location?.state?.prevPath?.includes("selectedView")
            ? -1
            : "/SandboxV2/model"
        }
        textArray={["AI Studio", "Retrain", userState?.baseModelName]}
      />
      <TransitionCards>
        <p className="text-[#3E3C42] text-xl font-medium">Retrain model</p>
        <div className="flex flex-col gap-6 items-end w-[280px] xs:w-[350px]">
          {/*Name */}
          <div className="flex flex-col gap-3 w-full">
            <FieldLabel text="Enter model name" />
            <OutlinedInput
              inputRef={ref}
              value={userState?.modelName}
              onChange={(e) => handleChange("modelName", e?.target?.value)}
              placeholder="Name"
              size="small"
              sx={{
                width: "100%",
              }}
            />
          </div>

          {/*Description */}
          <div className="flex flex-col gap-3 w-full">
            <FieldLabel text="Enter Model description" />
            <TextField
              inputRef={descRef}
              value={userState?.modelDescription}
              onChange={(e) =>
                handleChange("modelDescription", e?.target?.value)
              }
              placeholder="model description"
              sx={{
                width: "100%",
              }}
              multiline
              rows={2}
            />
          </div>

          {/*Data repo */}
          <div className="flex flex-col gap-3 w-full">
            <FieldLabel text="Select data repo" />
            <div className="flex gap-2 items-center flex-wrap">
              {data?.length > 0 && (
                <>
                  <CustomSelect
                    setValue={(value) => {
                      handleChange("selectedRepo", value);
                      handleChange(
                        "selectedRepoData",
                        data?.find((item) => item?.masterObjectId === value)
                      );
                    }}
                    value={userState?.selectedRepo}
                    options={[
                      { name: "Select repo", masterObjectId: "" },
                      ...data,
                    ]}
                    isPlain={false}
                    displayEmpty={true}
                    displayKey="name"
                    valueKey="masterObjectId"
                    width="100%"
                    disable={
                      !userState?.modelName ||
                      !userState?.modelDescription ||
                      userState?.selectedLabels?.length > 0
                    }
                  />
                  <p>OR</p>
                </>
              )}
              <TonalButton
                text={"Add data"}
                width={"120px"}
                onClick={async () => {
                  let id = await draftPostApi();
                  navigate(`/SandboxV2/add/data?modelId=${id}`, {
                    state: {
                      prevPath: location?.pathname + location?.search,
                    },
                  });
                }}
                disable={
                  !userState?.modelName ||
                  !userState?.modelDescription ||
                  userState?.selectedLabels?.length > 0
                }
              />
            </div>
          </div>

          {/*Labels */}
          {userState?.selectedRepo !== "" && (
            <div className="flex flex-col gap-3 w-full">
              <FieldLabel text="Select labels" />
              <div className="flex gap-2 items-center flex-wrap">
                {labelData?.length > 0 && (
                  <>
                    <LabelSelect
                      width="100%"
                      value={userState?.selectedLabels}
                      setValue={(value) =>
                        handleChange("selectedLabels", value)
                      }
                      options={labelData}
                      disable={
                        !userState?.modelName || !userState?.modelDescription
                      }
                    />
                    <p>OR</p>
                  </>
                )}
                <TonalButton
                  text={"Add label"}
                  width={"120px"}
                  onClick={async () => {
                    let id = await draftPostApi();
                    navigate(
                      `/SandboxV2/add/label?repoId=${userState?.selectedRepo}&repoName=${userState?.selectedRepoData?.name}&dataType=${userState?.selectedRepoData?.dataRepositoryType}&modelId=${id}`,
                      {
                        state: {
                          prevPath: location?.pathname + location?.search,
                        },
                      }
                    );
                  }}
                  disable={
                    !userState?.modelName || !userState?.modelDescription
                  }
                />
              </div>
            </div>
          )}

          <div className="flex gap-2 items-center">
            <PrimaryButton
              text={"Re-Train"}
              width={"120px"}
              onClick={async () => {
                let id = await draftPostApi();
                if (id) startRetrain(id);
              }}
              disable={!userState?.modelName || !userState?.modelDescription}
            />
            <TonalButton
              text={"Save as draft"}
              width={"120px"}
              onClick={draftPostApi}
              disable={
                !userState?.modelName ||
                !userState?.modelDescription ||
                userState?.selectedLabels?.length > 0
              }
            />
          </div>
        </div>
      </TransitionCards>
      {ref?.current && (
        <CommonUniqueTextCheck
          valueKey="name"
          name={"Model name"}
          inputRefValue={ref}
          bypassValue={modelId ?? ""}
          bypassValueKey="masterObjectId"
          endPoint={"selfserve/v2/project/v1/model/"}
        />
      )}
    </div>
  );
};

export default RetrainModel;
