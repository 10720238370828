import AddIcon from "@mui/icons-material/Add";
import { Button } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AddSubscriptionsModal } from "../Modals/AddSubscriptionsModal";
import NavContext from "../../NavContext";
import TextButton from "../../../util/Buttons/TextButton";

const ActiveSubsFilters = ({
  organisation,
  handleStatus,
  setChangingbutton,
  changingbutton,
  clientId,
  fetchTableData,
  setSubHistoryChange,
  issubscriptionHistoryChanged,
  mode,
  clientOrg,
}) => {
  const { auth } = useContext(NavContext);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  return (
    <div className="flex justify-end gap-3 ">
      <div className="flex items-center">
        {((organisation === "Ripik.Ai" && mode != "view") ||
          (organisation !== "Ripik.Ai" && mode === "update")) && (
          <div className="ml-2 sm:ml-0">
            <TextButton
              onClick={() => setIsAddModalOpen(true)}
              text={"Add Subscription"}
              Icon={<AddIcon sx={{ fontSize: "medium" }} />}
            />
          </div>
        )}
      </div>
      {isAddModalOpen && organisation === "Ripik.Ai" && (
        <AddSubscriptionsModal
          isOpen={isAddModalOpen}
          onClose={() => {
            setIsAddModalOpen(false);
          }}
          clientId={clientId}
          fetchTableData={fetchTableData}
          setSubHistoryChange={setSubHistoryChange}
          issubscriptionHistoryChanged={issubscriptionHistoryChanged}
          clientOrg={clientOrg}
        />
      )}
    </div>
  );
};

export default ActiveSubsFilters;
