import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Card,
  CardContainer,
  CardTableToggle,
  Chip,
  CustomMenu,
  DataGridContainer,
  FieldLabel,
  IosSwitch,
} from "../Common/CommonComponents";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { callApi } from "../utils/ApiUtil";
import { useToast } from "@chakra-ui/react";
import {
  appStatusColors,
  appStatusIcons,
  debounce,
  filterHomePageComparator,
} from "../utils/HandlerFuncs";
import { CommonHomeFilters } from "../Common/CommonFilters";
import CommonRenameModal from "../Common/CommonRenameModal";
import {
  mixpanelAppEnableDisableTrack,
  mixpanelDeleteTrack,
} from "../utils/MixpanelEvents";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import {
  AppColumnDefinition,
  appFlexMap,
  formatColumns,
} from "../Common/CommonColDefine";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import {
  createTheme,
  IconButton,
  Skeleton,
  ThemeProvider,
} from "@mui/material";
import NavContext from "../../NavContext";
import Paginator from "../../../util/VisionUtils/Paginator";
import CommonWidgets from "../Common/CommonWidgets";

const AppHomeView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const { creationLimits } = useContext(NavContext);
  const [apps, setApps] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const activeAppCount = apps?.filter(
    (item) => item?.status !== "FAILED" && item?.status !== "DISABLED"
  )?.length;
  const rightRef = useRef();
  const leftRef = useRef();
  const [loading, setLoading] = useState(true);
  const [disableToggle, setDisableToggle] = useState({
    left: true,
    right: true,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const disableCreation = activeAppCount >= creationLimits?.appPerUserAllowed;
  const selectedView = searchParams?.get("selectedView")
    ? searchParams?.get("selectedView")
    : "card";
  const [renameModal, setRenameModal] = useState({
    open: false,
    oldName: null,
    id: null,
  });
  const [filterState, setFilterState] = useState({
    modelType: [],
    owner: [],
    date: "",
    search: "",
  });
  const filteredData = useMemo(
    () =>
      apps?.filter((item) => {
        let check = filterHomePageComparator({
          data: item,
          timeKey: "lastUpdatedAt",
          typeKey: "modelType",
          filterState: filterState,
        });
        return check;
      }),
    [apps, filterState]
  );
  const modelOptions = [
    { type: "classify" },
    { type: "segment" },
    { type: "detect" },
  ];

  const getApps = async () => {
    try {
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/",
        toast: toast,
      });
      if (response?.status === 200) {
        setApps(response?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteApp = async ({ id = "", name = "" }) => {
    try {
      const param = {
        masterObjectId: id,
      };
      const response = await callApi({
        type: "delete",
        endPoint: "selfserve/v2/project/v1/app/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "App deleted",
          status: "success",
          duration: 3000,
          position: "top-right",
        });
        getApps();
        mixpanelDeleteTrack({
          name: name,
          type: "app",
          id: id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const enableDisable = async ({ id = "", type = "", name = "" }) => {
    try {
      const param = {
        masterObjectId: id,
      };
      const response = await callApi({
        type: "post",
        endPoint: `selfserve/v2/project/v1/app/${type}/`,
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "App " + type + "d",
          status: "success",
          duration: 3000,
          position: "top-right",
        });
        getApps();
        mixpanelAppEnableDisableTrack({
          name: name,
          id: id,
          method: type,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = ({ val = "", id = "", name = "" }) => {
    switch (val) {
      case "Delete":
        deleteApp({ id: id, name: name });
        break;
      case "Rename":
        setRenameModal({
          open: true,
          oldName: name,
          id: id,
        });
        break;
      default:
        break;
    }
  };

  const handleViewChange = (val) => {
    if (val !== selectedView) {
      setSearchParams((prev) => {
        prev.set("selectedView", val);
        return prev;
      });
    }
  };

  const debouncedSetSearchData = debounce(
    (e) =>
      setFilterState((prev) => ({
        ...prev,
        search: e.target.value,
      })),
    300
  );

  useEffect(() => {
    getApps();
    let intervalId = setInterval(() => getApps(), 15 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleViewChange(selectedView);
  }, [selectedView]);

  const additionalActionCols = [
    {
      field: "actions",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => {
        let status = row?.status;
        let toggleChecked = status !== "FAILED" && status !== "DISABLED";
        let toggleDisabled =
          status === "FAILED" ||
          (creationLimits?.appPerUserAllowed <=
            apps?.filter(
              (item) => item?.status !== "FAILED" && item?.status !== "DISABLED"
            )?.length &&
            status === "DISABLED");
        let btnName =
          status === "DISABLED" || status === "FAILED" ? "Enable" : "Disable";
        return (
          <div className="flex justify-evenly items-center gap-1 w-full">
            <IconButton
              title="View"
              color="primary"
              onClick={() => {
                navigate(
                  `/SandboxV2/view/app?appId=${row?.objectMasterId}&modelId=${row?.modelObjectId}&appName=${row?.name}&status=${status}`,
                  {
                    state: {
                      prevPath: location?.pathname + location?.search,
                    },
                  }
                );
              }}
              disabled={!["READY", "FAILED", "DISABLED"]?.includes(row?.status)}
            >
              <VisibilityRoundedIcon />
            </IconButton>
            <IosSwitch
              checked={toggleChecked}
              disable={toggleDisabled}
              title={toggleChecked ? "Enabled" : "Disabled"}
              isConfirmPopover={true}
              handleClick={() =>
                enableDisable({
                  id: row?.objectMasterId,
                  name: row?.name,
                  type: btnName?.toLowerCase(),
                })
              }
            />
            <div className="relative w-10 h-10">
              <CustomMenu
                top="0px"
                right="0px"
                options={[
                  // {
                  //   type: "Share access",
                  //   icon: "/selfServiceIcons/share.svg",
                  // },
                  {
                    type: "Rename",
                    icon: "/edit.svg",
                  },
                  {
                    type: "Delete",
                    icon: "/delete.svg",
                  },
                ]}
                handleMenuClick={(val) =>
                  handleMenuClick({
                    val: val,
                    id: row?.objectMasterId,
                    name: row?.name,
                  })
                }
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <ThemeProvider theme={createTheme()}>
      <div className="flex flex-col gap-3 rounded-xl w-full">
        <CommonWidgets type="apps" tagLine="Create insightful apps" />
        <div className="flex flex-col gap-6 w-full items-end">
          <div className="w-full flex flex-col xl:flex-row justify-between gap-4 items-start xl:items-center">
            <p className="text-[#605D64] text-lg font-medium whitespace-nowrap w-fit">
              Your Live Applications (
              {activeAppCount + "/" + creationLimits?.appPerUserAllowed})
            </p>
            <CommonHomeFilters
              filterOptions={[
                {
                  label: "Model type",
                  displayKey: "type",
                  valueKey: "type",
                  options: modelOptions,
                  value: filterState?.modelType,
                  handleChange: (val, newVal) => {
                    setFilterState((prev) => ({
                      ...prev,
                      modelType: newVal,
                    }));
                  },
                },
                {
                  label: "Owner",
                  displayKey: "owner",
                  valueKey: "owner",
                  options: Array.from(
                    new Set(apps?.map((item) => item?.owner))
                  )?.map((item) => ({ owner: item })),
                  value: filterState?.owner,
                  handleChange: (val, newVal) => {
                    setFilterState((prev) => ({
                      ...prev,
                      owner: newVal,
                    }));
                  },
                },
                {
                  isDateSelect: true,
                  type: "date",
                  value: filterState?.date,
                  handleChange: (val) => {
                    setFilterState((prev) => ({
                      ...prev,
                      date: val,
                    }));
                  },
                  label: "Updated after",
                  max: new Date(),
                },
              ]}
              hasSearch={true}
              searchObj={{
                value: filterState?.search,
                setValue: debouncedSetSearchData,
              }}
            >
              <CardTableToggle
                setViewType={handleViewChange}
                ViewType={selectedView}
              />
            </CommonHomeFilters>
          </div>
          {selectedView === "card" ? (
            <CardContainer
              showLeft={!disableToggle?.left}
              showRight={!disableToggle?.right}
              leftFunc={() => leftRef?.current?.click()}
              rightFunc={() => rightRef?.current?.click()}
            >
              {loading ? (
                [...Array(8)]?.map((val, idx) => (
                  <Skeleton
                    variant="rectangular"
                    height={"250px"}
                    width={"100%"}
                    key={idx}
                  />
                ))
              ) : (
                <>
                  <Card
                    isAddbutton={true}
                    addText="Create new app"
                    disableAdd={disableCreation}
                    handleAdd={() =>
                      navigate("/SandboxV2/add/app", {
                        state: {
                          prevPath: location?.pathname + location?.search,
                        },
                      })
                    }
                  />
                  {displayData?.map((item, idx) => {
                    let { text, elem } = appStatusIcons({
                      status: item?.status,
                    });
                    let isString = typeof elem === "string";
                    let toggleChecked =
                      item?.status !== "FAILED" && item?.status !== "DISABLED";
                    let toggleDisabled =
                      item?.status === "FAILED" ||
                      (disableCreation && item?.status === "DISABLED");
                    let btnName =
                      item?.status === "DISABLED" || item?.status === "FAILED"
                        ? "Enable"
                        : "Disable";
                    let labels = item?.cameraDetail?.reduce((a, b) => {
                      return [...a, ...b?.labels];
                    }, []);
                    return (
                      <Card
                        key={idx}
                        heading={item?.name}
                        buttonInfo={[
                          {
                            text: "View",
                            type: "primary",
                            handleClick: () => {
                              navigate(
                                `/SandboxV2/view/app?appId=${item?.objectMasterId}&modelId=${item?.modelObjectId}&appName=${item?.name}&status=${item?.status}`,
                                {
                                  state: {
                                    prevPath:
                                      location?.pathname + location?.search,
                                  },
                                }
                              );
                            },
                            disable: !["READY", "FAILED", "DISABLED"]?.includes(
                              item?.status
                            ),
                          },
                          {
                            elem: (
                              <div className="flex gap-3 w-full h-full justify-center items-center rounded-lg bg-[#DEF]">
                                <FieldLabel
                                  text={toggleChecked ? "Enabled" : "Disabled"}
                                  isRequired={false}
                                  color="#3A74CA"
                                />
                                <IosSwitch
                                  key={idx}
                                  title={toggleChecked ? "Enabled" : "Disabled"}
                                  checked={toggleChecked}
                                  disable={toggleDisabled}
                                  isConfirmPopover={true}
                                  handleClick={() =>
                                    enableDisable({
                                      id: item?.objectMasterId,
                                      name: item?.name,
                                      type: btnName?.toLowerCase(),
                                    })
                                  }
                                />
                              </div>
                            ),
                          },
                        ]}
                        labels={labels}
                        status={text}
                        bgImage={item?.sampleImageUrl}
                        statusColor={
                          appStatusColors({ status: item?.status })?.hex
                        }
                        statusIcon={
                          isString ? (
                            <img
                              src={elem}
                              alt="status"
                              className="w-full h-full"
                            />
                          ) : (
                            elem
                          )
                        }
                      >
                        <Chip
                          top={"12px"}
                          right={"12px"}
                          customElem={
                            <CustomMenu
                              position="block"
                              color="#48464C"
                              options={[
                                {
                                  type: item?.owner,
                                  icon: null,
                                },
                                {
                                  type: "Rename",
                                  icon: "/edit.svg",
                                },
                                {
                                  type: "Delete",
                                  icon: "/delete.svg",
                                },
                              ]}
                              handleMenuClick={(val) =>
                                handleMenuClick({
                                  val: val,
                                  id: item?.objectMasterId,
                                  name: item?.name,
                                })
                              }
                            />
                          }
                        />
                      </Card>
                    );
                  })}
                </>
              )}
            </CardContainer>
          ) : (
            <DataGridContainer
              showAdd={true}
              addText="Create new app"
              disableAdd={disableCreation}
              addUrl="/SandboxV2/add/app"
            >
              <CustomStyledDataGrid
                rows={filteredData}
                columns={formatColumns({
                  cols: [...AppColumnDefinition, ...additionalActionCols],
                  color: "blue",
                  flexMap: appFlexMap,
                })}
                autosizeOptions={{
                  columns: AppColumnDefinition?.map((item) => item.field),
                  includeOutliers: true,
                  includeHeaders: true,
                }}
                getRowId={(row) => row?.objectMasterId}
                pagination
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5, 15, 25]}
              />
            </DataGridContainer>
          )}
          {selectedView === "card" && (
            <Paginator
              data={filteredData}
              limit={7}
              initialPage={
                filteredData?.length === apps?.length ? currentPage : 1
              }
              setCurrentPage={setCurrentPage}
              setDisplayData={setDisplayData}
              leftRef={leftRef}
              rightRef={rightRef}
              setDisableToggle={setDisableToggle}
              additionalStyles={{
                style: {
                  boxShadow:
                    "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
                  backgroundColor: "#FFF",
                  border: "1px solid #CAC5CD",
                  borderRadius: "8px",
                },
              }}
            />
          )}
        </div>
        {renameModal?.open && renameModal?.id && (
          <CommonRenameModal
            openModal={renameModal?.open}
            closeModal={() =>
              setRenameModal({
                open: false,
                oldName: null,
                id: null,
              })
            }
            type="app"
            oldName={renameModal?.oldName}
            objectId={renameModal?.id}
            fetchCall={getApps}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default AppHomeView;
