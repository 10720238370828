import {
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { baseURL } from "../../../index";
import { useContext, useState } from "react";
import axios from "axios";
import NavContext from "../../NavContext";

const TrialPlan = () => {
  return (
    <div className="p-4 rounded-t-2xl bg-inherit">
      <img src="/admin/trialPlan.svg" alt="" />
      <p className="mt-1 font-[700] text-[20px] text-[#3E3C42]">Trial Plan</p>

      <div className="flex flex-col gap-4">
        <p className="text-[#938F96] text-base font-[700] mt-9">
          What’s included
        </p>
        <div className="flex items-center gap-2 font-bold">
          <Checkbox defaultChecked colorScheme="green" readOnly />
          <p className="text-[#3E3C42]">Create 3 data sets</p>
        </div>
        <div className="flex items-center gap-2 font-bold">
          <Checkbox defaultChecked colorScheme="green" readOnly />
          <p className="text-[#3E3C42]">Create 1 Project</p>
        </div>
        <div className="flex items-center gap-2 font-bold">
          <Checkbox defaultChecked colorScheme="green" readOnly />
          <p className="text-[#3E3C42]">Create 1 Project</p>
        </div>
      </div>
    </div>
  );
};

const StandardPlan = () => {
  return (
    <div className="p-4 rounded-t-2xl bg-inherit">
      <img src="/admin/standardPlan.svg" alt="" />
      <p className="mt-1 font-[700] text-[20px] text-[#3E3C42]">
        Standard Plan
      </p>
      <p className="text-[#3E3C42] text-[42px] font-extrabold">$500</p>
      <p className="text-[#938F96] text-xl">Per Live Inference feed</p>
      <div className="flex flex-col gap-4">
        <p className="text-[#938F96] text-base font-[700] mt-9">
          What’s included
        </p>
        <div className="flex items-center gap-2 font-bold">
          <Checkbox defaultChecked colorScheme="green" readOnly />
          <p className="text-[#3E3C42]">Create 10 data sets</p>
        </div>
        <div className="flex items-center gap-2 font-bold">
          <Checkbox defaultChecked colorScheme="green" readOnly />
          <p className="text-[#3E3C42]">Create 5 Projects</p>
        </div>
        <div className="flex items-center gap-2 font-bold">
          <Checkbox defaultChecked colorScheme="green" readOnly />
          <p className="text-[#3E3C42]">Create 2 Applications</p>
        </div>
      </div>
    </div>
  );
};

const EnterprisePlan = () => {
  return (
    <div className="p-4 rounded-2xl bg-inherit">
      <img src="/admin/enterprisePlan.svg" alt="" />
      <p className="mt-1 font-[700] text-[20px] text-[#3E3C42]">
        Enterprise Plan
      </p>
      <p className="text-[#3E3C42] text-[42px] font-extrabold">$800</p>
      <p className="text-[#938F96] text-xl">Per Live Inference feed</p>
      <div className="flex flex-col gap-4">
        <p className="text-[#938F96] text-base font-[700] mt-9">
          What’s included
        </p>
        <div className="flex items-center gap-2 font-bold">
          <Checkbox defaultChecked colorScheme="green" readOnly />
          <p className="text-[#3E3C42]">Unlimited data sets</p>
        </div>
        <div className="flex items-center gap-2 font-bold">
          <Checkbox defaultChecked colorScheme="green" readOnly />
          <p className="text-[#3E3C42]">Create 20 Projects</p>
        </div>
        <div className="flex items-center gap-2 font-bold">
          <Checkbox defaultChecked colorScheme="green" readOnly />
          <p className="text-[#3E3C42]">Create 10 Applications</p>
        </div>
      </div>
    </div>
  );
};

const SubscriptionPlanModal = ({
  modalData,
  closeModal,
  plantType = "trial",
  clientId = "",
  subscriptionId = "",
  fetchTableData = () => {},
}) => {
  const size = useWindowSize();
  const toast = useToast();
  const { auth, clientId: userClientId } = useContext(NavContext);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [toggleSwitch, setToggleSwitch] = useState(true);

  const plans = [
    {
      tier: "trial",
      ele: <TrialPlan />,
    },
    {
      tier: "standard",
      ele: <StandardPlan />,
    },
    {
      tier: "enterprise",
      ele: <EnterprisePlan />,
    },
  ];

  const handleToggle = (e) => {
    if (e.target.checked) {
      setToggleSwitch(true);
    } else {
      setToggleSwitch(false);
    }
  };

  const saveUpgradeRequest = async () => {
    if (!selectedPlan) {
      toast({
        title: "Please select plan",
        status: "error",
        position: "top-right",
        duration: 3000,
      });
      return;
    }
    try {
      const requestData = JSON.stringify({
        clientId: clientId ? clientId : userClientId,
        subscriptionId: subscriptionId,
        subscriptionType: selectedPlan?.toUpperCase(),
      });
      const response = await axios.post(
        baseURL + "v2/aisandbox/subscription/requestSubscriptionTypeUpdate/",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status === 200) {
        toast({
          title: "Upgrade tier request submitted",
          description: "Please view your request in user request tab",
          status: "success",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });
        fetchTableData();
        setTimeout(() => closeModal(), 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const plansComp = plans?.map((item, idx) => {
    let currentSelection = item?.tier === plantType;
    let selected = item?.tier === selectedPlan;
    return (
      <div
        className={`min-w-[300px] md:min-w-[33%] min-h-[500px] rounded-2xl border border-[#AEA9B1] ${
          !(selected || currentSelection) ? "hover:bg-[#DEF]" : "bg-white"
        }`}
        style={{
          cursor: !currentSelection ? "pointer" : "default",
        }}
        key={idx}
      >
        {currentSelection && (
          <div className="flex justify-center bg-[#34A853] w-full text-white rounded-t-2xl h-[50px]">
            <p className="text-[#FFF] p-2 font-semibold">Your Current Plan</p>
          </div>
        )}
        {selected && (
          <div className="flex justify-center bg-blue-500 w-full text-white rounded-t-2xl h-[50px]">
            <p className="text-[#FFF] p-2 font-semibold">Your Selection</p>
          </div>
        )}
        <div
          style={{
            marginTop: currentSelection || selected ? "-12px" : "0",
          }}
          className="bg-inherit rounded-2xl"
          onClick={() =>
            currentSelection ? null : setSelectedPlan(item?.tier)
          }
        >
          {item?.ele}
        </div>
      </div>
    );
  });

  return (
    <>
      {size.width >= 768 ? (
        <Modal isOpen={modalData?.open} onClose={closeModal} isCentered="true">
          <ModalOverlay />
          <ModalContent
            style={{ borderRadius: "12px" }}
            maxW={{ md: "95%", lg: "73%", xl: "58%", "2xl": "50%" }}
          >
            <ModalHeader padding="0px">
              <div className="p-4 rounded-2xl">
                <div className="flex justify-between items-center">
                  <p className=" font-semibold">Select Plan</p>
                  <div className="flex gap-4">
                    <div className="flex gap-1 items-center">
                      <Switch
                        id="email-alerts"
                        isChecked={toggleSwitch}
                        colorScheme="green"
                        onChange={handleToggle}
                      />
                      <p className=" font-medium text-base text-[#605D64]">
                        {toggleSwitch ? "Yearly" : "Monthly"}
                      </p>
                    </div>
                    <button className="pb-1" onClick={closeModal}>
                      <HighlightOffIcon />
                    </button>
                  </div>
                </div>
              </div>
            </ModalHeader>
            <ModalBody pos="relative" px="20px">
              <div className="flex gap-4 justify-center items-center p-2">
                {plansComp}
              </div>
              <div className="flex justify-end mt-5 mb-2">
                <div>
                  <PrimaryButton
                    text={"Submit upgrade request"}
                    onClick={saveUpgradeRequest}
                  />
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer onClose={closeModal} isOpen={modalData?.open} size="full">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader padding="0px">
              <div className="p-4 rounded-2xl ">
                <div className="flex justify-between items-center">
                  <p className=" font-semibold">Select Plan</p>
                  <div className="flex gap-4">
                    <div className="flex gap-1 items-center">
                      <Switch
                        id="email-alerts"
                        isChecked={toggleSwitch}
                        colorScheme="green"
                        onChange={handleToggle}
                      />
                      <p className=" font-medium text-base text-[#605D64]">
                        {toggleSwitch ? "Yearly" : "Monthly"}
                      </p>
                    </div>
                    <button className="pb-1" onClick={closeModal}>
                      <HighlightOffIcon />
                    </button>
                  </div>
                </div>
              </div>
            </DrawerHeader>
            <DrawerBody padding="0px">
              <div className="flex flex-col gap-4 justify-center items-center p-2">
                {plansComp}
              </div>
              <div className="flex justify-end mt-5 mb-2 mr-4">
                <div>
                  <PrimaryButton
                    text={"Submit upgrade request"}
                    onClick={saveUpgradeRequest}
                  />
                </div>
              </div>
            </DrawerBody>
            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default SubscriptionPlanModal;
