import React from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  Flex,
  useToast,
  Spinner,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
// import { AddNewModal, DeleteUserModal } from "./UserModals";
import { useState, useContext } from "react";
import { useEffect } from "react";
import NavContext from "../../NavContext";
import axios from "axios";
import { baseURL } from "../../..";

import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";

import UserMngmtTable from "../Tables/userMngmtTable";
import { useNavigate } from "react-router-dom";
import { AddBulkUsersModal } from "../Modals/AddBulkUsersModal";
import { AddNewModal, DeleteUserModal } from "../Modals/UserModals";
import CustomSelector from "../components/CustomSelector";

const UserMgmt = ({ clientId = "", clientOrg, mode }) => {
  // const { clientOrg, clientId, mode } = useParams();
  const size = useWindowSize();

  const { auth, clientId: userClientId } = useContext(NavContext);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [send, setSend] = useState([]);
  const [clientUpdate, setClientUpdate] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [displayUsers, setDisplayUsers] = useState([]);
  const [downloadData, setDownloadData] = useState({});
  const [loading, setLoading] = useState(false);
  const [organisation, setOrganisation] = useState("");
  const [status, setStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [downloadBulk, setDownloadBulk] = useState([
    {
      NAME: "Ashok Kumar",
      "EMAIL ID": "ashok123@gmail.com",
      ROLE: "Admin",
      DESIGNATION: "CXO",
      "PRIMARY LOCATION": "Pune",
      "PHONE NUMBER": "--",
    },
    {
      NAME: "John Doe",
      "EMAIL ID": "john.doe@gmail.com",
      ROLE: "Regular",
      DESIGNATION: "Manager",
      "PRIMARY LOCATION": "Pune",
      "PHONE NUMBER": "9876354xxx",
    },
    {
      NAME: "Jane Smith",
      "EMAIL ID": "jane.smith@gmail.com",
      ROLE: "Admin",
      DESIGNATION: "Sr. Manager",
      "PRIMARY LOCATION": "Pune",
      "PHONE NUMBER": "9876354xxx",
    },
  ]);
  const [order, setOrder] = useState([
    "NAME",
    "EMAIL ID",
    "ROLE",
    "DESIGNATION",
    "PRIMARY LOCATION",
    "PHONE NUMBER",
  ]);
  const fetchUsers = async () => {
    const param = {
      organisation: clientOrg || organisation,
    };
    try {
      const response = await axios.get(baseURL + "iam/users", {
        credentials: "same-origin",
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      const sortedUsers = [...response?.data];
      sortedUsers.sort((a, b) => {
        const dateA = new Date(a.createdat);
        const dateB = new Date(b.createdat);

        // Compare timestamps in descending order (newest first)
        return dateB - dateA;
      });

      // setUsers(sortedUsers);
      const organisation = sortedUsers[0].organisation;
      // setOrganisation(organisation); //setting organisation
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDownloadApi = async () => {
    const header = { header: "users" };
    try {
      const response = await axios.post(baseURL + "iam/header", header, {
        headers: {
          "Content-Type": "application/json",
          "X-auth-Token": auth,
        },
      });

      //setting order for downloading data
      setDownloadData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (auth) {
      setLoading(true);
      // fetchUsers();
      fetchDownloadApi();
      fetchStatus();
    }
  }, [auth]);

  useEffect(() => {
    setDisplayUsers(users);
  }, [users]);

  const [isOpenD, setIsOpenD] = useState(false);

  const onCloseD = () => {
    setIsOpenD(false);
  };

  const [isOpenA, setIsOpenA] = useState(false);

  const onCloseA = () => {
    setIsOpenA(false);
  };

  const [isOpenE, setIsOpenE] = useState(false);

  const onCloseE = () => {
    setIsOpenE(false);
  };
  const [isOpenB, setIsOpenB] = useState(false);
  const onCloseB = () => {
    setIsOpenB(false);
  };
  const [contact, setContact] = useState();
  const [fullName, setFullName] = useState("");
  const [whatsapp, setWhatsapp] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [designation, setDesignation] = useState("");
  const [baseLocation, setBaseLocation] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [emailInvitation, setEmailInvitation] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [isDeleted, setDelete] = useState(false);

  const [error, setError] = useState({
    name: "",
    email: "",
  });
  const [isReactivated, setReactivate] = useState({
    reactivated: false,
    isDeactivated: "",
  });
  const toast = useToast();
  const getLocations = async () => {
    try {
      const param = {
        clientId: clientId ? clientId : userClientId,
      };
      const response = await axios.get(baseURL + "v2/location/", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response?.status === 200) {
        let value = response?.data;
        setClientLocations(value);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleLocationChange = (event, obj) => {
    const { props } = obj;
    let value = props?.value;
    if (value === "all") {
      if (clientLocations?.length === baseLocation?.length)
        setBaseLocation((prev) => []);
      else setBaseLocation((prev) => [...clientLocations]);
    } else {
      setBaseLocation((prev) => {
        let newData = JSON.parse(JSON.stringify(prev));
        let idx = newData?.findIndex(
          (item) => item?.locationId === value?.locationId
        );
        if (idx !== -1) newData?.splice(idx, 1);
        else newData?.push(value);
        return newData;
      });
    }
  };
  const fetchStatus = async () => {
    try {
      const param = {
        organisation: clientOrg || organisation,
      };
      const response = await axios.get(baseURL + `iam/status`, {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-auth-Token": auth,
        },
      });
      const sortedUsers = response?.data?.data;
      sortedUsers.sort((a, b) => {
        const dateA = new Date(a.createdat);
        const dateB = new Date(b.createdat);

        // Compare timestamps in descending order (newest first)
        return dateB - dateA;
      });

      setUsers(sortedUsers);

      const org = sortedUsers[0].organisation;
      setOrganisation(org); //setting organisation
      setStatus(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async (userID) => {
    try {
      let data = JSON.stringify({
        userid: userID,
      });

      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: baseURL + "iam/users",
        headers: {
          "x-auth-token": auth,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response.status === 200) {
        // fetchUsers();
        fetchStatus();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const postBulkUpload = async () => {
    const param = { organisation: clientOrg || "" };

    try {
      let requestBody = new FormData();
      requestBody.append("file", send[0]);
      const response = await axios.post(
        baseURL + "iam/bulkAddUser",
        requestBody,

        {
          params: param,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": auth,
          },
        }
      );
      // fetchUsers();
      fetchStatus();
      toast({
        title: `File has been uploaded`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      // console.log(response.data);
    } catch (error) {
      toast({
        title: `${error.response.data.data}`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      console.log(error.response.data.data);
    }
  };

  useEffect(() => {
    // Enable the button if email and name are not empty
    setDisabled(!(userEmail && fullName));
  }, [userEmail, fullName]);

  // Function to validate email using regex
  const isValidEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  };

  const isValidName = (name) => {
    // Regular expression for a simple name validation
    // const nameRegex = /^[a-zA-Z]{2,30}(?: [a-zA-Z]{2,30})*$/;
    const nameRegex = /^[a-zA-Z ]{2,60}$/;

    return nameRegex.test(name.trim());
  };

  const patchUser = async () => {
    // Validate email and name before editing user details
    if (
      !userEmail ||
      !isValidEmail(userEmail) ||
      !fullName ||
      !isValidName(fullName) ||
      baseLocation?.length === 0
    ) {
      // Display an error message or handle it as per your UI/UX
      if (!userEmail || !isValidEmail(userEmail)) {
        setError((prevData) => ({
          ...prevData,
          email: "Please enter a valid email",
        }));
      }
      if (!fullName || !isValidName(fullName)) {
        setError((prevData) => ({
          ...prevData,
          name: "Please enter a valid name",
        }));
        // setError("Please enter a valid email.");
      }
      if (baseLocation?.length === 0)
        toast({
          title: "Please select location",
          status: "error",
          position: "top-right",
          duration: 3000,
        });
      return;
    }

    // Clear any previous error
    setError("");

    let requestData = {
      fullname: fullName?.trim(),
      email: userEmail?.trim(),
      phoneNumber: contact,
      role: userRole,
      userid: selectedUser.userid,
      baseLocation: "",
      locationIdList: baseLocation?.map((item) => item?.locationId),
      designation: designation,
    };

    if (isReactivated.reactivated) {
      requestData.isDeleted = false;
    } else {
      requestData.isDeleted = isDeleted;
    }
    requestData = JSON.stringify(requestData);
    const response = await axios
      .patch(baseURL + "iam/users", requestData, {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // fetchUsers();
          fetchStatus();
          setReactivate((prev) => ({
            ...prev,
            reactivated: false,
          }));
          onCloseE();
          toast({
            title: `User details has been Edited`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: `Unable to edit this user's details`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    console.log(contact);
  }, [contact]);

  useEffect(() => {
    if (selectedUser) {
      setContact(selectedUser.phoneNumber);
    }
  }, [selectedUser]);

  useEffect(() => {
    let temp = users.filter((user) => {
      return (
        user?.username?.slice(0, search?.length)?.toLowerCase() ===
          search?.toLowerCase() ||
        // (user?.email?.includes("@") &&
        //   user?.email?.split("@")[1]?.slice(0, search.length)?.toLowerCase() ===
        //     search?.toLowerCase())
        user?.email?.toLowerCase().includes(search?.toLowerCase())
      );
    });
    setDisplayUsers(temp);
  }, [search, users]);

  const handleBackButton = () => {
    mode === "update"
      ? navigate(`/superadmin/update/${clientId}`)
      : navigate(`/superadmin/viewClient/${clientId}`);
  };
  const handleDeactivateToggle = (e) => {
    const isChecked = e.target.checked;
    setReactivate((prev) => ({
      ...prev,
      reactivated: isChecked,
    }));
  };

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <>
      <div className={`w-full px-2 !font-roboto`}>
        <div className="flex flex-col 2xl:flex-row justify-between">
          <div className="flex flex-col md:flex-row justify-start gap-6 items-center md:items-start">
            <div className="flex flex-row justify-start gap-6 items-start">
              <div className="flex flex-col">
                <p className="text-lg font-semibold text-[#605D64]">
                  {/* {users?.length} */}
                  {status.Total || 0}
                </p>
                <p className="text-[#938F96]">Total</p>
              </div>
              <div className="flex flex-col">
                <p className="text-lg font-semibold text-[#605D64]">
                  {/* {users?.filter((elem) => elem?.isactive).length} */}
                  {status.Active || 0}
                </p>
                <p className="text-[#938F96]">Active</p>
              </div>
              <div className="flex flex-col">
                <p className="text-lg font-semibold text-[#605D64]">
                  {/* {users?.filter((elem) => !elem?.isactive).length} */}
                  {status.InActive || 0}
                </p>
                <p className="text-[#938F96]">
                  Inactive{size.width >= 1280 && <span> Last Week</span>}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-lg font-semibold text-[#605D64]">
                  {/* {users?.filter((elem) => elem?.isDeleted).length} */}
                  {status.Deleted || 0}
                </p>
                <p className="text-[#938F96]">Deleted</p>
              </div>
            </div>
            <div className="w-full h-10 xl:w-[320px] flex flex-row items-center border-2 py-2 rounded px-4 justify-between">
              <input
                className="w-full focus:outline-none text-sm"
                placeholder="Search email ID/name"
                onChange={(e) => setSearch(e.target.value)}
              />
              <img className="h-5 text-black" src="/search.svg" />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-start md:justify-end 2xl:justify-start items-end gap-6">
            {/* <div className="w-full xl:w-[320px] flex flex-row border-2 py-2 rounded px-4 justify-between">
              <input
                className="w-full focus:outline-none text-sm"
                placeholder="Search email ID/name"
                onChange={(e) => setSearch(e.target.value)}
              />
              <img className="h-5 text-black" src="/search.svg" />
            </div> */}
            <div className="flex gap-1 flex-col md:flex-row lg:gap-6 items-end">
              {clientOrg && mode === "view" ? (
                ""
              ) : (
                <>
                  <div>
                    <Button
                      onClick={() => setIsOpenA(true)}
                      className="!border-0 !text-[#1C56AC] !text-sm gap-1 !bg-white"
                    >
                      <AddIcon />
                      <span>Add New User</span>
                    </Button>
                    <Button
                      onClick={() => setIsOpenB(true)}
                      className="!border-0 !text-[#1C56AC] !text-sm gap-1 !bg-white"
                    >
                      <AddIcon />
                      <span>Bulk Add Users</span>
                    </Button>
                  </div>
                </>
              )}
              {displayUsers.length > 0 && (
                <ExlCsvDownload
                  data={displayUsers}
                  order={downloadData?.summary}
                  orderDetail={downloadData?.detail}
                  enable={true}
                />
              )}

              {/* <Paginator
                data={displayUsers}
                setDisplayData={setDisplayData}
                limit={10}
              /> */}
            </div>
          </div>
        </div>

        {loading ? (
          <div className="ml-[50%]">
            <Spinner speed="0.65s" />
          </div>
        ) : (
          <React.Fragment>
            {displayUsers && displayUsers.length !== 0 ? (
              <UserMngmtTable
                rowData={displayUsers}
                setIsOpenE={setIsOpenE}
                setIsOpenD={setIsOpenD}
                setSelectedUser={setSelectedUser}
                setFullName={setFullName}
                setUserEmail={setUserEmail}
                setDesignation={setDesignation}
                setBaseLocation={setBaseLocation}
                setUserRole={setUserRole}
                setContact={setContact}
                clientOrg={clientOrg}
                clientUpdate={clientUpdate}
                mode={mode}
                setReactivate={setReactivate}
                // fetchUsers={fetchUsers}
                setDelete={setDelete}
              />
            ) : (
              <p className="ml-[45%]">No data available</p>
            )}
          </React.Fragment>
        )}
      </div>

      {isOpenD && (
        <DeleteUserModal
          isOpen={isOpenD}
          onClose={onCloseD}
          userID={selectedUser?.userid}
          // fetchUsers={fetchUsers}
          fetchStatus={fetchStatus}
        />
      )}
      {isOpenA && (
        <AddNewModal
          isOpen={isOpenA}
          onClose={onCloseA}
          // fetchUsers={fetchUsers}
          clientId={clientId ? clientId : userClientId}
          clientOrg={clientOrg}
          fetchStatus={fetchStatus}
        />
      )}
      {isOpenB && (
        <AddBulkUsersModal
          isOpen={isOpenB}
          onClose={onCloseB}
          size={"3xl"}
          setSend={setSend}
          send={send}
          postBulkUpload={postBulkUpload}
          data={downloadBulk}
          order={order}
          headertext="Users"
          buttontext="Add"
        />
      )}
      {isOpenE && (
        <Modal
          isOpen={isOpenE}
          onClose={onCloseE}
          isCentered
          size={"sm"}
          width={740}
        >
          <ModalOverlay zIndex={1200} />
          <ModalContent containerProps={{ zIndex: 1200 }}>
            <div className="text-white w-full h-10 flex bg-[#2660B6] font-semibold justify-center items-center rounded-t-md">
              Edit User
            </div>
            <ModalCloseButton color={"white"} />
            <ModalBody className="mt-6">
              <Flex flexDirection={"column"} gap={"30px"}>
                <FormControl className="!h-12">
                  <div className="text-xs text-[#2660B6] mb-2 font-semibold">
                    Full Name<span className="text-red-500 ml-1">*</span>
                  </div>
                  <input
                    className="w-full border rounded text-sm border-[#938F96] py-2 px-2 capitalize"
                    placeholder="Enter full name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  {error?.name && !isValidName(fullName) && (
                    <p className=" !mb-1 !text-[red] !text-sm">{error?.name}</p>
                  )}
                </FormControl>

                <FormControl className="!h-12">
                  <div className="text-xs text-[#2660B6] mb-2 font-semibold">
                    E-mail ID<span className="text-red-500 ml-1">*</span>
                  </div>
                  <input
                    className="w-full border rounded text-sm border-[#938F96] py-2 px-2"
                    placeholder="Enter valid email ID"
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                      // setError((prevData) => ({
                      //   ...prevData,
                      //   email: "",
                      // }));
                    }}
                  />
                  {error?.email && !isValidEmail(userEmail) && (
                    <p className="!mb-1 !text-[red] !text-sm">{error?.email}</p>
                  )}
                </FormControl>

                <FormControl className="!h-12">
                  <div className="text-xs text-[#2660B6] mb-2 font-semibold">
                    Phone Number{" "}
                    {/* <span className="text-[#CAC5CD] text-xs mr-1">
                      ( optional )
                    </span> */}
                  </div>
                  <input
                    className="w-full border rounded text-sm border-[#938F96] py-2 px-2"
                    placeholder="Enter valid phone number"
                    value={contact}
                    onChange={(event) => setContact(event.target.value)}
                  />
                </FormControl>
                <FormControl className="!h-12 mb-2 font-semibold">
                  <div className="text-xs text-[#2660B6] mb-2 font-semibold">
                    Role
                  </div>
                  <select
                    value={userRole}
                    onChange={(e) => setUserRole(e.target.value)}
                    className="w-full border rounded text-sm border-[#938F96] py-2 px-1"
                  >
                    <option value={"ADMIN"}>Admin</option>
                    <option value={"USER"}>Regular</option>
                    {/* <option value={"Super Admin"}>CXO</option> */}
                  </select>
                  {/* <Input placeholder="Enter Your Name" /> */}
                </FormControl>
                <FormControl className="!h-12 mb-2 font-semibold">
                  <div className="text-xs text-[#2660B6] mb-2 font-semibold">
                    Designation
                  </div>
                  <select
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    className="w-full overflow-auto border rounded text-sm border-[#938F96] py-2 px-1"
                  >
                    <option value={""}>Select one</option>

                    <option value={"CXO"}>CXO</option>

                    <option value={"Senior Director"}>Senior Director</option>
                    <option value={"Associate Director"}>
                      Associate Director
                    </option>
                    <option value={"Director"}>Director</option>
                    <option value={"President"}>President</option>

                    <option value={"Senior Vice President"}>
                      Senior Vice President
                    </option>
                    <option value={"Vice President"}>Vice President</option>
                    <option value={"Associate Vice President"}>
                      Associate Vice President
                    </option>
                    <option value={"Senior Partner"}>Senior Partner</option>

                    <option value={"Associate Partner"}>
                      Associate Partner
                    </option>
                    <option value={"Partner"}>Partner</option>
                    <option value={"Manager"}>Manager</option>
                    <option value={"Senior Manager"}>Senior Manager</option>
                    <option value={"Plant Head"}>Plant Head</option>
                    <option value={"Shift Manager"}>Shift Manager</option>
                    <option value={"Plant Operator"}>Plant Operator</option>
                    <option value={"Business Analyst"}>Business Analyst</option>
                    <option value={"Consultant"}>Consultant</option>
                    <option value={"Corporate Staff"}>Corporate Staff</option>
                    <option value={"IT Analyst"}>IT Analyst</option>
                    <option value={"IT Developer"}>IT Developer</option>
                    <option value={"IT Manager"}>IT Manager</option>
                    <option value={"Plant Manager"}>Plant Manager</option>
                    <option value={"Mill Operator"}>Mill Operator</option>
                    <option value={"Automation Team"}>Automation Team</option>
                    <option value={"Automation Staff"}>Automation Staff</option>
                    <option value={"Equipment Operator"}>
                      Equipment Operator
                    </option>
                    <option value={"Device Operator"}>Device Operator</option>
                    <option value={"Other"}>Other</option>
                  </select>
                  {/* <Input placeholder="Enter Your Name" /> */}
                </FormControl>
                <FormControl className="!h-12">
                  <div className="text-xs text-[#2660B6] mb-2 font-semibold">
                    Base location <span className="text-red-700">*</span>
                    {/* <span className="text-[#CAC5CD] text-xs m ml-1">
                      ( optional )
                    </span> */}
                  </div>
                  <CustomSelector
                    formData={{
                      allLocations: baseLocation,
                    }}
                    selectData={{
                      allLocations: clientLocations,
                    }}
                    handleLocationChange={handleLocationChange}
                  />
                </FormControl>
                {isReactivated.isDeactivated && (
                  <div className="flex">
                    <Checkbox
                      isChecked={isReactivated.reactivated}
                      onChange={handleDeactivateToggle}
                      // onClick={handleCheckboxClick}
                      // isDisabled={status == 2 ? true : false}
                      mr={"4px"}
                    >
                      Reactivate
                    </Checkbox>
                  </div>
                )}
                {/* <div className="flex flex-col items-start gap-2 text-xs font-light"> */}
                {/* <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      disabled={contact?.length !== 10}
                      onSelect={() => setWhatsapp(!whatsapp)}
                    />
                    Enable WhatsApp Integration
                  </div> */}
                {/* <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  onSelect={() => setEmailInvitation(!emailInvitation)}
                />
                Send Invitation Email
              </div> */}
                {/* </div> */}
                {/* Display error message if there is any */}
                {/* {error && <div className="text-red-500 mt-1">{error}</div>} */}
              </Flex>
            </ModalBody>
            <ModalFooter className="!w-full !flex !flex-row !items-center !justify-start !gap-2">
              {/* <button
              onClick={() => {
                patchUser();
                onCloseE();
              }}
              className="bg-[#084298] text-sm h-10 text-white px-7 py-2 rounded-md mb-5 "
              mr={3}
            >
              Save
            </button> */}
              <Button
                isDisabled={
                  disabled || (!isReactivated.reactivated && isDeleted)
                } // Disable the button if there is an error
                onClick={() => {
                  patchUser();
                  // onCloseE();
                }}
                bg="#084298"
                color="white"
                size="sm"
                height="10"
                px="7"
                py="2"
                rounded="md"
                mb="5"
                mr="3"
                _hover={{ bg: "#084298", color: "white" }}
              >
                Save
              </Button>
              <button
                onClick={() => {
                  deleteUser(selectedUser?.userid);
                  onCloseE();
                }}
                className="text-sm h-10 border  bg-white px-5 py-2 rounded-md mb-5 "
                style={{
                  backgroundColor: selectedUser.isDeleted ? "#D3D3D3" : "white",
                  borderColor: selectedUser.isDeleted ? "none" : "#DC362E",
                  color: selectedUser.isDeleted ? "gray" : "#DC632E",
                }}
                mr={3}
                disabled={selectedUser?.isDeleted}
              >
                Delete
              </button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default UserMgmt;
