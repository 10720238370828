import { IconButton } from "@mui/material";
import { DataGridContainer, NavigateBack } from "../../Common/CommonComponents";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import {
  alertSettingsColumnDefinition,
  alertSettingsFlexMap,
  formatColumns,
} from "../../Common/CommonColDefine";
import { CustomStyledDataGrid } from "../../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { callApi } from "../../utils/ApiUtil";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { mixpanelDeleteTrack } from "../../utils/MixpanelEvents";

const AlertSettings = ({ searchParams, setSearchParams }) => {
  const appName = searchParams?.get("appName") ?? "";
  const cameraName = searchParams?.get("cameraName") ?? "";
  const camId = searchParams?.get("camId") ?? "";
  const appId = searchParams?.get("appId") ?? "";
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [allAlerts, setAllAlerts] = useState([]);

  const getAlerts = async () => {
    try {
      const param =
        camId !== ""
          ? {
              masterObjectId: appId,
              cameraId: camId,
            }
          : {
              masterObjectId: appId,
            };
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/app/alert/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) setAllAlerts(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAlert = async (name, id) => {
    try {
      const param = {
        masterObjectId: appId,
        alertId: id,
      };
      const response = await callApi({
        type: "delete",
        endPoint: "selfserve/v2/project/v1/app/alert/",
        toast: toast,
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "Alert deleted",
          status: "success",
          duration: 3000,
          position: "top-right",
        });
        mixpanelDeleteTrack({
          type: "Alert",
          name: name,
          id: id,
        });
        getAlerts();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const additionalActionCols = [
    {
      field: "actions",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => {
        return (
          <div className="flex justify-evenly items-center gap-1 w-full">
            <IconButton
              title="Edit"
              color="primary"
              onClick={() => {
                navigate(
                  `/SandboxV2/draft/alert?appId=${appId}&cameraName=${row?.cameraName}&appName=${appName}&camId=${row?.camId}&alertId=${row?.alertId}&alertName=${row?.alertName}`,
                  {
                    state: {
                      prevPath: location?.pathname + location?.search,
                    },
                  }
                );
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              title="Delete"
              color="error"
              onClick={() => {
                deleteAlert(row?.alertName, row?.alertId);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAlerts();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <NavigateBack
        targetRoute={-1}
        textArray={
          cameraName !== ""
            ? ["AI Studio", appName, cameraName, "alerts"]
            : ["AI Studio", appName, "alerts"]
        }
      />
      <div className="flex flex-col gap-6 p-6 bg-white rounded-xl">
        <DataGridContainer
          height="75vh"
          addText="Add new alert"
          addUrl={`/SandboxV2/add/alert?appId=${appId}&cameraName=${cameraName}&appName=${appName}&camId=${camId}`}
        >
          <CustomStyledDataGrid
            rows={allAlerts}
            columns={formatColumns({
              cols: [...alertSettingsColumnDefinition, ...additionalActionCols],
              color: "blue",
              flexMap: alertSettingsFlexMap,
            })}
            getRowId={(row) => row?.alertId}
            pagination={true}
            initialState={{
              pagination: { paginationModel: { pageSize: 15 } },
            }}
            disableRowSelectionOnClick={true}
            getRowHeight={() => "auto"}
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
            }}
            pageSizeOptions={[5, 15, 25]}
            autosizeOptions={{
              columns: alertSettingsColumnDefinition?.map((item) => item.field),
              includeOutliers: true,
              includeHeaders: true,
            }}
          />
        </DataGridContainer>
      </div>
    </div>
  );
};

export default AlertSettings;
