import {
  CustomSliderMui,
  FieldLabel,
  ImageContainer,
} from "../../Common/CommonComponents";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import SecondaryButton from "../../../../util/Buttons/SecondaryButton";
import { useState } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const CamCard = ({
  isAdd = false,
  modelId = "",
  appName = "",
  data = {},
  appId = "",
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sliderValue, setSliderValue] = useState(65);

  const handleView = () => {
    setSearchParams((prev) => {
      prev.set("rtsp", data?.rtspLink);
      prev.set("cameraName", data?.cameraName);
      prev.set("camId", data?.camId ?? "");
      return prev;
    });
  };

  return (
    <div className="flex relative flex-col gap-3 p-6 bg-white rounded-xl">
      <div className="flex flex-col gap-1 relative w-full h-fit">
        <FieldLabel text="Captured live feed" isRequired={false} />
        <ImageContainer width="100%" height="36vh">
          <img
            src={
              "annotatedImgUrl" in data ? data?.annotatedImgUrl : data?.imgUrl
            }
            className="rounded-xl w-full h-full"
            alt=""
          />
          {!("annotatedImgUrl" in data) && (
            <p
              className="absolute top-2 right-2 text-xs md:text-base font-medium text-white px-2 py-1 rounded-[32px] bg-[#AEA9B1]"
              style={{ boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.10)" }}
            >
              {data?.tagName}
            </p>
          )}
          <p
            className="absolute top-2 left-2 text-xs md:text-base font-medium text-white px-2 py-1 rounded-[32px] bg-[#AEA9B1]"
            style={{ boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.10)" }}
          >
            {data?.cameraName}
          </p>
        </ImageContainer>
      </div>
      {/* <FieldLabel text="Confidence threshold" isRequired={false} />
      <CustomSliderMui
        value={sliderValue}
        setValue={setSliderValue}
        isInput={true}
        max={99}
      />
      <SecondaryButton text={"Apply"} width={"fit-content"} height={"36px"} /> */}
      <div className="flex gap-3 flex-col xs:flex-row justify-between items-start xs:items-center">
        <div className="flex gap-3 items-center">
          <FieldLabel text="Last updated" isRequired={false} />
          <p className="text-[#79767D] text-sm font-medium">
            {new Date(data?.captureTime).toLocaleString("en-GB")}
          </p>
        </div>
        <PrimaryButton
          text={"View detail"}
          width={"fit-content"}
          onClick={handleView}
          disable={!("rtspLink" in data) || !("camId" in data)}
        />
      </div>
      {isAdd && (
        <div className="h-full rounded-l-xl transition-all duration-500 ease-in-out opacity-0 hover:opacity-100 cursor-pointer hover:bg-white hover:bg-opacity-60 top-0 left-0 absolute content-center text-center">
          <AddCircleRoundedIcon
            className="hover:scale-105"
            sx={{ color: "#6CA6FC", fontSize: "48px" }}
            onClick={() =>
              navigate(
                `/SandboxV2/draft/app?modelId=${modelId}&appName=${appName}&appId=${appId}`,
                {
                  state: {
                    prevPath: location?.pathname + location?.search,
                  },
                }
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default CamCard;
