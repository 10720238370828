import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ThemeProvider, createTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

dayjs.extend(utc);
dayjs.extend(timezone);

const FloatingInput = ({
  type = "",
  value = "",
  min = "",
  max = "",
  text = "Select",
  disabled = false,
  isClearable = false,
  setDateTime = () => {},
  customProps = {},
}) => {
  const theme = createTheme();
  const date = (
    <DatePicker
      label={text}
      slotProps={{
        textField: { size: "small", fullWidth: true },
        field: { clearable: isClearable },
      }}
      value={value ? dayjs.utc(value) : null}
      timezone="system"
      onChange={(newValue) => {
        let val = newValue?.toDate();
        setDateTime(val);
      }}
      {...customProps}
      disabled={disabled}
      minDate={min ? dayjs.utc(min) : undefined}
      maxDate={max ? dayjs.utc(max) : undefined}
    />
  );
  const dateTime = (
    <DateTimePicker
      label={text}
      slotProps={{
        textField: { size: "small" },
        field: { clearable: isClearable },
      }}
      value={value ? dayjs.utc(value) : null}
      timeSteps={{
        minutes: 1,
      }}
      disabled={disabled}
      timezone="system"
      onChange={(newValue) => {
        let val = newValue?.toDate();
        setDateTime(val);
      }}
      minDateTime={min ? dayjs.utc(min) : undefined}
      maxDateTime={max ? dayjs.utc(max) : undefined}
    />
  );
  const time = (
    <TimePicker
      label={text}
      slotProps={{
        textField: { size: "small" },
        field: { clearable: isClearable },
      }}
      value={value ? dayjs.utc(value) : null}
      timezone="system"
      disabled={disabled}
      onChange={(newValue) => {
        let val = newValue?.toDate();
        setDateTime(val);
      }}
      timeSteps={{
        minutes: 1,
      }}
      disableIgnoringDatePartForTimeValidation={true}
      minTime={min ? dayjs.utc(min) : undefined}
      maxTime={max ? dayjs.utc(max) : undefined}
    />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        {type === "date" ? date : type === "time" ? time : dateTime}
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default FloatingInput;
