import "./App.css";
import Navbar from "./components/Navbar";
import { Routes, Route, useRoutes } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import LoginV2 from "./components/Auth/LoginV2";
import Sidebar from "./components/Sidebar/Sidebar";
import NavContext from "./components/NavContext";
import { routes } from "./Route";
import axios from "axios";
import { baseURL, bypassRipikAdmin } from ".";
import { mixpanelAppRegister } from "./util/mixpanelFunctions";
import { useWindowSize } from "@uidotdev/usehooks";
import { callApi } from "./components/Sandbox/utils/ApiUtil";

function generateFilteredRoutes(userType, homeRef) {
  return routes
    .filter((route) => route.role.includes(userType))
    .map((route) => ({
      ...route,
      element: route.role.includes(userType) ? (
        route.hasHomeRef ? (
          React.cloneElement(route.element, {
            homeRef: homeRef,
          })
        ) : (
          route.element
        )
      ) : (
        <div>Access Denied</div>
      ),
    }));
}

function App() {
  const homeRef = useRef();
  const [login, setLogin] = useState(
    localStorage.getItem("logged_in") || false
  ); // used on Login.jsx to set login provider to true
  const [auth, setAuth] = useState(localStorage.getItem("auth_token")); // used on Login.jsx to set auth provider to true
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [userType, setUserType] = useState(localStorage.getItem("user_type"));
  const [organisation, setOrganisation] = useState(
    localStorage.getItem("organization")
  );
  const [clientId, setClientId] = useState(
    localStorage.getItem("clientId") ?? ""
  );
  const toast = useToast();
  const [creationLimits, setCreationLimits] = useState({});
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [location, setLocation] = useState(localStorage.getItem("location"));
  const [fullName, setFullName] = useState(localStorage.getItem("fullname"));
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("phone"));
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const size = useWindowSize();

  const [twoFactorAuthoInfo, setTwoFactorAuthInfo] = useState({
    is2FAuthenticatedAdmin: true,
    is2FAuthenticatedSuperAdmin:
      JSON.parse(localStorage.getItem("authInfo"))?.isSuperAdminAuthenticated ||
      bypassRipikAdmin
        ? true
        : false,
  });
  const getSubscriptionsNew = async () => {
    const param = {
      organisation: organisation,
    };
    try {
      const response = await axios.get(
        baseURL + `subscription/fetch/subscription`,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      setSubscriptions(response?.data?.subscribed?.subscriptionData);
      //console.log(response);
    } catch (error) {
      //console.log(error);
      setSubscriptions([]);
    }
  };

  const getMaxCreationLimits = async () => {
    try {
      const response = await callApi({
        type: "get",
        endPoint: "selfserve/v2/project/v1/limit/config/",
        toast: toast,
      });
      if (response?.status === 200) setCreationLimits(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProductGroupsNew = async () => {
    try {
      const response = await axios.get(
        baseURL + "product/v1/fetch/productGroup/detail",
        {
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );
      let data = response?.data?.filter((item) => item.status === "ACTIVE");
      setProductGroups(data);
    } catch (error) {}
  };

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };
    getMaxCreationLimits();
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    setAuth(localStorage.getItem("auth_token"));
    setEmail(localStorage.getItem("email"));
    setUserType(localStorage.getItem("user_type"));
    setLocation(localStorage.getItem("location"));
    setOrganisation(localStorage.getItem("organisation"));
    setFullName(localStorage.getItem("fullname"));
    setClientId(localStorage.getItem("clientId"));
    setPhoneNumber(localStorage.getItem("phone"));
  }, [login]);

  useEffect(() => {
    if (login) {
      mixpanelAppRegister();
    }
  }, [login, organisation, fullName, phoneNumber, userType, location]);

  useEffect(() => {
    setFilteredRoutes(generateFilteredRoutes(userType, homeRef));
  }, [userType]);

  const allRoutes = useRoutes(filteredRoutes);

  return (
    <>
      <NavContext.Provider
        value={{
          setLogin,
          login,
          setAuth,
          auth,
          setEmail,
          email,
          userType,
          clientId,
          organisation,
          fullName,
          productGroups,
          subscriptions,
          getProductGroupsNew,
          getSubscriptionsNew,
          twoFactorAuthoInfo,
          setTwoFactorAuthInfo,
          creationLimits,
        }}
      >
        <div
          className="relative min-h-[100dvh] min-w-screen h-full w-full !font-roboto"
          style={{
            background:
              "var(--Gradient-New, linear-gradient(180deg, #2660B6 0%, #112A50 100%))",
          }}
        >
          {login ? (
            <>
              <Sidebar
                userRole={userType}
                homeRef={homeRef}
                organisation={organisation}
              />
              <div
                className="px-4 pt-4 pb-16 md:p-6 absolute top-14 left-0 md:left-24 bottom-[75px] md:bottom-1 right-0 md:rounded-tl-lg overflow-y-auto bg-white"
                style={{
                  boxShadow:
                    "0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
                  background:
                    "var(--BG-Gradient-AI-Studio, linear-gradient(138deg, #FAFAFA 23.82%, #EBF0F8 92.94%))",
                }}
              >
                {allRoutes}
              </div>
            </>
          ) : (
            <Routes>
              <Route path="*" element={<LoginV2 />} />
            </Routes>
          )}

          {!isOnline && (
            <Modal
              size={"sm"}
              isOpen={!isOnline}
              onClose={() => setIsOnline(true)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton className=" w-1 h-1" />
                <ModalBody m={"auto"} fontSize={"15px"}>
                  <div className="flex flex-col items-center justify-center w-full mt-4">
                    <div className="flex flex-col items-center mt-4">
                      <p className="font-bold">⚠️ No internet connection !</p>
                      <div className="flex justify-center items-center">
                        Seems like your internet connection is broken
                      </div>
                      <div className="flex justify-center items-center">
                        Please check and try to refresh the page
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </div>
      </NavContext.Provider>
    </>
  );
}

export default App;
