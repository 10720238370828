import { useRef, useState } from "react";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import { IconButton, InputBase } from "@mui/material";
import TonalButton from "../../../../util/Buttons/TonalButton";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

const AddLabels = ({
  confirm = false,
  setAnchorEl = () => {},
  labels = [],
  setLabels = () => {},
  handleAdd = () => {},
  annotationType = "",
}) => {
  const [labelText, setLabelText] = useState("");
  return !confirm ? (
    <div className="flex flex-col gap-3 w-full">
      <div className="flex w-full gap-3 items-center flex-wrap">
        <div
          className="flex gap-2 items-center p-1 pl-4 bg-white rounded-lg border border-[#CAC5CD]"
          style={{
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <InputBase
            value={labelText}
            onChange={(e) => setLabelText(e?.target?.value)}
            placeholder="Enter label name to add"
            onKeyDownCapture={(e) => {
              if (e.code === "Enter") {
                handleAdd(e.target.value);
                setLabelText("");
              }
            }}
            disabled={labels?.length >= 5 || !annotationType}
            sx={{
              ".MuiInputBase-input": {
                color: "#605D64",
                fontWeight: 500,
              },
            }}
          />
          <TonalButton
            text={
              <AddIcon
                style={{
                  fontSize: "24px",
                }}
              />
            }
            height={"44px"}
            width={"44px"}
            disable={!labelText?.trim() || labels?.length >= 5 || !annotationType}
            onClick={() => {
              handleAdd(labelText);
              setLabelText("");
            }}
          />
        </div>
        {labels?.map((item, idx) => {
          return (
            <div
              key={idx}
              className="flex gap-1 items-center p-2 rounded-lg bg-[#F5F5F5]"
            >
              <p className="text-[#48464C] text-sm">{item}</p>
              <IconButton
                color="primary"
                onClick={() =>
                  setLabels((prev) => {
                    let newData = [...prev];
                    let idx = newData?.findIndex((val) => val === item);
                    if (idx !== -1) newData?.splice(idx, 1);
                    return newData;
                  })
                }
                title="Delete"
                size="small"
              >
                <HighlightOffRoundedIcon />
              </IconButton>
            </div>
          );
        })}
      </div>
      <PrimaryButton
        text={"Confirm labels"}
        width={"fit-content"}
        disable={labels.length === 0}
        onClick={(e) => setAnchorEl(e?.currentTarget)}
      />
    </div>
  ) : null;
};

export default AddLabels;
