import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import SessionLogs from "./Tabs/SessionLogs";
import UserMgmt from "./Tabs/UserMgmt";

import { useContext } from "react";
import NavContext from "../NavContext";
import UserRequests from "./Tabs/UserRequests";
import Subscriptions from "./Tabs/Subscriptions";


const AdminHome = () => {
  const { twoFactorAuthoInfo } = useContext(NavContext);
  const size = useWindowSize();
  const [issubscriptionHistoryChanged, setSubHistoryChange] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = searchParams.get("tabIndex")
    ? parseInt(searchParams.get("tabIndex"), 10)
    : 0;

  const tabList = [
    {
      index: 0,
      tabName: "Subscriptions",
      element: (
        <Subscriptions
          setSubHistoryChange={setSubHistoryChange}
          issubscriptionHistoryChanged={issubscriptionHistoryChanged}
          mode={'view'}
        />
      ),
    },
    {
      index: 1,
      tabName: "User Management",
      element: <UserMgmt />,
    },
    {
      index: 2,
      tabName: "User Requests",
      element: <UserRequests mode="update"/>,
    },
    {
      index: 3,
      tabName: "Session Logs",
      element: <SessionLogs />,
    },
  ];
  const handleTabIndex = (val) => {
    if (val !== tabIndex) {
      setSearchParams({ tabIndex: val }, { replace: true });
    }
  };

  useEffect(() => {
    handleTabIndex(tabIndex);
  }, [tabIndex]);

  return (
    <div
      className="pl-0 pr-5  font-roboto flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div className="flex justify-between mb-3">
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87] capitalize">
          Admin Panel
        </p>
      </div>

      {twoFactorAuthoInfo?.is2FAuthenticatedAdmin && ( // rendering the tabs based on 2FA
        <Tabs
          className="bg-white rounded-md px-3 border-2 py-4"
          isLazy={true}
          index={tabIndex}
          onChange={handleTabIndex}
        >
          <TabList className="!flex !border-0">
            <div className="flex items-center gap-4 overflow-x-auto h-14 xl:h-10">
              {tabList?.map((item, id) => (
                <Tab
                  key={id}
                  className={`xl:!text-sm !text-[14px] !text-[#605D64] whitespace-nowrap !border !rounded-full md:w-auto ${
                    tabIndex === item?.index
                      ? "  !bg-[#6CABFC] !bg-opacity-20  !border-[#6CA6FC]  "
                      : "!border-[#EBEBEB]"
                  }`}
                  onClick={() => handleTabIndex(item?.index)}
                >
                  {item?.tabName}
                </Tab>
              ))}
            </div>
          </TabList>
          <TabPanels className="!pt-4">
            {tabList?.map((item) => {
              return (
                <TabPanel className="!pl-0 !pr-0">{item?.element}</TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      )}
    </div>
  );
};

export default AdminHome;
