import { useToast } from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import { callApi } from "../utils/ApiUtil";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { Modal, Checkbox } from "@mui/material";
import { AutoComplete, modalStyle } from "./CommonComponents";
import { useContext, useEffect, useState } from "react";
import NavContext from "../../NavContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CommonShareModal = ({
  openModal = false,
  closeModal = () => {},
  objectId = "",
  type = "",
}) => {
  const toast = useToast();
  const { organisation, email } = useContext(NavContext);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const shareApi = async () => {
    try {
      const endPointType = {
        app: "selfserve/v2/project/v1/app/",
        project: "selfserve/v2/project/v1/project/",
        model: "selfserve/v2/project/v1/model/share/",
        dataRepo: "selfserve/v2/project/v1/dataRepository/",
      };
      const requestData = JSON.stringify({
        [type + "ObjectId"]: objectId,
        shareTo: selectedUsers,
      });
      const response = await callApi({
        type: "post",
        endPoint: endPointType[type],
        requestBody: requestData,
        toast: toast,
      });
      if (response?.status === 200) {
        toast({
          title: "Shared " + type + " successfully",
          duration: 3000,
          position: "top-right",
          status: "success",
        });
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    try {
      const param = {
        organisation: organisation,
      };
      const response = await callApi({
        type: "get",
        endPoint: "iam/users",
        params: param,
        toast: toast,
      });
      if (response?.status === 200) {
        let data = response?.data
          ?.map((item) => item?.email)
          ?.filter((item) => item !== email);
        setUsers(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Modal
      open={openModal}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") return;
        closeModal();
      }}
    >
      <div
        style={{ ...modalStyle, maxWidth: "300px" }}
        className="flex flex-col gap-3"
      >
        <div
          className="flex justify-between items-center"
          style={{
            borderBottom: "1px solid #EBE2F0",
          }}
        >
          <p className="text-[#605D64] text-sm font-medium">Share</p>
          <CloseIcon
            className="cursor-pointer hover:scale-105"
            onClick={closeModal}
            sx={{ color: "#605D64" }}
          />
        </div>
        <div className="flex flex-col gap-4 items-end w-full">
          <AutoComplete
            label={"Share with"}
            options={users}
            value={selectedUsers}
            handleChange={(e, newVal) => setSelectedUsers(newVal)}
            autoCompleteProps={{
              renderOption: (props, option, { selected }) => (
                <li
                  {...props}
                  key={Math.random() * 1000000}
                  className="capitalize"
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              ),
              sx: {
                width: "100%",
              },
              multiple: true,
              disableCloseOnSelect: true,
            }}
          />
          <PrimaryButton
            text={"Share access"}
            onClick={shareApi}
            width={"fit-content"}
            disable={selectedUsers?.length === 0}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CommonShareModal;
