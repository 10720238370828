import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Card,
  CardContainer,
  CardTableToggle,
  Chip,
  CustomMenu,
  DataGridContainer,
} from "../Common/CommonComponents";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { callApi } from "../utils/ApiUtil";
import { useToast } from "@chakra-ui/react";
import {
  debounce,
  filterHomePageComparator,
  modelOptions,
  modelStatusColors,
  modelStatusIcon,
} from "../utils/HandlerFuncs";
import { CommonHomeFilters } from "../Common/CommonFilters";
import CommonRenameModal from "../Common/CommonRenameModal";
import {
  mixpanelDeleteTrack,
  mixpanelModelRetrainClickTrack,
} from "../utils/MixpanelEvents";
import {
  ModelColumnDefinition,
  formatColumns,
  modelFlexMap,
} from "../Common/CommonColDefine";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import {
  createTheme,
  IconButton,
  Skeleton,
  ThemeProvider,
} from "@mui/material";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CommonShareModal from "../Common/CommonShareModal";
import NavContext from "../../NavContext";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ModelInferDrawer from "./Components/ModelInferDrawer";
import Paginator from "../../../util/VisionUtils/Paginator";
import CommonWidgets from "../Common/CommonWidgets";

const ModelHomeView = () => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { email, creationLimits } = useContext(NavContext);
  const selectedView = searchParams?.get("selectedView")
    ? searchParams?.get("selectedView")
    : "card";
  const [existingModels, setExistingModels] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const activeModels = existingModels?.filter((item) =>
    item?.modelPlatform?.includes("CUSTOM")
  )?.length;
  const rightRef = useRef();
  const leftRef = useRef();
  const [disableToggle, setDisableToggle] = useState({
    left: true,
    right: true,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const disableCreation = activeModels >= creationLimits?.modelPerUserAllowed;
  const [openModal, setOpenModal] = useState({
    open: false,
    oldName: null,
    type: null,
    id: null,
  });
  const modelMenuOptions = [
    {
      type: "View model",
      icon: "/viewIcon.svg",
    },
    {
      type: "Rename",
      icon: "/edit.svg",
    },
    {
      type: "Download",
      icon: "/download.svg",
    },
    // {
    //   type: "Clone",
    //   icon: "/clone.svg",
    // },
    // {
    //   type: "Share model",
    //   icon: "/selfServiceIcons/share.svg",
    // },
    {
      type: "Delete",
      icon: "/delete.svg",
    },
  ];
  const [filterState, setFilterState] = useState({
    modelType: [],
    owner: [],
    date: "",
    search: "",
  });
  const filteredData = useMemo(
    () =>
      existingModels?.filter((item) => {
        let check = filterHomePageComparator({
          filterState: filterState,
          typeKey: "modelType",
          timeKey: "createdAt",
          data: item,
        });
        return check;
      }),
    [existingModels, filterState]
  );

  const getExistingModels = async () => {
    try {
      const response = await callApi({
        type: "get",
        toast: toast,
        endPoint: "selfserve/v2/project/v1/model/",
      });
      if (response?.status === 200) {
        setExistingModels(response?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteModel = async ({ id = "", name = "" }) => {
    try {
      const param = {
        masterObjectId: id,
      };
      const response = await callApi({
        type: "delete",
        toast: toast,
        endPoint: "selfserve/v2/project/v1/model/",
        params: param,
      });
      if (response?.status === 200) {
        toast({
          title: "Model deleted",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        getExistingModels();
        mixpanelDeleteTrack({
          name: name,
          type: "model",
          id: id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = ({ val = "", data = {} }) => {
    switch (val) {
      case "Delete":
        deleteModel({ id: data?.masterObjectId, name: data?.name });
        break;
      case "View model":
        let noModelRuns =
          !(data?.predictionRunCount > 0) &&
          ["COMPLETED", "FAILED", "TERMINATED"]?.includes(data?.status);
        navigate(
          `/SandboxV2/view/model?modelName=${data?.name}&modelId=${data?.masterObjectId}&noModelRuns=${noModelRuns}`,
          {
            state: {
              prevPath: location?.pathname + location?.search,
            },
          }
        );
        break;
      case "Rename":
        setOpenModal({
          open: true,
          oldName: data?.name,
          type: "rename",
          id: data?.masterObjectId,
        });
        break;
      case "Delete":
        deleteModel({ id: data?.masterObjectId, name: data?.name });
        break;
      case "Download":
        const link = document.createElement("a");
        link.href = data?.modelLink;
        link.download = data?.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        break;
      default:
        break;
    }
  };

  const debouncedSetSearchData = debounce(
    (e) =>
      setFilterState((prev) => ({
        ...prev,
        search: e.target.value,
      })),
    300
  );

  const handleViewChange = (val) => {
    if (val !== selectedView) {
      setSearchParams((prev) => {
        prev.set("selectedView", val);
        return prev;
      });
    }
  };

  const navigateAdd = (item) => {
    let view = item?.status === "PENDING" ? "draft" : "add";
    let param = item
      ? `?modelId=${item?.masterObjectId}&modelName=${item?.name}`
      : "";
    navigate(`/SandboxV2/${view}/model${param}`, {
      state: {
        prevPath: location?.pathname + location?.search,
      },
    });
  };

  const navigateRetrain = (item) => {
    if (item?.status === "PENDING")
      navigate(`/SandboxV2/draft/retrain?modelId=${item?.masterObjectId}`, {
        state: {
          prevPath: location?.pathname + location?.search,
        },
      });
    else
      navigate(
        `/SandboxV2/add/retrain?baseModelId=${item?.masterObjectId}&modelName=${item?.name}&modelType=${item?.modelType}`,
        {
          state: {
            prevPath: location?.pathname + location?.search,
          },
        }
      );
  };

  const navigateResume = (item) => {
    if (item?.draftType === "UPLOAD_MODEL") navigateAdd(item);
    else navigateRetrain(item);
  };

  const filterMenu = (item) => {
    const isDraft = item?.status === "PENDING";
    const isDefault = item?.isDefault;

    return modelMenuOptions?.filter((val) => {
      if (isDraft && val?.type === "Delete") {
        return true;
      }
      if (
        isDefault &&
        ["Share model", "Delete", "Rename"].includes(val?.type)
      ) {
        return false;
      }
      if (val?.type === "Download" && !item?.modelLink) {
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    getExistingModels();
    let intervalId = setInterval(() => {
      getExistingModels();
    }, 30 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleViewChange(selectedView);
  }, [selectedView]);

  const additionalActionCols = [
    {
      field: "actions",
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => {
        let isShared = email !== row?.owner;
        let isDraft = row?.status === "PENDING";
        return (
          <div className="flex justify-end items-center gap-1 w-full">
            {isDraft ? (
              <IconButton
                title="Resume"
                color="primary"
                onClick={() => {
                  navigateResume(row);
                }}
              >
                <HourglassEmptyIcon />
              </IconButton>
            ) : (
              <>
                <IconButton
                  title="Infer"
                  color="primary"
                  onClick={() => {
                    setOpenModal((prev) => ({
                      open: true,
                      id: row?.masterObjectId,
                      oldName: row?.name,
                      type: "infer",
                    }));
                  }}
                  disabled={
                    row?.status &&
                    !["FAILED", "TERMINATED", "COMPLETED"]?.includes(
                      row?.status
                    )
                  }
                >
                  <ImageSearchIcon />
                </IconButton>
                <IconButton
                  title="Re-train"
                  color="primary"
                  onClick={() => {
                    navigateRetrain(row);
                    mixpanelModelRetrainClickTrack({
                      modelId: row?.masterObjectId,
                      modelName: row?.name,
                    });
                  }}
                  disabled={
                    (row?.status && row?.status !== "COMPLETED") ||
                    disableCreation
                  }
                >
                  <AutorenewRoundedIcon />
                </IconButton>
              </>
            )}
            <div className="relative w-10 h-10">
              <CustomMenu
                top={undefined}
                right={undefined}
                position="normal"
                options={filterMenu(row)}
                handleMenuClick={(val) =>
                  handleMenuClick({
                    val: val,
                    data: row,
                  })
                }
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <ThemeProvider theme={createTheme()}>
      <div className="flex flex-col gap-3 rounded-xl w-full">
        <CommonWidgets type="models" tagLine="Play with our models" />
        <div className="flex flex-col gap-6 w-full items-end">
          <div className="w-full flex flex-col xl:flex-row justify-between gap-4 items-start xl:items-center">
            <p className="text-[#605D64] text-lg font-medium whitespace-nowrap w-fit">
              Your list of Models (
              {activeModels + "/" + creationLimits?.modelPerUserAllowed})
            </p>
            <CommonHomeFilters
              filterOptions={[
                {
                  label: "Model type",
                  displayKey: "type",
                  valueKey: "type",
                  options: modelOptions,
                  value: filterState?.modelType,
                  handleChange: (val, newVal) => {
                    setFilterState((prev) => ({
                      ...prev,
                      modelType: newVal,
                    }));
                  },
                },
                {
                  label: "Owner",
                  displayKey: "owner",
                  valueKey: "owner",
                  options: Array.from(
                    new Set(existingModels?.map((item) => item?.owner))
                  )?.map((item) => ({ owner: item })),
                  value: filterState?.owner,
                  handleChange: (val, newVal) => {
                    setFilterState((prev) => ({
                      ...prev,
                      owner: newVal,
                    }));
                  },
                },
                {
                  isDateSelect: true,
                  type: "date",
                  value: filterState?.date,
                  handleChange: (val) => {
                    setFilterState((prev) => ({
                      ...prev,
                      date: val,
                    }));
                  },
                  label: "Created after",
                  max: new Date(),
                },
              ]}
              hasSearch={true}
              searchObj={{
                value: filterState?.search,
                setValue: debouncedSetSearchData,
              }}
            >
              <CardTableToggle
                setViewType={handleViewChange}
                ViewType={selectedView}
              />
            </CommonHomeFilters>
          </div>
          {selectedView === "card" ? (
            <CardContainer
              showLeft={!disableToggle?.left}
              showRight={!disableToggle?.right}
              leftFunc={() => leftRef?.current?.click()}
              rightFunc={() => rightRef?.current?.click()}
            >
              {loading ? (
                [...Array(8)]?.map((val, idx) => (
                  <Skeleton
                    variant="rectangular"
                    height={"250px"}
                    width={"100%"}
                    key={idx}
                  />
                ))
              ) : (
                <>
                  <Card
                    isAddbutton={true}
                    addText="Add new model"
                    disableAdd={disableCreation}
                    handleAdd={() => navigateAdd()}
                  />
                  {displayData?.map((item, idx) => {
                    let { elem, text } = modelStatusIcon({
                      status: item?.status,
                    });
                    let isString = typeof elem === "string";
                    let isDraft = item?.status === "PENDING";
                    let isShared = email !== item?.owner;
                    return (
                      <Card
                        key={idx}
                        heading={item?.name}
                        labels={item?.labelInfo?.map((val) => val?.label)}
                        bgImage={item?.sampleImageUrl}
                        buttonInfo={
                          isDraft
                            ? [
                                {
                                  text: "Resume",
                                  handleClick: () => {
                                    navigateResume(item);
                                  },
                                  type: "ternary",
                                  color: "blue",
                                },
                              ]
                            : [
                                {
                                  text: "Infer",
                                  handleClick: () => {
                                    setOpenModal((prev) => ({
                                      open: true,
                                      id: item?.masterObjectId,
                                      oldName: item?.name,
                                      type: "infer",
                                    }));
                                  },
                                  type: "primary",
                                  color: "blue",
                                  disable:
                                    item?.status &&
                                    ![
                                      "FAILED",
                                      "TERMINATED",
                                      "COMPLETED",
                                    ]?.includes(item?.status),
                                },
                                {
                                  text: "Re-train",
                                  handleClick: () => {
                                    navigateRetrain(item);
                                    mixpanelModelRetrainClickTrack({
                                      modelId: item?.masterObjectId,
                                      modelName: item?.name,
                                    });
                                  },
                                  type: "secondary",
                                  color: "blue",
                                  disable:
                                    (item?.status &&
                                      item?.status !== "COMPLETED") ||
                                    disableCreation,
                                },
                              ]
                        }
                        status={item?.isDefault ? "" : text}
                        statusColor={
                          modelStatusColors({ status: item?.status })?.hex
                        }
                        statusIcon={
                          isString ? (
                            <img
                              src={elem}
                              alt="status"
                              className="w-full h-full"
                            />
                          ) : (
                            elem
                          )
                        }
                      >
                        <Chip
                          top={"12px"}
                          right={"12px"}
                          customElem={
                            <CustomMenu
                              position="block"
                              color="#48464C"
                              options={filterMenu(item)}
                              handleMenuClick={(val) =>
                                handleMenuClick({
                                  val: val,
                                  data: item,
                                })
                              }
                            />
                          }
                        />
                      </Card>
                    );
                  })}
                </>
              )}
            </CardContainer>
          ) : (
            <DataGridContainer
              showAdd={true}
              addText="Add new model"
              disableAdd={disableCreation}
              handleAdd={() => navigateAdd()}
            >
              <CustomStyledDataGrid
                rows={filteredData}
                columns={formatColumns({
                  cols: [...ModelColumnDefinition, ...additionalActionCols],
                  color: "blue",
                  flexMap: modelFlexMap,
                })}
                autosizeOptions={{
                  columns: ModelColumnDefinition?.map((item) => item.field),
                  includeOutliers: true,
                  includeHeaders: true,
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                getRowId={(row) => row?.masterObjectId}
                pagination
                pageSizeOptions={[5, 15, 25]}
              />
            </DataGridContainer>
          )}
          {selectedView === "card" && (
            <Paginator
              data={filteredData}
              limit={7}
              initialPage={
                filteredData?.length === existingModels?.length
                  ? currentPage
                  : 1
              }
              setCurrentPage={setCurrentPage}
              setDisplayData={setDisplayData}
              leftRef={leftRef}
              rightRef={rightRef}
              setDisableToggle={setDisableToggle}
              additionalStyles={{
                style: {
                  boxShadow:
                    "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
                  backgroundColor: "#FFF",
                  border: "1px solid #CAC5CD",
                  borderRadius: "8px",
                },
              }}
            />
          )}
        </div>
        {openModal?.type === "rename" && openModal?.open && (
          <CommonRenameModal
            openModal={openModal?.open}
            closeModal={() =>
              setOpenModal({
                open: false,
                type: null,
                oldName: null,
                id: null,
              })
            }
            oldName={openModal?.oldName}
            type="model"
            objectId={openModal?.id}
            fetchCall={getExistingModels}
          />
        )}
        {openModal?.type === "share" && openModal?.open && (
          <CommonShareModal
            openModal={openModal?.open}
            closeModal={() =>
              setOpenModal({
                open: false,
                type: null,
                oldName: null,
                id: null,
              })
            }
            type="model"
            objectId={openModal?.id}
          />
        )}
        {openModal?.type === "infer" && openModal?.open && (
          <ModelInferDrawer
            openModal={openModal?.open}
            closeModal={() =>
              setOpenModal({
                open: false,
                type: null,
                oldName: null,
                id: null,
              })
            }
            modelId={openModal?.id}
            modelName={openModal?.oldName}
            depthView={false}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default ModelHomeView;
