import ReactImageAnnotate from "@starwit/react-image-annotate";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Paginator from "../../../../util/VisionUtils/Paginator";

const DetectSegment = ({
  labels = [],
  setAnnotatedImages = () => {},
  annotatedImages = [],
  allImages = [],
  ImageSet = [],
  setImageSet = () => {},
  options = [],
}) => {
  const { accessMode } = useParams();
  const [displayData, setDisplayData] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(0);

  const handleSave = (r) => {
    setAnnotatedImages((prev) => {
      return r?.images?.filter((item) => item?.regions?.length !== 0);
    });
  };

  useEffect(() => {
    if (allImages && allImages.length > 0 && accessMode !== "draft") {
      setImageSet((prev) => {
        let newData = allImages.map((item) => {
          return {
            src: item.img,
            id: item.id,
            name: item.id,
            regions: [],
          };
        });
        return newData;
      });
    }
  }, [allImages, accessMode]);

  return ImageSet.length > 0 ? (
    <div className="flex flex-col gap-1 w-full h-full relative">
      {ImageSet?.length > 0 && (
        <Paginator limit={15} data={ImageSet} setDisplayData={setDisplayData} />
      )}
      <div className="flex flex-wrap h-fit gap-2 items-center">
        {displayData?.map((item, index) => {
          let isAnnotate = annotatedImages?.some((val) => val.id === item.id);
          let idx = ImageSet?.findIndex((val) => val.id === item.id);
          return (
            <img
              key={index}
              alt="no image"
              loading="lazy"
              src={item?.src}
              className={`h-12 w-12 rounded ${
                isAnnotate ? "border-[4px] border-solid border-green-600" : ""
              } ${
                item?.id === ImageSet[selectedIdx]?.id
                  ? "opacity-20"
                  : "cursor-pointer hover:scale-105"
              }`}
              onClick={() => setSelectedIdx(idx)}
            />
          );
        })}
      </div>
      <ReactImageAnnotate
        labelImages
        regionClsList={labels}
        images={ImageSet}
        selectedImage={ImageSet[selectedIdx].src}
        onExit={(r) => {
          handleSave(r);
        }}
        hideClone={true}
        hideSettings={true}
        enabledTools={options}
        onNextImage={() => setSelectedIdx((prev) => prev + 1)}
        onPrevImage={() => setSelectedIdx((prev) => prev - 1)}
        hideNext={selectedIdx === ImageSet?.length - 1}
        hidePrev={selectedIdx === 0}
        taskDescription="Please annotate the image by giving it a bounding box or polygon then assigning a label"
      />
    </div>
  ) : null;
};

export default DetectSegment;
