import React, { useMemo, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "../../utils/HandlerFuncs";
import {
  Avatar,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Paginator from "../../../../util/VisionUtils/Paginator";
import DownloadIcon from "@mui/icons-material/Download";

const ModelDetails = ({ modelInfo = {}, model = {} }) => {
  const [searchValue, setSearchValue] = useState("");
  const [displayData, setDisplayData] = useState([]);

  const inputRef = useRef();
  const labels = useMemo(
    () =>
      modelInfo?.labelInfo?.filter((item) =>
        item?.label?.includes(searchValue)
      ) ?? [],
    [searchValue, modelInfo]
  );
  const modelParameters = Object.entries(
    modelInfo?.modelMetaData?.[0]?.performance || {}
  );
  const debouncedSearchSet = debounce(
    (e) => setSearchValue((prev) => e?.target?.value),
    300
  );

  return (
    <div className="flex flex-col lg:flex-row gap-4 items-stretch">
      <div className="flex flex-col gap-4 flex-1">
        {/*About model */}
        <div
          className="rounded-lg flex flex-col gap-0"
          style={{
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <div className="bg-[#F5F5F5] p-2 rounded-t-lg">
            <p className="text-[#3E3C42] text-lg font-medium">About model</p>
          </div>
          <div className="flex flex-col gap-[10px] bg-white p-[10px] rounded-b-lg">
            <div className="flex justify-between gap-4 items-center py-2">
              <p className="text-[#938F96] text-base font-medium">Name</p>
              <p className="text-[#48464C] text-xl font-medium">
                {model?.name}
              </p>
            </div>
            <Divider />
            <div className="flex justify-between gap-4 py-2">
              <p className="text-[#938F96] text-base font-medium">
                Description
              </p>
              <p
                className="text-[#48464C] text-xl font-medium text-justify text-ellipsis line-clamp-[8]"
                title={model?.description ?? "N/A"}
              >
                {model?.description ?? "N/A"}
              </p>
            </div>
          </div>
        </div>
        {/*Model details */}
        <div
          className="rounded-lg flex flex-col gap-0 flex-grow"
          style={{
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <div className="bg-[#F5F5F5] p-2 rounded-t-lg">
            <p className="text-[#3E3C42] text-lg font-medium">Model details</p>
          </div>
          <div className="flex flex-col gap-[10px] bg-white p-[10px] rounded-b-lg flex-grow">
            <div className="flex justify-between gap-4 items-center py-2">
              <p className="text-[#938F96] text-base font-medium">Model type</p>
              <p className="text-[#48464C] text-xl font-medium">
                {model?.modelType}
              </p>
            </div>
            <Divider />
            <div className="flex justify-between gap-4 items-center py-2">
              <p className="text-[#938F96] text-base font-medium">Created on</p>
              <p className="text-[#48464C] text-xl font-medium">
                {new Date(model?.createdAt)?.toLocaleString("en-GB")}
              </p>
            </div>
            <Divider />
            <div className="flex justify-between gap-4 items-center py-2">
              <p className="text-[#938F96] text-base font-medium">
                Last updated on
              </p>
              <p className="text-[#48464C] text-xl font-medium">
                {new Date(model?.lastUpdatedAt)?.toLocaleString("en-GB")}
              </p>
            </div>
            {model?.modelLink && (
              <>
                <Divider />
                <div className="flex justify-between gap-4 items-center py-2">
                  <p className="text-[#938F96] text-base font-medium">
                    Download model
                  </p>
                  <IconButton
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = model?.modelLink;
                      link.download = model?.name;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 flex-1">
        {/*Label details */}
        <div
          className="flex flex-col gap-0 rounded-lg flex-grow"
          style={{
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <div className="bg-[#F5F5F5] p-2 rounded-t-lg">
            <p className="text-[#3E3C42] text-lg font-medium">Label details</p>
          </div>
          <div className="flex flex-col gap-4 justify-between flex-grow bg-white p-[10px] rounded-b-lg">
            <div className="flex flex-col gap-1">
              {/*Prediction iteration */}
              <div className="flex justify-between gap-4 items-center py-2">
                <p className="text-[#938F96] text-base font-medium">
                  Prediction iteration
                </p>
                <p className="text-[#48464C] text-xl font-medium">
                  {model?.predictionRunCount ?? 0}
                </p>
              </div>
              <Divider />
              {/*Number of labels */}
              <div className="flex justify-between gap-4 items-center py-2">
                <p className="text-[#938F96] text-base font-medium">
                  Number of labels
                </p>
                <p className="text-[#48464C] text-xl font-medium">
                  {modelInfo?.labelInfo?.length}
                </p>
              </div>
              <Divider />
              {/*Search */}
              <OutlinedInput
                ref={inputRef}
                defaultValue={searchValue}
                onChange={(e) => debouncedSearchSet(e)}
                placeholder="Search label..."
                sx={{
                  width: "100%",
                  minWidth: "180px",
                  boxShadow:
                    "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
                  backgroundColor: "#FFF",
                  borderRadius: "8px",
                }}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </div>
            {/*label chips and pagination */}
            <div className="flex flex-col gap-2 justify-between p-2 flex-grow">
              <div className="flex flex-wrap gap-2 justify-around self-start">
                {displayData?.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      avatar={
                        <Avatar>{item?.label?.charAt(0)?.toUpperCase()}</Avatar>
                      }
                      variant="outlined"
                      sx={{
                        color: "#79767D",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                      label={item?.label}
                    />
                  );
                })}
              </div>
              <div className="flex items-center justify-end">
                <Paginator
                  limit={10}
                  data={labels}
                  setDisplayData={setDisplayData}
                />
              </div>
            </div>
          </div>
        </div>
        {/*Model parameters */}
        {modelParameters?.length > 0 && (
          <div
            className="rounded-lg flex flex-col gap-0"
            style={{
              boxShadow:
                "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
            }}
          >
            <div className="bg-[#F5F5F5] p-2 rounded-t-lg">
              <p className="text-[#3E3C42] text-lg font-medium">
                Model parameters
              </p>
            </div>
            <div className="flex gap-4 items-center flex-wrap bg-white rounded-b-lg p-[10px]">
              {modelParameters?.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <div className="flex flex-col gap-1">
                      <p className="text-[#938F96] text-base font-medium capitalize">
                        {item[0]}
                      </p>
                      <p className="text-[#48464C] text-xl font-medium">
                        {Math.round(item[1] * 100)}%
                      </p>
                    </div>
                    {idx !== modelParameters?.length - 1 && (
                      <Divider orientation="vertical" flexItem />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelDetails;
