import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import SendIcon from "@mui/icons-material/Send";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../";
import NavContext from "../../NavContext";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const CommentBar = ({
  img = "",
  name = "",
  msg = "",
  dateTime = "",
  index = 0,
  userEmail = "",
  files = [],
  fileNames = [],
}) => {
  const { email } = useContext(NavContext);
  const isUser = email === userEmail;
  return (
    <div
      className="flex justify-between gap-4 pb-4 w-full relative"
      id={"comment" + index}
    >
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          {img ? (
            <img src={img} alt="img" className="h-6 w-6 rounded" />
          ) : (
            <AccountBoxRoundedIcon sx={{ fontSize: "24px" }} />
          )}
          <p
            className="text-base font-medium"
            style={{
              color: isUser ? "#3A74CA" : "#3E3C42",
            }}
          >
            {isUser ? "You" : name}
          </p>
        </div>
        <p className="text-[#79767D] text-sm">{msg}</p>
        {files?.length > 0 && (
          <div className="flex flex-wrap gap-2 items-center w-full">
            {files?.map((item, idx) => {
              return (
                <div
                  className="h-9 flex gap-1 items-center py-1 px-3 text-[#48464C] text-sm bg-[#EBEBEB] rounded"
                  key={idx}
                >
                  <p className="max-w-[100px] truncate" title={fileNames[idx]}>
                    {fileNames[idx]}
                  </p>
                  <FileDownloadOutlinedIcon
                    className="cursor-pointer hover:scale-105"
                    onClick={() => {
                      let downloadLink = item;
                      window.open(downloadLink, "_blank").focus();
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <p className="p-1 rounded text-[#938F96] text-xs font-medium bg-[#F5F5F5] h-fit">
        {dateTime}
      </p>
      <div className="h-[1px] bg-[#EBEBEB] absolute bottom-0 left-0 right-0" />
    </div>
  );
};

const RequestComments = ({ requestId = "" }) => {
  const [focusType, setFocusType] = useState(false);
  const [conversation, setConversation] = useState([]);
  const fileRef = useRef();
  const msgRef = useRef();
  const toast = useToast();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { auth } = useContext(NavContext);

  const getUserComment = async () => {
    try {
      const param = {
        userRequestId: requestId,
      };
      const response = await axios.get(
        baseURL + "v2/aisandbox/userRequests/comment/",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
          params: param,
        }
      );
      if (response?.status === 200) {
        setConversation(response?.data?.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postUserComment = async () => {
    try {
      if (!msgRef?.current?.value?.trim()) {
        toast({
          title: "Msg cannot be empty",
          status: "error",
          position: "top-right",
          duration: 3000,
        });
        return;
      }

      const requestData = JSON.stringify({
        userRequestId: requestId,
        msg: msgRef?.current?.value,
      });
      const formData = new FormData();
      selectedFiles?.map((item) => formData?.append("files", item));
      let userRequestCommentBlob = new Blob([requestData], {
        type: "application/json",
      });
      formData?.append("userRequestComment", userRequestCommentBlob);
      const response = await axios.post(
        baseURL + "v2/aisandbox/userRequests/comment/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status === 200) {
        getUserComment();
        setSelectedFiles([]);
        msgRef.current.value = "";
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let elem = document.getElementById("comment" + (conversation?.length - 1));
    if (elem) {
      elem?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [conversation]);

  useEffect(() => {
    getUserComment();
  }, []);

  return (
    <div
      className="relative flex flex-col gap-0 rounded-lg"
      style={{
        boxShadow:
          "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
      }}
    >
      <div className="w-full font-medium text-start py-[10px] px-4 rounded-t-lg bg-[#6CA6FC] text-white text-base">
        Recent response
      </div>
      <div className="flex flex-col min-h-[370px] gap-4 bg-white p-4 rounded-b-lg border-x border-b border-[#EBEBEB]">
        {/*chat history */}
        <div className="flex flex-col gap-4 w-full overflow-y-auto max-h-[240px]">
          {conversation?.map((item, idx) => (
            <CommentBar
              key={idx}
              index={idx}
              img={item?.profileImage ?? null}
              name={item?.userName}
              msg={item?.msg}
              dateTime={new Date(item?.createdAt)?.toLocaleString("en-GB")}
              userEmail={item?.email}
              files={item?.fileUrls?.split(",")}
              fileNames={item?.fileNames?.split(",")}
            />
          ))}
        </div>
        {/*User inputs */}
        <div
          className="absolute bottom-4 left-4 right-4 flex flex-col gap-2 px-4 pb-2 rounded-lg"
          style={{
            backgroundColor: focusType ? "#F5F9FF" : "#FAFAFA",
            border: focusType ? "1px solid #6CA6FC" : "",
            boxShadow: focusType
              ? "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);"
              : "",
          }}
          onFocus={() => setFocusType(true)}
          onBlur={() => setFocusType(false)}
        >
          <InputGroup>
            <Input
              placeholder="Type message..."
              variant={"flushed"}
              _focusVisible={{
                borderBottom: "1px solid #EBEBEB",
              }}
              ref={msgRef}
              onKeyDownCapture={(e) => {
                if (e.code === "Enter") postUserComment();
              }}
            />
            <InputRightElement>
              <IconButton
                icon={<SendIcon sx={{ color: "#084298" }} />}
                onClick={postUserComment}
                colorScheme="facebook"
                variant={"ghost"}
              />
            </InputRightElement>
          </InputGroup>
          {selectedFiles?.length > 0 && (
            <div className="flex flex-wrap gap-2 py-2 items-center w-full">
              {selectedFiles?.map((file, idx) => {
                return (
                  <div
                    key={idx}
                    className="relative rounded border border-[#CAC5CD] w-[10vw]"
                    style={{
                      boxShadow:
                        "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)",
                    }}
                  >
                    <div className="flex gap-2 w-full items-center h-full p-2">
                      <DescriptionIcon />
                      <p className="w-full truncate" title={file.name}>
                        {file.name}
                      </p>
                    </div>
                    <CancelRoundedIcon
                      sx={{ color: "#938F96" }}
                      className="cursor-pointer hover:scale-105 absolute -top-2 -right-2"
                      onClick={() =>
                        setSelectedFiles((prev) => {
                          let newData = [...prev];
                          let index = newData?.findIndex(
                            (val) => val?.name === file?.name
                          );
                          if (index !== -1) newData?.splice(index, 1);
                          return newData;
                        })
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}
          <input
            style={{ display: "none" }}
            multiple
            type="file"
            ref={fileRef}
            onChange={(e) => {
              let files = e.target.files;
              console.log(files);
              setSelectedFiles((prev) => [...prev, ...files]);
              fileRef.current.files = null;
            }}
          />
          <IconButton
            icon={<UploadFileIcon sx={{ color: "#938F96" }} />}
            colorScheme="gray"
            variant={"ghost"}
            onClick={() => fileRef?.current?.click()}
            width={"fit-content"}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestComments;
