import React, { useState, useContext, useEffect } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  Input,
  Select,
  useToast,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";

import NavContext from "../../NavContext";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { baseURL } from "../../..";
import axios from "axios";
import {
  Autocomplete,
  createTheme,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const queryTypes = [
  {
    type: "QUERY",
    role: "all",
  },
  {
    type: "BUG",
    role: "all",
  },
  {
    type: "REQUEST",
    role: "all",
  },
  {
    type: "REPORT",
    role: "all",
  },
  {
    type: "OTHERS",
    role: "all",
  },
  {
    type: "CHANGE_SUBSCRIPTION",
    role: "ADMIN",
  },
  {
    type: "CHANGE_CONFIGURATION",
    role: "ADMIN",
  },
];

const AddRequestModal = ({
  isOpen,
  onClose,
  clientId,
  clientOrg,
  setSelectedSubscription = null,
  setSubscriptionModalData = () => {},
}) => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const { auth, organisation, email, userType } = useContext(NavContext);
  const [requestForOther, setRequestForOther] = useState(
    setSelectedSubscription ? 1 : 0
  );
  const toast = useToast();
  const [subscriptions, setSubcriptions] = useState([]);
  const [userState, setUserState] = useState({
    queryType: "",
    email: setSelectedSubscription ? null : email,
    subscriptionId: "",
    selectedSubscriptionData: null,
  });

  const handleChange = (name, value) => {
    setUserState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const routeToForm = () => {
    if (
      ["CHANGE_SUBSCRIPTION", "CHANGE_CONFIGURATION"]?.includes(
        userState?.queryType
      ) &&
      userState?.selectedSubscriptionData?.subscriptionConfigurationRequested
    ) {
      toast({
        title: "Error",
        description:
          userState?.queryType +
          " request is already raised for this subscription, Please wait till it is closed",
        status: "error",
        position: "top-right",
        duration: 6000,
        isClosable: true,
      });
      return;
    }
    if (
      !userState?.queryType ||
      !userState?.email ||
      !userState?.subscriptionId
    ) {
      toast({
        title: "Error",
        description: (
          <div>
            {!userState?.queryType && <p>Please select request type</p>}
            {!userState?.email && <p>Please select email</p>}
            {!userState?.subscriptionId && <p>Please select subscription</p>}
          </div>
        ),
        status: "error",
        position: "top-right",
        duration: 6000,
        isClosable: true,
      });
      return;
    }
    let data = userState?.selectedSubscriptionData;
    const isExistingUser = !(data?.configurationCount === 0);
    let param = "isExisting=true";
    if (!isExistingUser) {
      param = "isFirstTime=true";
    }
    switch (userState?.queryType) {
      case "CHANGE_CONFIGURATION":
        if (setSelectedSubscription) {
          setSelectedSubscription((prev) => ({
            clientId: clientId,
            subscriptionId: data?.subscriptionId,
            subscriptionType: data?.subscriptionType,
            configurationNo: data?.configurationCount,
            isExistingUser: !(data?.configurationCount === 0),
            lastUpdatedDate: new Date(data?.lastUpdatedAt)?.toLocaleString(
              "en-GB"
            ),
          }));
        } else {
          let paramString = `?clientId=${clientId}&subscriptionId=${
            userState?.subscriptionId
          }&subscriptionType=${data?.subscriptionType}&configurationNo=${
            data?.configurationCount
          }&lastUpdatedDate=${new Date(data?.lastUpdatedAt)?.toLocaleString(
            "en-GB"
          )}&${param}`;
          navigate(`/admin/configuresubscription${paramString}`);
        }
        break;
      case "CHANGE_SUBSCRIPTION":
        onClose();
        setSubscriptionModalData((prev) => ({
          ...prev,
          open: true,
          rowData: {
            subscriptionId: data?.subscriptionId,
            subscriptionType: data?.subscriptionType,
          },
        }));
        break;
      default:
        if (setSelectedSubscription) {
          setSelectedSubscription((prev) => ({
            type: "requestDetail",
            clientId: clientId,
            queryType: userState?.queryType,
            email: userState?.email,
            subscriptionId: data?.subscriptionId,
          }));
        } else {
          let paramString = `?clientId=${clientId}&email=${userState?.email}&queryType=${userState?.queryType}&subscriptionId=${userState?.subscriptionId}`;
          navigate(`/admin/requestDetails${paramString}`);
        }
        break;
    }
  };

  const getUsers = async () => {
    try {
      const param = {
        organisation: clientOrg ? clientOrg : organisation,
      };
      const response = await axios.get(baseURL + "iam/users", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
        params: param,
      });
      if (response?.data?.length > 0) {
        let data = response?.data
          ?.map((item) => item?.email)
          ?.filter((item) => item !== email);
        setUsers(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserSubscriptions = async () => {
    try {
      const param = {
        requestedEmail: userState?.email,
      };
      const response = await axios?.get(
        baseURL + "v2/aisandbox/subscription/userSpecific",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
          params: param,
        }
      );
      if (response?.status === 200) {
        setSubcriptions(response?.data);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (userState?.email) getUserSubscriptions();
  }, [userState?.email]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay zIndex={1200} />
      <ModalContent containerProps={{ zIndex: 1200 }} maxW={"400px"}>
        <div className="text-white w-full h-12  flex bg-[#2660B6] font-semibold justify-center items-center rounded-t-md">
          Add New Request
        </div>
        <ModalCloseButton color={"white"} />
        <ModalBody padding={"24px"}>
          <div className="flex flex-col gap-4">
            {userType?.includes("ADMIN") && !setSelectedSubscription && (
              <RadioGroup
                onChange={(e) => {
                  let val = Number(e);
                  setRequestForOther(val);
                  if (val) handleChange("email", null);
                  else handleChange("email", email);
                }}
                value={requestForOther}
              >
                <div className="flex gap-2 items-center">
                  {[
                    { title: "Raising for myself", value: 0 },
                    { title: "Raising for other", value: 1 },
                  ]?.map((item, idx) => {
                    let isMatch = item?.value === requestForOther;
                    return (
                      <div
                        style={{
                          backgroundColor: isMatch ? "#DDEEFF80" : "#FFF",
                        }}
                        className="w-fit rounded-lg"
                      >
                        <Radio
                          key={idx}
                          value={item?.value}
                          p={"8px"}
                          pr={"12px"}
                          rounded={"8px"}
                          fontSize={"14px"}
                          fontWeight={500}
                          color={"#3E3C42"}
                          _checked={{
                            bg: "#6CA6FC",
                            borderColor: "#6CA6FC",
                          }}
                          _hover={{
                            borderColor: "#6CA6FC",
                          }}
                        >
                          {item?.title}
                        </Radio>
                      </div>
                    );
                  })}
                </div>
              </RadioGroup>
            )}
            <div className="flex flex-col gap-3">
              <p className="text-[#2660B6] text-sm font-medium">
                User Id<span className="text-red-700">*</span>
              </p>
              {requestForOther ? (
                <ThemeProvider theme={createTheme()}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    isOptionEqualToValue={(option, value) => option === value}
                    value={userState?.email}
                    onChange={(e, newVal) => handleChange("email", newVal)}
                    options={users}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select email" />
                    )}
                  />
                </ThemeProvider>
              ) : (
                <p className="text-[#48464C] text-base">{email}</p>
              )}
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-[#2660B6] text-sm font-medium">
                Subscription Id<span className="text-red-700">*</span>
              </p>
              <Select
                value={userState?.subscriptionId}
                onChange={(e) => {
                  let value = e.target.value;
                  let selectedData = subscriptions?.find(
                    (item) => item.subscriptionId === value
                  );
                  handleChange("subscriptionId", value);
                  handleChange(
                    "selectedSubscriptionData",
                    selectedData ?? null
                  );
                }}
              >
                <option value={""}>Select subscription</option>
                {subscriptions?.map((item, idx) => (
                  <option key={idx} value={item?.subscriptionId}>
                    {item?.subscriptionType} (
                    {item?.locations
                      ?.map((val) => val?.locationName)
                      ?.join(", ")}
                    ) ({item?.supportType})
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-[#2660B6] text-sm font-medium">Product name</p>
              <p className="text-[#48464C] text-base">AI Studio</p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-[#2660B6] text-sm font-medium">
                Request type<span className="text-red-700">*</span>
              </p>
              <Select
                value={userState?.queryType}
                onChange={(e) => handleChange("queryType", e.target.value)}
              >
                <option value={""}>Select request type</option>
                {queryTypes
                  ?.filter(
                    (item) =>
                      item?.role === "all" || userType?.includes(item?.role)
                  )
                  ?.map((item, idx) => (
                    <option key={idx} value={item?.type}>
                      {item?.type}
                    </option>
                  ))}
              </Select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          flexDirection={"row"}
          display={"flex"}
          gap={"4px"}
          justifyContent={"start"}
          onClick={routeToForm}
        >
          <PrimaryButton text={"Save"} width="80px" />
          <TonalButton text={"Discard"} width="80px" onClick={onClose} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddRequestModal;
