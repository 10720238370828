import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { baseURL } from "../..";
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useWindowSize } from "@uidotdev/usehooks";

const ProfileNav = ({ setLogin }) => {
  const size = useWindowSize();
  const param = useAuth0();
  const [nav, setNav] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setAuthToken(localStorage.getItem("auth_token"));
  }, []);

  const handleLogout = async () => {
    try {
      const data = await fetch(baseURL + "logout", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": authToken,
        },
      });
      if (data.status === 200) {
        localStorage.clear();
        if (param?.isAuthenticated) {
          param.logout({
            logoutParams: {
              returnTo: window.location.origin + "/",
            },
          });
        } else {
          navigate("/");
          window.location.reload();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setNav(false)}>
        <div className="relative flex flex-row-reverse gap-1 text-white text-sm font-medium p-[6px] items-center rounded-lg">
          <AccountCircleOutlinedIcon
            onClick={() => setNav(!nav)}
            sx={{ fontSize: "24px" }}
            className="hover:rotate-12 cursor-pointer"
          />
          {size?.width >= 768 && <p>User</p>}
          {nav === true ? (
            <div
              className="absolute right-0 top-6 z-10 mt-2 w-40 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div className="py-1" role="none">
                <Link
                  to="/profile"
                  onClick={() => setNav(false)}
                  class="text-gray-700 block px-4 py-2 text-sm flex items-center hover:bg-gray-100 gap-3 hover:transition duration-200"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-0"
                >
                  <img src="/profile_dropdown.svg" />
                  <p style={{ marginLeft: "1px" }}>Profile</p>
                </Link>
                <button
                  onClick={handleLogout}
                  className="text-gray-700 block w-full px-4 py-2 text-left text-sm flex items-center hover:bg-gray-100 gap-2 hover:transition duration-200"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-3"
                >
                  <img src="/logout.svg" />
                  Logout
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
    </>
  );
};

export default ProfileNav;
