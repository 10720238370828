import {
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { baseURL } from "../../../index";
import axios from "axios";
import NavContext from "../../NavContext";

const SubscriptionForm = ({
  inputFields = [
    {
      text: "",
      handleChange: () => {},
      defaultValue: 0,
      valueName: "",
      max: 0,
      min: 0,
    },
  ],
  viewOnly = false,
  additionElem = <img />,
  headerElem = <img />,
  children,
}) => {
  const size = useWindowSize();
  return (
    <div className="flex flex-col gap-6 p-6 rounded-xl justify-between border border-[#CAC5CD] shadow-md w-full sm:w-fit">
      {headerElem}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-6 relative gap-x-8 w-full sm:w-fit">
        {inputFields?.map((item, idx) => {
          return (
            <div
              className={`flex flex-col gap-3 ${
                item?.singleColPosition && size.width <= 640
                  ? item?.singleColPosition
                  : ""
              }`}
              key={idx}
            >
              <p className="text-[#2660B6] text-sm font-medium">
                {item?.text} ({item?.min + "-" + item?.max})
              </p>
              <NumberInput
                size="sm"
                width={"250px"}
                defaultValue={item?.defaultValue ?? 0}
                min={item?.min}
                max={item?.max}
                value={item?.defaultValue}
                isDisabled={viewOnly}
                onChange={(val) => {
                  if (!isNaN(val)) {
                    let value = Number(val);
                    item?.handleChange(item?.valueName, value);
                  }
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </div>
          );
        })}
        <div className="w-full sm:w-[1px] h-[1px] sm:h-full absolute top-[47%] sm:top-0 left-0 sm:left-[50%] bg-[#CAC5CD]" />
        {additionElem}
      </div>
      {children}
    </div>
  );
};

const ConfigureSubscription = ({
  selectedSubscription = null,
  setSelectedSubscription = () => {},
}) => {
  const [userSubscriptionState, setUserSubscriptionState] = useState({
    userCount: 5,
    liveAiCount: 5,
    userRepoCount: 5,
    liveDataCount: 1,
    modelUserCount: 5,
    userAppCount: 5,
  });
  const [additionalRequestState, setAdditionalRequestState] = useState({
    userCount: "",
    liveAiCount: "",
    userRepoCount: "",
    liveDataCount: "",
    modelUserCount: "",
    userAppCount: "",
  });
  const [data, setData] = useState({});
  const size = useWindowSize();
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const isExisting = selectedSubscription
    ? selectedSubscription?.isExistingUser
    : searchParams?.get("isExisting");
  const isFirstTime = selectedSubscription
    ? !selectedSubscription?.isExistingUser
    : searchParams?.get("isFirstTime");
  const configurationNo = selectedSubscription
    ? selectedSubscription?.configurationNo
    : searchParams?.get("configurationNo");
  const lastUpdatedDate = selectedSubscription
    ? selectedSubscription?.lastUpdatedDate
    : searchParams?.get("lastUpdatedDate");
  const subscriptionType = selectedSubscription
    ? selectedSubscription?.subscriptionType
    : searchParams?.get("subscriptionType");
  const subscriptionId = selectedSubscription
    ? selectedSubscription?.subscriptionId
    : searchParams?.get("subscriptionId");
  const clientId = selectedSubscription
    ? selectedSubscription?.clientId
    : searchParams?.get("clientId");
  const navigate = useNavigate();

  const handleChange = (name, value) => {
    if (!isExisting) {
      setUserSubscriptionState((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else
      setAdditionalRequestState((prev) => ({
        ...prev,
        [name]: value,
      }));
  };

  const checkValues = () => {
    let val = true;
    if (isExisting) {
      if (
        additionalRequestState?.userAppCount > data?.maxLimit?.appLimit ||
        additionalRequestState?.userAppCount < data?.minLimit?.appLimit
      ) {
        toast({
          title: "Error",
          status: "error",
          description: "App per user value is not in range",
          position: "top-right",
          duration: 6000,
          isClosable: true,
        });
        val = false;
      } else
        for (let i in additionalData) {
          let obj = additionalData[i];
          if (obj?.defaultValue > obj.max || obj?.defaultValue < obj.min) {
            toast({
              title: "Error",
              status: "error",
              description: obj?.text + " value is not in range",
              position: "top-right",
              duration: 6000,
              isClosable: true,
            });
            val = false;
            break;
          }
        }
    } else {
      if (
        userSubscriptionState?.userAppCount > data?.maxLimit?.appLimit ||
        userSubscriptionState?.userAppCount < data?.minLimit?.appLimit
      ) {
        toast({
          title: "Error",
          status: "error",
          description: "App per user value is not in range",
          position: "top-right",
          duration: 6000,
          isClosable: true,
        });
        val = false;
      } else
        for (let i in firstTimeData) {
          let obj = firstTimeData[i];
          if (obj?.defaultValue > obj.max || obj?.defaultValue < obj.min) {
            toast({
              title: "Error",
              status: "error",
              description: obj?.text + " value is not in range",
              position: "top-right",
              duration: 6000,
              isClosable: true,
            });
            val = false;
            break;
          }
        }
    }
    return val;
  };

  const appUserInput = (val, bypass = false) => (
    <div className="col-span-full flex items-center justify-between gap-6 flex-wrap">
      <div className="flex flex-col gap-3">
        <p className="text-[#2660B6] text-sm font-medium">
          App per user ({data?.minLimit?.appLimit} - {data?.maxLimit?.appLimit})
        </p>
        <NumberInput
          size="sm"
          width={"250px"}
          defaultValue={val?.userAppCount ?? 0}
          min={data?.minLimit?.appLimit}
          max={data?.maxLimit?.appLimit}
          value={val?.userAppCount}
          isDisabled={isExisting && !bypass}
          onChange={(val) => {
            if (!isNaN(val)) {
              let value = Number(val);
              handleChange("userAppCount", value);
            }
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </div>
      <div className="flex gap-2 items-center self-end">
        <PrimaryButton
          text={"Save"}
          width={"fit-content"}
          onClick={() =>
            saveConfigValues({
              val: val,
              endPoint: "v2/aisandbox/subscription/saveResourceLimit/",
            })
          }
          disable={isExisting && !bypass}
        />
        <TonalButton
          text={"Submit request"}
          width={"fit-content"}
          onClick={() =>
            saveConfigValues({
              val: val,
              endPoint: "v2/aisandbox/subscription/requestResourceLimit/",
              requestSubmit: true,
            })
          }
          disable={isExisting && !bypass}
        />
      </div>
    </div>
  );

  const firstTimeData = [
    {
      text: "Number of users",
      handleChange: handleChange,
      defaultValue: userSubscriptionState?.userCount,
      valueName: "userCount",
      min: data?.minLimit?.userLimit,
      max: data?.maxLimit?.userLimit,
    },
    {
      text: "Number of Live AI Inferences (App)",
      handleChange: handleChange,
      defaultValue: userSubscriptionState?.liveAiCount,
      valueName: "liveAiCount",
      min: data?.minLimit?.clientAppLimit,
      max: data?.maxLimit?.clientAppLimit,
      singleColPosition: "row-start-1",
    },
    {
      text: "Data repo per users",
      handleChange: handleChange,
      defaultValue: userSubscriptionState?.userRepoCount,
      valueName: "userRepoCount",
      min: data?.minLimit?.dataRepoLimit,
      max: data?.maxLimit?.dataRepoLimit,
    },
    {
      text: "Live feeds for data repo only",
      handleChange: handleChange,
      defaultValue: userSubscriptionState?.liveDataCount,
      valueName: "liveDataCount",
      min: data?.minLimit?.liveDataLimit,
      max: data?.maxLimit?.liveDataLimit,
      singleColPosition: "row-start-2",
    },
    {
      text: "Models per user (user defined)",
      handleChange: handleChange,
      defaultValue: userSubscriptionState?.modelUserCount,
      valueName: "modelUserCount",
      min: data?.minLimit?.modelLimit,
      max: data?.maxLimit?.modelLimit,
    },
  ];

  const additionalData = [
    {
      text: "Number of users",
      handleChange: handleChange,
      defaultValue: additionalRequestState?.userCount,
      valueName: "userCount",
      min: data?.minLimit?.userLimit,
      max: data?.maxLimit?.userLimit,
    },
    {
      text: "Number of Live AI Inferences (App)",
      handleChange: handleChange,
      defaultValue: additionalRequestState?.liveAiCount,
      valueName: "liveAiCount",
      min: data?.minLimit?.clientAppLimit,
      max: data?.maxLimit?.clientAppLimit,
      singleColPosition: "row-start-1",
    },
    {
      text: "Data repo per users",
      handleChange: handleChange,
      defaultValue: additionalRequestState?.userRepoCount,
      valueName: "userRepoCount",
      min: data?.minLimit?.dataRepoLimit,
      max: data?.maxLimit?.dataRepoLimit,
    },
    {
      text: "Live feeds for data repo only",
      handleChange: handleChange,
      defaultValue: additionalRequestState?.liveDataCount,
      valueName: "liveDataCount",
      min: data?.minLimit?.liveDataLimit,
      max: data?.maxLimit?.liveDataLimit,
      singleColPosition: "row-start-2",
    },
    {
      text: "Models per user (user defined)",
      handleChange: handleChange,
      defaultValue: additionalRequestState?.modelUserCount,
      valueName: "modelUserCount",
      min: data?.minLimit?.modelLimit,
      max: data?.maxLimit?.modelLimit,
    },
  ];

  const getConfigValues = async () => {
    try {
      const param = {
        subscriptionId,
        subscriptionType,
        clientId,
      };
      const response = await axios?.get(
        baseURL + "v2/aisandbox/subscription/resourceLimit/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status === 200) {
        setData(response?.data);
        //user saved values
        let {
          appLimit,
          clientAppLimit,
          dataRepoLimit,
          liveDataLimit,
          modelLimit,
          userLimit,
        } =
          isExisting || !response?.data?.saveLimit
            ? response?.data?.currentLimit
            : response?.data?.saveLimit;
        setUserSubscriptionState((prev) => ({
          ...prev,
          userCount: userLimit,
          liveAiCount: clientAppLimit,
          userRepoCount: dataRepoLimit,
          liveDataCount: liveDataLimit,
          modelUserCount: modelLimit,
          userAppCount: appLimit,
        }));
        if (isExisting && response?.data?.saveLimit) {
          let savedData = response?.data?.saveLimit;
          setAdditionalRequestState((prev) => ({
            ...prev,
            userCount: savedData?.userLimit,
            liveAiCount: savedData?.clientAppLimit,
            userRepoCount: savedData?.dataRepoLimit,
            liveDataCount: savedData?.liveDataLimit,
            modelUserCount: savedData?.modelLimit,
            userAppCount: savedData?.appLimit,
          }));
        }
      }
    } catch (error) {
      console.log(error);
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const saveConfigValues = async ({
    val = {},
    endPoint = "",
    requestSubmit = false,
  }) => {
    if (!checkValues()) return;
    try {
      const requestBody = JSON.stringify({
        clientId: clientId,
        subscriptionId: subscriptionId,
        userLimit: val?.userCount,
        userAppLimit: val?.userAppCount,
        userDataRepoLimit: val?.userRepoCount,
        userModelLimit: val?.modelUserCount,
        userLiveDataLimit: val?.liveDataCount,
        clientAppLimit: val?.liveAiCount,
      });
      const response = await axios.post(baseURL + endPoint, requestBody, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response?.status === 200) {
        toast({
          title: requestSubmit
            ? "Request has been submitted"
            : "Configuration draft saved",
          description:
            requestSubmit && "You can view the request at request page",
          status: "success",
          duration: 3000,
          position: "top-right",
        });
        if (!selectedSubscription) navigate(-1);
        else setSelectedSubscription(null);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    getConfigValues();
  }, []);

  const total = (val) => val?.liveAiCount + val?.liveDataCount;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2 items-center">
        <img
          src="/backtick.svg"
          onClick={() =>
            !selectedSubscription ? navigate(-1) : setSelectedSubscription(null)
          }
          className="cursor-pointer"
          alt=""
        />
        <p className="text-xl font-medium text-[#084298]">
          Configure subscription
        </p>
      </div>
      <div className="flex flex-col xl:flex-row w-fit gap-6 justify-between bg-white p-2 rounded-xl">
        {/*first time & reference form */}
        <SubscriptionForm
          inputFields={firstTimeData}
          additionElem={
            <div
              className={`${
                size?.width <= 640 ? "row-start-3" : ""
              } p-2 rounded w-[250px] bg-[#F5F5F5] text-[#3E3C42] h-fit self-end text-base flex justify-between items-center`}
            >
              <p>
                Total live feeds{" "}
                {total(userSubscriptionState)
                  ? `(${userSubscriptionState?.liveAiCount} +
                  ${userSubscriptionState?.liveDataCount})`
                  : ""}
              </p>
              <p>{total(userSubscriptionState)}</p>
            </div>
          }
          headerElem={
            <div className="flex items-center justify-between gap-4 flex-wrap">
              {!isFirstTime ? (
                <div className="flex flex-col gap-1">
                  <p className="text-xl font-medium text-[#3E3C42]">
                    Current configuration
                  </p>
                  <p className="text-[#AEA9B1] text-sm">Current plan</p>
                </div>
              ) : (
                <p className="text-xl font-medium text-[#3E3C42]">
                  Setup current configuration
                </p>
              )}
              {!isFirstTime && (
                <>
                  <div className="flex flex-col gap-1">
                    <p className="text-sm text-[#AEA9B1]">
                      Configuration Number
                    </p>
                    <p className="text-[#605D64] text-sm font-bold">
                      {configurationNo}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-sm text-[#AEA9B1]">Last Updated On</p>
                    <p className="text-[#605D64] text-sm font-bold">
                      {lastUpdatedDate}
                    </p>
                  </div>
                </>
              )}
            </div>
          }
          viewOnly={isExisting}
        >
          {appUserInput(userSubscriptionState)}
        </SubscriptionForm>

        {/*Existing form */}
        {isExisting && (
          <SubscriptionForm
            inputFields={additionalData}
            additionElem={
              <div
                className={`${
                  size?.width <= 640 ? "row-start-3" : ""
                } p-2 rounded w-[250px] bg-[#F5F5F5] text-[#3E3C42] h-fit self-end text-base flex justify-between items-center`}
              >
                <p>
                  Total live feeds{" "}
                  {total(additionalRequestState)
                    ? `(${additionalRequestState?.liveAiCount} +
                  ${additionalRequestState?.liveDataCount})`
                    : ""}
                </p>
                <p>{total(additionalRequestState)}</p>
              </div>
            }
            headerElem={
              <p className="text-xl font-medium text-[#3E3C42]">
                Update configuration request
              </p>
            }
          >
            {appUserInput(additionalRequestState, true)}
          </SubscriptionForm>
        )}
      </div>
    </div>
  );
};

export default ConfigureSubscription;
