import { useEffect, useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import {
  CustomRadio,
  FieldLabel,
  NavigateBack,
  UploadButton,
  CommonProgressBar,
  CommonUniqueTextCheck,
} from "../Common/CommonComponents";
import { callApi } from "../utils/ApiUtil";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import {
  ImageVideoFileChecker,
  rtspPingCheck,
  rtspRegex,
  videoInfoReturn,
} from "../utils/HandlerFuncs";
import { useLocation, useNavigate } from "react-router-dom";
import RtspModal from "./Components/RtspModal";
import { mixpanelDataRepoCreateTrack } from "../utils/MixpanelEvents";
import { OutlinedInput } from "@mui/material";

const AddData = ({ searchParams }) => {
  const fileRef = useRef();
  const folderRef = useRef();
  const toast = useToast();
  const repoNameRef = useRef();
  const LinkRef = useRef();
  const [openModal, setOpenModal] = useState({
    open: false,
    id: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const [pollFrames, setPollFrames] = useState({
    id: "",
    jobIds: [],
  });
  const repoName = searchParams?.get("repoName") ?? "";
  const repoId = searchParams?.get("repoId");
  const rtsp = searchParams?.get("rtsp") ?? "";
  const modelId = searchParams?.get("modelId") ?? "";
  const modelRoute = `/SandboxV2/draft/retrain?modelId=${modelId}`;
  const [selectedDataType, setSelectedDataType] = useState(
    searchParams?.get("dataType") ?? ""
  );
  const dataOptions = ["image", "video"];

  const createRepo = async () => {
    try {
      const requestData = JSON.stringify({
        name: repoNameRef?.current?.value,
        dataType: selectedDataType?.toUpperCase(),
      });
      const response = await callApi({
        toast: toast,
        type: "post",
        endPoint: "selfserve/v2/project/v1/dataRepository/",
        requestBody: requestData,
      });
      if (response?.status === 200) {
        let id = response?.data?.masterObjectId;
        if (selectedDataType === "image") uploadData(id);
        else if (LinkRef?.current?.value)
          setOpenModal((prev) => ({
            ...prev,
            open: true,
            id: id,
          }));
        else uploadVideoApi(id);
      }
    } catch (error) {
      toast({
        title: "Error in data processing",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      console.log(error);
    }
  };

  const uploadData = async (id) => {
    const param = {
      masterObjectId: id,
    };
    const files = Array.from(selectedFiles);
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    try {
      let chunkSize = 90;
      const totalChunks = Math.ceil(files.length / chunkSize);
      let completedChunks = 0;
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = (i + 1) * chunkSize;
        const filesChunk = files.slice(start, end);
        let requestData = new FormData();
        filesChunk.map((x) => {
          requestData.append("files", x);
        });
        let res = await callApi({
          toast: toast,
          endPoint: "selfserve/v2/project/v1/image/",
          type: "post",
          isFormData: true,
          params: param,
          requestBody: requestData,
        });
        if (res?.status === 200) {
          completedChunks++;
          if (completedChunks == totalChunks) {
            setProgressState((prev) => ({ ...prev, state: 2 }));
            let repoRoute = `/SandboxV2/view/data?repoId=${id}&repoName=${
              repoNameRef?.current?.value
            }&dataType=${selectedDataType?.toLowerCase()}`;
            setTimeout(
              () =>
                navigate(
                  modelId !== "" ? modelRoute + `&repoId=${id}` : repoRoute,
                  {
                    state: {
                      prevPath: location?.pathname + location?.search,
                    },
                  }
                ),
              2000
            );
            mixpanelDataRepoCreateTrack({
              repoId: id,
              repoName: repoNameRef?.current?.value,
              repoType: selectedDataType,
              rtspLink: LinkRef?.current?.value,
              fileCount: selectedFiles?.length,
            });
          }
        } else {
          throw new Error("upload failed"); // Throw error to trigger catch block
        }
      }
    } catch (error) {
      setProgressState((prev) => ({ ...prev, state: 0 }));
      console.log(error);
    }
  };

  const uploadVideoApi = async (id) => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    try {
      const totalChunks = selectedFiles.length;
      let completedChunks = 0;
      for (let i = 0; i < totalChunks; i++) {
        const videoFile = selectedFiles[i];
        const val = await videoInfoReturn(videoFile);
        let param = {
          masterObjectId: id,
          length: val?.duration,
          dimension: `${val?.videoWidth}x${val?.videoHeight}`,
          codec: "h.264",
          fps: 30,
          sizeBytes: videoFile?.size,
        };
        let requestData = new FormData();
        requestData.append("files", videoFile);
        const response = await callApi({
          type: "post",
          toast: toast,
          endPoint: "selfserve/v2/project/v1/videofeed/",
          params: param,
          requestBody: requestData,
          isFormData: true,
        });
        if (response?.status === 200) {
          completedChunks++;
          if (completedChunks === totalChunks) {
            setPollFrames((prev) => ({
              ...prev,
              id: id,
              jobIds: [
                ...prev["jobIds"],
                Object?.entries(response?.data?.videoDataset)[0][0],
              ],
            }));
          } else
            setPollFrames((prev) => ({
              ...prev,
              jobIds: [
                ...prev["jobIds"],
                Object?.entries(response?.data?.videoDataset)[0][0],
              ],
            }));
        } else throw new Error("upload failed");
      }
    } catch (error) {
      console.log(error);
      setProgressState((prev) => ({ ...prev, state: 0 }));
    }
  };

  const getUploadVideoStatus = async (intervalId) => {
    try {
      const param = {
        masterObjectId: pollFrames?.id,
        videoDatasetId: pollFrames?.jobIds?.join(","),
      };
      const response = await callApi({
        type: "get",
        toast: toast,
        endPoint: "selfserve/v2/project/v1/video/status/",
        params: param,
      });
      if (response?.status === 200) {
        let proccessingDone = response?.data?.isVideoProcessed;
        if (proccessingDone) {
          clearInterval(intervalId);
          setProgressState((prev) => ({ ...prev, state: 2 }));
          let repoRoute = `/SandboxV2/view/data?repoId=${
            pollFrames?.id
          }&repoName=${
            repoNameRef?.current?.value
          }&dataType=${selectedDataType?.toLowerCase()}`;
          setTimeout(
            () =>
              navigate(
                modelId !== ""
                  ? modelRoute + `&repoId=${pollFrames?.id}`
                  : repoRoute,
                {
                  state: {
                    prevPath: location?.pathname + location?.search,
                  },
                }
              ),
            2000
          );
        }
      } else throw new Error("polling failed");
    } catch (error) {
      console.log(error);
      clearInterval(intervalId);
      setProgressState((prev) => ({ ...prev, state: 0 }));
    }
  };

  const handleSubmit = async () => {
    if (
      !repoNameRef?.current?.value ||
      (selectedFiles.length === 0 && !LinkRef?.current?.value) ||
      !selectedDataType
    ) {
      toast({
        title: "Missing data",
        description: "Please ensure mandatory feilds are filled",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (LinkRef?.current?.value && !rtspRegex?.test(LinkRef?.current?.value)) {
      toast({
        title: "Invalid rtsp",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      return;
    } else if (LinkRef?.current?.value) {
      let res = await rtspPingCheck({
        uuid: "unique_Id_" + Math.random() * 1000000,
        toast: toast,
        rtsp: LinkRef?.current?.value,
        setProgressState: setProgressState,
      });
      if (!res) return;
    }
    if (repoId) {
      if (selectedDataType === "image") uploadData(repoId);
      else if (LinkRef?.current?.value)
        setOpenModal((prev) => ({
          ...prev,
          open: true,
          id: repoId,
        }));
      else uploadVideoApi(repoId);
    } else createRepo();
  };

  useEffect(() => {
    if (pollFrames?.id !== "" && pollFrames?.jobIds?.length > 0) {
      let intervalId = setInterval(() => {
        getUploadVideoStatus(intervalId);
      }, 10 * 1000);

      return () => clearInterval(intervalId);
    }
  }, [pollFrames]);

  return (
    <div className="flex flex-col gap-2">
      <NavigateBack
        targetRoute={modelId !== "" ? modelRoute : -1}
        textArray={
          repoName
            ? ["AI Studio", repoName, "Add data"]
            : ["AI Studio", "Add new data"]
        }
      />
      <div className="flex flex-col gap-4 justify-between bg-white rounded-xl items-start w-full h-fit p-5">
        <div className="flex flex-col gap-6 w-full">
          <p className="text-[#3E3C42] text-xl font-medium">Add new data</p>
          <div className="flex flex-col gap-3">
            <FieldLabel text="Provide Data Feed/Repo Name" />
            <OutlinedInput
              inputRef={repoNameRef}
              placeholder="Enter data feed name"
              sx={{
                width: "300px",
              }}
              size="small"
              defaultValue={repoName}
              disabled={repoName !== ""}
            />
          </div>
          <div className="flex flex-col gap-3">
            <FieldLabel text="Select Data Feed Type" />
            <CustomRadio
              options={dataOptions}
              value={selectedDataType}
              setValue={setSelectedDataType}
              disable={repoName !== "" || selectedFiles?.length > 0}
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-6 items-start sm:items-center">
            <div className="flex flex-col gap-3">
              <UploadButton
                forwardRef={fileRef}
                text={"Upload files"}
                width="130px"
                handleChange={async (e) => {
                  let good = await ImageVideoFileChecker({
                    toast: toast,
                    dataType: selectedDataType,
                    selectedFile: Array.from(e.target.files),
                  });
                  if (!good) fileRef.current.value = null;
                  else {
                    if (LinkRef?.current?.value) LinkRef.current.value = "";
                    setSelectedFiles((prev) => [
                      ...Array.from(fileRef?.current?.files),
                    ]);
                  }
                }}
                disable={!selectedDataType || rtsp}
                height="fit-content"
              />
              <UploadButton
                forwardRef={folderRef}
                text="Upload folder"
                allowFolder={true}
                width="130px"
                handleChange={async (e) => {
                  let good = await ImageVideoFileChecker({
                    toast: toast,
                    dataType: selectedDataType,
                    selectedFile: Array.from(e.target.files),
                  });
                  if (!good) folderRef.current.value = null;
                  else {
                    if (LinkRef?.current?.value) LinkRef.current.value = "";
                    setSelectedFiles((prev) => [
                      ...Array.from(folderRef?.current?.files),
                    ]);
                  }
                }}
                disable={!selectedDataType || rtsp}
                height="fit-content"
              />
            </div>
            {selectedDataType === "video" && (
              <>
                <p className="text-[#AEA9B1] text-sm">or</p>
                <OutlinedInput
                  inputRef={LinkRef}
                  placeholder={
                    selectedDataType === "image"
                      ? "Aws bucket link"
                      : "Rtsp link"
                  }
                  sx={{
                    width: "300px",
                  }}
                  size="small"
                  defaultValue={rtsp}
                  disabled={
                    !selectedDataType ||
                    repoId?.length > 0 ||
                    selectedFiles?.length > 0
                  }
                />
              </>
            )}
          </div>
          <div className="flex gap-2 items-center">
            <PrimaryButton
              text={"Confirm"}
              width={"fit-content"}
              onClick={handleSubmit}
            />
            {selectedDataType === "image" || selectedFiles?.length !== 0
              ? selectedFiles?.length + " files selected"
              : selectedDataType === "video"
              ? "Link RTSP"
              : ""}
          </div>
        </div>
      </div>
      {openModal?.open && openModal?.id !== "" && (
        <RtspModal
          openModal={openModal?.open}
          closeModal={() =>
            setOpenModal((prev) => ({
              ...prev,
              open: false,
              id: "",
            }))
          }
          pollFrames={pollFrames}
          setPollFrames={setPollFrames}
          masterId={openModal?.id}
          rtsp={LinkRef?.current?.value}
          setProgressState={setProgressState}
          isExistingRepo={repoId ? true : false}
        />
      )}
      {progressState?.open && (
        <CommonProgressBar
          openModal={progressState?.open}
          closeModal={() =>
            setProgressState((prev) => ({
              ...prev,
              open: false,
              state: null,
            }))
          }
          state={progressState?.state}
        />
      )}
      {repoName === "" && (
        <CommonUniqueTextCheck
          valueKey="name"
          name="Repo name"
          inputRefValue={repoNameRef}
          endPoint="selfserve/v2/project/v1/dataRepository/"
        />
      )}
    </div>
  );
};

export default AddData;
